import { memo } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { get } from 'lodash';

import { BodyCellProps, CellRendererProps, ColumnConfig, GridBodyProps, RowProps } from '../types';
import { bem } from '../../../bem';

const { block, element } = bem('Table');

const defaultCellRenderer = ({ value }: CellRendererProps) => value;

export const nestedPathRenderer = ({ value }: CellRendererProps) => {
  return (
    <div className="NestedPath">
      {value &&
        typeof value === 'object' &&
        value.map((item, index) => (
          <div className="NestedPath__item" key={index}>
            {item}
          </div>
        ))}
    </div>
  );
};

const GridRow = ({ row, columns, onRowClick, rowModifiers }: RowProps) => {
  const handleClick = () => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const modifiers = rowModifiers ? rowModifiers(row) : {};

  return (
    <TableRow hover onClick={handleClick} {...element('row', modifiers)}>
      {columns.map((column) => (
        // TODO: Add key prop
        // eslint-disable-next-line react/jsx-key
        <GridCell column={column} row={row} />
      ))}
    </TableRow>
  );
};

const GridCell = ({ column, row }: BodyCellProps) => {
  const cellValue = column.getValue
    ? column.getValue(get(row, column.accessor))
    : get(row, column.accessor);

  const modifiers = column.type
    ? {
        [column.type]: Boolean(column.type),
      }
    : {};

  const cellRenderer = column.render || (column.nested ? nestedPathRenderer : defaultCellRenderer);

  return (
    <TableCell {...element('cell', modifiers)}>
      {cellRenderer({ value: cellValue, row, column })}
    </TableCell>
  );
};

const GridBody = ({ data, columns, onRowClick, rowModifiers }: GridBodyProps) => {
  return (
    <TableBody {...element('body')}>
      {data.map((row) => (
        // TODO: Add key prop
        // eslint-disable-next-line react/jsx-key
        <GridRow row={row} columns={columns} onRowClick={onRowClick} rowModifiers={rowModifiers} />
      ))}
    </TableBody>
  );
};

export default memo(GridBody);
