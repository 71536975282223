import './PhotoGalleryModal.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { Gallery, ReduxModal } from 'lib/ui';

const { block } = bem('PhotoGalleryModal');

export default ReduxModal({
  name: 'galleryModal',
})(class PhotoGalleryModal extends PureComponent {
  static propTypes = {

  };

  render() {
    const { photos, startFrom } = (this.props as any).data;

    return (

      <div {...block()}>
        <Gallery
          title={t('photo_uploader.gallery_title')}
          files={photos}
          startFrom={photos.indexOf(startFrom)}
        />
      </div>
    );
  }
});
