export const hexToRgb = (hex: string): [number, number, number] | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : null;
};

export const getBlurredColor = (hex: string, opacity = 0.3) => {
  return `rgba(${hexToRgb(hex)?.join(',')}, ${opacity})`;
};
