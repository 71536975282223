import { qsStringify, qsParse } from 'lib/helpers/qs';
import { isEqual } from 'lodash';
import { router } from 'config/router';

export const getLocationQuery = (location: Location) => {
  return qsParse(location.search);
};

export function updateQuery(
  routerObj: { location: { pathname: string }; query: Record<string, unknown> },
  fields = {},
  replaceRoute = true,
  replaceSearch = false,
) {
  const { location, query } = routerObj;

  const search = replaceSearch
    ? { ...fields }
    : {
        ...query,
        ...fields,
      };

  Object.keys(fields).forEach((key) => {
    const value = fields[key];

    if (value !== undefined && value !== null && value !== '') {
      search[key] = value;
    } else {
      delete search[key];
    }
  });

  if (!isEqual(query, search)) {
    const queryString = qsStringify(search);
    const newRoute = `${location.pathname}${queryString}`;
    // TODO: Unsafe solution
    // See: https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
    router.navigate(newRoute, { replace: replaceRoute });
  }
}
