import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { AsyncTreeViewSelect } from 'shared/ui/async-tree-view-select';
import { expandNode, extractTree, getGrandParents } from 'shared/lib/tree-view-helpers';
import type { TreeData, TreeNode } from 'shared/ui/async-tree-view';
import { useDebounceEffect } from 'lib/hooks/useDebounceEffect';

import { rootNode, useGetInitialSelectValues } from '../model/useGetInitialSelectValues';
import { locationsQueries } from '../api/queries';

interface Props {
  // TODO: удалить, после фикса новых Расписаний
  saveValue?: (value: string[]) => void;
  multiSelect?: boolean;
  hasStartIcon?: boolean;
  required?: boolean;
  error?: boolean;
  value?: string | number[];
  onChange?: (value: string[]) => void;
  name?: string;
  className?: string;
  // title используется в FormBuilder
  customTitle?: string;
}

export const FilterTreeViewMultiSelect = ({
  multiSelect,
  hasStartIcon,
  required,
  error,
  value,
  onChange,
  className,
  customTitle,
}: Props) => {
  const [parentNode, setParentNode] = useState<TreeNode>(rootNode);
  // Триггер, когда следует отправить запрос locationsQueries.byParentId с дефолтными значениями
  const [shouldPerformQueryForParentNode, setShouldPerformQueryForParentNode] = useState(false);
  const [treeData, setTreeData] = useState<TreeData>({});
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  // Хук useDebounceField не работает, пока костыль
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const firstRender = useRef(false);
  const [initialLocationIds, setInitialLocationIds] = useState<number[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    // чтобы работали кнопки "Сбросить" в фильтрах
    if (typeof value === 'string' && !value.length) {
      setSelectedNodes([]);
      setExpandedNodes([]);
    }

    // хак, чтобы не реагировать на последущие изменения value
    if (firstRender.current) {
      return;
    }
    // старый формат их редакс-форм со строкой айдишников
    // #TODO удалить, когда избавимся от редакс-форм
    if (value && typeof value === 'string') {
      setInitialLocationIds(value.split(',').map((i) => Number(i)));
      firstRender.current = true;
      setLoading(true);
    }
    if (Array.isArray(value) && value.length > 0) {
      setInitialLocationIds(value);
      firstRender.current = true;
      setLoading(true);
    }
  }, [value]);

  useEffect(() => {
    if (initialLocationIds.length) {
      setSelectedNodes(initialLocationIds.map((i) => i.toString()));
    }
  }, [initialLocationIds]);

  useGetInitialSelectValues(initialLocationIds, setTreeData, setExpandedNodes, setLoading);

  const locationQueryByParentIdEnabled =
    (shouldPerformQueryForParentNode || !treeData[parentNode.id]) && !search;

  const { data: locations, isSuccess } = useQuery({
    ...locationsQueries.byParentId({ parentId: parentNode.id }),
    enabled: parentNode.hasChildren && locationQueryByParentIdEnabled,
  });

  const { data: filteredLocations, isSuccess: filteredDataSuccess } = useQuery({
    ...locationsQueries.list({ search: debouncedSearch }),
    enabled: !!debouncedSearch,
  });

  useDebounceEffect(
    () => {
      setDebouncedSearch(search);
    },
    300,
    [search],
  );

  useEffect(() => {
    const nodeId = parentNode.id.toString();

    if (isSuccess && locationQueryByParentIdEnabled) {
      let children: TreeNode[] = [];
      setTreeData((prevState) => {
        const parents = [nodeId, ...getGrandParents(prevState, parentNode.parentId)];

        children = locations.map((i) => expandNode(i, parents));
        return { ...prevState, [nodeId]: { parentId: parentNode.parentId, children } };
      });
      setShouldPerformQueryForParentNode(false);

      setSelectedNodes((prevState) =>
        prevState.includes(nodeId)
          ? [...prevState, ...(children as TreeNode[]).map((i) => String(i.id))]
          : prevState,
      );

      setExpandedNodes((prevState) =>
        !prevState.includes(nodeId) ? [...prevState, nodeId] : prevState,
      );
    }
  }, [isSuccess, locations, parentNode.id, locationQueryByParentIdEnabled, parentNode.parentId]);

  useEffect(() => {
    if (search && filteredDataSuccess) {
      const tree = extractTree(filteredLocations.response.data);
      setTreeData(tree);
      setExpandedNodes([]);
    }
  }, [filteredDataSuccess, filteredLocations, search]);

  const changeSelectedNodeIds = useCallback(
    (data: string[]) => {
      setSelectedNodes(data);
      onChange?.(data);

      if (!multiSelect) setOpen(false);
    },
    [multiSelect, onChange],
  );

  const toggleNode = useCallback(
    (node: TreeNode) => {
      setParentNode(node);
      const nodeId = node.id.toString();

      setExpandedNodes((prevState) => {
        if (prevState.includes(nodeId)) {
          return prevState.filter((item) => item !== nodeId);
        }

        if (treeData[nodeId]) {
          return prevState.concat(nodeId);
        }

        return prevState;
      });
    },
    [treeData],
  );

  const handleChangeSearch = (searchValue: string) => {
    setSearch(searchValue);

    if (searchValue === '') {
      setParentNode(rootNode);
      setShouldPerformQueryForParentNode(true);
    }
  };

  return (
    <AsyncTreeViewSelect
      open={open}
      toggleSelect={setOpen}
      treeData={treeData}
      expandedNodeIds={expandedNodes}
      changeExpandedNodes={setExpandedNodes}
      selectedNodeIds={selectedNodes}
      changeSelectedNodeIds={changeSelectedNodeIds}
      multiSelect={multiSelect}
      searchable
      search={search}
      onSearchChange={handleChangeSearch}
      onToggleNode={toggleNode}
      startIcon={hasStartIcon ? 'pin' : undefined}
      error={error}
      required={required}
      loading={isLoading}
      className={className}
      title={customTitle}
    />
  );
};
