import { PureComponent } from 'react';
import { reduxForm } from 'redux-form';
import { bem } from 'lib/bem';
import { t, t_prefixed } from 'lib/i18n';
import { Button, FormBuilder } from 'lib/ui';
import users from 'app/users/users.resource';

import './CreatePassword.scss';

const { block, element } = bem('CreatePassword');
const label = t_prefixed('users.profile');

const { validators } = FormBuilder;
const maxLength64 = validators.maxLength(64);

const passwordValidators = [validators.password(), validators.minLength(6), maxLength64];

export default reduxForm({
  form: 'createPassword',

  onSubmit(data, dispatch) {
    (users as any).createPassword(data);
  },
})(
  class CreatePassword extends PureComponent {
    render() {
      return (
        <div {...block()}>
          <div {...element('title')}>{label('create_password')}</div>

          <FormBuilder.Password
            required
            name="password"
            title={label('password')}
            autoComplete="off"
            validate={passwordValidators}
          />

          <FormBuilder.Buttons left>
            <Button disabled={(this.props as any).invalid} onClick={this.handleSubmit}>
              {t('common.save')}
            </Button>
          </FormBuilder.Buttons>
        </div>
      );
    }

    handleSubmit = () => {
      (this.props as any).submit();
    };
  },
);
