import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { trim, isEmpty, isFunction } from 'lodash';
import escapeStringRegexp from 'escape-string-regexp';

import { bem } from 'lib/bem';

import NoResults from '../NoResults/NoResults';
import TreeViewNodeV2 from './TreeViewNode';
import { getTreeContext } from './TreeContext';
import NodeDragLayerV2 from './NodeDragLayer';
import './TreeView.scss';

const { block, element } = bem('TreeView');

export const TreeContext = getTreeContext();

const TreeView = ({
  filter,
  tree,
  remoteFilterFn,
  getTitle,
  onDrop,
  selectable,
  onSelect,
  draggable,
  filteredObjects,
  renderContent,
  expandedLevel,
  onInit,
  metaData,
  isDisplayFolders,
  onCheck,
}: any) => {
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [visibleNodes, setVisibleNodes] = useState({});

  const filterNodes = useCallback(
    (filterFn) => {
      const newVisibleNodes = {};

      function filterNode(node, parentMatch?: boolean) {
        if (!node) return;

        const filterMatch = filterFn(node);

        const visibleChildren = (node.children || []).filter((_node) =>
          filterNode(_node, parentMatch || filterMatch),
        );

        if (parentMatch || visibleChildren.length || filterMatch) {
          newVisibleNodes[node.id] = true;
        } else {
          delete newVisibleNodes[node.id];
        }

        return newVisibleNodes[node.id];
      }

      filterNode(tree);
      setVisibleNodes(newVisibleNodes);
    },
    [tree],
  );

  const applyFilter = useCallback(() => {
    if (isFunction(filter)) {
      filterNodes(filter);
    } else {
      const pattern = new RegExp(escapeStringRegexp(trim(filter || '')), 'i');
      filterNodes((node) => pattern.test(node.name));
    }
  }, [filter, filterNodes]);

  const [expandedNodes, setExpandedNodes] = useState({});
  const [selectedNodes, setSelectedNodes] = useState();

  const handleExpandedNodesChange = useCallback(
    (targetNode, expandState) => {
      setExpandedNodes({ ...expandedNodes, [targetNode.id]: expandState });
    },
    [expandedNodes],
  );

  const replaceExpandedNodes = useCallback((newExpandedNodes) => {
    setExpandedNodes(newExpandedNodes);
  }, []);

  const replaceSelectedNodes = useCallback((newSelectedNodes) => {
    setSelectedNodes(newSelectedNodes);
  }, []);

  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current && onInit) {
      onInit({ replaceExpandedNodes, replaceSelectedNodes });
      initialized.current = true;
    }
  }, [replaceExpandedNodes, onInit, replaceSelectedNodes]);

  useEffect(() => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    if (!remoteFilterFn) {
      applyFilter();
    } else {
      const newVisibleNodes = remoteFilterFn({ tree, filter, filteredObjects });
      setVisibleNodes(newVisibleNodes);
    }
  }, [applyFilter, filter, filteredObjects, remoteFilterFn, tree]);

  const getNodeTitle = useCallback(
    (node) => {
      if (getTitle) {
        return getTitle(node);
      }

      return node.name;
    },
    [getTitle],
  );

  const isNodeVisible = useCallback(
    (node) => {
      return !filter || visibleNodes[node.id];
    },
    [filter, visibleNodes],
  );

  const handleDrop = useCallback(
    (target, source) => {
      if (!onDrop) {
        throw new TypeError('TreeView: onDrop property should be passed for draggable component');
      }

      onDrop({
        newParent: target,
        item: source,
      });
    },
    [onDrop],
  );

  const handleDropBefore = useCallback(
    (target, source) => {
      if (!onDrop) {
        throw new TypeError('TreeView: onDrop property should be passed for draggable component');
      }

      onDrop({
        newParent: target.$parent,
        item: source,
        before: target,
      });
    },
    [onDrop],
  );

  const selectNode = useCallback(
    (node) => {
      if (selectable === false) return false;
      if (selectedNodes) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined[]' is not assignable t... Remove this comment to see the full error message
        setSelectedNodes([]);
      }

      setSelectedNode(node);

      if (onSelect) {
        onSelect(node);
      }
    },
    [onSelect, selectable, selectedNodes],
  );

  const isNodeSelected = useCallback(
    (node) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (selectedNodes && selectedNodes.length > 0) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        return selectedNodes.includes(node.id);
      }
      return selectedNode ? selectedNode.$treeNodeId === node.$treeNodeId : false;
    },
    [selectedNode, selectedNodes],
  );

  const contextValue = useMemo(
    () => ({
      getNodeTitle,
      isNodeVisible,
      handleDrop,
      handleDropBefore,
      selectNode,
      isNodeSelected,
      selectedNode,
      visibleNodes,
      renderContent,
      filter,
      expandedLevel,
      isTreeDraggable: draggable,
      selectable,
      expandedNodes,
      onToggleExpand: handleExpandedNodesChange,
      metaData,
      onCheck,
    }),
    [
      draggable,
      expandedLevel,
      expandedNodes,
      filter,
      getNodeTitle,
      handleDrop,
      handleDropBefore,
      handleExpandedNodesChange,
      isNodeSelected,
      isNodeVisible,
      metaData,
      onCheck,
      renderContent,
      selectNode,
      selectable,
      selectedNode,
      visibleNodes,
    ],
  );

  if (isEmpty(visibleNodes)) {
    return <NoResults />;
  }

  if (!tree) {
    return null;
  }

  return (
    <TreeContext.Provider value={contextValue}>
      <div {...block()}>
        {draggable && <NodeDragLayerV2 />}

        <TreeViewNodeV2
          root
          key={tree.id}
          selected={false}
          expanded
          node={tree}
          level={-1}
          isDisplayFolders={isDisplayFolders}
          onCheck={onCheck}
        />
      </div>
    </TreeContext.Provider>
  );
};

export default memo(TreeView);
