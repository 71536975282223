const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

export default function download(url) {
  // iOS devices do not support downloading. We have to inform user about this.
  // if (/(iP)/g.test(navigator.userAgent)) {
  //   alert('Your device does not support files downloading. Please try again in desktop browser.');
  //   return false;
  // }

  // If in Chrome or Safari - download via virtual link click
  if (isChrome || isSafari) {
    // Creating new link node.
    const link = document.createElement('a');
    link.href = url;

    if (link.download !== undefined) {
      // Set HTML5 download attribute. This will prevent file from opening if supported.
      const fileName = url.substring(url.lastIndexOf('/') + 1, url.length);
      link.download = fileName;
    }

    // Dispatching click event.
    if (document.createEvent) {
      const e = document.createEvent('MouseEvents');
      e.initEvent('click', true, true);
      link.dispatchEvent(e);
      return true;
    }
  }

  // Force file download (whether supported by server).
  if (url.indexOf('?') === -1) {
    url += '?download';
  }

  window.open(url, '_self');
  return true;
}
