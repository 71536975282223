/* eslint-disable camelcase */
import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { DndItem, DndItemFlat, FilteredTreeItems } from './types';

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

export function getProjection<ItemData>(
  items: DndItemFlat<ItemData>[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
  expandable?: boolean | ((item: ItemData) => boolean),
) {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({
    previousItem,
    expandable,
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }
  const isLast = (nextItem?.depth ?? -1) < depth;
  return { depth, parent_id: getParentId(), isLast };

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parent_id;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parent_id;

    return newParent ?? null;
  }
}

export function buildTree<ItemData>(flattenedItems: DndItemFlat<ItemData>[]) {
  const root = {
    id: 0,
    children: [],
  } as unknown as DndItem<ItemData>;
  const nodes = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, children: [] }));

  items.forEach((item) => {
    const { id, children } = item;
    const parent_id = item?.parent_id ?? root.id;
    const parent = nodes[parent_id] ?? findItem(items, parent_id);

    nodes[id] = { id, children };
    parent.children?.push(item);
  });

  return root.children;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findItem<T extends DndItem<any>>(items: T[], itemId: UniqueIdentifier) {
  return items.find(({ id }) => id === itemId);
}

function getMaxDepth<ItemData>({
  previousItem,
  expandable,
}: {
  previousItem?: DndItemFlat<ItemData>;
  expandable?: boolean | ((item: ItemData) => boolean);
}) {
  if (!previousItem) {
    return 0;
  }

  if (!expandable) {
    return previousItem.depth;
  }

  if (typeof expandable === 'function') {
    return previousItem.itemData && expandable(previousItem.itemData)
      ? previousItem.depth + 1
      : previousItem.depth;
  }

  return previousItem.depth + 1;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getMinDepth({ nextItem }: { nextItem: DndItemFlat<any> }) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

function flatten<ItemData>(
  items: DndItem<ItemData>[],
  filteredItemIds?: UniqueIdentifier[],
  parent_id?: UniqueIdentifier,
  depth = 0,
): DndItemFlat<ItemData>[] {
  return items.reduce<DndItemFlat<ItemData>[]>((acc, item, index) => {
    if (
      filteredItemIds !== undefined &&
      filteredItemIds?.length > 0 &&
      (!filteredItemIds.includes(item.id) ||
        !filteredItemIds.includes(item?.parent_id == null ? 0 : item.parent_id))
    ) {
      return acc;
    }

    const flattenedItem: DndItemFlat<ItemData> = {
      ...item,
      parent_id: parent_id ?? 0,
      depth,
      index,
      expanded: item.expanded || filteredItemIds?.includes(item.id),
      last: items.length === index + 1,
    };

    return acc.concat([
      flattenedItem,
      ...flatten(item.children ?? [], filteredItemIds, item.id, depth + 1),
    ]);
  }, []);
}

export function flattenTree<ItemData>(
  items?: DndItem<ItemData>[] | null,
  filteredItemIds?: UniqueIdentifier[],
): DndItemFlat<ItemData>[] {
  if (items === undefined || !items) {
    return [];
  }

  return flatten(items, filteredItemIds);
}

export function getFilteredItemIds(items?: FilteredTreeItems) {
  if (!items?.length) {
    return [];
  }

  const uniqueIds = new Set<UniqueIdentifier>();

  items.forEach((item) => {
    if (!item.id) {
      return;
    }

    uniqueIds.add(item.id);
    item.parents.forEach((parentId) => uniqueIds.add(parentId));
  });

  return Array.from(uniqueIds);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function expand<T extends DndItem<any>>(items: T[], depth: number): T[] {
  if (!items || items.length === 0) {
    return [];
  }

  if (depth === -1) {
    return items;
  }

  if (depth === 0) {
    return items.map((item) => ({ ...item, expanded: true }));
  }

  return items.map((item) => ({
    ...item,
    expanded: true,
    children: expand(item?.children || [], depth - 1),
  }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function expandByIds<T extends DndItem<any>>(
  items: T[],
  expandedIds: UniqueIdentifier[],
): T[] {
  if (!items || items.length === 0) {
    return [];
  }

  if (expandedIds.length === 0) {
    return items;
  }
  return items.map((item) => {
    if (expandedIds.includes(item.id)) {
      return { ...item, expanded: true, children: expandByIds(item?.children || [], expandedIds) };
    }
    return item;
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setProperty<T extends DndItem<any>, K extends keyof T>(
  items: T[] | null,
  id: UniqueIdentifier,
  property: K,
  setter: (value: T[K]) => T[K],
): T[] | undefined {
  return items?.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        [property]: setter(item[property]),
      };
    }

    if (item.children?.length) {
      return {
        ...item,
        children: setProperty<T, K>(item.children as T[], id, property, setter),
      };
    }

    return item;
  });
}

export function removeChildrenOf<ItemData>(
  items: DndItemFlat<ItemData>[],
  ids: UniqueIdentifier[],
) {
  const excludeParentIds = [...ids];

  return items.filter((node) => {
    if (node.parent_id && excludeParentIds.includes(node.parent_id)) {
      if (node.children?.length) {
        excludeParentIds.push(node.id);
      }
      return false;
    }

    return true;
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findNextElement<T extends DndItem<any>>(
  roots: T[] | null,
  currentId: UniqueIdentifier | null,
): T | null {
  if (roots === null || currentId === null) {
    return null;
  }
  const stack: T[] = [];

  let found = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const root of roots) {
    stack.push(root);

    while (stack.length > 0) {
      const node = stack.pop()!;

      if (found) {
        return node;
      }

      if (node.id === currentId) {
        found = true;
      }

      if (node.children && !found) {
        node.children
          .slice()
          .reverse()
          .forEach((child) => {
            stack.push(child as T);
          });
      }
    }
  }

  return null;
}
