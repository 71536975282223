import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouteInfo } from 'config/routesMap';
import { useQueryParams } from 'lib/helpers/useQueryParams';

export const withRouter = (Component, route?: RouteInfo) => {
  const ComponentWithRouterProps = (props) => {
    const { hash, pathname, search } = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { query } = useQueryParams();

    const router = useMemo(
      // For compatibility reasons
      () => ({
        location: { hash, pathname, search, query },
        navigate,
        params,
        query,
        goBack: () => navigate(-1),
      }),
      [hash, navigate, params, pathname, query, search],
    );

    return (
      <Component
        route={route}
        {...props}
        router={router}
        location={router.location}
        params={router.params}
      />
    );
  };

  return ComponentWithRouterProps;
};
