import { createQueryKeys } from '@lukemorales/query-key-factory';

import { apiClient_v3 } from 'shared/api/v3/client';

export const locationTypesQueries = createQueryKeys('locationTypes', {
  list: {
    queryKey: null,
    queryFn: () => apiClient_v3.locationTypes.getLocationTypes(),
  },
});
