import { memo, useCallback, useMemo } from 'react';
import { CellRendererProps, ColumnConfig } from 'lib/ui/Table/types';
import { Table } from 'lib/ui';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';
import { t_prefixed } from 'lib/i18n';

import renderers from '../../../../reports/ReportTable/reportCellRenderers';
import { DashboardWidget } from '../../BaseWidget';

const tr = t_prefixed('analytical_reports.widgets.issues_in_progress');

const IssuesInProgressWidget = ({ data }) => {
  const getColumnRenderer = useCallback((tableColumn) => {
    return ({ value, row, column }: CellRendererProps) => (
      <div>{(renderers[tableColumn.type] || renderers.default)(value, row, column)}</div>
    );
  }, []);

  const mapColumn = useCallback(
    (column): ColumnConfig => {
      return {
        header: column.title,
        accessor: column.name,
        type: column.type,
        render: getColumnRenderer(column),
      };
    },
    [getColumnRenderer],
  );

  const mappedColumns: ColumnConfig[] = useMemo(() => {
    return data?.columns?.map((x) => mapColumn(x));
  }, [data, mapColumn]);

  if (data && data.count === 0) {
    return <DashboardWidget title={tr('title')}>No data</DashboardWidget>;
  }

  return (
    <DashboardWidget title={tr('title')}>
      {data ? <Table columns={mappedColumns} data={data.rows} /> : <BusyIndicator />}
    </DashboardWidget>
  );
};

export default memo(IssuesInProgressWidget);
