import { api } from 'lib/api';
import { createResource } from 'lib/resource';
import { put } from 'redux-saga/effects';
import { closeModal } from 'lib/ui/Modal';

import { SAVE_SUBSTITUTION_MODAL } from './config';

function* closeOnSuccess() {
  yield put(closeModal(SAVE_SUBSTITUTION_MODAL));
}

export default createResource('substitutions', {
  scopes: {
    list: ({ payload }) => api.get('/substitutions/', { search: payload }),
  },

  mutators: {
    create: {
      request: ({ payload }) => {
        return api.post(`/users/${payload.user_id}/substitutions/create/`, {
          body: payload,
        });
      },
      onSuccess: closeOnSuccess,
    },

    update: {
      request: ({ payload }) => {
        const { id, ...data } = payload;

        return api.post(`/users/${payload.user_id}/substitutions/${id}/update/`, {
          body: data,
        });
      },
      onSuccess: closeOnSuccess,
    },

    remove({ payload }) {
      return api.post(`/users/${payload.user_id}/substitutions/${payload.id}/delete/`);
    },
  },
});
