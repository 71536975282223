import { Box } from '@mui/material';

import { getDateAsString } from 'lib/helpers/dates';
// eslint-disable-next-line import/no-cycle
import NewDesignTable from 'lib/ui/Table/NewDesignTable';
import { bem } from 'lib/bem';
import { IInventoryReportItem } from 'app/inventories/types';

import './InventotyReportTable.scss';

interface Props {
  items: Array<IInventoryReportItem>;
}

const { block, element } = bem('InventoryReportTable');

export const InventoryReportTable = ({ items }: Props) => {
  const getDataColumns = () => {
    const dates = items[0].history;
    return dates.map((item) => ({
      header: getDateAsString(item.date, 'D MMMM'),
      accessor: 'history',
      render: (props) => {
        const currentData = props.value.filter((i) => i.date === item.date);
        const { amount, change } = currentData[0];

        if (amount === null || amount === undefined) return null;

        return (
          <p {...element('cell')}>
            {amount}
            {!!change && (
              <span {...element('change', {}, change > 0 ? 'positive' : 'negative')}>{change}</span>
            )}
          </p>
        );
      },
    }));
  };
  const dataColumns = getDataColumns();

  const columns = [
    {
      header: '',
      accessor: 'name',
      render: ({ value }) => <div className={element('name-column').className}>{value}</div>,
    },
    ...dataColumns,
    {
      header: t('inventory_report.fields.current_amount'),
      accessor: 'current_amount',
      render: ({ value }) => <div>{value}</div>,
    },
  ];

  return (
    <NewDesignTable
      {...block()}
      data={items}
      columns={columns}
      fixedLeftColumn
      fixedRightColumn
      fixedHeaderAtTableTop
    />
  );
};
