import './InventoryReportFilters.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { convertDateToLocal, normalizeEndDate } from 'lib/helpers/dates';
import { Paper } from '@mui/material';
import { FormBuilder } from 'lib/ui';
import { change } from 'redux-form';
import { StocksTreeSelect } from 'app/inventories/InventotyReport/StocksTreeSelect';

const { block, element } = bem('InventoryReportFilter');

const InventoryReportFilters = ({ value }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const { created_from, created_to } = value;

    if (created_from && created_to && moment(created_from).isAfter(created_to)) {
      dispatch(change('InventoryReportForm', 'created_to', null));
    }
  }, [dispatch, value]);
  return (
    <Paper {...block()}>
      <FormBuilder.Date
        title={t('common.date_from')}
        name="date_from"
        placeholder={t('common.date_from')}
        normalize={normalizeEndDate}
        maxDate={moment()}
      />
      <FormBuilder.Date
        title={t('common.date_to')}
        name="date_to"
        placeholder={t('common.date_to')}
        normalize={normalizeEndDate}
        minDate={convertDateToLocal(value.created_from)}
        format={convertDateToLocal}
        maxDate={moment()}
      />
      <StocksTreeSelect
        {...element('stocks')}
        name="stock_id"
        title={t('inventory_report.filters.stocks')}
        forQueryForm
      />
    </Paper>
  );
};

export default InventoryReportFilters;
