import Linkify from 'linkify-react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import DOMPurify from 'dompurify';

import typograph from 'lib/typograph';
import { useParseTitle } from 'lib/hooks/useParseTitle';

interface Props {
  text: string;
  sx?: SxProps;
  className?: string;
  boldStyle?: SxProps;
  commonStyle?: SxProps;
}

const FormattedText = ({ text, sx, className, boldStyle = {}, commonStyle = {} }: Props) => {
  const [boldText, commonText] = useParseTitle(text);

  return (
    <Box sx={sx} className={className}>
      <Box
        component="span"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(typograph(boldText)) }}
        sx={{ fontWeight: 600, ...boldStyle }}
      />
      <Linkify as="p" options={{ rel: 'noopener noreferrer', target: '_blank', nl2br: true }}>
        <Box sx={commonStyle}>{commonText}</Box>
      </Linkify>
    </Box>
  );
};

export default FormattedText;
