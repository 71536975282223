function getModifier(modifiersMap = {}) {
  const result = Object.keys(modifiersMap)
    .filter((key) => modifiersMap[key])
    .join(' _');

  return result ? ` _${result}` : '';
}

export function bem(blockName) {
  return {
    block: (modifiersMap = {}, extraClassName = '') => ({
      className: `${blockName}${getModifier(modifiersMap)} ${extraClassName}`,
    }),

    element: (elementName, modifiersMap = {}, extraClassName = '') => ({
      className: `${blockName}__${elementName}${getModifier(modifiersMap)} ${extraClassName}`,
    }),
  };
}
