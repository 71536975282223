import { confirmable } from 'react-confirm';

import Modal from './ModalComponent';
import { createConfirmation } from './modalMounter';

/**
 * HOC, создающий модальное окно, вызываемое в виде функции,
 * и возвращающее Promise.
 *
 * Удобно для случаев, когда модальное окно не имеет своего
 * собственного состояния и используется как промежуточный шаг
 * для выполнения некоторых действий.
 *
 * @example
 *   const verifyModalNumber = processModal({
 *     getTitle: () => 'Verify Mobile Number'
 *   })(class extends Component {
 *     render () {
 *       return (
 *         <div>
 *           <CodeInput onChange={this.handleInput} value={this.state.codeValue}/>
 *           <Button onClick={props.dismiss}>Cancel</Button>
 *           <Button onClick={e => props.proceed(this.state.codeValue)}>OK</Button>
 *         </div>
 *       );
 *     }
 *   })
 *
 *   verifyMobileNumber({
 *     number: mobileNumber
 *   }).then(save)
 *     .catch(showError);
 *
 *
 * @param {Object} defaultOptions
 * @return {function} Функция, открывающая модальное окно
 */
export default function processModal(
  defaultOptions: any = {
    getTitle: () => null,
  },
) {
  return (ModalBody) =>
    (options = {}) =>
      createConfirmation(
        confirmable<any, any>((props) => {
          // Стандартные свойства confirmable
          const { proceed, dismiss, proceedText, dismissText, show } = props;
          // Свойства, передаваемые при открытии попапа
          const { getTitle, data, className = '', modalProps } = props.options;
          return (
            <Modal
              component={ModalBody}
              className={`_processModal ${className}`}
              {...{
                proceed,
                dismiss,
                proceedText,
                dismissText,
                data,
                modalProps,
              }}
              onClose={dismiss}
              isOpened={show}
              getTitle={getTitle}
            />
          );
        }),
      )({ options: { ...defaultOptions, ...options } });
}
