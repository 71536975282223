import { useCallback, useEffect, useRef } from 'react';
import {
  NotificationsObserver,
  NotificationMessage,
  NotificationSubscriber,
} from 'features/notify';

export const useNotifications = (subscriber?: NotificationSubscriber) => {
  const subscriberRef = useRef(subscriber ?? (() => {}));

  const showNotification = useCallback((notification: NotificationMessage) => {
    NotificationsObserver.addNotification(notification);
  }, []);

  const closeNotification = useCallback((notificationId: string) => {
    NotificationsObserver.removeNotification(notificationId);
  }, []);

  useEffect(() => {
    const unsubscribe = NotificationsObserver.subscribe(subscriberRef.current);

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (subscriber) {
      subscriberRef.current = subscriber;
    }
  }, [subscriber]);

  return { showNotification, closeNotification };
};
