import Label from 'lib/ui/Label';
import { bem } from 'lib/bem';
import type { Node } from 'shared/lib/tree-view-helpers';

import './AsyncTreeViewLabels.scss';

const { block } = bem('LabelsWrapper');

interface AsyncTreeViewLabelProps {
  visibleLabels: Node[];
  onRemove: (id: number, parentIds?: string[]) => void;
}

export const AsyncTreeViewLabels = ({ visibleLabels, onRemove }: AsyncTreeViewLabelProps) => {
  return (
    <div {...block()}>
      {visibleLabels.map((node) => (
        <Label removable onRemove={() => onRemove(node.id, node.parentIds)} key={node.id}>
          {node.fullName ?? node.name}
        </Label>
      ))}
    </div>
  );
};
