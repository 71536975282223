import users from 'app/users/users.resource';
import { t } from 'lib/i18n';
import { FormBuilder } from 'lib/ui';

const RoundReportsAuthorSelect = (props) => {
  const connectResource = (state, search, page) => {
    return (users as any).list(state, {
      rounds_authors_only: 't',
      search,
      offset: page * 20,
    });
  };

  const getItemValue = (user) => {
    if (typeof user !== 'object') return user;
    return user && user.id;
  };

  const getLabel = (user) => user && user.full_name;

  const normalizeUser = (item) => item && item.id;

  return (
    <FormBuilder.Select
      {...props}
      name="author_id"
      searchable
      clearable
      title={t('round_reports.filters.author')}
      connectResource={connectResource}
      getItemValue={getItemValue}
      getItemLabel={getLabel}
      normalize={normalizeUser}
    />
  );
};

export default RoundReportsAuthorSelect;
