import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';

import { Select } from 'shared/ui/select';

import { locationTypesQueries } from '../api/queries';

interface LocationTypesSelectProps {
  name?: string;
  title?: string;
  className?: string;
}

export const LocationTypesSelect = ({ name, title, className }: LocationTypesSelectProps) => {
  const { control } = useFormContext();

  const { data: locationTypes, isLoading } = useQuery(locationTypesQueries.list);

  const locTypes = useMemo(
    () => locationTypes?.response.data?.map((i) => ({ label: i.name_ru, id: i.id })) ?? [],
    [locationTypes?.response.data],
  );

  return (
    <Controller
      name={name ?? 'location_types'}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Select
          multiple
          value={value ? locTypes.filter((i) => value?.includes(i.id)) : []}
          options={locTypes}
          onChange={onChange}
          className={className}
          title={title}
          isLoading={isLoading}
        />
      )}
    />
  );
};
