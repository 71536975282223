import { useCallback, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Box } from '@mui/material';

import { reconnect } from 'lib/resource';
import { PageLoader } from 'lib/ui';
import { Notifications } from 'app/notifications';
import { brandName } from 'config/constants';
import users from 'app/users/users.resource';
import { router } from 'config/router';
import { refreshFcmToken } from 'lib/helpers/firebase/firebase';
import { AUTO_RELOAD_INTERVAL_MS, MS_PER_MIN } from 'lib/constants/units';
import { Roles } from 'app/userRoles/roles';
import device from 'lib/device';

import AppHead from './head/AppHead';

const app = reconnect((state) => ({
  currentUser: (users as any).current(state, {}, { skipFetchState: false }),
}))(({ currentUser, dispatch }) => {
  const handlePreventDefault = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    window.addEventListener('dragover', handlePreventDefault, false);
    window.addEventListener('drop', handlePreventDefault, false);
    return () => {
      window.removeEventListener('dragover', handlePreventDefault);
      window.removeEventListener('drop', handlePreventDefault);
    };
  }, [handlePreventDefault]);

  useEffect(() => {
    if (!device.is.desktop) {
      const rootElement = document.getElementById('main');
      if (rootElement) {
        const viewPortH = rootElement.getBoundingClientRect().height;
        const windowH = window.innerHeight;
        const browserUiBarsH = viewPortH - windowH;
        // rootElement.style.height = `calc(100vh - ${Math.abs(browserUiBarsH)}px)`;
      }
    }
  }, []);

  useEffect(() => {
    if (
      currentUser.data &&
      !currentUser.data.isGuest &&
      (currentUser.data?.roles?.includes(Roles.RESPONSIBLE) ||
        currentUser.data?.roles?.includes(Roles.EXECUTIVE) ||
        currentUser.data?.roles?.includes(Roles.BOSS) ||
        currentUser.data?.roles?.includes(Roles.MANAGER) ||
        currentUser.data?.roles?.includes(Roles.WATCHER))
    ) {
      const getIssuesStats = () => {
        dispatch({
          type: 'ISSUES/LISTS_STATS',
        });
      };
      getIssuesStats();
      const intervalGetIssuesStats = setInterval(
        getIssuesStats,
        AUTO_RELOAD_INTERVAL_MS * MS_PER_MIN,
      );

      return () => {
        clearInterval(intervalGetIssuesStats);
      };
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (currentUser.data && !currentUser.data.isGuest) {
      refreshFcmToken();
    }
  }, [currentUser.data]);

  return (
    <>
      <AppHead brand={brandName} />
      {/* <AppBanner /> */}
      <PageLoader />
      <Notifications />
      <RouterProvider router={router} />
    </>
  );
});

export default app;
