import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

// eslint-disable-next-line import/no-cycle
import users from 'app/users/users.resource';
import confirm from 'lib/ui/Modal/Confirm';
import { api } from 'lib/api';
import { reconnect } from 'lib/resource';
import { getLocalStorageItem, saveLocalStorageItem } from 'lib/helpers/localStorage';
import { handleAuthTokenRefresh } from 'shared/api/utils';

const MiniApp = reconnect((state) => ({
  currentUser: (users as any).current(state, {}),
}))((props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [codeInfo, setCodeInfo] = useState();
  const { location: routerLocation } = props;

  const addAnonymous = useCallback(() => {
    if (!getLocalStorageItem('deviceId')) {
      saveLocalStorageItem('deviceId', uuidv4());
    }
    const deviceId = getLocalStorageItem('deviceId');
    return api
      .post('/v3/auth/anonymous_login/', { body: { device_id: deviceId } })
      .then(handleAuthTokenRefresh);
  }, []);

  const fetchCodeInfo = useCallback(() => {
    return api.get(`/v2/qrcodes/${routerLocation.query.code}`);
  }, [routerLocation.query.code]);

  useEffect(() => {
    (async () => {
      try {
        if (props.currentUser.data.isGuest) {
          await addAnonymous();
        }
        const { response, error } = await fetchCodeInfo();
        if (!response) {
          if (error.code === 212 && !props.currentUser.data) {
            navigate(
              `/quick_login?redirect=${encodeURIComponent(`/?code=${routerLocation.query.code}`)}`,
              { replace: true },
            );
          } else {
            confirm(error.message, {
              alarmModal: true,
              proceedText: t('common.ok'),
            }).then(() => {
              navigate('/', { replace: true });
            });
          }
          localStorage.removeItem('authToken');
          Cookies.remove('refresh-token');
        } else {
          setCodeInfo(response);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    dispatch,
    fetchCodeInfo,
    props.currentUser.data,
    routerLocation.query.code,
    navigate,
    addAnonymous,
  ]);

  useEffect(() => {
    const { currentUser } = props;
    if (codeInfo) {
      const { type, location } = codeInfo as any;
      if (type === 'location' && location) {
        if (!currentUser.data.isGuest) {
          navigate(`/?location=${location.id}`, { replace: true });
        } else {
          navigate(`/loc/${location.id}`, { replace: true });
        }
      } else if (type === 'booking_item') {
        const { booking_item } = codeInfo as any;
        navigate(`/locations/${booking_item.related_managed_object_id}/bookings/new`, {
          replace: true,
        });
      } else {
        navigate('/login', { replace: true });
      }
    }
  }, [codeInfo, props, navigate]);

  return null;
});

export default memo(MiniApp);
