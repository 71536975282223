import { useDrop } from 'react-dnd';

function isSameItem(nodeA, nodeB) {
  return nodeA && nodeB && nodeA.id === nodeB.id;
}

export const useDropTargetNode = ({ node, onDrop }) => {
  return useDrop(() => ({
    accept: 'node',
    drop: (item, monitor) => {
      if (isSameItem(node, item)) {
        return;
      }
      if (onDrop) {
        onDrop(node, item);
      }
    },
    collect: ((monitor) => ({
      isOver: monitor.isOver(),
    })),
  }));
};
