import brandedData from 'config/brandedData';

import ru from './ru.json';
import en from './en.json';
import da from './da.json';

export const defaultNS = 'translation';
export const fallbackLng = brandedData.defaultLang;

export const resources = {
  ru,
  en,
  da,
};
