import { TOptions } from 'i18next';
import { t } from 'lib/i18n';

import notify from './notify';

export { default as notificationsReducer } from './notifications.reducer';
export { notificationsSaga } from './notifications.saga';
export { default as notify } from './notify';
export { Notifications } from './Notifications/Notifications';

export const contextNotification =
  (context) =>
  (label, translationOptions: TOptions = {}, notifyOptions = {}) => {
    const translationKey = t(`${context}.notifications.${label}`, {
      // TODO: #i18n Add translation key type check
      defaultValue: `${context}.notifications.${label}`,
      ...translationOptions,
    });

    const message = { id: translationKey, text: translationKey };
    if ((notifyOptions as any).error) {
      (message as any).type = 'error';
    }

    return notify(message, notifyOptions);
  };
