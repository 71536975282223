import { FC, lazy, LazyExoticComponent, RefObject, Suspense, SVGProps, useMemo } from 'react';
import { SvgIcon, SvgIconProps, IconButton, IconButtonProps, Skeleton } from '@mui/material';

import brandedData from 'config/brandedData';

import { GlyphName } from './glyphs-type';
import './Icon.scss';

type IconProps = SvgIconProps & {
  glyph: keyof typeof ICONS;
  fullSize?: boolean;
};

type CSSVars = { [key: `--${string}`]: string | undefined };

const DEFAULT_ICON_SIZE = 20;

const { logo, shortLogo } = brandedData;

const WithLazyIconWrapper = (LazyIcon: LazyExoticComponent<FC<SVGProps<SVGSVGElement>>>) => {
  const LazyIconWrapper = (
    props: JSX.IntrinsicAttributes &
      Omit<SVGProps<SVGSVGElement>, 'ref'> & {
        ref?:
          | ((instance: SVGSVGElement | null) => void)
          | RefObject<SVGSVGElement>
          | null
          | undefined;
      },
  ) => {
    const { className, style } = props;
    return (
      <Suspense
        fallback={
          <div className={className} style={style}>
            <Skeleton variant="rounded" width="100%" height="100%" />
          </div>
        }
      >
        <LazyIcon {...props} />
      </Suspense>
    );
  };

  return LazyIconWrapper;
};

// 'lazy-once' mode - все иконки соберутся в один бандл, который будет загружен один раз при первом рендере любой иконки из бандла
const svgIcons = require.context('./glyphs/', false, /.*\.svg$/, 'lazy-once');

function requireAll(requireContext: __WebpackModuleApi.RequireContext) {
  return requireContext.keys().reduce((acc, svgFileName) => {
    const glyph = svgFileName.replace(/^.*[\\/]/, '').replace('.svg', '');
    const IconComponent = WithLazyIconWrapper(
      lazy(() =>
        requireContext(svgFileName).then(({ ReactComponent }) => ({ default: ReactComponent })),
      ),
    );
    return { ...acc, [glyph]: IconComponent };
  }, {}) as Record<GlyphName, FC<SVGProps<SVGSVGElement>>>;
}

const ICONS = {
  ...requireAll(svgIcons),
  textLogo: WithLazyIconWrapper(logo),
  shortLogo: shortLogo && WithLazyIconWrapper(shortLogo),
} as const;

const getIconClassName = (className?: string) => (className ? `${className} icon` : 'icon');

const getIconCSSVars = ({
  height = DEFAULT_ICON_SIZE,
  width = DEFAULT_ICON_SIZE,
  fullSize,
}: Pick<IconProps, 'width' | 'height' | 'fullSize'>): CSSVars =>
  fullSize
    ? {
        '--icon-width': '100%',
        '--icon-height': '100%',
      }
    : {
        '--icon-width': typeof width === 'string' ? width : `${width}px`,
        '--icon-height': typeof height === 'string' ? height : `${height}px`,
      };

export const Icon = ({ glyph, width, height, fullSize, className, ...props }: IconProps) => {
  const style = useMemo(
    () => getIconCSSVars({ width, height, fullSize }),
    [fullSize, height, width],
  );

  return (
    <SvgIcon
      component={ICONS[glyph]}
      style={style}
      className={getIconClassName(className)}
      inheritViewBox
      {...props}
    />
  );
};

// та же svg иконка, но с аттрибутами button
export const IconAsButton = ({
  glyph,
  className,
  width,
  height,
  fullSize,
  ...props
}: IconButtonProps & Pick<IconProps, 'glyph' | 'width' | 'height' | 'fullSize'>) => {
  const style = useMemo(
    () => getIconCSSVars({ width, height, fullSize }),
    [fullSize, height, width],
  );

  return (
    <IconButton
      className={getIconClassName(className)}
      style={style}
      component={ICONS[glyph]}
      {...props}
    />
  );
};
