import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { FileInput } from 'lib/ui';
import { useUploadChildren } from 'entities/locations';

import './LocationsFileUploader.scss';

const { block } = bem('LocationsFileUploader');

type Props = {
  parentId: number;
};

export const LocationsFileUploader = ({ parentId }: Props) => {
  const uploadChildren = useUploadChildren();
  const handleFilesSelect = (files) => {
    uploadChildren.mutate({
      id: parentId,
      file: files[0],
    });
  };

  return (
    <div {...block()}>
      <FileInput
        small
        multiple={false}
        maxCount={1}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        onSelect={handleFilesSelect}
        showFiles={false}
        showIcon={false}
        buttonText={t('locations.pages.edit_locations.upload_file')}
        accept=".xls,.xlsx,.csv"
      />
    </div>
  );
};
