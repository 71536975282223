import './DictEditorFilter.scss';
import { bem } from 'lib/bem';
import queryForm from 'lib/queryForm';
import { FormBuilder } from 'lib/ui';

const { block } = bem('DictEditorFilter');

interface Props {
  filterable: boolean;
  pagination: boolean;
  total: number;
}

export default queryForm({
  form: 'dictEditorFilter',
})(({ filterable, pagination, total }:Props) => {
  return (
    <div {...block()}>
      {filterable && (<FormBuilder.Text clearable name="search" icon="search" />)}

      {pagination && (<FormBuilder.Pagination total={total} perPage={20} />)}
    </div>
  );
});
