export enum BookingStatus {
  AVAILABLE = 'available',
  BOOKED = 'booked',
  UNAVAILABLE = 'unavailable',
  EMPTY_SLOTS = 'empty_slots',
}

export interface Slot {
  end: string;
  start: string;
  status: BookingStatus;
  id: string;
  title: string;
}

export interface DayInfo {
  date: string;
  slots: Slot[];
  status?: BookingStatus;
}

export interface SlotsFieldProps {
  data: {
    value?: DayInfo[] | null;
    data: {
      options: {byDays: boolean};
    };
  };
  input: {
    value: DayInfo[];
    onChange: (value: DayInfo[]) => void;
  };
}

export enum DayBusyness {
  LOW = 'low',
  AVERAGE = 'average',
  HIGH = 'high',
}
