import { api } from 'lib/api';
import { createResource } from 'lib/resource';

function normalizeResponse(json) {
  return {
    ...json,
    response: json.response.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    })),
  };
}

export default createResource('dicts', {
  scopes: {
    slas() {
      return api.get('slas')
        .then(normalizeResponse);
    },

    attributes({ payload }) {
      return api.get('/v2/issuetypes/attributes/', {
        search: { applied_to: payload.metatype },
      }).then(normalizeResponse);
    },
  },
});
