import { getLocalStorageItem, saveLocalStorageItem } from 'lib/helpers/localStorage';

import { UserModel } from './types';

interface GetOptions {
  user: UserModel;
  key?: string;
}

interface SetOptions {
  user: UserModel;
  key: string;
  value: unknown;
}

const generateKey = (user: UserModel) => `user-${user.id}`;

export const userLocalStorage = {
  get: <T>({ user, key }: GetOptions): T | null => {
    const found = getLocalStorageItem(generateKey(user));
    if (found && key) {
      return found[key];
    }
    return found;
  },
  set: ({ user, key, value }: SetOptions) => {
    const storageKey = generateKey(user);
    const found = getLocalStorageItem(storageKey);
    if (found) {
      found[key] = value;
      saveLocalStorageItem(storageKey, found);
    } else {
      saveLocalStorageItem(storageKey, { [key]: value });
    }
  },
};
