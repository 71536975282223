import { PureComponent } from 'react';
import { keyBy } from 'lodash';
import { openModal } from 'lib/ui/Modal';
import { objectResourcePropType } from 'lib/resource';
import { t_prefixed } from 'lib/i18n';
import './InventoryAudit.scss';

const { block, element } = bem('InventoryAudit');
const label = t_prefixed('inventories.audit');

type InventoryAuditProps = {
    // @ts-expect-error ts-migrate(2749) FIXME: 'objectResourcePropType' refers to a value, but is... Remove this comment to see the full error message
    inventoryAudit: objectResourcePropType;
};

export default class InventoryAudit extends PureComponent<InventoryAuditProps> {
  get inventoryAudit() {
    return this.props.inventoryAudit.data;
  }

  get items() {
    const { expected_items, found_items } = this.inventoryAudit;
    const foundItemsIndex = keyBy(found_items, 'id');

    return expected_items.map((item) => ({
      ...item,
      found: !!foundItemsIndex[item.id],
    }));
  }

  get unexpectedItems() {
    const { expected_items, found_items } = this.inventoryAudit;
    const expectedItemsIndex = keyBy(expected_items, 'id');

    return found_items.filter((item) => !expectedItemsIndex[item.id]);
  }

  render() {
    if (!this.inventoryAudit) return null;

    return (
      <div {...block()}>
        {this.items.length === 0
          && <div {...element('noItems')}>{label('no_items')}</div>}

        <div {...element('expectedItems')}>
          {this.items.map((item) => this.renderItem(item, item.found))}
        </div>

        {this.unexpectedItems.length > 0 && (
          <div {...element('unexpectedItems')}>
            <div {...element('unexpectedItemsTitle')}>
              {label('unexpected_items')}
            </div>
            <div {...element('unexpectedItemsList')}>
              {this.unexpectedItems.map(this.renderItem)}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderItem = (item, found) => (
    <div {...element('item')} key={item.id}>
      <div {...element('itemType')} onClick={() => this.handleOpenInventory(item)}>
        {item.type.name}
      </div>
      <div {...element('itemStatus')}>
        {found === true && label('found')}
        {found === false && label('not_found')}
      </div>
    </div>
  );

  handleOpenInventory = (item) => {
    (this.props as any).dispatch(openModal('inventory', item.id));
  };
}
