import { Box } from '@mui/system';

import { IssueType } from 'app/issueTypes/types';
import { Icon } from 'lib/ui/icon';

interface Props {
  issueTypesList: IssueType[];
  onChange: any;
}

const renderItems = (list: IssueType[], onClick: any) => {
  return list.map((item) => {
    return (
      <Box
        key={item.id}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '13px 16px',
          backgroundColor: '#FAFBFC',
          borderRadius: '10px',
          border: '0.5px solid #E0E2E6',
          fontFamily: 'robotoregular',
          fontSize: '17px',
          lineHeight: '22px',
          '&:hover': {
            cursor: 'pointer',
            border: '0.5px solid #d3d3d3',
          },
        }}
        onClick={() => onClick(item)}
      >
        {item.name}
        <Icon
          glyph="squareArrow"
          sx={{ transform: 'rotate(-90deg)', width: '22px', height: '22px' }}
        />
      </Box>
    );
  });
};

const FlatList = ({ issueTypesList, onChange }: Props) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {renderItems(issueTypesList, onChange)}
    </Box>
  );
};

export default FlatList;
