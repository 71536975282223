import picaInit from 'pica';

const pica = picaInit();

function createSourceImage(imageUrl) {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.src = imageUrl;
  });
}

function createDestCanvas(image, maxDimensionSize) {
  const canvas = document.createElement('canvas');
  const aspectRatio = image.width / image.height;

  if (image.width > image.height) {
    canvas.width = Math.min(maxDimensionSize, image.width);
    canvas.height = canvas.width / aspectRatio;
  } else {
    canvas.height = Math.min(maxDimensionSize, image.height);
    canvas.width = canvas.height * aspectRatio;
  }

  return canvas;
}

export default function resize(imageDataUrl, maxDimensionSize) {
  return createSourceImage(imageDataUrl)
    .then((image) => pica.resize(image, createDestCanvas(image, maxDimensionSize)))
    .then((result) => pica.toBlob(result, 'image/jpeg'))
    .then(
      (resultBlob) =>
        new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.onload = (e) => resolve(e.target?.result);
          fileReader.readAsArrayBuffer(resultBlob);
        }),
    );
}
