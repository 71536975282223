import './DocumentUploader.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { t, t_prefixed } from 'lib/i18n';
import FileUploader from 'lib/ui/FileUploader';
import DocumentPreview from '../DocumentPreview';

const { block, element } = bem('DocumentUploader');

export default class DocumentUploader extends PureComponent {
  static PropTypes = {
  };

  static defaultProps = {
  };

  render() {
    const getLabel = t_prefixed('document_uploader');

    return (
      <FileUploader
        {...block()}
        {...this.props}
        getLabel={getLabel}
        withResizing={false}
        renderFilePreview={this.renderFilePreview}
      />
    );
  }

  renderFilePreview(file, onRemove) {
    return (
      <DocumentPreview
        {...element('document')}
        key={file.md5}
        id={file.id}
        url={file.url}
        name={file.name}
        type={file.type}
        savable={false}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        status={file.processingStatus}
        removable
        onRemove={onRemove}
      />
    );
  }
}
