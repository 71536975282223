import { memo, useCallback, useRef } from 'react';
import { useDragLayer } from 'react-dnd';
import { memoize } from 'lodash';

const layerStyles = {
  position: 'absolute',
  pointerEvents: 'none',
  zIndex: 1000,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
};

const NodeDragLayer = () => {
  const layer = useRef<any>(null);

  const getLayerOffset = memoize(
    () => layer.current?.getBoundingClientRect(),
    () => Date.now() / 1000,
  );

  const { item, isDragging, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const getItemStyles = useCallback(() => {
    if (!initialOffset || !currentOffset || !layer.current) {
      return {
        display: 'none',
      };
    }

    const layerOffset = getLayerOffset();
    const y = currentOffset.y - layerOffset.top;
    const x = currentOffset.x - layerOffset.left;

    const transform = `translate(${x}px, ${y}px)`;

    return {
      transform,
      WebkitTransform: transform,
    };
  }, [currentOffset, getLayerOffset, initialOffset]);

  if (!isDragging) return null;

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: string; pointerEvents: string; z... Remove this comment to see the full error message
    <div style={layerStyles} ref={layer}>
      <div className="TreeView__dragPreview" style={getItemStyles()}>
        {item.name}
      </div>
    </div>
  );
};

export default memo(NodeDragLayer);
