import { all, call, put } from 'redux-saga/effects';

import { takeMap } from 'lib/saga-utils';
import { contextNotification } from 'app/notifications';
import { issueTypes } from 'app/issueTypes';

const notify = contextNotification('issue_types');

export function* issueTypesSaga() {
  yield all([
    call(issueTypes.saga),

    call(takeMap({
      'ISSUETYPES/MOVE_SUCCESS': function* () {
        yield put(notify('moved'));
      },

      'ISSUETYPES/UPDATE_SUCCESS': function* () {
        yield put(notify('updated'));
      },

      'ISSUETYPES/CREATE_SUCCESS': function* () {
        yield put(notify('created'));
      },

      'ISSUETYPES/REMOVE_SUCCESS': function* () {
        yield put(notify('removed'));
      },
    })),
  ]);
}
