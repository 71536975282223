import { useDrag } from 'react-dnd';

export const useDragSourceNode = ({ node }) => {
  return useDrag(() => ({
    type: 'node',
    item: () => node,
    collect: ((monitor) => ({
      isDragging: monitor.isDragging(),
    })),
  }));
};
