import i18n from 'lib/i18n/i18n';
import brandedData from 'config/brandedData';

import Tel from './Tel';
import InternationalTel from './InternationalTel';
import InternationalTelOld from './InternationalTelOld';

export const TelOld = brandedData.defaultLang === 'ru' ? Tel : InternationalTelOld;
const TelComponent = InternationalTel;
export default TelComponent;
