import './DownloadButton.scss';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import device from 'lib/device';
import { Button, Loader } from 'lib/ui';
import { notify } from 'app/notifications';

import download from './download';

const { block } = bem('DownloadButton');

class DownloadButton extends PureComponent {
  static propTypes = {
    getDownloadData: PropTypes.func.isRequired,
    text: PropTypes.string,
    basePath: PropTypes.string.isRequired,
    small: PropTypes.bool,
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    small: true,
    text: t('common.download'),
  };

  state = {
    isDownloading: false,
  };

  render() {
    if (!device.is.desktop) return null;

    return (
      <Button
        small={(this.props as any).small}
        {...block({
          loading: this.state.isDownloading,
          alignEnd: (this.props as any).alignEnd,
        })}
        onClick={this.handleDownload}
      >
        <span>{(this.props as any).text}</span>

        <Loader />
      </Button>
    );
  }

  handleDownload = () => {
    this.setState({ isDownloading: true });

    const { getDownloadData, basePath, errorMessage, dispatch } = this.props as any;
    getDownloadData().then(({ response }) => {
      this.setState({ isDownloading: false });

      if (response && response.name) {
        download(`${basePath}${response.name}`);
      } else {
        if (!errorMessage) return;

        dispatch(
          notify({
            id: 'DOWNLOAD_ERROR',
            text: errorMessage,
            type: 'error',
          }),
        );
      }
    });
  };
}

export default connect()(DownloadButton as any);
