import { forwardRef, InputHTMLAttributes } from 'react';

import { Icon } from 'lib/ui/icon';
import { bem } from 'lib/bem';

import './search-input.scss';

const { block, element } = bem('SearchInput');

export const SearchInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
  function SearchInputComponent(props, ref) {
    return (
      <div className={block().className}>
        <input ref={ref} {...props} />
        <div className={element('icon-container').className}>
          <Icon glyph="search" />
        </div>
      </div>
    );
  },
);
