import { api } from 'lib/api';

function responseAsDict(json) {
  return json.response.map((sla) => ({
    value: sla.id,
    label: sla.name,
  }));
}

export function getSlas() {
  return api.get('slas').then(responseAsDict);
}

export function getAttributesDict({ metatype }) {
  return api
    .get('/v2/issuetypes/attributes/', {
      search: { applied_to: metatype },
    })
    .then(responseAsDict);
}

export const sendFcmToken = (token: string) => {
  return api.post('/v3/add_push_token/', {
    body: {
      token,
      client: 'fcm',
    },
  });
};
