import './MonthName.scss';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';

const { block } = bem('MonthName');

export default function MonthName({ className, date, ...props }) {
  return (
    <span {...block({}, className)}>
      {/* TODO: #i18n Add translation key type check */}
      {t(`dates.month_names.${date.month()}`, date.month())}
    </span>
  );
}
