import './FieldValue.scss';
import { bem } from 'lib/bem';

const { block, element } = bem('FieldValue');

interface Props {
  name?: any;
  children?: any;
  className?: any;
  valueClassName?: any;
}

export function FieldValue({
  name, children, className, valueClassName,
}: Props) {
  return (
    <div {...block({}, className)}>
      <div {...element('name')}>{name}</div>
      <div {...element('value', {}, valueClassName)}>{children}</div>
    </div>
  );
}
