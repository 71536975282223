import { TFuncKey, TFunction, CustomTypeOptions } from 'i18next';

import i18n from './i18n';

export const { t } = i18n;

export const t_prefixed = <KeyPrefix extends TFuncKey>(prefix: KeyPrefix) =>
  ((...[key, ...args]: Parameters<TFunction<CustomTypeOptions['defaultNS'][], KeyPrefix>>) =>
    t(
      Array.isArray(key) ? key.map((_key) => `${prefix}.${_key}`) : `${prefix}.${key}`,
      ...args,
    )) as TFunction<CustomTypeOptions['defaultNS'][], KeyPrefix>;
