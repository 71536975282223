import { useCallback, useState } from 'react';
import { getCaptcha } from 'app/captcha/captcha.api';
import { useMounting } from 'lib/hooks/useMounting';
import { processModal } from 'lib/ui';
import CaptchaForm from 'app/captcha/CaptchaForm';

export const useCaptchaModal = (): any => {
  const [captchaDisabled, setCaptchaDisabled] = useState(true);

  const init = useCallback(() => {
    getCaptcha().then(({ httpStatusCode, error }) => {
      setCaptchaDisabled(httpStatusCode === 421 && error && error.code === 2002);
    });
  }, []);

  useMounting(init);

  return useCallback(async () => {
    if (captchaDisabled) {
      return {};
    }
    return processModal({
      getTitle: () => '',
      className: 'captchaModal',
      modalProps: {
        width: 'unset',
      },
    })(CaptchaForm)();
  }, [captchaDisabled]);
};
