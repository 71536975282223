import './page-loader.scss';
import { PureComponent } from 'react';
import { getClasses } from 'lib/react-utils';
import { connect } from 'react-redux';

export default connect((state) => ({
  loading: (state as any).loading > 0,
}))(class PageLoader extends PureComponent {
    loadingState: any;

    UNSAFE_componentWillReceiveProps(nextProps) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
      if (nextProps.loading && !this.props.loading) {
        this.loadingState = 'progress';
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
      else if (!nextProps.loading && this.props.loading) {
        this.loadingState = 'finished';
      }
    }

    render() {
      return (
        <div className="page-loader">
          <div className={getClasses({
            'page-loader__fill': true,
            _progress: this.loadingState === 'progress',
            _finished: this.loadingState === 'finished',
          })}
          />
        </div>
      );
    }
});
