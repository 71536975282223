import { memo } from 'react';
import { bem } from '../../bem';
import './NoResults.scss';

const { block } = bem('NoResults');

const NoResults = () => {
  return (
    <div {...block()}>
      {t('common.no_results')}
    </div>
  );
};

export default memo(NoResults);
