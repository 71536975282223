import { PropsWithChildren } from 'react';

import { bem } from 'lib/bem';

import './PageActions.scss';

const { block } = bem('PageActions');

export const PageActions = ({ children }: PropsWithChildren) => {
  return <div {...block()}>{children}</div>;
};
