import './SquareBlock.scss';
import { bem } from 'lib/bem';

const { block, element } = bem('SquareBlock');

/**
 * Рендерит блок, сохраняющий квадратные пропорции
 * при любом способе задания ширины.
 *
 * @param {*} param0
 */
export default function SquareBlock({ className, children, ...props }) {
  return (
    <div {...block({}, className)} {...props}>
      <div {...element('content')}>
        {children}
      </div>
    </div>
  );
}
