import { ReactNode, useCallback, useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { getFormValues as getReduxFormValues } from 'redux-form';
import { isEqual } from 'lodash';

// eslint-disable-next-line import/no-cycle
import { reconnect } from 'lib/resource';
import { Button, confirm, FormBuilder, List, ModalLink, Page, PageActions } from 'lib/ui';
import { DraggableItem } from 'lib/ui/DraggableItem';
import { usePrevious } from 'lib/hooks/usePrevious';
import { ScopeSelectorData } from 'lib/resource/types';
import { t_prefixed } from 'lib/i18n';

import DictEditorForm from './DictEditorForm';
import DictEditorFilter from './DictEditorFilter';
import './DictEditor.scss';
import { RootState } from '../../../config/redux';

const { block, element } = bem('DictEditor');
const filtersSelector = getReduxFormValues('dictEditorFilter');
const label = t_prefixed('dictEditor');

export interface DictEditorProps<T = any> {
  connectResource: (state: RootState, options?: Record<string, any>) => ScopeSelectorData<T>;
  connectItemResource?: (state: RootState, id: string | number) => ScopeSelectorData<T>;
  onAddItem?: (createDto: T) => any;
  onUpdateItem?: (updateDto: Partial<T>, foundItem: T) => any;
  onRemoveItem?: (removeDto: T) => any;
  onSortEnd?: (sortData: { oldIndex: number; newIndex: number; beforeId: number; item: T }) => any;
  renderFormFields?: (item: T, props: DictEditorProps) => ReactNode;
  renderItemExtraButtons?: (item: T) => ReactNode;
  renderListItem?: (item: T) => ReactNode;
  getFormValues?: (item: T, props: DictEditorProps) => Partial<T>;
  itemNameField?: string;
  title: string;
  newModalTitle: string;
  editModalTitle: string;
  removeConfirmationText?: string;
  editable?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  removable?: boolean;
  addable?: boolean;
  pagination?: boolean;
  hints?: string[];
  itemModalLink?: string;
  items?: { data: Record<string, any>[]; total: number } | null;
  filters?: Record<string, any>;
  isDraggable?: boolean;
  maxFieldLength?: number;
}

const defaultProps = {
  onAddItem: (item) => {},
  onUpdateItem: (item) => {},
  onRemoveItem: (item) => {},
  onSortEnd: () => {},
  renderFormFields: (item, modalProps) => {
    const items = modalProps.items.data;

    return (
      <FormBuilder.Text
        required
        name={modalProps.itemNameField}
        title={label('name')}
        validate={[FormBuilder.validators.notInList(items)]}
        maxLength={modalProps.maxFieldLength}
      />
    );
  },
  renderItemExtraButtons: (item) => [],
  getFormValues: (item, modalProps) => {
    const { itemNameField } = modalProps;

    return {
      [itemNameField]: item[itemNameField],
    };
  },
  itemNameField: 'name',
  removeConfirmationText: t('common.remove_element'),
  editable: true,
  filterable: true,
  sortable: false,
  removable: true,
  addable: true,
  pagination: false,
  isDraggable: false,
};

const DictEditorV2 = reconnect<DictEditorProps>((state, ownProps) => {
  const filters = filtersSelector(state);

  return {
    filters,
    items: ownProps.connectResource(state, filters),
  };
})((props: DictEditorProps) => {
  const finalProps = { ...defaultProps, ...props };

  const {
    onRemoveItem,
    onSortEnd,
    renderItemExtraButtons,
    renderListItem,
    itemNameField,
    title,
    removeConfirmationText,
    editable,
    filterable,
    sortable,
    removable,
    addable,
    pagination,
    itemModalLink,
    items,
    filters,
    isDraggable,
  } = finalProps;

  const dispatch = useDispatch();
  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [isBeforeApplyDragAndDrop, setIsBeforeApplyDragAndDrop] = useState(true);

  const getFilteredItems = useCallback(() => {
    const { data } = items ?? {};

    if (!filters || !filters.search) return data;

    return (
      data &&
      data.filter((item) =>
        item[itemNameField].toLowerCase().includes(filters.search.trim().toLowerCase()),
      )
    );
  }, [filters, itemNameField, items]);

  const prevFilters = usePrevious(filters);
  const prevItems = usePrevious(items?.data);

  useEffect(() => {
    if (isBeforeApplyDragAndDrop) setFilteredItems(getFilteredItems());

    if (!isEqual(prevFilters, filters)) {
      setFilteredItems(getFilteredItems());
    }

    if (!isEqual(items?.data, prevItems)) setIsBeforeApplyDragAndDrop(true);
  }, [getFilteredItems, isBeforeApplyDragAndDrop, filters, prevFilters, items?.data, prevItems]);

  const handleRemove = useCallback(
    (item) => (e) => {
      confirm(removeConfirmationText).then(() => onRemoveItem(item));
      e.stopPropagation();
      e.preventDefault();
    },
    [onRemoveItem, removeConfirmationText],
  );

  const renderItem = useCallback(
    (item) => (
      <div {...element('item')}>
        <div {...element('itemName')}>
          {renderListItem ? renderListItem(item) : item[itemNameField]}
        </div>
        <div {...element('itemActions')}>
          {renderItemExtraButtons(item)}

          {editable && (
            <Button small ghost>
              {t('common.edit')}
            </Button>
          )}

          {removable && !item.is_disabled && (
            <Button small ghost onClick={handleRemove(item)}>
              {t('common.remove')}
            </Button>
          )}
        </div>
      </div>
    ),
    [editable, handleRemove, itemNameField, removable, renderItemExtraButtons, renderListItem],
  );

  const getLinkProps = (item) => ({
    to: itemModalLink || 'dictEditorModal',
    data: item.id,
  });

  const onMoveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = filteredItems[dragIndex];
      const hoverItem = filteredItems[hoverIndex];

      setFilteredItems((elements) => {
        const updatedItems = [...elements];
        updatedItems[dragIndex] = hoverItem;
        updatedItems[hoverIndex] = dragItem;
        return updatedItems;
      });
    },
    [filteredItems],
  );

  const onDropItem = useCallback(
    (item) => {
      const { index, draggableItem } = item;
      const beforeItem = filteredItems[index + 1];

      setIsBeforeApplyDragAndDrop(false);

      dispatch({
        type: 'ACTIVITIES/MOVE',
        payload: {
          body: { ...draggableItem, rank: null },
          id: draggableItem.id,
          beforeId: beforeItem ? beforeItem.id : null,
        },
      });
    },
    [dispatch, filteredItems],
  );

  const renderDraggableItem = useCallback(
    (item, i) => {
      return (
        <DraggableItem
          key={item.id}
          index={i}
          id={item.id}
          label={renderItem(item)}
          onMove={onMoveItem}
          draggableItem={item}
          onDrop={onDropItem}
        />
      );
    },
    [onDropItem, onMoveItem, renderItem],
  );

  return (
    <>
      <DictEditorForm modalProps={finalProps} />

      <Page
        {...block()}
        title={title}
        actions={
          <PageActions>
            {addable && (
              <ModalLink to={itemModalLink || 'dictEditorModal'} data="new">
                <Button>{t('common.add')}</Button>
              </ModalLink>
            )}
          </PageActions>
        }
      >
        <DictEditorFilter
          filterable={filterable}
          pagination={pagination}
          total={props.items?.total}
        />

        <List
          {...block()}
          sortable={sortable}
          onSortEnd={onSortEnd}
          data={filteredItems}
          showId={false}
          renderItem={isDraggable ? renderDraggableItem : renderItem}
          linkProps={getLinkProps}
        />

        <DictEditorFilter filterable={false} pagination={pagination} total={items?.total} />
      </Page>
    </>
  );
});

export default memo(DictEditorV2);
