import { createResource } from 'lib/resource';
import { api } from 'lib/api';
import { EndpointType } from 'lib/resource/endpointTypes';

const endpoint = '/v3/zenit/';

export const fieldsResources = createResource('footballFields', {
  scopes: {
    list: () => api.get(`${endpoint}fields/`),

    dates: ({ payload }) => api.get(`${endpoint}shooting_dates/${payload}`),

    photos: {
      request: ({ payload }) =>
        api.get(`${endpoint}fields/${payload.id}`, { search: { date: payload.date } }),
      type: EndpointType.ITEM_V2,
    },
  },
});
