import { ReactNode } from 'react';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Box, Checkbox, Typography } from '@mui/material';

import { bem } from 'lib/bem';
import { IconAsButton } from 'lib/ui/icon';

import type { TreeNode } from '../../AsyncTreeView';
import './AsyncTreeViewItem.scss';

const { block, element } = bem('TreeViewItem');

interface AsyncTreeViewItemProps {
  node: TreeNode;
  onToggle: (node: TreeNode) => void;
  onSelectNode: (node: TreeNode) => void;
  childNodes: ReactNode[];
  selected: boolean;
  isIndeterminate?: boolean;
  multiple?: boolean;
}

export const AsyncTreeViewItem = ({
  node,
  onToggle,
  onSelectNode,
  childNodes,
  selected,
  isIndeterminate,
  multiple,
}: AsyncTreeViewItemProps) => {
  const handleCheckbox = (event) => {
    event.stopPropagation();
    onSelectNode(node);
  };

  const level = node.parentIds?.length ?? 0;

  return (
    <TreeItem
      {...block()}
      sx={{
        '.MuiTreeItem-content': {
          paddingLeft: `${level * 12}px`,
          border: 'none',

          '&.Mui-focused': {
            background: 'transparent',
          },
        },
        '.MuiTreeItem-iconContainer': {
          height: '40px',
          width: '20px',
          display: 'flex',
          alignItems: 'center',
          marginLeft: '6px',
        },
      }}
      key={node.id}
      nodeId={node.id.toString()}
      label={
        <Box
          {...element('item')}
          onClick={multiple || !node.hasChildren ? () => onSelectNode(node) : () => onToggle(node)}
        >
          <Typography {...element('label')} component="div">
            {node.name}
          </Typography>
          {multiple && (
            <Checkbox
              disableRipple
              checked={selected}
              onChange={handleCheckbox}
              indeterminate={selected ? false : isIndeterminate}
            />
          )}
        </Box>
      }
      expandIcon={
        node.hasChildren ? (
          <IconAsButton
            {...element('collapseIcon')}
            onClick={() => onToggle(node)}
            glyph="squareArrow"
          />
        ) : null
      }
      collapseIcon={
        node.hasChildren ? (
          <IconAsButton
            {...element('expandIcon')}
            onClick={() => onToggle(node)}
            glyph="squareArrow"
          />
        ) : null
      }
    >
      {node.hasChildren ? childNodes.length > 0 ? childNodes : <div /> : null}
    </TreeItem>
  );
};
