export enum ActivityType {
  create_issue = 'create_issue',
  show_details = 'show_details',
  book = 'book',
  share_booking_item = 'share_booking_item',
  open_url = 'open_url'
}

export interface ActivityModel {
  id: number;
  name: string;
  icon_url: string;
  rank: number;
  mcid?: number;
  type: ActivityType;
  params: Record<string, any>;
}
