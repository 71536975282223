import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { flow, get, isEqual } from 'lodash';
import { withRouter } from 'config/withRouter';
import { updateQuery } from 'lib/routerUtils';

/**
 * @function queryForm
 *
 * @description
 *   Создает форму на основе redux-form, значения которой будут синхронизироваться с location.query.
 *   Полезна в случае форм фильтрации, требующих сохранения в истории.
 *
 * @param options {Object}
 * @param options.form {String} redux-form form name
 * @param options.pushLocation {Boolean}
 *   Если true, то при изменении значений
 *   фильтра будет вызваться lcoation.push. Иначе - location.replace.
 *   По умолчанию = false.
 *
 * @example
 *   import queryForm from 'lib/queryForm';
 *   import {Field} from 'redux-form';
 *
 *   queryForm({
 *     form: 'usersFilter',
 *   })(() =>
 *     <div>
 *       <Field component="input" type="text" name="name" />
 *       <Field component="input" type="number" name="age" />
 *     </div>
 *   );
 */
export default function queryForm<P = any>(options) {
  const formDataSelector = (field) => (state) => get(state, `form.${options.form}.${field}`);

  // Поскольку у нас может быть несколько одинаковых форм
  // на одной странице (например, фильтр с пагинацией),

  return (Component) => {
    return flow(
      connect((state) => ({
        values: formDataSelector('values')(state) || {},
        fields: Object.keys(formDataSelector('registeredFields')(state) || {}),
      })),
      reduxForm({
        form: options.form,
      }),
      withRouter,
    )((props: any) => {
      const { fields, values, dispatch, router } = props;

      const { query: routerValues } = router;

      const syncFields = useCallback(
        (routerValues) => {
          (fields as any).forEach((field) => {
            const routerValue = routerValues[field];

            const value = routerValue === undefined ? '' : routerValue;
            (dispatch as any)(change(options.form, field, value));
          });
        },
        [dispatch, fields],
      );

      const [savedFilters, setSavedFilters] = useState({});

      const handleSavedFiltersChange = useCallback((newSavedFilters) => {
        setSavedFilters(newSavedFilters);
      }, []);

      // Параметры, используемые компонентом из стора
      const filterParams = useMemo(
        () => ({ ...props?.initialValues, ...values, ...savedFilters }),
        [savedFilters, props?.initialValues, values],
      );

      // Параметры из стора, которые надо синхронизировать с URL
      const targetParams = useMemo(
        () => ({
          ...fields.reduce((acc, field) => {
            return filterParams[field] == null ? acc : { ...acc, [field]: filterParams[field] };
          }, {}),
        }),
        [filterParams, fields],
      );

      const targetParamsRef = useRef(targetParams);

      // Параметры URL, которые должны быть синхронизированы с параметрами из стора
      const routeParams = useMemo(
        () =>
          fields.reduce((acc, field) => {
            return routerValues[field] == null ? acc : { ...acc, [field]: routerValues[field] };
          }, {}),
        [fields, routerValues],
      );

      const routeParamsRef = useRef(routeParams);

      const updateRoute = useCallback(
        (newValues) => {
          updateQuery(router, newValues, !options.pushLocation);
        },
        [router],
      );

      useEffect(() => {
        if (
          !isEqual(targetParams, targetParamsRef.current) &&
          !isEqual(targetParams, routeParams)
        ) {
          // Обновляем параметры URL при изменении параметров в сторе
          const newParams = { ...targetParams };
          // Помечаем удалённые ключи как undefined чтобы удалить их из query params
          Object.keys(targetParamsRef.current).forEach((key) => {
            if (!(key in newParams)) {
              newParams[key] = undefined;
            }
          });
          updateRoute(newParams);
        }
        if (!isEqual(routeParams, routeParamsRef.current) && !isEqual(targetParams, routeParams)) {
          // Обновляем параметры в сторе при изменении параметров URL
          syncFields(routeParams);
        }
        targetParamsRef.current = targetParams;
        routeParamsRef.current = routeParams;
      }, [targetParams, routeParams, updateRoute, syncFields]);

      return <Component {...props} onSavedFiltersChange={handleSavedFiltersChange} />;
    });
  };
}

export const normalizeTreeValue = (multiple) => (value) => {
  if (multiple) {
    return value && value.map((i) => i.id).join(',');
  }
  return value && value.id;
};

export const formatTreeValue = (multiple, tree) => (value) => {
  if (multiple) {
    if (!tree || !value) return [];
    return value
      .toString()
      .split(',')
      .map((id) => tree.byId[id]);
  }
  if (!tree || !value) return '';
  return tree.byId[value];
};
