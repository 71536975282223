import { useCallback } from 'react';
import { confirmable } from 'react-confirm';
import { t } from 'lib/i18n';

import Modal from './ModalComponent';
import Button from '../Button';
import { createConfirmation } from './modalMounter';

const ModalBody = (props) => {
  const { proceedText, dismissText, onClose, proceed, mainContent, alarmModal, title } = props;

  const { confirmation } = props.modalProps;

  return (
    <div>
      <p className="Modal__text">{confirmation}</p>
      {mainContent}
      <div className="Modal__buttons">
        <Button ghost onClick={proceed}>
          {proceedText || t('common.yes')}
        </Button>

        {!alarmModal && (
          <Button sx={{ marginLeft: 1 }} onClick={onClose}>
            {dismissText || t('common.no')}
          </Button>
        )}
      </div>
    </div>
  );
};

const confirm = createConfirmation(
  confirmable<any, any>(
    ({
      show,
      dismiss,
      proceed,
      proceedText,
      dismissText,
      confirmation,
      mainContent,
      options = {},
      onClose,
      alarmModal = false,
      title,
    }) => {
      const handleClose = useCallback(() => {
        dismiss();
        if (onClose) {
          onClose();
        }
      }, [dismiss, onClose]);

      const confirmTitle = title || t('common.modal.confirm.title');

      return (
        <Modal
          width={480}
          component={ModalBody}
          className={`_confirm ${(options as any).className || ''}`}
          proceed={proceed}
          proceedText={proceedText}
          dismissText={dismissText}
          onClose={handleClose}
          isOpened={show}
          mainContent={mainContent}
          getTitle={() => confirmTitle}
          alarmModal={alarmModal}
          modalProps={{ confirmation }}
        />
      );
    },
  ),
);

export default function (confirmation, options = {}) {
  return confirm({ confirmation, ...options });
}
