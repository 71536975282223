import { useCallback, useEffect, useState } from 'react';

import { useDeleteProfile } from 'features/update-profile-info/api/mutations';
import { t, t_prefixed } from 'lib/i18n';
// eslint-disable-next-line import/no-cycle
import { ConfirmModal } from 'shared/ui/confirm-modal';
import { Alert } from 'shared/ui/alert';
import { TextButton } from 'shared/ui/text-button';

const label = t_prefixed('users.profile');
export const DeleteProfileButton = () => {
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { mutate, data, error } = useDeleteProfile();

  const handleConfirm = useCallback(() => {
    mutate();
    setOpen(false);
  }, [mutate]);

  const message =
    data && 'response' in data && !error
      ? data.response.metadata.message
      : {
          title: t('profile_page.delete_profile.error_title'),
          body: t('profile_page.delete_profile.error_body'),
        };

  useEffect(() => {
    if (data) {
      setShowAlert(true);
    }
  }, [data]);

  return (
    <div>
      <Alert
        isOpen={showAlert}
        body={message?.body}
        title={message ? message.title : t('profile_page.delete_profile.error_title')}
        onClose={() => setShowAlert(false)}
      />

      <ConfirmModal
        title={label('delete_account')}
        body={label('delete_account_warning')}
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={handleConfirm}
        confirmText={label('delete_account')}
        cancelColor="ghost"
        confirmColor="error"
      />

      <div>
        <TextButton color="error" onClick={() => setOpen(true)}>
          {label('delete_account')}
        </TextButton>
      </div>
    </div>
  );
};
