import './Number.scss';
import { Component } from 'react';
import { bem } from 'lib/bem';
import { NumberInput } from 'lib/ui';

const { block } = bem('Number');

export default class Number extends Component {
  static propTypes = (({ string, number, bool }) => ({
    delimiter: string,
    minFracSize: number,
    maxFracSize: number,
    min: number,
    max: number,
    multiplier: number,
    wide: bool,
  }))(PropTypes);

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'input' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { input, data, wide } = this.props;

    return (
      <NumberInput {...block({ wide })} {...data} {...input} />
    );
  }
}
