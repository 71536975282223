import { IParseOptions, IStringifyOptions, parse, stringify } from 'qs';

const stringifyOptions: IStringifyOptions = {
  arrayFormat: 'comma',
  skipNulls: true,
  addQueryPrefix: true,
};

const parseOptions: IParseOptions = {
  comma: true,
  ignoreQueryPrefix: true,
  decoder: (str, decoder, charset) => {
    const strWithoutPlus = str.replace(/\+/g, ' ');
    if (charset === 'iso-8859-1') {
      // unescape never throws, no try...catch needed:
      return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
    }

    const keywords = {
      true: true,
      false: false,
      null: null,
      undefined,
    };
    if (str in keywords) {
      return keywords[str];
    }

    // utf-8
    try {
      return decodeURIComponent(strWithoutPlus);
    } catch (e) {
      return strWithoutPlus;
    }
  },
};

export const qsParse = (value: string, options: IParseOptions = {}) => {
  return parse(value, { ...parseOptions, ...options });
};

export const qsStringify = (value: unknown, options: IStringifyOptions = {}) => {
  return stringify(value, { ...stringifyOptions, ...options });
};
