import queryString from 'query-string';
import { trim, pickBy, isPlainObject, isString, each } from 'lodash';
import { cloneDeepWith } from 'lodash/fp';
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';

import { fakeNull, appVersion } from 'config/constants';
import brandedData from 'config/brandedData';
import i18n from 'lib/i18n/i18n';
import { getLocalStorageItem, saveLocalStorageItem } from 'lib/helpers/localStorage';
// eslint-disable-next-line import/no-cycle
import { clearFcmToken } from 'lib/helpers/firebase';

// eslint-disable-next-line import/no-cycle
import { api } from './api-methods';
import { ApiSendResponse } from './types/api-methods-types';

const BASE_PATH = '/api/';

function normalizePath(pathStr) {
  return pathStr.replace(/\/+/g, '/');
}

const serializeData = cloneDeepWith((value) => {
  if (value === fakeNull) return null;
  if (isString(value)) return trim(value);
});

function prepareBody(body) {
  return body && serializeData(body);
}

function getBody(body) {
  if (isPlainObject(body)) {
    return JSON.stringify(prepareBody(body));
  }

  return body;
}

function getHeaders(argHeaders = {}) {
  const headers = new Headers();
  const language = i18n.language.toLowerCase(); // 'ru' 'en' 'da' ...
  const headersObject = {
    'Content-Type': 'application/json',
    ...(argHeaders || {}),
  };

  headers.append('Cache-control', 'no-cache');
  headers.append('Cache-control', 'no-store');
  headers.append('Pragma', 'no-cache');
  headers.append('Client-App', `${brandedData.clientApp}/${appVersion}`);
  headers.append('Accept', 'application/vnd.smartspace.v2.23+json');
  headers.append('Accept-Language', language);

  const storedAuthToken = getLocalStorageItem('authToken');
  if (storedAuthToken) {
    headers.append('Authorization', `Bearer ${storedAuthToken}`);
  }

  each(headersObject || {}, (value, key) => {
    if (argHeaders[key] !== null) {
      headers.append(key, value);
    }
  });

  return headers;
}

export function getUrl(url, params) {
  const definedParams = pickBy(params, (param) => param || param === false || param === 0);
  const query = queryString.stringify(serializeData(definedParams));
  return normalizePath(`/${BASE_PATH}/${url}/${query ? `?${query}` : ''}`);
}

export function getOptions(method, options) {
  return {
    method,
    mode: 'cors',
    ...options,
    body: getBody(options.body),
    headers: getHeaders(options.headers),
  };
}

export function handleAuthTokenRefresh(data: ApiSendResponse<unknown>) {
  const { responseHeaders, httpStatusCode } = data;

  const authToken = responseHeaders?.get('auth-token');
  const refreshToken = responseHeaders?.get('refresh-token');

  if ((httpStatusCode === 200 || httpStatusCode === 201) && authToken && refreshToken) {
    Cookies.remove('refresh-token');
    saveLocalStorageItem('authToken', authToken);
    Cookies.set('refresh-token', refreshToken);
  }

  return data;
}

export async function fetchRefreshToken(data: Response, url: string, options: RequestInit) {
  const storedAuthToken = getLocalStorageItem('authToken');
  if (data.status === 401 && storedAuthToken && url.includes('refresh_token')) {
    localStorage.removeItem('authToken');
    Cookies.remove('refresh-token');
    clearFcmToken();
    window.location.reload();
    return data;
  }

  if (data.status === 401 && storedAuthToken && !url.includes('refresh_token')) {
    const token = Cookies.get()['refresh-token'];

    const res = await api.post('/v3/auth/refresh_token/', {
      body: { refresh_token: token ?? null },
    });
    handleAuthTokenRefresh(res);
    const reqHeaders = new Headers(options.headers);
    reqHeaders.set('Authorization', `Bearer ${res.responseHeaders?.get('auth-token')}`);
    options.headers = reqHeaders;
    return fetch(url, options);
  }
  return data;
}

export type ResponseWithContext = {
  response: Response;
  context: { url: string; options: RequestInit };
};

export const fetchWithContext = async (
  url: string,
  options: RequestInit,
): Promise<ResponseWithContext> => ({
  response: await fetch(url, options),
  context: { url, options },
});

export const authRoutine = async (data: ResponseWithContext): Promise<ResponseWithContext> => {
  const { context, response } = data;
  const { url, options } = context;

  return { response: (await fetchRefreshToken(response, url, options)) ?? response, context };
};
