import { isObject } from 'lodash';

export default function stringify(payload = {}, prefix = '') {
  if (isObject(payload)) {
    return Object.keys(payload)
      .sort()
      .filter((key) => {
        const value = payload[key];

        // Считаем, что offset=0 идентичен отсутствию offset
        if (key === 'offset' && Number(value || 0) === 0) return false;
        if (value === null || value === undefined) return false;

        return true;
      })
      .map((key) => {
        const value = payload[key];
        if (isObject(value)) return stringify(value, `${key}.`);
        return `${encodeURIComponent(`${prefix}${key}`)}=${encodeURIComponent(value)}`;
      })
      .join('&');
  }

  return String(payload);
}
