import { useCallback } from 'react';
import { AnyObject, InferType, ObjectSchema, ValidationError } from 'yup';

export const useYupValidationResolver = <T extends AnyObject>(
  validationSchema: ObjectSchema<T>,
) => {
  const resolver = useCallback(
    async (data: InferType<ObjectSchema<T>>) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (_errors) {
        const errors = _errors as ValidationError;

        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, { path, type, message }) => ({
              ...allErrors,
              ...(path != null
                ? {
                    [path]: {
                      type: type ?? 'validation',
                      message,
                    },
                  }
                : undefined),
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );

  return { resolver };
};
