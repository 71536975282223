/* eslint-disable no-underscore-dangle */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient_v4 } from 'shared/api/v4/client';

import { notificationsQueries } from './queries';

export const useMarkAllSeen = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (lastNotificationId: number) => {
      await apiClient_v4.notifications.markAllSeen({ lastNotificationId });
      return { lastNotificationId };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: notificationsQueries._def }); // все запросы списков
    },
  });
};

export const useMarkNotificationSeen = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (notificationId: number) => {
      await apiClient_v4.notifications.markSeen({ notificationId });
      return { notificationId };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: notificationsQueries._def });
    },
  });
};
