import { forwardRef, ReactNode, useCallback, UIEvent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { styled, SxProps } from '@mui/material/styles';

import { bem } from 'lib/bem';
import { Icon } from 'lib/ui/icon';

import { t } from '../../i18n';
import { useMounting } from '../../hooks/useMounting';
import brandedData from '../../../config/brandedData';
import './Page.scss';

const { block, element } = bem('Page');

interface Props {
  title?: ReactNode;
  pageTitle?: string;
  loading?: any;
  isReady?: boolean;
  backPath?: string;
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
  contentSx?: SxProps;
  withoutTitle?: boolean;
  beforeTitleContent?: ReactNode;
  onScroll?: (e: UIEvent<HTMLDivElement>) => void;
  actions?: ReactNode;
}

// eslint-disable-next-line react/display-name
const Page = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      pageTitle,
      className,
      isReady,
      children,
      backPath,
      fullWidth,
      contentSx,
      withoutTitle,
      onScroll,
      actions,
    }: Props,
    ref,
  ) => {
    const isMenuHidden = useSelector((state) => (state as any).menu.hidden);

    const handleMount = useCallback(() => {
      const docTitle = pageTitle ?? title;
      document.title = `${docTitle} - ${brandedData.applicationName}`;
      window.scroll(0, 0);
    }, [title, pageTitle]);
    useMounting(handleMount);

    const BackLink = styled(Link)(({ theme }) => {
      return {
        color: theme.palette.primary.main,
      };
    });

    return (
      <div {...block({ isMenuHidden }, className)} ref={ref} onScroll={onScroll}>
        {!!backPath && (
          <BackLink to={backPath} className="Page__back">
            <Icon glyph="squareArrow" />
            {t('common.back')}
          </BackLink>
        )}
        <div {...element('wrapper', { fullWidth })}>
          <div {...element('header')}>
            {!withoutTitle && <div {...element('title')}>{title}</div>}
            {actions}
          </div>
          <Box {...element('content')} sx={contentSx}>
            {isReady !== false && children}
          </Box>
        </div>
      </div>
    );
  },
);

export default Page;
