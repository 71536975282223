import { createQueryKeys } from '@lukemorales/query-key-factory';

import { apiClient_v4 } from 'shared/api/v4/client';

export const activitiesQueries = createQueryKeys('activity', {
  icons: { queryKey: null, queryFn: () => apiClient_v4.activities.getActivitiesIcons() },

  list: { queryKey: null, queryFn: () => apiClient_v4.activities.getActivities() },
  issueTypes: (activityId?: number) => ({
    queryKey: [activityId!],
    queryFn: () =>
      activityId ? apiClient_v4.activities.getActivityIssueType({ activityId }) : undefined,
  }),
});
