import { put, call } from 'redux-saga/effects';
import { isObjectLike } from 'lodash';
import { t } from 'lib/i18n';

export function httpError(err, requestAction) {
  let error;
  let code;

  if (isObjectLike(err)) {
    error = err.message;
    code = err.code;
  } else {
    error = err;
  }

  return {
    type: 'HTTP_ERROR',
    error: error || t('http_errors.serviceUnavailable'),
    code,
    requestAction,
  };
}

function* getRequestIndex() {
  let index = 0;
  while (true) yield index++;
}

const indexGenerator = getRequestIndex();

export function getActions(actionName) {
  const requestIndex = indexGenerator.next();
  actionName = actionName.toUpperCase();

  return {
    start: (data) => ({
      type: `${actionName}_START`,
      requestIndex,
      ...data,
    }),

    success: (data) => ({
      type: `${actionName}_SUCCESS`,
      requestIndex,
      ...data,
    }),

    fail: (data) => ({
      type: `${actionName}_FAIL`,
      requestIndex,
      ...data,
    }),
  };
}

export function* resourceAction(actionName, fn, ...args) {
  const actions = getActions(actionName);
  const requestAction = args[0];

  yield put(actions.start({ requestAction }));

  const callResult = yield call(fn, ...args);
  if (callResult) {
    const {
      response, error, total, request,
    } = callResult;

    if (response) {
      yield put(actions.success({
        request, response, total, requestAction,
      }));
    } else {
      yield put(httpError(error, actionName));
      yield put(actions.fail({
        request, error, requestAction, response,
      }));
    }
  }
}
