import { useDrag } from 'react-dnd';

import { DragSourceCellProps, DropTargetCellProps } from '../types';

export const useDragSourceCell = ({ column, onColumnReorder }: DragSourceCellProps) => {
  return useDrag(
    () => ({
      type: 'TableHeaderCell',
      item: () => column,
      end: (item, monitor) => {
        const dropTarget: DropTargetCellProps | null = monitor.getDropResult();

        if (dropTarget && dropTarget.column.accessor) {
          onColumnReorder({
            source: column.accessor,
            target: dropTarget.column.accessor,
            dropBefore: dropTarget.dropBefore,
          });
        }
      },
    }),
    [column],
  );
};
