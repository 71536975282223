import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { qsParse, qsStringify } from './qs';

export const useQueryParams = <
  QueryParams extends Record<string, unknown> = Record<string, unknown>,
>() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = useMemo(() => qsParse(searchParams.toString()) as QueryParams, [searchParams]);

  const onChange = useCallback(
    (values: Record<string, unknown>) => {
      setSearchParams(qsStringify(values));
    },
    [setSearchParams],
  );

  return {
    query,
    setQuery: onChange,
  };
};
