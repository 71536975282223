import './Attributes.scss';
import { bem } from 'lib/bem';
import { keyBy } from 'lodash';
import { attrsService } from 'app/issues/services';
import { Attribute } from '.';

const { block } = bem('Attributes');

function getIssueAttrsMap(issue) {
  return keyBy(issue.issue_type.attributes || [], (attr) => attr.internal_name || attr.id);
}

function sortByRank(a, b) {
  return a.attrDescriptor.rank - b.attrDescriptor.rank;
}

export default function Attributes({ issue }) {
  if (!issue.attributes) return null;

  const attrsMap = getIssueAttrsMap(issue);
  const attrValues = attrsService.filterValuesByConditions(issue.issue_type.attributes, issue, issue.attributes);
  delete (attrValues as any)._comment_resp;

  return (
    <div {...block()}>
      {Object.keys(attrValues || {})
        .map((attrName) => ({
          value: attrValues[attrName],
          attrDescriptor: attrsMap[attrName],
        }))
        .filter(({ attrDescriptor }) => attrDescriptor)
        .sort(sortByRank)
        .map(({ value, attrDescriptor }) => <Attribute {...{ value, attrDescriptor }} key={attrDescriptor.id} />)}
    </div>
  );
}
