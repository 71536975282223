import { getFormValues as getReduxFormValues } from 'redux-form';

import { Button, List, ModalLink, Page, PageActions } from 'lib/ui';
import DictEditorForm from 'app/common/DictEditor/DictEditorForm/DictEditorForm';
import DictEditorFilter from 'app/common/DictEditor/DictEditorFilter/DictEditorFilter';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';
import { ScopeResult } from 'lib/resource';

import userRolesResource from '../userRoles.resource';
import './UserRoles.scss';
import UserRolesListItem from '../ui/UserRolesListItem';
import { modalProps, label, modalName, getFilteredItems } from '../config';
import { IUserRoles } from '../lib';

interface Props {
  roles: ScopeResult<IUserRoles[]>;
  filters: {
    name: string;
  };
}

const { block, element } = bem('UserRoles');
const filtersSelector = getReduxFormValues('dictEditorFilter');

const mapStateToProps = (state) => {
  return {
    roles: userRolesResource.list(state, {}),
    filters: filtersSelector(state),
  };
};

const UserRoles = reconnect(mapStateToProps)(({ roles, filters }: Props) => {
  const renderItem = (item) => <UserRolesListItem item={item} />;

  const getLinkProps = (item) => ({
    to: modalName,
    data: item.id,
  });

  if (roles?.fetching) return <BusyIndicator />;

  return (
    <>
      <DictEditorForm modalProps={modalProps} />
      <Page
        title={label('title')}
        actions={
          <PageActions>
            <ModalLink to={modalName} data="new">
              <Button>{t('common.add')}</Button>
            </ModalLink>
          </PageActions>
        }
      >
        <DictEditorFilter filterable />

        <div {...element('container')}>
          <h2 {...element('listTitle')}>{label('system_roles')}</h2>
          <List
            {...block()}
            {...element('system')}
            sortable={false}
            showId={false}
            data={getFilteredItems(roles, filters).filter((item) => item.system)}
            renderItem={renderItem}
            linkProps={() => null}
          />

          <h2 {...element('listTitle')}>{label('custom_roles')}</h2>
          <List
            {...block()}
            sortable={false}
            showId={false}
            data={getFilteredItems(roles, filters).filter((item) => !item.system)}
            renderItem={renderItem}
            linkProps={getLinkProps}
          />
        </div>
      </Page>
    </>
  );
});

export default UserRoles;
