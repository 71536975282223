export { default as createResource } from './createResource';
export { default as treeScope } from './treeScope';
export { default as reconnect } from './reconnect';

export type { ScopeResult } from './ResourceScope';

export function updateById(state, id, data) {
  if (!state.index || !state.index[id]) return state;

  return {
    ...state,
    index: {
      ...state.index,
      [id]: {
        ...state.index[id],
        ...data,
      },
    },
  };
}

const resourceShape = {
  $ResourceResults: PropTypes.oneOf([true]).isRequired,
  fetching: PropTypes.bool.isRequired,
  failed: PropTypes.bool,
  outdated: PropTypes.bool,

  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    payload: PropTypes.any,
  }).isRequired,

  byId: PropTypes.func.isRequired,
};

export const objectResourcePropType = PropTypes.shape({
  ...resourceShape,

  data: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object,
  ]),
});

export const listResourcePropType = PropTypes.shape({
  ...resourceShape,

  total: PropTypes.number,
  data: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.array,
  ]),
});
