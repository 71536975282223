import { Link } from 'react-router-dom';
import { keyBy, memoize, round } from 'lodash';
import moment from 'moment';

import { Date, Duration, ModalLink, Rating } from 'lib/ui';
import { IssueStatus } from 'app/issues/IssueFields';
import { t } from 'lib/i18n';
import { qsParse, qsStringify } from 'lib/helpers/qs';
import GeolocationStatus from 'app/issues/GeolocationStatus';
import attributeViewRenderers from 'app/issues/Attributes/attributeViewRenderers';
import { nestedPathRenderer } from 'lib/ui/Table/GridBody/GridBody';

const getAttrsIndex = memoize((attrs) => keyBy(attrs, (attr) => attr.internal_name || attr.id));

export default {
  default(value) {
    return value && value.toString();
  },

  issue_id(value) {
    if (value) {
      return (
        <ModalLink to="issue" data={value.id}>
          {value.display_id}
        </ModalLink>
      );
    }
    return null;
  },

  datetime(value) {
    return value && <Date value={value} format="short" showIcon={false} />;
  },

  author_rating(value) {
    return <Rating value={value} />;
  },

  rating(value) {
    return round(value, 2);
  },

  issue_type: (value, row, column) => nestedPathRenderer({ value, row, column }),

  issue_type_path: (value, row, column) => nestedPathRenderer({ value, row, column }),

  issue_type_object(value, row, column) {
    if (value) {
      return nestedPathRenderer({ value: value.path, row, column });
    }
    return null;
  },

  report_link(value, detailedReport, issueTypeId) {
    if (!value) return value;

    const query = qsParse(window.location.search);

    const newQuery: Record<string, any> = {
      ...query,
      issue_type: issueTypeId,
    };

    delete newQuery.offset;

    return (
      <Link to={`/reports/${detailedReport}${qsStringify(newQuery)}`}>{value.toString()}</Link>
    );
  },

  location_path: (value, row, column) => nestedPathRenderer({ value, row, column }),
  status: (value) => <IssueStatus value={value} showIcon={false} showInProgress />,

  seen_by_responsible: (value) => (value ? t('common.yes') : t('common.no')),

  history: (value) => {
    if (value) {
      return value.map((pair, index) => (
        <div key={index} className="StatusPair">
          <span className="status">{t('issues.status')[pair[0]]}</span>
          <GeolocationStatus status={pair[1]} />
        </div>
      ));
    }
    return null;
  },

  bool: (value) => {
    if (value === true) return t('common.yes');

    if (value === false) return t('common.no');
    if (value === null) return null;
  },

  attr: (value, column, attributes) => {
    const attrDescriptor = getAttrsIndex(attributes)[column.name];
    if (
      (!value && value !== 0 && value !== false) ||
      !attributeViewRenderers[attrDescriptor?.renderer]
    )
      return null;

    return attributeViewRenderers[attrDescriptor.renderer]({
      attrDescriptor,
      value,
    });
  },

  duration: (value) => {
    if (!value) {
      return null;
    }
    return (
      <Duration
        duration={moment.duration(value, 'seconds')}
        nowStatusText={t('common.less_minute')}
      />
    );
  },
};
