interface BookingStatus {
  id: number;
  internal_name: string;
  title: string;
}

export interface Booking {
  id: number;
  created: Date;
  updated: Date;
  start_datetime: Date;
  end_datetime: Date;
  status: BookingStatus;
  booking_item_name: string;
  related_managed_object_full_path: string;
  author_name: string;
  actual_start_datetime: string;
  actual_end_datetime: string;
  company_name: string;
  comment: string;
}

export enum BookingPageName {
  MY_BOOKINGS = 'my',
  ADMIN = 'admin',
  BOOK_FROM_ACTIVITY_LIST = 'bookFromActivityList',
  BOOK_FROM_BOOKINGS_SECTION = 'bookFromBookingsSection'
}

export enum BookingItemAction {
  READ = 'read',
  BOOK = 'book',
  SHARE = 'booking_sharing',
}
