import { DASHBBOARD_WIDGETS } from './ui/widgets';

export const TILE_MARGIN = 10;
export const TILE_HEIGHT = 165;
export const TILE_WIDTH = TILE_HEIGHT;

export const DASHBOARD_COLS = {
  lg: 12, md: 10, sm: 10, xs: 4, xxs: 2,
};

export const DASHBOARD_BREAKPOINTS = {
  lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0,
};

export const DASHBOARD_LAYOUT = [
  {
    'w': 4,
    'h': 2,
    'x': 0,
    'y': 0,
    'i': DASHBBOARD_WIDGETS.issuesTotal.key,
    'moved': false,
    'static': false,
  },
  {
    'w': 4,
    'h': 2,
    'x': 1,
    'y': 0,
    'i': DASHBBOARD_WIDGETS.issuesByType.key,
    'moved': false,
    'static': false,
  },
  {
    'w': 4,
    'h': 2,
    'x': 0,
    'y': 0,
    'i': DASHBBOARD_WIDGETS.issuesInProgress.key,
    'moved': false,
    'static': false,
  },
  {
    'w': 4,
    'h': 2,
    'x': 0,
    'y': 0,
    'i': DASHBBOARD_WIDGETS.responsibles.key,
    'moved': false,
    'static': false,
  },
  {
    'w': 4,
    'h': 2,
    'x': 0,
    'y': 0,
    'i': DASHBBOARD_WIDGETS.issuesRates.key,
    'moved': false,
    'static': false,
  },
  {
    'w': 4,
    'h': 2,
    'x': 0,
    'y': 0,
    'i': DASHBBOARD_WIDGETS.issuesOverdue.key,
    'moved': false,
    'static': false,
  },
];

export const DASHBOARD_LAYOUTS = {
  lg: DASHBOARD_LAYOUT,
  md: DASHBOARD_LAYOUT,
  sm: DASHBOARD_LAYOUT,
  xs: DASHBOARD_LAYOUT,
  xxs: DASHBOARD_LAYOUT,
};
