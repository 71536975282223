import './PhotoPreviewList.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { reconnect } from 'lib/resource';
import attachmentsResource from 'app/attachments.resource';
import { openModal } from 'lib/ui/Modal';
import { makeArray } from 'app/issues/Attributes/attributeViewRenderers';

import PhotoPreview from '../PhotoPreview';

const { block } = bem('PhotoPreviewList');

type OwnProps = {
  photoIds?: any;
  onRemove?: (...args: any[]) => any;
  getPhotoClassName?: (...args: any[]) => any;
  removable?: boolean;
};

export default reconnect((state, props) => {
  const cachedPhotos = state.attachments.byIds.index || {};
  const photoIds = makeArray(props.photoIds);
  const idsToFetch = photoIds.filter((id) => !(id in cachedPhotos));

  return {
    resolvedPhotos: (attachmentsResource as any).byIds(
      state,
      idsToFetch.length ? idsToFetch : undefined,
    ),
    photosIndex: state.attachments.byIds.index || {},
    photoIds,
  };
})(
  class PhotoPreviewList extends PureComponent<any> {
    static defaultProps = {
      onRemove: () => null,
      getPhotoClassName() {},
      removable: false,
    };

    photos: any;

    UNSAFE_componentWillMount() {
      this.photos = this.collectPhotos(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.photos = this.collectPhotos(nextProps);
    }

    collectPhotos(props) {
      const { photosIndex, photoIds } = props;

      return photoIds.map((photoId) => ({
        id: photoId,
        url: photosIndex[photoId] ? photosIndex[photoId].url : null,
      }));
    }

    render() {
      const { removable, getPhotoClassName } = this.props;

      return (
        <div {...block()}>
          {this.photos.map((photo) => (
            <PhotoPreview
              className={getPhotoClassName(photo)}
              key={photo.id}
              removable={removable}
              url={photo.url}
              onRemove={(e) => {
                this.props.onRemove(photo.id);
                e.stopPropagation();
              }}
              onClick={this.handlePhotoClick(photo)}
            />
          ))}
        </div>
      );
    }

    handlePhotoClick = (photo) => (e) => {
      this.openGallery(photo);
      e.stopPropagation();
      e.preventDefault();
    };

    openGallery = (photo) => {
      if (!photo.url) return null;

      (this.props as any).dispatch(
        openModal('galleryModal', {
          photos: this.photos,
          startFrom: photo,
        }),
      );
    };
  },
);
