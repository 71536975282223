import { Outlet, useLocation } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import clsx from 'clsx';

import { isProduction } from 'config/constants';
// eslint-disable-next-line import/no-cycle
import AppVersionChecker from 'app/common/AppVersionChecker';
import { bem } from 'lib/bem';
import { EntryModal } from 'app/entry';
import PhotoGalleryModal from 'app/photos/PhotoGalleryModal';
import { ConfirmationMountPoint } from 'lib/ui/Modal';
import device from 'lib/device';
import brandedData from 'config/brandedData';
import { Icon } from 'lib/ui/icon';

import MiniAppHeader from './miniApp/MiniAppHeader/MiniAppHeader';
import MenuV2 from './menu/Menu';
import { useAuthContext } from './providers';

const { block, element } = bem('app');

const StoreLink = () => {
  let store = 'APP_GALLERY';

  if (device.is.iphone || device.is.ipad) {
    store = 'APP_STORE';
  }

  if (device.on.android) {
    store = 'GOOGLE_PLAY';
  }
  const onBannerClick = () => {
    window.location.href = brandedData.appScheme;
    setTimeout(function () {
      window.location.href = brandedData[store];
    }, 25);
  };

  return (
    <div {...element('storeLink')} onClick={onBannerClick}>
      <div className="wrapper">
        <Icon className="brand" glyph="shortLogo" />
        <p className="text">{t('feed.open_store')}</p>
      </div>
      <Icon className="link_arrow" glyph="link_arrow" />
    </div>
  );
};

export const InternalApp = () => {
  const { pathname } = useLocation();
  const { userData } = useAuthContext();

  const isMiniApp = pathname.startsWith('/loc') && !pathname.startsWith('/locations');
  const shouldShowSideMenu = userData && !isMiniApp && !pathname.startsWith('/login');
  const showMiniAppHeader =
    isMiniApp && ((userData && userData.isGuest) || pathname.endsWith('/issues/new'));

  return (
    <DndProvider backend={HTML5Backend}>
      <div {...block()}>
        {device.is.phone && <StoreLink />}
        {isProduction && <AppVersionChecker />}

        {shouldShowSideMenu && <MenuV2 />}

        {showMiniAppHeader && <MiniAppHeader />}

        {userData && (
          <div className={clsx({ main: true, miniAppMain: isMiniApp })}>
            <Outlet />
          </div>
        )}

        <PhotoGalleryModal />
        <EntryModal />
        <ConfirmationMountPoint />
      </div>
    </DndProvider>
  );
};
