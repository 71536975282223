import './CurrencyInput.scss';
import { bem } from 'lib/bem';
import { NumberInput } from 'lib/ui';

const { block } = bem('CurrencyInput');

export function CurrencyInput(props) {  
  return (
    
    <div {...block()}>
      <NumberInput
        min={0}
        maxFracSize={2}
        multiplier={100}
        {...props}
      />
    </div>
  );
}
