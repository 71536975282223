import { FC } from 'react';
import SimpleSelect from 'lib/ui/SimpleSelect';
import { brandName } from 'config/constants';

export type TCurrency = 'EUR' | 'USD' | 'GBP' | 'RUB' | 'LKR' | 'KZT';

interface Props {
  input: {
    name: string;
    value: TCurrency | '';
    onChange: (code: TCurrency) => void;
  };
}

interface ICurrency {
  code: TCurrency;
  title: string;
}

export const currencyList: ICurrency[] = [
  {
    code: 'USD',
    title: '$',
  },
  {
    code: 'EUR',
    title: '€',
  },
  {
    code: 'GBP',
    title: '£',
  },
  {
    code: 'RUB',
    title: '₽',
  },
  {
    code: 'LKR',
    title: '₨',
  },
  {
    code: 'KZT',
    title: '₸',
  },
];

export const defaultCurrency: TCurrency = brandName !== 'tagpoint' ? 'RUB' : 'GBP';

const CurrencySelect: FC<Props> = (props) => {
  const { input } = props;

  return (
    <SimpleSelect
      name={input.name}
      options={currencyList}
      getItemValue={(item: ICurrency) => item.code}
      getItemLabel={(item: ICurrency) => item.title}
      value={input.value || defaultCurrency}
      onChange={(item: ICurrency) => input.onChange(item.code)}
    />
  );
};

export default CurrencySelect;
