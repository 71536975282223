export const firebaseConfig = {
  waveservice: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:cdc2b3a322a89815aa1d91',
    measurementId: 'G-7REKDBN57B',
  },
  officeservice: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:7945095d04f3220caa1d91',
    measurementId: 'G-78H1E9E3CF',
  },
  tagpoint: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:c544b1952709b2b9aa1d91',
    measurementId: 'G-GFYZYQTL4H',
  },
  teorema: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:dde72b6f924b9cc5aa1d91',
    measurementId: 'G-PR9TH2BFLJ',
  },
  landia: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:c72656a567603386aa1d91',
    measurementId: 'G-5Q5WEW8Q5V',
  },
  zabotaapp: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:cdc2b3a322a89815aa1d91',
    measurementId: 'G-7REKDBN57B',
  },
  suop: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:cdc2b3a322a89815aa1d91',
    measurementId: 'G-7REKDBN57B',
  },
  gsp: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:cdc2b3a322a89815aa1d91',
    measurementId: 'G-7REKDBN57B',
  },
  'code-service': {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:cdc2b3a322a89815aa1d91',
    measurementId: 'G-7REKDBN57B',
  },
  nornikel: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:cdc2b3a322a89815aa1d91',
    measurementId: 'G-7REKDBN57B',
  },
  elma: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:cdc2b3a322a89815aa1d91',
    measurementId: 'G-7REKDBN57B',
  },
  rkvr: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:cdc2b3a322a89815aa1d91',
    measurementId: 'G-7REKDBN57B',
  },
  myoffice20: {
    apiKey: 'AIzaSyAd8yhNznWvWvr51yY98M7R6WAdo49NiPo',
    authDomain: 'smart-space-ad8a6.firebaseapp.com',
    databaseURL: 'https://smart-space-ad8a6.firebaseio.com',
    projectId: 'smart-space-ad8a6',
    storageBucket: 'smart-space-ad8a6.appspot.com',
    messagingSenderId: '401513233604',
    appId: '1:401513233604:web:cdc2b3a322a89815aa1d91',
    measurementId: 'G-7REKDBN57B',
  },
};
