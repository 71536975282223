import { ActivityModel } from 'app/activities/types';

export interface IssueType {
  activity: ActivityModel;
  attributes: any[];
  auto: boolean;
  block_message: any;
  byId: any;
  can_defer: boolean;
  can_rate: boolean;
  can_reopen: boolean;
  can_use_in_chat: boolean;
  children: IssueType[];
  create_message_body: any;
  create_message_title: any;
  description: any;
  do_not_check: boolean;
  id: string;
  internal_name: any;
  metatype: string;
  name: string;
  on_duplicate: any;
  on_overdue: any;
  rank: number;
  require_location: boolean;
  require_roles: any[];
  sla: Sla;
}

export interface Sla {
  id: number;
  name: string;
  time: number;
  working_time: boolean;
}

export enum TYPES_OF_UNSEEN_ISSUES {
  INCOMING = 'incoming',
  IN_PROGRESS = 'in_progress',
  ON_CHECK = 'on_check',
  ACCIDENT = 'accident',
}
