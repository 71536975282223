import { Portal } from 'react-portal';

import { bem } from 'lib/bem';

import './SubMenu.scss';
import { memo, ReactNode, useCallback, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { brandedThemeOptions } from 'lib/ui/AppThemeProvider/theme';

const { block, element } = bem('SubMenu');

interface Props {
  index: number;
  items: Record<string, any>[];
  renderItem: (item) => ReactNode;
  smallScreen: boolean;
}

const SubMenu = ({ index, items, renderItem, smallScreen }: Props) => {
  const handler = useRef<any>(null);
  const dropdown = useRef(null);

  const [visible, setVisible] = useState(false);
  const [preventHideOnScroll, setPreventHideOnScroll] = useState(false);
  const [hideTimeoutId, setHideTimeoutId] = useState<number>();
  const [dropdownStyle, setDropdownStyle] = useState({});

  const showDropdown = useCallback(
    (e) => {
      clearTimeout(hideTimeoutId);
      setHideTimeoutId(undefined);

      if (visible) return;

      setPreventHideOnScroll(true);
      setTimeout(() => {
        setPreventHideOnScroll(false);
      }, 500);

      const handlerCoords = handler.current?.getBoundingClientRect();

      const offsetBottom =
        document.documentElement.getBoundingClientRect().height - handlerCoords.bottom;
      const dropdownHeight = handlerCoords.height * items.length;

      setVisible(true);
      setDropdownStyle({
        top: `${
          offsetBottom < dropdownHeight
            ? handlerCoords.bottom - (index + 1) * handlerCoords.height - index
            : handlerCoords.top
        }px`,
        left: `${handlerCoords.right}px`,
      });
    },
    [hideTimeoutId, index, items, visible],
  );

  const handleMouseLeave = useCallback(() => {
    const timeoutId = window.setTimeout(() => {
      setVisible(false);
    }, 100);
    setHideTimeoutId(timeoutId);
  }, []);

  const StyledDropDown = styled(Box)(({ theme }) => {
    return {
      [theme.breakpoints.up('lg')]: {
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? 'all' : 'none',
        position: 'fixed',
        zIndex: 1001,
        background: theme.palette.primary.main,
        width: '15.625rem',
        color: brandedThemeOptions.menuTextColor || '#FFF',
        borderRadius: '10px',
        padding: '4px',
        marginLeft: '2px',
      },
    };
  });

  const DropDown = (
    <StyledDropDown
      sx={visible ? dropdownStyle : {}}
      ref={dropdown}
      onMouseMove={showDropdown}
      onMouseLeave={handleMouseLeave}
    >
      <div {...element('items')}>{items.map(renderItem)}</div>
    </StyledDropDown>
  );

  const handleMouseWheel = useCallback(() => {
    if (hideTimeoutId || preventHideOnScroll) return;
    setHideTimeoutId(
      window.setTimeout(() => {
        setVisible(false);
      }, 50),
    );
  }, [hideTimeoutId, preventHideOnScroll]);

  return (
    <div {...block()}>
      <div
        {...element('handler')}
        ref={handler}
        onMouseMove={showDropdown}
        onMouseLeave={handleMouseLeave}
        onWheel={handleMouseWheel}
        onMouseDown={showDropdown}
      />
      {smallScreen ? DropDown : <Portal isOpen>{DropDown}</Portal>}
    </div>
  );
};

export default memo(SubMenu);
