import { memo } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import './CustomScrollbars.scss';

const CustomScrollbars = ({ children }) => {
  return (
    <SimpleBar>
      { children }
    </SimpleBar>
  );
};

export default memo(CustomScrollbars);
