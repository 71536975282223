import './NonSquareBlock.scss';
import { bem } from 'lib/bem';

const { block, element } = bem('NonSquareBlock');

export default function NonSquareBlock({ className, children, ...props }) {
  return (
    <div {...block({}, className)} {...props}>
      <div {...element('content')}>
        {children}
      </div>
    </div>
  );
}
