import { PureComponent } from 'react';
import { styled } from '@mui/material/styles';

import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { Icon, IconAsButton } from 'lib/ui/icon';

import './FileInput.scss';

const { block, element } = bem('FileInput');
const noop = () => {};

type OwnFileInputProps = {
  onChange?: (...args: any[]) => any;
  onSelect?: (...args: any[]) => any;
  inputRef?: (...args: any[]) => any;
  multiple?: boolean;
  showFiles?: boolean;
  showIcon?: boolean;
  buttonText?: string;
  maxCount?: number;
  accept?: string;
  small?: boolean;
  disabled?: boolean;
};

type FileInputProps = OwnFileInputProps & typeof FileInput.defaultProps;

export default class FileInput extends PureComponent<FileInputProps> {
  static defaultProps = {
    onChange: noop,
    onSelect: noop,
    inputRef: noop,
    showFiles: true,
    showIcon: true,
    multiple: false,
    disabled: false,
  };

  input: any;

  render() {
    const StyledLabel = styled('label')(({ theme }) => ({
      color: theme.palette.primary.main,
    }));

    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className,
      showFiles,
      showIcon,
      multiple,
      accept,
      small,
      disabled,
    } = this.props;

    return (
      <div {...block({ disabled }, className)}>
        <StyledLabel {...element('button', { showFiles, showIcon, small }, 'Button _ghost')}>
          {showIcon && <Icon glyph="upload" />}

          {this.getButtonText()}

          <input
            {...element('input')}
            ref={this.bindInput}
            type="file"
            accept={accept}
            multiple={multiple}
            onChange={this.handleChange}
          />
        </StyledLabel>

        {showFiles && <div {...element('files')}>{this.renderFiles()}</div>}

        {this.hasFiles() && showFiles && (
          <IconAsButton {...element('clear')} onClick={this.clear} glyph="close" />
        )}
      </div>
    );
  }

  getButtonText() {
    const { multiple, buttonText } = this.props;

    if (buttonText) return buttonText;

    return multiple ? t('file_input.choose_files') : t('file_input.choose_file');
  }

  renderFiles() {
    if (!this.hasFiles()) return null;

    const { files } = this.input;

    return files.length === 1
      ? files[0].name
      : `${files.length} ${t('files.plural', { count: files.length })}`;
  }

  hasFiles() {
    return this.input && this.input.files.length > 0;
  }

  bindInput = (input) => {
    this.input = input;
    this.props.inputRef(input);
  };

  handleChange = (e) => {
    this.props.onChange(e);
    this.props.onSelect(this.input.files, e);
    this.forceUpdate();
  };

  clear = (e) => {
    this.input.value = '';
    this.forceUpdate();
  };
}
