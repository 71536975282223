import './IssueTypesTreeSelect.scss';
import { PureComponent } from 'react';
import { reconnect } from 'lib/resource';
import { t } from 'lib/i18n';
import { FormBuilder } from 'lib/ui';
import { formatTreeValue, normalizeTreeValue } from 'lib/queryForm';
import { issueTypes as issueTypesResource } from 'app/issueTypes';

export default reconnect((state, props) => ({
  issueTypes: props.locationId
    ? (issueTypesResource as any).byLocation(state, {
      id: props.locationId,
      activity_id: props.activityId,
    }, { skipFetchState: true })
    : (issueTypesResource as any).tree(state, {}, { skipFetchState: true }),
}))(class IssueTypesTreeSelect extends PureComponent {
  render() {
    const {
      issueTypes, multiple, forQueryForm, title,
    } = this.props as any;
    return (
      <FormBuilder.TreeViewSelect
        title={title || t('issue_types.title')}
        tree={issueTypes.data}
        getLabel={(item) => item.name}
        normalize={forQueryForm ? normalizeTreeValue(multiple) : undefined}
        format={forQueryForm ? formatTreeValue(multiple, issueTypes.data) : undefined}
        autoSelectSingleOption
        {...this.props}
      />
    );
  }
});
