import {
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { CircularProgress } from '@mui/material';
import { bem } from 'lib/bem';

import './scrollable-list.scss';

const { block, element } = bem('ScrollableList');

type ScrollableVerticalListProps = {
  onScrollBootom?: () => void;
  bottomOffset?: number;
  emptyPlaceholder?: ReactNode;
  isLoading?: boolean;
};

export const ScrollableVerticalList = forwardRef<
  HTMLUListElement,
  PropsWithChildren<ScrollableVerticalListProps> & HTMLAttributes<HTMLUListElement>
>(function ScrollableVerticalListComponent(
  { bottomOffset = 100, onScrollBootom, isLoading, children, ...rest },
  ref,
) {
  const containerRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const [containerHeight, setContainerHeight] = useState<number>(bottomOffset);

  const triggerBottomOffset = Math.min(bottomOffset, containerHeight);

  useLayoutEffect(() => {
    const containerElement = containerRef.current;
    const triggerElement = triggerRef.current;

    if (!containerElement || !triggerElement || !onScrollBootom) {
      return () => {};
    }

    const intersectionObserver = new IntersectionObserver(
      ([entity]) => {
        const { intersectionRatio } = entity;

        if (intersectionRatio) {
          onScrollBootom();
        }
      },
      {
        threshold: [1],
        root: containerElement,
      },
    );

    intersectionObserver.observe(triggerElement);

    return () => {
      intersectionObserver.disconnect();
    };
  }, [onScrollBootom, triggerBottomOffset]);

  useLayoutEffect(() => {
    const containerElement = containerRef.current;
    if (!containerElement) {
      return;
    }

    setContainerHeight(containerElement.offsetHeight);
  }, []);

  return (
    <div ref={containerRef} className={block().className}>
      {isLoading && (
        <div className={element('loader-container').className}>
          <CircularProgress />
        </div>
      )}
      <ul ref={ref} className={element('list', { loading: isLoading }).className} {...rest}>
        {children}
        <div
          ref={triggerRef}
          className={element('trigger-element').className}
          style={{
            bottom: triggerBottomOffset,
          }}
        />
      </ul>
    </div>
  );
});
