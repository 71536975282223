import { Divider, Stack, Typography } from '@mui/material';

interface Props {
  name: string;
  cta: string;
  statusView: JSX.Element;
}

const VerifyLocationView = ({ cta, statusView, name }: Props) => {
  return (
    <Stack
      spacing={1}
      sx={{
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        padding: '1rem',
      }}
    >
      <Typography fontSize="20px">{name}</Typography>
      <Typography>{cta}</Typography>
      <Divider />
      <Stack direction="row" spacing={1} alignItems="center">
        {statusView}
      </Stack>
    </Stack>
  );
};

export default VerifyLocationView;
