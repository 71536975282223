import './Expander.scss';
import { PropsWithChildren, PureComponent } from 'react';

import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { Icon } from 'lib/ui/icon';

const { block, element } = bem('Expander');

type OwnProps = {
  className?: string;
  showMoreText?: string;
};

type State = any;

type Props = OwnProps & typeof Expander.defaultProps;

export default class Expander extends PureComponent<PropsWithChildren<Props>, State> {
  static defaultProps = {
    showMoreText: t('common.show_more'),
  };

  state = {
    expanded: false,
  };

  render() {
    const { expanded } = this.state;
    const { className, showMoreText } = this.props;

    return (
      <div {...block({ expanded }, className)}>
        <div {...element('handler')} onClick={this.toggle}>
          {expanded ? t('common.hide') : showMoreText}
          <Icon glyph="squareArrow" />
        </div>

        {expanded && <div {...element('content')}>{this.props.children}</div>}
      </div>
    );
  }

  toggle = (e) => {
    this.setState((state) => ({
      expanded: !state.expanded,
    }));

    e.stopPropagation();
    e.preventDefault();
  };
}
