import { createResource, treeScope } from 'lib/resource';
import { api } from 'lib/api';
import { translit } from 'lib/i18n';
import { put } from 'redux-saga/effects';
import { closeModal } from 'lib/ui/Modal';
import { Roles } from 'app/userRoles/roles';
import { locationsQueries } from 'entities/locations';
import { queryClient } from 'shared/lib/react-query';

import { SAVE_LOCATION_MODAL, VERIFY_LOCATION_MODAL } from './config';

function createNewLocationType(body) {
  // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
  if (typeof body.location_type_id !== 'object' || !body.location_type_id === 'new')
    return Promise.resolve(body);
  return api
    .post('/location_types/create/', {
      body: {
        name: translit(body.location_type_id.name),
        name_ru: body.location_type_id.name,
      },
    })
    .then((json) => ({
      ...body,
      location_type_id: json.response.id,
    }));
}

function* closeOnSuccess() {
  queryClient.invalidateQueries({ queryKey: locationsQueries._def });

  yield put(closeModal(SAVE_LOCATION_MODAL));
}

const defaultPayload = {};

function wrapInRootNode(json) {
  return {
    response: {
      id: -1,
      children: json.response,
    },
  };
}

function wrapInRootNodeNew(json) {
  return {
    response: {
      id: 0,
      children: json.response?.data,
    },
  };
}

const locations = createResource('locations', {
  scopes: {
    tree: {
      request: ({ payload }) =>
        api.get('/locations/', { search: { ...defaultPayload, ...payload } }),

      ...treeScope({
        moveAction: 'LOCATIONS/MOVE',
        removeAction: 'LOCATIONS/REMOVE',
      }),
    },

    newtree: {
      ...treeScope({}),

      request: ({ payload }) =>
        api
          .get('/v4//managed_object_hierarchy/location', { search: { ...payload } })
          .then(wrapInRootNodeNew),
    },

    verifiable: {
      ...treeScope({}),

      request({ payload }) {
        return api.get('/v2/locations/verifiable/', { search: { ...defaultPayload, ...payload } });
      },
    },

    list({ payload }) {
      return api.get('/v2/locations/', { search: { ...defaultPayload, ...payload } });
    },

    suggested: () =>
      api.get('/v2/locations/suggested/', {
        search: defaultPayload,
      }),

    bc: {
      // eslint-disable-next-line no-use-before-define
      request: ({ payload }) =>
        api
          .get('/locations/bc', { search: { ...defaultPayload, ...payload } })
          .then(addChildSelectors),
      ...treeScope({}),
    },

    bcOnly: {
      // eslint-disable-next-line no-use-before-define
      request: ({ payload }) =>
        api
          .get('/locations/bc', { search: { ...defaultPayload, ...payload } })
          .then(addChildSelectors),
      ...treeScope({}),
    },

    byName: ({ payload }) => {
      return api.get('/v3/locations/', { search: { ...defaultPayload, ...payload } });
    },

    byRole: {
      request: ({ payload }) =>
        api
          .get(`/locations/for_role/${Roles.DASHBOARD_WATCHER}/`, {
            search: { ...defaultPayload, ...payload },
          })
          .then(wrapInRootNode),

      ...treeScope({}),
    },
  },

  byId: ({ payload }) =>
    api.get(`/locations/${payload}/`, {
      search: {
        filter_management_company: 'f',
        ...defaultPayload,
      },
    }),

  mutators: {
    create: {
      request: ({ payload }) => {
        return createNewLocationType(payload).then((body) =>
          api.post('/locations/create/', { body }),
        );
      },
      onSuccess: closeOnSuccess,
    },

    update: {
      request: ({ payload }) => {
        return createNewLocationType(payload).then(({ id, ...body }) =>
          api.put(`/locations/${id}/`, { body }),
        );
      },
      onSuccess: closeOnSuccess,
    },

    updateLC({ payload }) {
      return createNewLocationType(payload).then(({ id, attributes }) =>
        api.post(`v3/managed_objects/${id}/update/`, { body: { attributes } }),
      );
    },

    remove({ payload }) {
      return api.post(`/locations/${payload.id}/delete/`);
    },

    move({ payload }) {
      const { id, parentId, beforeId } = payload;

      return api.put(`/locations/${id}/`, {
        body: {
          parent_id: parentId,
          before_id: beforeId,
        },
      });
    },

    verify: {
      request: ({ payload }) => {
        const { id, code } = payload;

        return api.post(`/v2/locations/${id}/owners/`, {
          body: { account_number: code },
        });
      },
      *onSuccess() {
        yield put(closeModal(VERIFY_LOCATION_MODAL));
      },
    },

    uploadChildren({ payload }) {
      const { id, file } = payload;
      const formData = new FormData();

      formData.set('locations_file', file);
      formData.set('parent_id', id);

      return api.post('/v2/locations/upload/', {
        body: formData,
        headers: {
          'Content-Type': null,
        },
      });
    },
  },
});

function addChildSelectors(json) {
  return {
    ...json,
    response: {
      id: 0,
      children: json.response
        ? json.response.map((location) => ({
            ...location,
            childrenSelector:
              location.has_children &&
              ((state) =>
                (locations as any).tree(
                  state,
                  {
                    parent_id: location.id,
                  },
                  { forceFetchOnMount: false },
                )),
          }))
        : [],
    },
  };
}

export default locations;
