import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ModalV2 } from 'shared/ui/modal-v2';
import { notificationsQueries } from 'entities/notification/api/queries';
import { Date, FieldValue, Loader } from 'lib/ui';
import { Box } from '@mui/material';
import { t } from 'lib/i18n';

import './AnnouncementModal.scss';

const { block, element } = bem('AnnouncementModal');

interface AnnouncementModalProps {
  notificationId: number;
  onClose: () => void;
}

export const AnnouncementModal = ({ notificationId, onClose }: AnnouncementModalProps) => {
  const [open, setOpen] = useState(false);

  const {
    data: announcementResponse,
    isLoading,
    isError,
  } = useQuery({
    ...notificationsQueries.announcementDetail(notificationId),
  });

  const announcement = useMemo(() => {
    if (announcementResponse && 'response' in announcementResponse) {
      return announcementResponse.response.data;
    }
    return null;
  }, [announcementResponse]);

  useEffect(() => {
    if (announcementResponse && 'response' in announcementResponse) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [announcementResponse]);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  if (isLoading)
    return (
      <div {...element('loader')}>
        <Loader />
      </div>
    );

  if (isError || !announcement) return <div />;

  return (
    <ModalV2 open={open} onClose={handleClose} title={announcement.title} uppercase>
      <Box {...block()}>
        <Box {...element('dates')}>
          {announcement.start_date && (
            <FieldValue name={t('announcements.fields.start_date')}>
              <Date value={announcement.start_date} />
            </FieldValue>
          )}
          {announcement.end_date && (
            <FieldValue name={t('announcements.fields.end_date')}>
              <Date value={announcement.end_date} />
            </FieldValue>
          )}
        </Box>
        <Box>{announcement.message}</Box>
      </Box>
    </ModalV2>
  );
};
