import {
  forwardRef,
  useMemo,
  useState,
  MouseEvent,
  PropsWithChildren,
  HTMLAttributes,
} from 'react';
import { Fade, Popper, Tooltip } from '@mui/material';

import { bem } from 'lib/bem';
import { Icon } from 'lib/ui/icon';
import type { ActionsComponentProps } from 'shared/ui/dnd-provider';
import { useClickOutside } from 'lib/hooks/useClickOutside';
import { t } from 'lib/i18n';

import './ActionsBlock.scss';

const { block, element } = bem('ActionsBlock');

const MenuButton = (props: PropsWithChildren<HTMLAttributes<HTMLSpanElement>>) => (
  <span className={element('menu-button').className} {...props} />
);

export const ActionsBlock = forwardRef<HTMLDivElement, ActionsComponentProps>(
  function ActionsBlockComponent({ actions, itemId }: ActionsComponentProps, ref) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const inlineActions = useMemo(() => actions?.filter((action) => !action.isMenu), [actions]);

    const menuActions = useMemo(() => actions?.filter((action) => action.isMenu), [actions]);

    const handleClickOutside = () => {
      setAnchorEl(null);
    };

    const { ref: outsideRef } = useClickOutside(handleClickOutside);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const id = `menu-icon-${itemId}`;
    return (
      <div ref={ref} {...block()}>
        <div {...element('wrapper')}>
          {inlineActions?.map((action) => {
            if (action?.component) {
              return action.component;
            }

            return (
              <Tooltip
                key={`${itemId}-${action.name}`}
                placement="top"
                // TODO: #i18n Add translation key type check
                title={t(`common.${action.name.toLowerCase()}`, action.name.toLowerCase())}
              >
                <span
                  {...element('inline-icon', { currentColor: action.isIconCurrentColor })}
                  onClick={() => action.onClick?.()}
                >
                  {action.icon && <Icon glyph={action.icon} />}
                </span>
              </Tooltip>
            );
          })}
          {menuActions && menuActions.length > 0 && (
            <>
              <span
                key={id}
                {...element('inline-icon', { menuIcon: true })}
                aria-controls={open ? id : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <Icon glyph="sortHandler" />
              </span>
              <Popper
                id={id}
                key={`menu-icon-popover-${itemId}`}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                ref={outsideRef}
                {...element('menu-popover')}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={50}>
                    <div {...element('menu-items-container')}>
                      {menuActions.map((action) => {
                        if (action?.component) {
                          return action.component;
                        }

                        return (
                          <MenuButton
                            key={`${itemId}-${action.name}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              action.onClick?.();
                            }}
                          >
                            {/* TODO: #i18n Add translation key type check */}
                            {t(`common.${action.name.toLowerCase()}`, action.name.toLowerCase())}
                          </MenuButton>
                        );
                      })}
                    </div>
                  </Fade>
                )}
              </Popper>
            </>
          )}
        </div>
      </div>
    );
  },
);
