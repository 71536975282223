export enum Roles {
  EXECUTIVE = 'executive',
  RESPONSIBLE = 'responsible',
  EMPLOYEE = 'employee',
  BOSS = 'boss',
  FITTER = 'fitter',
  ADMIN = 'admin',
  MANAGER = 'manager',
  ANALYST = 'analyst',
  CONTACT = 'contact',
  SUPERVISOR = 'supervisor',
  PHONE_AGENT = 'phone_agent',
  USER = 'user',
  TEOREMA_PASS = 'teorema_pass',
  CHECK_IN_WATCHER = 'check_in_watcher',
  CHECK_IN = 'check_in',
  WATCHER = 'watcher',
  SUPER_ADMIN = 'superadmin',
  DELIVERYMAN = 'deliveryman',
  PICKER = 'picker',
  REOPEN_RESPONSIBLE = 'reopen_responsible',
  ANNOUNCEMENT_EDITOR = 'announcement_editor',
  BOOKING_ADMIN = 'booking_admin',
  BOOKINGS = 'bookings',
  INVENTORY_ACCOUNTANT = 'inventory_accountant',
  CHECK_IN_PUSH = 'check_in_push',
  ROUNDS_MONITOR = 'rounds_monitor',
  INSPECTOR = 'inspector',

  CHIEF_OFFICER = 'chief_officer',
  SCHEDULING_MANAGER = 'scheduling_manager',
  GUEST = 'guest',
  DASHBOARD_WATCHER = 'dashboard_watcher',
  SUPPLY_MANAGER = 'supply_manager',
  METERS_MANAGER = 'meters_manager',
  TENANT_REPRESENTATIVE = 'tenant_representative',
  FIELD_COMPARISON = 'field_comparison',
  ISSUE_REMOVER = 'issue_remover',
  TEMP_EMPLOYEE = 'tmp_employee',
}
