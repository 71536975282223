import { PureComponent } from 'react';

// eslint-disable-next-line import/no-cycle
import companies from 'app/companies/companies.resource';
import DictEditor from 'app/common/DictEditor';
import { t_prefixed } from 'lib/i18n';

const label = t_prefixed('companies.pages.list');
export const COMPANY_NAME_MAX_LENGHT = 64;

export default class Companies extends PureComponent {
  render() {
    return (
      <DictEditor
        connectResource={this.connectResource}
        title={label('title')}
        editModalTitle={label('edit_campaign_title')}
        newModalTitle={label('add_campaign_title')}
        removeConfirmationText={label('remove_confirmation')}
        onAddItem={this.addCompany}
        onUpdateItem={this.updateCompany}
        onRemoveItem={this.removeCompany}
        maxFieldLength={COMPANY_NAME_MAX_LENGHT}
      />
    );
  }

  connectResource = (state) => (companies as any).list(state, { count: 9000 });

  addCompany = (company) => {
    (companies as any).create(company);
  };

  updateCompany = (company) => {
    (companies as any).update(company);
  };

  removeCompany = (company) => {
    (companies as any).remove(company);
  };
}
