import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Fade, Paper, Popper } from '@mui/material';
import moment, { Moment } from 'moment';

// eslint-disable-next-line import/no-cycle
import { TextFieldButton } from '../text-field-button/TextFieldButton';
// eslint-disable-next-line import/no-cycle
import { ClockPicker } from './ui/ClockPicker';
import './TimePicker.scss';

const { block, element } = bem('TimePicker');

interface TimePickerProps {
  value?: string | null;
  onChange?: (value: string | null) => void;
  label?: string;
  required?: boolean;
  error?: boolean;
  minTime?: string;
  maxTime?: string;
  format?: string;
  placeholder?: string;
  showTooltip?: boolean;
  width?: string;
}

export const TimePicker = ({
  value,
  onChange,
  label,
  required,
  error,
  minTime,
  maxTime,
  format = 'HH:mm',
  placeholder,
  showTooltip,
  width,
}: TimePickerProps) => {
  const { t } = useTranslation();
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(error);

  useEffect(() => {
    if (error) {
      setIsError(true);
      onChange?.(null);
    }
  }, [error, onChange]);

  const tooltip = useMemo(() => {
    if (!showTooltip) return undefined;

    let text = '';

    if (minTime) {
      text += `${t('time_picker.interval.from')} ${minTime} `;
    }

    if (maxTime) {
      text += `${t('time_picker.interval.to')} ${maxTime} `;
    }

    return text;
  }, [maxTime, minTime, showTooltip, t]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!isOpen && event.target) {
        anchorEl.current = event.target as HTMLButtonElement;
        setIsOpen(true);
      }
    },
    [isOpen],
  );

  const handleSubmitTime = useCallback(
    (time: Moment | null) => {
      setIsError(false);
      let timeValue = time;

      const minTimeMoment = moment(minTime, format);
      const maxTimeMoment = moment(maxTime, format);

      if (timeValue?.isBefore(minTimeMoment) || timeValue?.isAfter(maxTimeMoment)) {
        timeValue = null;
        setIsError(true);
      }

      onChange?.(timeValue ? moment(timeValue).format(format) : null);
      setIsOpen(false);
    },
    [format, maxTime, minTime, onChange],
  );

  const handleCLearValue = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onChange?.(null);
    },
    [onChange],
  );

  return (
    <>
      <Backdrop open={isOpen} style={{ zIndex: 100 }} />
      <div {...block()}>
        <TextFieldButton
          value={value ?? ''}
          onClick={handleClick}
          label={label}
          icon="clock"
          placeholder={placeholder ?? t('time_picker.placeholder')}
          required={required}
          error={error || isError}
          ref={anchorEl}
          tooltip={tooltip}
          onClearValue={handleCLearValue}
          width={width}
        />
        <Popper
          sx={{ zIndex: 1400 }}
          open={isOpen}
          anchorEl={anchorEl.current}
          placement="top"
          transition
          autoFocus
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper {...element('timeSelect')}>
                <ClockPicker
                  onClose={() => setIsOpen((prevState) => !prevState)}
                  value={value ?? null}
                  onSubmitTime={handleSubmitTime}
                  format={format}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </>
  );
};
