import { keyBy, get } from 'lodash';

import { api } from 'lib/api';
import { createResource, updateById } from 'lib/resource';

export default createResource('events', {
  // scopes: {
  //   list: {
  //     request({ payload }) {
  //       return api.get('/v2/users/me/eventstre/', { search: payload });
  //     },
  //     selector(data, index) {
  //       return data && data.map((i) => index[i]);
  //     },
  //     normalizer(response) {
  //       return {
  //         data: response.map((i) => i.id),
  //         index: keyBy(response, (i) => i.id),
  //       };
  //     },
  //     reducer: (state, action) => {
  //       if (action.type === 'EVENTS/REMOVE') {
  //         return updateById(state, action.payload.id, {
  //           $removed: true,
  //         });
  //       }
  //       if (action.type === 'ISSUES/RATE') {
  //         if (!state.index) return state;
  //         const { id } = action.payload;
  //         const itemsToUpdate: any[] = Object.values(state.index).filter(
  //           (event) => get(event, 'payload.issue.id') === id,
  //         );
  //         if (itemsToUpdate.length === 0) return state;
  //         return {
  //           ...state,
  //           index: {
  //             ...state.index,
  //             // в ленте может быть несколько одинаковых событий из-за переоткрытий
  //             ...itemsToUpdate.reduce(
  //               (a, itemToUpdate) => ({
  //                 ...a,
  //                 [itemToUpdate.id]: {
  //                   ...itemToUpdate,
  //                   payload: {
  //                     ...itemToUpdate.payload,
  //                     issue: {
  //                       ...itemToUpdate.payload.issue,
  //                       review: {
  //                         rating: action.payload.rating,
  //                       },
  //                     },
  //                   },
  //                 },
  //               }),
  //               {},
  //             ),
  //           },
  //         };
  //       }
  //       return state;
  //     },
  //   },
  // },
  // byId({ payload }) {
  //   return api.get(`/v2/users/me/events/${payload}/`);
  // },
  // mutators: {
  //   remove({ payload }) {
  //     return api.delete(`/v2/users/me/events/${payload.id}/`);
  //   },
  // },
});
