import { all, call, put, select } from 'redux-saga/effects';
import { reset, submit } from 'redux-form';

import { takeMap } from 'lib/saga-utils';
import { contextNotification } from 'app/notifications';
import { closeModal } from 'lib/ui/Modal';
import { qsParse } from 'lib/helpers/qs';
import users, { isCrossDomainAuth } from 'app/users/users.resource';
import { locations } from 'app/locations';
import { issues } from 'app/issues';
import { issueTypes } from 'app/issueTypes';
import { t } from 'lib/i18n';
import { router } from 'config/router';

import { confCode, pushMobile } from '../entry/QuickLogin';

const notify = contextNotification('users');

function setVisitedFlag() {
  try {
    localStorage.setItem('isVisited', 'true');
  } catch (e) {
    console.error(e);
  }
}

export function redirectAfterLogin(flag, redirectPath = null) {
  const query = qsParse(window.location.search);

  const redirect = query.redirect as string;
  const quickLogin = window.location.hash;
  const redirectUrl = flag ? '/profile/' : '/';

  const regexList = [/^\/login\/?$/, /^\/quick_login\/?$/, /^\/loc\/quick_login\/?$/];

  if (regexList.some((regex) => regex.test(window.location.pathname)) && !quickLogin) {
    // TODO: Unsafe solution
    // See: https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
    router.navigate(redirectPath || redirect || redirectUrl, { replace: true });
  }
}

let isNewUser = false;

export default function* () {
  yield all([
    call(users.saga),

    call(
      takeMap({
        'USERS/LOGIN_SUCCESS': function* () {
          yield setVisitedFlag();
        },

        'USERS/QUICKLOGIN_SUCCESS': function* ({ response }) {
          if (response.metadata.http_code === 201) {
            isNewUser = true;
          }
          yield setVisitedFlag();
        },

        'USERS/CURRENT_SUCCESS': function* ({ response }) {
          setVisitedFlag();

          const loginData = yield select((state) => state.modal.login);

          if (loginData === 'submitIssue') {
            yield put(submit('newIssueForm'));
          }

          yield put(closeModal('login'));
          locations.invalidate();
          issues.invalidate();
          issueTypes.invalidate();

          if (!response.isGuest && !isCrossDomainAuth()) {
            redirectAfterLogin(isNewUser);
          }
        },

        'USERS/UPDATEPROFILE_SUCCESS': function* () {
          // yield put(notify('profile_updated'));
        },

        'USERS/UPDATEPASSWORD_SUCCESS': function* () {
          yield put(notify('password_updated'));
          yield put(reset('changePassword'));
        },

        'USERS/CREATEPASSWORD_SUCCESS': function* () {
          yield put(notify('password_created'));
          yield put(reset('changePassword'));
        },

        *SEND_CONFIRMATION_CODE_SUCCESS() {
          yield put(notify('confirmation_code_sent'));
        },

        'USERS/LOGOUT_SUCCESS': function () {
          window.location.href = '/login';
        },

        'USERS/UPDATE_SUCCESS': function* ({ response }) {
          yield all([
            put(notify('updated')),
            put({
              type: 'USERS_TREE/UPDATE',
              id: response.id,
              data: response,
            }),
            put({
              type: 'GET_USERS_TREE',
            }),
          ]);
        },

        'USERS/PUSHCONFIRMATIONCODE': function* (response) {
          yield put(pushMobile(response.payload));
        },

        'USERS/PUSHCONFIRMATIONCODE_SUCCESS': function* ({ response }) {
          yield put(confCode(response));
          // yield put(notify('confirmation_code_sent'));
        },

        'USERS/PUSHCONFIRMATIONCODE_FAIL': function* (response) {
          try {
            if (response.error.details) {
              yield put({
                type: 'REPEAT_REQUEST',
                data: response.error.details,
                name: 'repeatCode',
              });
            } else {
              return new Error(t('http_errors.serviceUnavailable'));
            }
          } catch (er) {
            yield put({
              type: 'REQUEST_ERROR',
              name: ['isCodeSent', 'repeatCode'],
            });
          }
        },
      }),
    ),
  ]);
}
