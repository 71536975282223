export const ATTRIBUTES_WITHOUT_TITLE = [
  'geolocation_verification',
  'qrcode_verification',
  'label',
];
export const ATTRIBUTES_WITH_COORDINATES = ['geolocation_verification'];

export const getAttrTitle = (attr) => {
  return ATTRIBUTES_WITHOUT_TITLE.includes(attr.renderer) ? null : attr.cta || attr.name;
};

export const getAttrData = (attr, issue) => {
  return {
    ...attr,
    coordinates: ATTRIBUTES_WITH_COORDINATES.includes(attr.renderer)
      ? issue.location?.coordinates
      : {},
  };
};
