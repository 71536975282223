import './EmptyPlaceholder.scss';
import { bem } from 'lib/bem';
import { Icon } from 'lib/ui/icon';

const { block, element } = bem('EmptyPlaceholder');

export default function EmptyPlaceholder({ className, placeholder, ...props }) {
  return (
    <div {...block({}, className)}>
      <div>
        <Icon glyph="rocket" />
      </div>
      <div {...element('text')}>{placeholder}</div>
    </div>
  );
}
