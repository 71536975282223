import i18n from './i18n';

export default function declineWord(number, one, two, five) {
  if (!i18n.language.toLowerCase().startsWith('ru')) {
    if (number > 1) {
      return two;
    }
    return one;
  }
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}
