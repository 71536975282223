import { api } from 'lib/api';
import { createResource } from 'lib/resource';
import { put } from 'redux-saga/effects';
import { closeModal } from 'lib/ui/Modal';

import { SAVE_EMPLOYEE_MODAL } from './EmployeesPermissions/config';

const endpoint = '/v3/teorema/employees/';

function* closeEmployeeModalOnSuccess() {
  yield put(closeModal(SAVE_EMPLOYEE_MODAL));
}

function* closeReportModalOnSuccess() {
  yield put(closeModal(SAVE_EMPLOYEE_MODAL));
}

export default createResource('employees', {
  scopes: {
    employees({ payload }) {
      return api.get(endpoint, { search: payload });
    },

    reportsList({ payload }) {
      return api.get(`${endpoint}/pass_report/`, { search: payload });
    },
  },
  mutators: {
    addTeoremaEmployee: {
      request: ({ payload }) => {
        return api.post(endpoint, { body: payload });
      },
      onSuccess: closeEmployeeModalOnSuccess,
    },

    updateTeoremaEmployee: {
      request: ({ payload }) => {
        const { id } = payload.data;
        return api.put(`${endpoint}/${id}`, { body: payload });
      },
      onSuccess: closeEmployeeModalOnSuccess,
    },

    removeTeoremaEmployee({ payload }) {
      const { id, ...body } = payload;
      return api.delete(`${endpoint}/${id}`, { body });
    },

    reissuePass({ payload }) {
      return api.post(`${endpoint}/reissue_pass`, { body: payload });
    },

    addRegularReport: {
      request: ({ payload }) => {
        return api.post(`${endpoint}/pass_report/`, { body: payload });
      },
      onSuccess: closeReportModalOnSuccess,
    },

    removeRegularReport({ payload }) {
      const { id, ...body } = payload;
      return api.delete(`${endpoint}/pass_report/${id}`, { body });
    },

    updateRegularReport: {
      request: ({ payload }) => {
        const { id, ...body } = payload;
        return api.put(`${endpoint}/pass_report/${id}`, { body });
      },
      onSuccess: closeReportModalOnSuccess,
    },

    confirmEmployeesUpload({ payload }) {
      return api.post(`${endpoint}/upload/${payload}`);
    },
  },
});
