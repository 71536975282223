import { useState } from 'react';
import { TimeClock, TimeField, TimeView } from '@mui/x-date-pickers';
import { ButtonGroup, Typography } from '@mui/material';
import moment, { Moment } from 'moment';

// eslint-disable-next-line import/no-cycle
import { Button } from 'lib/ui';

import './ClockPicker.scss';

const { block, element } = bem('ClockPicker');

interface ClockPickerProps {
  onClose: () => void;
  value: string | null;
  onSubmitTime: (value: Moment | null) => void;
  format?: string;
}

export const ClockPicker = ({
  onClose,
  value,
  onSubmitTime,
  format = 'HH:mm',
}: ClockPickerProps) => {
  const [focusedView, setFocusedView] = useState<TimeView>('hours');
  const [time, setTime] = useState<Moment | null>(() => (value ? moment(value, format) : null));

  return (
    <div {...block()}>
      <Typography {...element('title')}>{t('time_picker.title')}</Typography>
      <div {...element('textFieldContainer')}>
        <div>
          <TimeField
            {...element('textField')}
            value={time}
            selectedSections="hours"
            format="HH"
            onFocus={() => setFocusedView('hours')}
            onChange={setTime}
          />
          <Typography {...element('timeFieldLabel')}>{t('time_picker.hour')}</Typography>
        </div>
        <div style={{ marginTop: '25px' }}>:</div>
        <div>
          <TimeField
            {...element('textField')}
            value={time}
            selectedSections="minutes"
            format="mm"
            onFocus={() => setFocusedView('minutes')}
            onChange={setTime}
          />
          <Typography {...element('timeFieldLabel')}>{t('time_picker.minute')}</Typography>
        </div>
      </div>
      <TimeClock
        {...element('timeClock')}
        value={time}
        onChange={setTime}
        views={['hours', 'minutes']}
        openTo={focusedView}
      />
      <ButtonGroup {...element('buttonGroup')}>
        <div>
          <Button {...element('button')} ghost onClick={onClose}>
            {t('time_picker.cancel')}
          </Button>
          <Button {...element('button')} ghost onClick={() => onSubmitTime(time)}>
            {t('time_picker.ok')}
          </Button>
        </div>
      </ButtonGroup>
    </div>
  );
};
