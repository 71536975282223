import './Attribute.scss';
import { bem } from 'lib/bem';
import { isArray } from 'lodash';
import { FieldValue } from 'lib/ui';
import renderers from '../attributeViewRenderers';
import { getAttrTitle } from '../lib';

const { block, element } = bem('Attribute');

interface Props {
  attrDescriptor: any;
  value: any;
}

export default function Attribute({ attrDescriptor, value }: Props) {
  const Renderer = getRenderer(attrDescriptor);

  if (!Renderer) return null;

  return (
    <FieldValue
      {...block()}
      name={getAttrTitle(attrDescriptor)}
      key={attrDescriptor.id}
    >
      <div {...element('renderer')}>
        {isNoAttrValue(value)
          ? <div {...block()}>–</div>
          : <Renderer {...{ attrDescriptor, value }} />}
      </div>
    </FieldValue>
  );
}

function isNoAttrValue(value) {
  return (isArray(value) && value.length === 0) || value === undefined;
}

function getRenderer(attrDescriptor) {
  const Renderer = renderers[attrDescriptor.renderer];

  if (!Renderer) {
    console.warn(`Missing attribute renderer: "${attrDescriptor.renderer}"`);
    return null;
  }

  return Renderer;
}
