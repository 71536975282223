import { all, call, put } from 'redux-saga/effects';
import { takeMap } from 'lib/saga-utils';
import { contextNotification } from 'app/notifications';
import sms from './sms.resource';

const notify = contextNotification('sms');

export default function* smsSaga() {
  yield all([
    call(sms.saga),

    call(takeMap({
      'SMS/CHANGESMSSETTINGS_SUCCESS': function* () {
        yield put(notify('settings_updated'));
      },
    })),
  ]);
}
