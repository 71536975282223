import { mapValues } from 'lodash';
import { keyBy } from 'lodash/fp';

const keyById = keyBy('id');

export default function updateList(listState, resources, total = undefined) {
  return {
    ...listState,
    fetching: false,
    total: Number(total),
    data: resources.map((i) => i.id),
    byId: {
      ...listState.byId,
      ...mapValues(keyById(resources), (data) => ({ data })),
    },
  };
}
