import './MenuUserBar.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { ModalLink } from 'lib/ui';
import { Icon } from 'lib/ui/icon';
import users from 'app/users/users.resource';
import { t } from 'lib/i18n';
import { Box } from '@mui/material';

const { block, element } = bem('MenuUserBar');

type Props = {
  currentUser: any;
};

export default class MenuUserBar extends PureComponent<Props> {
  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const { currentUser, className } = this.props;

    if (currentUser.isGuest) {
      return (
        <ModalLink to="login" data="1" {...block({}, className)}>
          {t('login.title')}

          <div {...element('logout')}>
            <Icon glyph="logout" />
          </div>
        </ModalLink>
      );
    }

    return (
      <Box {...block({}, className)}>
        {this.renderUserName()}

        <div {...element('logout')} onClick={this.logout}>
          <Icon glyph="logout" />
        </div>
      </Box>
    );
  }

  renderUserName() {
    const { name, surname } = this.props.currentUser;
    return [surname, name].filter(Boolean).join(' ');
  }

  logout = () => (users as any).logout();
}
