import { useCallback, useMemo, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useIsFetching } from '@tanstack/react-query';

import { ModalV2 } from 'shared/ui/modal-v2';
// eslint-disable-next-line import/no-cycle
import { Button, confirm } from 'lib/ui';
import { t } from 'lib/i18n';
import {
  ReactHookFormBuilder as FormBuilder,
  Form,
  useYupValidationResolver,
} from 'shared/ui/form';
import { ActivitySettingsFormData, ActivityTypeEnum } from 'pages/activities-settings';
import {
  ActivitySchema,
  IssueTypeSchema,
  UpdateActivityDataSchema,
} from 'shared/api/v4/swagger/data-contracts';
import { mapActivitySettingsToForm } from 'pages/activities-settings/model/mapActivitySettingsToForm';
import Decline from 'lib/i18n/Decline';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';
import {
  activitiesQueries,
  useCreateActivity,
  useUpdateActivity,
  activitySettingsUpdateSchema,
} from 'entities/activities';
import { LocationTreeSelect } from 'entities/locations';
import { LocationTypesSelect } from 'entities/location-types';

import './ActivitiesSettings.scss';
import { ActivityIconSelect } from './ActivityIconSelect';
import { ActivityTypeSelect } from './ActivityTypeSelect';

interface Props {
  activity?: ActivitySchema;
  issueTypes: IssueTypeSchema[] | null;
  onClose: () => void;
}

const { block, element } = bem('ActivitiesSettingsDialog');
const SubmitButton = () => {
  const {
    formState: { isDirty, isSubmitting },
  } = useFormContext();

  return (
    <Button type="submit" disabled={!isDirty || isSubmitting}>
      {t('common.save')}
    </Button>
  );
};

const ActivitiesSettingsForm = ({ isEdit }) => {
  const { watch } = useFormContext();
  const typeFieldValue = watch('type');

  return (
    <>
      <FormBuilder.Text
        name="name"
        required
        title={t('activities.fields.name')}
        className={element('input', { full: true }).className}
      />
      <ActivityIconSelect element={element('horizontal-select')} />
      <ActivityTypeSelect disableControl={isEdit} />
      {typeFieldValue === ActivityTypeEnum.OPEN_URL && (
        <div className={element('link-fields').className}>
          <LocationTypesSelect
            title={t('location_types.title')}
            name="managed_object_types"
            className={element('input', { full: true }).className}
          />
          <LocationTreeSelect
            name="managed_objects"
            hasStartIcon
            multiSelect
            title={t('locations.title')}
            className={element('input', { full: true }).className}
          />
          <FormBuilder.Text
            name="url"
            required
            title={t('activities.fields.url')}
            className={element('input', { full: true }).className}
          />
        </div>
      )}
    </>
  );
};

export const ActivitiesSettingsDialog = ({ activity, issueTypes, onClose }: Props) => {
  const [open, setOpen] = useState(true);
  const { reset } = useForm();

  const newActivity = useMemo(
    () =>
      ({ name: '', icon_url: '', type: ActivityTypeEnum.CREATE_ISSUE }) as UpdateActivityDataSchema,
    [],
  );

  const initialValues = useMemo(
    () => mapActivitySettingsToForm(activity ?? newActivity),
    [activity, newActivity],
  );
  const { resolver } = useYupValidationResolver(activitySettingsUpdateSchema);

  const { mutateAsync: updateMutation } = useUpdateActivity();
  const { mutateAsync: createMutation } = useCreateActivity();

  const isFetchingIcons = useIsFetching(activitiesQueries.icons);

  const isEdit = activity && 'id' in activity;

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
    reset();
  }, [onClose, reset]);

  const handleSubmit = (formData: ActivitySettingsFormData) => {
    return new Promise((res) => {
      (async () => {
        if (isEdit) {
          const relatedIssueTypesNumber = issueTypes?.length;
          const relatedIssueTypesIds = issueTypes?.map((it) => it.id) ?? [];
          // TODO: remake warning popup
          const warning = (
            <Trans
              i18nKey="activities.pages.editor.update_confirmation"
              components={{
                decline: <Decline number={relatedIssueTypesNumber} />,
                issueTypesLink: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                  <a
                    href={`/settings/issue_types/?issue_type_ids=${relatedIssueTypesIds.join(',')}`}
                  />
                ),
              }}
              values={{ relatedIssueTypesNumber }}
            />
          );
          if (relatedIssueTypesNumber) {
            await confirm(warning, { onClose: () => res(undefined) });
          }

          await updateMutation({
            activityId: activity?.id,
            item: {
              name: formData.name,
              icon_url: formData.icon_url,
              type: formData.type,
              params:
                formData.type === ActivityTypeEnum.OPEN_URL
                  ? { open_url: { in_app: false, url: formData.url } }
                  : undefined,
              managed_objects: formData.managed_objects,
              managed_object_types: formData.managed_object_types,
            },
          });

          // TODO: Обратока ошибок при мутации

          res(undefined);
        } else {
          await createMutation({
            item: {
              name: formData.name,
              icon_url: formData.icon_url,
              type: formData.type,
              params:
                formData.type === ActivityTypeEnum.OPEN_URL
                  ? { open_url: { in_app: false, url: formData.url } }
                  : undefined,
              managed_objects: formData.managed_objects,
              managed_object_types: formData.managed_object_types,
            },
          });
          res(undefined);
        }
      })();
      handleClose();
    });
  };

  return (
    <ModalV2
      open={open}
      onClose={handleClose}
      title={
        isEdit
          ? t('activities.pages.editor.edit_activity_title')
          : t('activities.pages.editor.new_activity_title')
      }
    >
      {isFetchingIcons ? (
        <BusyIndicator sx={{ mt: 1 }} />
      ) : (
        <div className={block().className}>
          <Form<ActivitySettingsFormData>
            initValues={initialValues}
            onSubmit={handleSubmit}
            resolver={resolver}
            className={element('form').className}
          >
            <div className={element('content').className}>
              <ActivitiesSettingsForm isEdit={isEdit} />
            </div>

            <FormBuilder.Buttons left>
              <Button ghost onClick={handleClose}>
                {t('common.cancel')}
              </Button>
              <SubmitButton />
            </FormBuilder.Buttons>
          </Form>
        </div>
      )}
    </ModalV2>
  );
};
