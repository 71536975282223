import { Mutation } from '@tanstack/react-query';

import { contextNotification } from 'app/notifications';
import { activitiesMutationKeys } from 'entities/activities/api/mutations';
import { smsSettingsMutationKeys } from 'entities/sms-settings/api/mutations';
import { NotificationsObserver } from 'features/notify';

export const notifyMutationSuccess = (mutation: Mutation<unknown, unknown, unknown, unknown>) => {
  const { mutationKey } = mutation.options;
  switch (mutationKey) {
    case smsSettingsMutationKeys.update:
      NotificationsObserver.addNotification(contextNotification('sms')('settings_updated').message);
      break;
    case activitiesMutationKeys.create:
      NotificationsObserver.addNotification(contextNotification('activities')('created').message);
      break;
    case activitiesMutationKeys.update:
      NotificationsObserver.addNotification(contextNotification('activities')('updated').message);
      break;
    case activitiesMutationKeys.delete:
      NotificationsObserver.addNotification(contextNotification('activities')('deleted').message);
      break;
    default:
      break;
  }
};
