import { Box } from '@mui/system';

import { t } from 'lib/i18n';
import { ModalV2 } from 'shared/ui/modal-v2';
// eslint-disable-next-line import/no-cycle
import { Button } from 'lib/ui';

import './Alert.scss';

const { block, element } = bem('Alert');
interface Props {
  body?: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

export const Alert = ({ title, body, isOpen, onClose }: Props) => {
  return (
    <ModalV2 title={title} open={isOpen} onClose={onClose}>
      <Box {...block()}>
        <div {...element('body')}>{body}</div>

        <div {...element('buttons')}>
          <Button {...element('button')} onClick={onClose}>
            {t('common.ok')}
          </Button>
        </div>
      </Box>
    </ModalV2>
  );
};
