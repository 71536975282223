import './DocumentPreviewList.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { reconnect } from 'lib/resource';
import attachmentsResource from 'app/attachments.resource';
import DocumentPreview from '../DocumentPreview';
import { makeArray } from '../../issues/Attributes/attributeViewRenderers';

const { block } = bem('DocumentPreviewList');

type OwnProps = {
    docIds?: any;
    onRemove?: (...args: any[]) => any;
    removable?: boolean;
};

export default reconnect((state, props) => {
  const cachedDocs = state.attachments.byIds.index || {};
  const docIds = makeArray(props.docIds);
  const idsToFetch = docIds.filter((id) => !(id in cachedDocs));

  return ({
    resolvedPhotos: (attachmentsResource as any).byIds(state, idsToFetch.length ? idsToFetch : undefined),
    docsIndex: state.attachments.byIds.index || {},
    docIds,
  });
})(class DocumentPreviewList extends PureComponent<any> {
  static defaultProps = {
    onRemove() {},
    removable: false,
  };

  docs: any;

  UNSAFE_componentWillMount() {
    this.docs = this.collectDocs(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.docs = this.collectDocs(nextProps);
  }

  collectDocs(props) {
    const { docsIndex, docIds } = props;

    return docIds
      .map((docId) => docsIndex[docId] || { id: docId })
      .map((doc) => ({
        id: doc.id,
        url: doc.url,
        type: doc.type,
        name: doc.user_file_name,
      }));
  }

  render() {
    const { removable } = this.props;

    return (

      <div {...block()}>
        {this.docs.map((doc) => {
          if (!doc.name) {
            return null;
          }
          return (
            <DocumentPreview
              key={doc.id}
              id={doc.id}
              removable={removable}
              name={doc.name}
              url={doc.url}
              onRemove={(e) => {
                this.props.onRemove(doc.id);
                e.stopPropagation();
              }}
            />
          );
        })}
      </div>
    );
  }
});
