import { useEffect, useRef, useState } from 'react';

import { DASHBOARD_BREAKPOINTS } from '../config';
import { calculateCurrentBreakpoint } from '../lib';

const DEFAULT_CONTAINER_WIDTH = 800;

export const usePageModel = () => {
  const pageRef = useRef<any>(null);

  const [currentContainerWidth, setCurrentContainerWidth] = useState(DEFAULT_CONTAINER_WIDTH);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('');
  useEffect(() => {
    if (pageRef.current) {
      const rem = 16;
      const initialDashboardWidth = pageRef.current.clientWidth - 2 * 2 * rem;
      setCurrentContainerWidth(initialDashboardWidth);
      setCurrentBreakpoint(
        calculateCurrentBreakpoint(DASHBOARD_BREAKPOINTS, initialDashboardWidth),
      );
    }
  }, []);

  const handleBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };

  const handleWidthChange = (containerWidth) => {
    setCurrentContainerWidth(containerWidth);
  };

  return {
    ref: pageRef,
    currentBreakpoint,
    currentContainerWidth,
    onBreakpointChange: handleBreakpointChange,
    onWidthChange: handleWidthChange,
  };
};
