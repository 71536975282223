import { Helmet } from 'react-helmet';
import { BrandName } from '../../types/brand';

interface Props {
  brand: BrandName;
}

const AppFavicons = ({ brand }: Props) => {
  const faviconPath = `/brands/${brand}/favicon`;
  const splashscreensPath = `/brands/${brand}/splashscreens`;
  return (
    <Helmet>
      <link rel="icon" type="image/png" href={`${faviconPath}/favicon-16x16.png`} sizes="16x16" />
      <link rel="icon" type="image/png" href={`${faviconPath}/favicon-32x32.png`} sizes="32x32" />
      <link rel="icon" type="image/png" href={`${faviconPath}/favicon-96x96.png`} sizes="96x96" />
      <link rel="icon" type="image/png" href={`${faviconPath}/favicon-128x128.png`} sizes="128x128" />
      <link rel="icon" type="image/png" href={`${faviconPath}/favicon-196x196.png`} sizes="196x196" />
      <link rel="apple-touch-icon" sizes="16x16" href={`${faviconPath}/favicon-16x16.png`} />
      <link rel="apple-touch-icon" sizes="32x32" href={`${faviconPath}/favicon-32x32.png`} />
      <link rel="apple-touch-icon" sizes="96x96" href={`${faviconPath}/favicon-96x96.png`} />
      <link rel="apple-touch-icon" sizes="128x128" href={`${faviconPath}/favicon-128x128.png`} />
      <link rel="apple-touch-icon" sizes="196x196" href={`${faviconPath}/favicon-196x196.png`} />
      <link rel="apple-touch-icon" sizes="392x392" href={`${faviconPath}/favicon-392x392.png`} />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2048-2732.png`} media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2732-2048.png`} media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1668-2388.png`} media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2388-1668.png`} media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1536-2048.png`} media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2048-1536.png`} media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1668-2224.png`} media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2224-1668.png`} media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1620-2160.png`} media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2160-1620.png`} media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1284-2778.png`} media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2778-1284.png`} media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2778-1284.png`} media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2532-1170.png`} media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1125-2436.png`} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2436-1125.png`} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1242-2688.png`} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2688-1242.png`} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-828-1792.png`} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1792-828.png`} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1242-2208.png`} media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-2208-1242.png`} media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-750-1334.png`} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1334-750.png`} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-640-1136.png`} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
      <link rel="apple-touch-startup-image" href={`${splashscreensPath}/apple-splash-1136-640.png`} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
    </Helmet>
  );
};

export default AppFavicons;
