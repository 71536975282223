import './datepicker.scss';
import { useCallback, useEffect, useRef, useState, memo, ComponentType } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { DatePicker as MUIDatePicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

import { DATE_WITHOUT_TIME, READABLE_DATE } from 'lib/constants/dates';

interface DatepickerProps {
  name?: string;
  value?: any;
  onChange: any;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date;
  endDate?: Date;
  externalOpen?: boolean;
  disableCloseOnSelect?: boolean;
  onExternalOpenChange?: (value: boolean) => void;
  isRangePicker?: boolean;
  form?: string;
  isEndPicker?: boolean;
}
type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween?: boolean;
  isFirstDay?: boolean;
  isLastDay?: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: 'lightgray',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
  }),
  ...(isFirstDay && {
    borderRadius: 0,
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isLastDay && {
    borderRadius: 0,
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.light,
  }),
})) as ComponentType<CustomPickerDayProps>;

export const Datepicker = memo(
  ({
    onChange,
    value,
    placeholder,
    maxDate,
    minDate,
    externalOpen,
    disableCloseOnSelect,
    isEndPicker,
    startDate,
    endDate,
    name,
    onExternalOpenChange,
    isRangePicker,
    form,
  }: DatepickerProps) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    // MUI datepicker doesnt rerender if dont store redux value in internal state.
    const [currentValue, setCurrentValue] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(moment());

    useEffect(() => {
      setCurrentValue(value || null);
    }, [value]);

    const handleMonthChange = useCallback((date: Date) => {
      setCurrentMonth(moment(date));
    }, []);

    const handleClose = useCallback(() => {
      if (onExternalOpenChange) {
        onExternalOpenChange(false);
      } else {
        setOpen(false);
      }
    }, [onExternalOpenChange]);

    const handleChange = useCallback(
      (newVal) => {
        const newValue = newVal || null;
        setCurrentValue(newValue);
        onChange(newValue);
        handleClose();
      },
      [handleClose, onChange],
    );

    const handleClear = useCallback(
      (evt) => {
        evt.stopPropagation();
        onChange(null);
        setCurrentValue(null);
      },
      [onChange],
    );

    const renderDay = useCallback(
      (props) => {
        const start = startDate;
        const end = endDate;
        const { day } = props;

        if (start && end) {
          const dayIsBetween = moment(day).isBetween(start, end);
          const isFirstDay = moment(day).isSame(start, 'day');
          const isLastDay = moment(day).isSame(end, 'day');
          return (
            <CustomPickersDay
              day={day}
              outsideCurrentMonth={day.month() !== currentMonth.month()}
              onDaySelect={handleChange}
              isFirstVisibleCell={false}
              isLastVisibleCell={false}
              disabled={isEndPicker && day.isBefore(minDate)}
              dayIsBetween={dayIsBetween}
              isFirstDay={isFirstDay}
              isLastDay={isLastDay}
              selected={day.isSame(value)}
            />
          );
        }

        if (start) {
          const isFirstDay = moment(day).isSame(start, 'day');
          return (
            <CustomPickersDay
              day={day}
              outsideCurrentMonth={day.month() !== currentMonth.month()}
              onDaySelect={handleChange}
              disabled={day.isBefore(minDate)}
              isFirstVisibleCell={false}
              isLastVisibleCell={false}
              isFirstDay={isFirstDay}
              selected={day.isSame(value)}
            />
          );
        }

        if (end) {
          const isLastDay = moment(day).isSame(end, 'day');
          return (
            <CustomPickersDay
              day={day}
              outsideCurrentMonth={day.month() !== currentMonth.month()}
              onDaySelect={handleChange}
              isFirstVisibleCell={false}
              isLastVisibleCell={false}
              isLastDay={isLastDay}
              selected={day.isSame(value)}
            />
          );
        }

        return (
          <PickersDay
            day={day}
            outsideCurrentMonth={day.month() !== currentMonth.month()}
            onDaySelect={handleChange}
            isFirstVisibleCell={false}
            disabled={minDate && day.isBefore(minDate)}
            selected={day.isSame(value)}
            isLastVisibleCell={false}
            today={day.isSame(new Date(), 'day')}
          />
        );
      },
      [currentMonth, endDate, handleChange, isEndPicker, minDate, startDate, value],
    );

    const InputRef = useRef<HTMLInputElement>(null);

    const handleOpen = useCallback(() => {
      if (onExternalOpenChange) {
        onExternalOpenChange(true);
      } else {
        setOpen(true);
      }
    }, [onExternalOpenChange]);

    const renderInput = useCallback(
      (props) => {
        // Для красивого отображения даты
        const label =
          props.value && props.value.indexOf('YYYY') === -1
            ? moment(props.value, DATE_WITHOUT_TIME).format(READABLE_DATE.toUpperCase())
            : props.placeholder ?? '';

        return <TextField {...props} type="text" value={label} onClick={handleOpen} />;
      },
      [handleOpen],
    );

    // В mui висит PR, способ с патчем не подошел
    if (value && value._i === 'Invalid date') {
      dispatch({
        type: '@@redux-form/CHANGE',
        payload: null,
        meta: {
          form,
          field: name,
          touch: false,
          persistentSubmitErrors: false,
        },
      });
    }

    return (
      <MUIDatePicker
        slotProps={{
          popper: { placement: 'bottom-start', className: 'CalendarWrapper', keepMounted: true },
          inputAdornment: { position: 'start', style: { position: 'absolute', marginLeft: '8px' } },
          toolbar: { toolbarPlaceholder: t('common.date_placeholder') },
          clearButton: { style: { opacity: 1, marginLeft: '-40px' } },
          field: {
            clearable: true,
            onClear: handleClear,
            onKeyDown: (e) => {
              e.preventDefault();
            },
          },
          textField: { placeholder },
        }}
        views={['year', 'month', 'day']}
        open={externalOpen === undefined ? open : externalOpen}
        onOpen={handleOpen}
        onMonthChange={handleMonthChange}
        onClose={handleClose}
        value={currentValue}
        inputRef={InputRef}
        autoFocus={false}
        format={DATE_WITHOUT_TIME}
        sx={{
          '& .MuiInputBase-adornedStart': {
            paddingLeft: 0,
            paddingRight: 0,
          },
          '& .MuiInputBase-inputAdornedStart': {
            paddingLeft: '28px !important',
          },
          '& .MuiSvgIcon-root': {
            fill: 'black',
            opacity: '0.5',
          },
          '& .MuiOutlinedInput-root': {
            paddingRight: 0,
          },
        }}
        slots={{ day: renderDay, textField: renderInput }}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleChange}
        closeOnSelect
        localeText={{
          toolbarTitle: t('common.date_placeholder'),
          cancelButtonLabel: t('common.cancel'),
        }}
      />
    );
  },
);

Datepicker.displayName = 'Datepicker';
