import { keyBy } from 'lodash/fp';
import { without } from 'lodash';
import updateList from './updateList';

const arrayMap = keyBy((i) => i);

const getListInitialState = () => ({
  fetching: false,
  data: null,
  byId: {},
});

function updateResource(listState, action) {
  const id = action.response ? action.response.id : action.id;
  const data = action.response ? action.response : action.data;

  if (!listState.byId[id]) {
    return listState;
  }

  return {
    ...listState,
    byId: {
      ...listState.byId,
      [id]: {
        ...listState.byId[id],
        data: {
          ...listState.byId[id].data,
          ...data,
        },
      },
    },
  };
}

export default function listReducer(prefix, options = {}) {
  const updateOn = arrayMap((options as any).updateOn || []);
  const updateListOn = arrayMap((options as any).updateListOn || []);
  const removeOn = arrayMap((options as any).removeOn || []);

  return (state = getListInitialState(), action) => {
    if (action.type in updateOn) {
      return updateResource(state, action);
    }

    if (action.type in updateListOn) {
      return {
        ...state,
        forceUpdate: true,
      };
    }

    if (action.type in removeOn) {
      return {
        ...state,
        data: without(state.data, action.id),
      };
    }

    switch (action.type) {
      case `${prefix}_START`:
        return {
          ...state,
          request: action.requestAction,
          fetching: true,
          forceUpdate: false,
        };

      case `${prefix}_SUCCESS`:
        return updateList(state, action.response, action.total);

      default:
        return state;
    }
  };
}
