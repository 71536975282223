import { memo, useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { chunk, groupBy } from 'lodash';
import moment from 'moment';

import { DayInfo } from '../types';
import Button from '../../../../Button';
import { convertDate } from '../../../../../helpers/dates';
import './DaySlots.scss';
import { bem } from '../../../../../bem';
import MonthsSwitcher from './MonthsSwitcher';
import { addMissingSlotsToEachMonth } from './utils';
import { getNeededStylesFromModifiers } from '../../../../../helpers/styles';

const { block, element } = bem('DaySlots');

interface Props {
  initialSlots: DayInfo[];
  getSlotWrapperModifiers: (slot: DayInfo) => Record<string, boolean>;
  getSlotModifiers: (slot: DayInfo) => Record<string, boolean>;
  onSlotSelection: (slot: DayInfo) => () => void;
  checkIfSlotButtonDisabled: (slot: DayInfo) => boolean;
}

const DaySlotsView = ({
  initialSlots,
  getSlotWrapperModifiers,
  getSlotModifiers,
  onSlotSelection,
  checkIfSlotButtonDisabled,
}: Props) => {
  const slotsByMonth: Record<string, DayInfo[]> = useMemo(() => {
    return groupBy(initialSlots, (day) => {
      return moment(day.date).format('YYYY-MM');
    });
  }, [initialSlots]);

  const slotsDividedByWeekForEachMonth = useMemo(() => {
    const allSlots = addMissingSlotsToEachMonth(slotsByMonth);
    return Object.values(allSlots).map((monthSlots) => chunk(monthSlots, 7));
  }, [slotsByMonth]);

  const [currentMonthIndex, setCurrentMonthIndex] = useState(0);

  const handleMonthChange = useCallback(
    (newIndex) => () => {
      if (newIndex >= 0 && newIndex < Object.keys(slotsByMonth).length) {
        setCurrentMonthIndex(newIndex);
      }
    },
    [slotsByMonth],
  );

  const currentMonthSlots = slotsDividedByWeekForEachMonth[currentMonthIndex];

  const getSlotStyles: any = useCallback(
    (modifiers) =>
      ({ theme }) => {
        const selectors = {
          selected: {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: '#FFF !important',
          },
        };
        return getNeededStylesFromModifiers(selectors, modifiers);
      },
    [],
  );

  return (
    <div {...block()}>
      <MonthsSwitcher
        slotsByMonth={slotsByMonth}
        value={currentMonthIndex}
        onChange={handleMonthChange}
      />
      <Box>
        <Box {...element('row')}>
          {moment.weekdaysShort(true).map((weekDay) => (
            // TODO: Add key prop
            // eslint-disable-next-line react/jsx-key
            <Box {...element('slotWrapper', { weekDay: true })}>{weekDay}</Box>
          ))}
        </Box>
        {(currentMonthSlots || []).map((group) => {
          return (
            // TODO: Add key prop
            // eslint-disable-next-line react/jsx-key
            <Box sx={{}} {...element('row')}>
              {group.map((slot: DayInfo) => {
                if (!slot.date) {
                  // TODO: Add key prop
                  // eslint-disable-next-line react/jsx-key
                  return <Box {...element('slotWrapper', { monthDay: true })} />;
                }

                const slotWrapperModifiers = getSlotWrapperModifiers(slot);
                const slotModifiers = getSlotModifiers(slot);
                const StyledButton = styled(Button)(getSlotStyles(slotModifiers));

                const parsedDate = convertDate(slot.date);

                return (
                  // TODO: Add key prop
                  // eslint-disable-next-line react/jsx-key
                  <Box {...element('slotWrapper', slotWrapperModifiers)}>
                    <StyledButton
                      {...element('slot', slotModifiers)}
                      id={slot.date}
                      ghost
                      onClick={onSlotSelection(slot)}
                      disabled={checkIfSlotButtonDisabled(slot)}
                    >
                      {parsedDate?.date()}
                    </StyledButton>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

export default memo(DaySlotsView);
