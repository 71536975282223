/* eslint-disable camelcase */
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { apiClient_v4 } from 'shared/api/v4/client';
import { GetManagedObjectsHierarchyManagedObjectHierarchyMoInternalNameGetParams } from 'shared/api/v4/swagger/data-contracts';
import { apiClient_v2 } from 'shared/api/v2/client';
import { convertLocationsArrayIntoTreeNode } from 'shared/lib/tree-view-helpers';
import { apiClient_v1 } from 'shared/api/v1/client';

export const locationsQueries = createQueryKeys('locations', {
  list: (
    params: Omit<
      GetManagedObjectsHierarchyManagedObjectHierarchyMoInternalNameGetParams,
      'moInternalName'
    >,
  ) => ({
    queryKey: [params],
    queryFn: () => apiClient_v4.locations.getLocations(params),
  }),
  suggestedLocationsList: {
    queryKey: null,
    queryFn: async () => {
      const result = await apiClient_v2.locations.getSuggestedLocations();
      return convertLocationsArrayIntoTreeNode(result.response);
    },
  },
  byName: (params: { name: string }) => ({
    queryKey: [params],
    queryFn: async () => apiClient_v2.locations.getLocationsByName(params),
  }),

  byParentId: (params: { parentId: number }) => ({
    queryKey: [params],
    queryFn: async () => {
      const result = await apiClient_v4.locations.getLocationChildrenByParentId(params);
      return result.response.data;
    },
  }),

  byId: (params: { id: number }) => ({
    queryKey: [params],
    queryFn: async () => apiClient_v1.locations.getLocationById(params),
  }),
});
