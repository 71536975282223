import { all, call, put } from 'redux-saga/effects';
import { takeMap } from 'lib/saga-utils';
import { notify } from 'app/notifications';

import employees from './employees.resource';

export default function* employeesSaga() {
  yield all([
    call(employees.saga),

    call(
      takeMap({
        'EMPLOYEES/ADDTEOREMAEMPLOYEE_SUCCESS': function* ({ response }) {
          if (response.metadata?.message) {
            yield put(
              notify({
                id: 'EMPLOYEES/ADDTEOREMAEMPLOYEE_SUCCESS',
                text: response.metadata?.message,
              }),
            );
          }
        },

        'EMPLOYEES/REMOVETEOREMAEMPLOYEE_SUCCESS': function* ({ response }) {
          if (response.metadata?.message) {
            yield put(
              notify({
                id: 'EMPLOYEES/REMOVETEOREMAEMPLOYEE_SUCCESS',
                text: response.metadata?.message,
              }),
            );
          }
        },

        'EMPLOYEES/UPDATETEOREMAEMPLOYEE_SUCCESS': function* ({ response }) {
          if (response.metadata?.message) {
            yield put(
              notify({
                id: 'EMPLOYEES/UPDATETEOREMAEMPLOYEE_SUCCESS',
                text: response.metadata?.message,
              }),
            );
          }
        },
        'EMPLOYEES/ADDREGULARREPORT_SUCCESS': function* ({ response }) {
          if (response.metadata?.message) {
            yield put(
              notify({
                id: 'EMPLOYEES/ADDREGULARREPORT_SUCCESS',
                text: response.metadata?.message,
              }),
            );
          }
        },

        'EMPLOYEES/REMOVEREGULARREPORT_SUCCESS': function* ({ response }) {
          if (response.metadata?.message) {
            yield put(
              notify({
                id: 'EMPLOYEES/REMOVEREGULARREPORT_SUCCESS',
                text: response.metadata?.message,
              }),
            );
          }
        },

        'EMPLOYEES/UPDATEREGULARREPORT_SUCCESS': function* ({ response }) {
          if (response.metadata?.message) {
            yield put(
              notify({
                id: 'EMPLOYEES/UPDATEREGULARREPORT_SUCCESS',
                text: response.metadata?.message,
              }),
            );
          }
        },
        'EMPLOYEES/REISSUEPASS_SUCCESS': function* ({ response }) {
          if (response.metadata?.message) {
            yield put(
              notify({ id: 'EMPLOYEES/REISSUEPASS_SUCCESS', text: response.metadata?.message }),
            );
          }
        },
      }),
    ),
  ]);
}
