import { t } from 'lib/i18n';
// eslint-disable-next-line import/no-cycle
import { usersService } from 'app/users';
import { routesMap } from 'config/routesMap';

enum MenuItemsIds {
  NOTIFICATIONS = 'notifications',
  ISSUES = 'issues',
  BOOKINGS = 'bookings',
  REPORTS = 'reports',
  METRICS = 'metrics',
  INVENTORY = 'inventory',
  LOGS = 'logs',
  SETTINGS = 'management',
}

const visibleForUser =
  (user, areSubmenuItems = false) =>
  (item) => {
    if (areSubmenuItems && item.route && item.route.path === routesMap.bookings.path) {
      if (user.management_company && usersService.userHasAccessToRoute(user, item.route)) {
        return user.management_company.booking_enable;
      }
      return false;
    }

    if (item.route && !usersService.userHasAccessToRoute(user, item.route)) {
      return false;
    }

    if (item.subMenu) {
      const atLeastOneVisible = item.subMenu.some(visibleForUser(user));
      if (!atLeastOneVisible) {
        return false;
      }
    }

    return true;
  };

const getAllowedMenuItems = (menuItems) => (currentUser) => {
  const filtredItems = menuItems.filter(visibleForUser(currentUser)).map((item) => ({
    ...item,
    subMenu: item.subMenu && item.subMenu.filter(visibleForUser(currentUser, true)),
  }));

  if (currentUser.external_links) {
    currentUser.external_links.forEach((item) => {
      if (item.parent_menu_item === null) {
        filtredItems.push({
          label: item.name,
          route: {
            path: `/external?${item.uuid}`,
          },
          icon: 'upload',
        });

        return;
      }
      filtredItems.forEach((menuItem) => {
        if (menuItem.id && menuItem.id === item.parent_menu_item) {
          menuItem.subMenu.push({
            label: item.name,
            route: {
              path: `/external?${item.uuid}`,
            },
          });
        }
      });
    });
  }

  return filtredItems;
};

export const menuItems = getAllowedMenuItems([
  {
    label: t('activities.title'),
    route: routesMap.main,
    icon: 'new2',
    onlyActiveOnIndex: true,
    isItemActive(pathname) {
      return pathname.match(/^\/(qr|issues\/new|issues\/group\/new|locations)/);
    },
  },
  {
    id: MenuItemsIds.NOTIFICATIONS,
    label: t('menu.feed'),
    route: routesMap.notifications,
    icon: 'notification_bell_icon',
  },
  {
    id: MenuItemsIds.ISSUES,
    label: t('menu.issues'),
    icon: 'issues',
    isItemActive(pathname) {
      return pathname.match(
        /^\/(issues\/incoming|issues\/in_progress|issues\/on_check|issues\/accidents|issues\/closed)/,
      );
    },
    subMenu: [
      {
        label: t('menu.created_by_me_issues'),
        route: routesMap.createdByMeIssues,
      },
      {
        label: t('menu.incoming_issues'),
        route: routesMap.incomingIssues,
      },
      {
        label: t('menu.in_progress_issues'),
        route: routesMap.issuesInProgress,
      },
      {
        label: t('menu.on_check_issues'),
        route: routesMap.issuesOnCheck,
      },
      {
        label: t('menu.closed_issues'),
        route: routesMap.closedIssues,
      },
    ],
  },
  {
    id: MenuItemsIds.BOOKINGS,
    label: t('menu.bookings.title'),
    icon: 'bookings',
    isItemActive(pathname) {
      return pathname.match(/^\/(bookings)/);
    },
    subMenu: [
      {
        label: t('menu.bookings.my_bookings'),
        route: routesMap.myBookings,
      },
      {
        label: t('menu.bookings.book'),
        route: routesMap.book,
      },
    ],
  },
  {
    id: MenuItemsIds.REPORTS,
    label: t('menu.reports'),
    icon: 'list',
    isItemActive(pathname) {
      return pathname.match(/^\/(reports)|(analytical-reports)/);
    },
    subMenu: [
      {
        label: t('menu.reports'),
        route: routesMap.reports,
      },
      {
        label: t('menu.analytical_reports'),
        route: routesMap.analyticalReports,
      },
    ],
  },
  {
    id: MenuItemsIds.LOGS,
    label: t('menu.logs'),
    icon: 'logs',
    isItemActive(pathname) {
      return pathname.match(/^\/(round-reports)|(checkins)|(inventory-report)/);
    },
    subMenu: [
      {
        label: t('menu.rounds_reports'),
        route: routesMap.roundsReports,
      },
      {
        label: t('menu.inventory_report'),
        route: routesMap.inventoryReport,
      },
      {
        label: t('menu.checkin_list'),
        route: routesMap.checkins,
      },
    ],
  },
  {
    id: MenuItemsIds.METRICS,
    label: t('menu.metrics'),
    icon: 'metrics',
    route: routesMap.metrics,
  },
  {
    id: MenuItemsIds.INVENTORY,
    label: t('inventories.title'),
    icon: 'inventory',
    route: routesMap.inventory,
  },
  {
    label: t('menu.field_comparison'),
    icon: 'onCheck',
    route: routesMap.fieldComparison,
  },
  {
    label: t('menu.profile'),
    icon: 'person',
    route: routesMap.profile,
  },
  {
    id: MenuItemsIds.SETTINGS,
    label: t('menu.settings.title'),
    icon: 'settings',
    isItemActive(pathname) {
      return pathname.match(/^\/(settings)/);
    },
    subMenu: [
      {
        label: t('menu.settings.companies'),
        route: routesMap.companies,
      },
      {
        label: t('menu.settings.roles'),
        route: routesMap.userRoles,
      },
      {
        label: t('menu.settings.users'),
        route: routesMap.users,
      },
      {
        label: t('menu.settings.replaces'),
        route: routesMap.substitutions,
      },
      {
        label: t('menu.settings.activities'),
        route: routesMap.activities,
      },
      {
        label: t('menu.settings.attributes'),
        route: routesMap.attributes,
      },
      {
        label: t('menu.settings.issue_types'),
        route: routesMap.issueTypes,
      },
      {
        label: t('menu.settings.location_types'),
        route: routesMap.locationTypes,
      },
      {
        label: t('menu.settings.locations'),
        route: routesMap.locations,
      },
      {
        label: t('menu.settings.metaflows'),
        route: routesMap.metaflows,
      },
      {
        label: t('menu.settings.issue_schedules'),
        route: routesMap.issueSchedules,
      },
      {
        label: t('menu.settings.announcements'),
        route: routesMap.announcements,
      },
      {
        label: t('menu.settings.bookings'),
        route: routesMap.bookings,
      },
      {
        label: t('menu.settings.employees-permissions'),
        route: routesMap.employeesPermissions,
      },
      {
        label: t('menu.settings.tenant-documents'),
        route: routesMap.tenantDocs,
      },
    ],
  },
]);
