import { snakeCase, isPlainObject, memoize, isArray } from 'lodash';
import { getStore } from 'index';
import { createSelector } from 'reselect';

import * as endpointTypes from './endpointTypes';
import getRequestKey from './getRequestKey';

const stringify = getRequestKey;

const normalizeActionPart = (str) => snakeCase(str).toUpperCase();

const getRequestActionName = (scope, endpoint) =>
  [scope, endpoint].map(normalizeActionPart).join('/');

export interface ScopeResult<T> {
  data?: T;
  fetching: boolean;
}

export default class ResourceScope {
  _selector: any;

  actionName: any;

  name: any;

  normalizer: any;

  options: any;

  reducer: any;

  request: any;

  resource: any;

  selector: any;

  constructor(resource, name, options, list = true) {
    this.name = name;
    this.resource = resource;
    this.actionName = getRequestActionName(this.resource.name, this.name);

    if (isPlainObject(options)) {
      const type = endpointTypes[options.type];

      this.request = options.request;
      this.selector = options.selector || type.selector;
      this.normalizer = options.normalizer || type.normalizer;
      this.reducer = options.reducer || ((state) => state);
      this.options = options.options || {};
    } else {
      const type = list ? endpointTypes.list : endpointTypes.item;

      this.request = options;
      this.selector = type.selector;
      this.normalizer = type.normalizer;
      this.reducer = (state) => state;
      this.options = {};
    }

    this._selector = createSelector(
      (state) => {
        if (!state || !state[this.resource.name]) {
          throw new TypeError(
            `Resources connecting error (${this.resource.name}).\n Probably, you didn't pass state as first arguments to resource selector`,
          );
        }

        return state[this.resource.name][this.name];
      },
      (scope) =>
        memoize((payload) => {
          if (!payload) return {};
          const { data, fetching, failed, total, outdated } =
            scope.data[getRequestKey(payload)] || {};
          const { index } = scope;

          return {
            $Resource: true,
            options: {
              ...this.options,
            },
            content: {
              $ResourceResults: true,
              fetching,
              failed,
              outdated,
              total,
              action: {
                type: this.actionName,
                payload,
              },
              data: this.selector(data, index),

              byId(id) {
                // для списка в виде массива
                if (isArray(this.data)) {
                  return (this.data || []).find((c) => String(c.id) === String(id));
                }

                // для деревьев
                let obj = {};
                const findValue = (arr) => {
                  for (let i = 0; i < arr.length; i++) {
                    if (String(arr[i].id) === String(id)) {
                      obj = arr[i];
                      break;
                    }

                    if (arr[i].children.length !== 0) {
                      findValue(arr[i].children);
                    }
                  }
                  return obj;
                };

                return findValue(this.data.children);
              },
            },
          };
        }, stringify),
    );
  }

  _getState() {
    return getStore().getState()[this.resource.name][this.name];
  }

  createSelector() {
    /* eslint-disable-next-line */
    const scope = this;
    /* eslint-disable-next-line */
    const selector = (state, payload, options) => {
      return {
        $Resource: true,
        options,
        result: this._selector(state)(payload, options),
      };
    };

    selector.invalidate = () => {
      getStore().dispatch({
        type: `${this.actionName}/INVALIDATE`,
      });
    };

    selector.request = (payload) => scope.request({ payload }).then((json) => json.response);

    return selector;
  }
}
