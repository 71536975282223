import { Box } from '@mui/material';

import { Icon } from 'lib/ui/icon';
import { bem } from 'lib/bem';

import './Logo.scss';

const { block } = bem('Logo');

interface Props {
  inverted?: boolean;
  width?: number | string | null;
  height?: number | string;
  fullSize?: boolean;
  className?: string;
  hidden?: boolean;
}

export const Logo = ({ inverted, width, height, fullSize, className, hidden }: Props) => {
  const sx = width ? { '& svg': { width } } : undefined;
  const glyphStr = hidden ? 'shortLogo' : 'textLogo';
  return (
    <Box sx={sx} {...block({}, className)}>
      <Icon
        width={width ?? undefined}
        height={height}
        fullSize={fullSize}
        glyph={glyphStr}
        sx={{
          bgcolor: ({ palette: { customBackground, primary } }) =>
            inverted ? customBackground?.main : primary.main,
          fill: ({ palette: { primary } }) => (inverted ? primary.main : primary.light),
          position: 'relative',
        }}
      />
    </Box>
  );
};
