import { memo } from 'react';
import { FormBuilder } from 'lib/ui';
import { ManagedObjectType } from '../../managedObjects/types';

interface Props {
  name: string;
  title?: string;
  data: ManagedObjectType[];
  required?: boolean;
  normalize?: (item: any) => any;
}

const BookingItemTypesSelect = ({ name, title, data, normalize, required }: Props) => {
  const getItemLabel = (item: ManagedObjectType) => {
    return item.name_ru;
  };

  const getItemValue = (item: ManagedObjectType) => {
    return item.id;
  };

  return (
    <FormBuilder.Select
      required={required}
      clearable
      name={name}
      title={title || t('locations.fields.booking_item_type')}
      options={data}
      getItemLabel={getItemLabel}
      getItemValue={getItemValue}
      normalize={normalize || null}
    />
  );
};

export default memo(BookingItemTypesSelect);
