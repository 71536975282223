import { mapKeys, snakeCase } from 'lodash';
import { call, fork, takeEvery } from 'redux-saga/effects';
import { resourceAction } from 'lib/api/resourceAction';

export function takeMap(map) {
  const pattern = (action) => action.type in map;

  return function* () {
    yield takeEvery(pattern, function* (action) {
      yield call(map[action.type], action);
    });
  };
}

export function resourceWatchers(watchMap) {
  return function* watchRequests() {
    for (const watchAction in watchMap) {
      yield takeEvery(watchAction, function* (request) {
        const fn = watchMap[watchAction];
        yield fork(resourceAction, watchAction, fn, request);
      });
    }
  };
}

function mapApi(api) {
  return mapKeys(api, (value, key) => snakeCase(key).toUpperCase());
}

/**
 * Creates resource watchers for all api methods.
 * getUsers -> 'GET_USERS'
 *
 * @example
 * function* issuesSaga () {
 *   yield [
 *     call(watchAPI({
 *       getIssue () {},
 *       updateIssue () {},
 *     })),
 *   ]
 * }
 */
export function watchAPI(api) {
  return resourceWatchers(mapApi(api));
}
