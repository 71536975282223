import { memo, useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import { capitalize } from 'lodash';

import Button from '../../../../Button';
import NoResults from '../../../../NoResults/NoResults';
import { bem } from '../../../../../bem';
import './TimeSlots.scss';
import { DayInfo, Slot } from '../types';
import DaysCarousel from './DaysCarousel';
import { getNeededStylesFromModifiers } from '../../../../../helpers/styles';

const { block, element } = bem('TimeSlots');

interface Props {
  initialSlots: DayInfo[];
  getSlotWrapperModifiers: (slot: Slot) => Record<string, boolean>;
  getSlotModifiers: (slot: Slot) => Record<string, boolean>;
  onSlotSelection: (slot: Slot) => () => void;
  checkIfSlotButtonDisabled: (slot: Slot) => boolean;
  selectedDayIndex: number;
  onChangeDay: (value: number) => () => void;
}

const TimeSlotsView = ({
  initialSlots,
  getSlotWrapperModifiers,
  getSlotModifiers,
  onSlotSelection,
  checkIfSlotButtonDisabled,
  selectedDayIndex,
  onChangeDay,
}: Props) => {
  const selectedDaySlots = useMemo(() => {
    if (initialSlots && initialSlots[selectedDayIndex]) {
      return initialSlots[selectedDayIndex].slots;
    }
    return [];
  }, [initialSlots, selectedDayIndex]);

  const getSlotStyles: any = useCallback(
    (modifiers) =>
      ({ theme }) => {
        const selectors = {
          selected: {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: '#FFF !important',
          },
        };
        return getNeededStylesFromModifiers(selectors, modifiers);
      },
    [],
  );

  return (
    <div {...block({})}>
      <DaysCarousel
        selectedDayIndex={selectedDayIndex}
        onChangeDay={onChangeDay}
        initialSlots={initialSlots}
      />
      <Box {...element('title')} mt={2}>
        {t('bookings.fields.slots_time')}
      </Box>
      {selectedDaySlots && selectedDaySlots.length === 0 && <NoResults />}
      <Grid container spacing={2} {...element('gridContainer')}>
        {selectedDaySlots.map((slot: Slot) => {
          const slotWrapperModifiers = getSlotWrapperModifiers(slot);
          const slotModifiers = getSlotModifiers(slot);
          const StyledButton = styled(Button)(getSlotStyles(slotModifiers));
          return (
            <Grid
              key={slot.id}
              {...element('slotWrapper', slotWrapperModifiers)}
              item
              xs={3}
              md={2}
            >
              <StyledButton
                {...element('slot')}
                sx={{ width: '100%' }}
                ghost
                disabled={checkIfSlotButtonDisabled(slot)}
                onClick={onSlotSelection(slot)}
              >
                {slot.title}
              </StyledButton>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default memo(TimeSlotsView);
