import { array, InferType, object, ObjectSchema, string } from 'yup';

import { ProfileInfo } from './ProfileInfo';

export const profileInfoSchema: ObjectSchema<ProfileInfo> = object({
  surname: string().required(),
  name: string().required(),
  patronymic: string(),
  notifications: array().of(string().required()),
  mobile: string().required(),
});

export type ProfileInfoFormData = InferType<typeof profileInfoSchema>;
