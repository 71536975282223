export const formatPhone = (value) => {
  const LAST_GROUP = 4;
  const CODE = 2;

  // Если пользователь начал набирать номер с девятки,
  // то добавляем в начало код страны, +7
  if (value === '9') {
    return '+7 9';
  }

  if (value.charAt(0) !== '+') {
    return `+${value}`;
  }

  return value
    // Удаляем все кроме цифр и "+"
    .replace(/[^+\d]/g, '')
    // Разбираем номер на группы - так накладывать маску удобнее.
    .replace(/(\+7|8)?(\d{3})?(\d{3})?(\d{2})?(\d+)?/, (fullMatch, ...groups) => {
      // Две последние группы содержат полно
      groups.pop();
      groups.pop();

      return groups
        // Оставляем только непустые группы
        .filter(Boolean)
        .reduce((str, group, index) => {
          if (index === 0) return group;
          if (index > LAST_GROUP) return str;

          if (index === LAST_GROUP && group) {
            group = group.substring(0, 2);
          }

          // Каждую законченную группу  завершаем "-", а код - пробелом
          return str + (index > CODE ? '-' : ' ') + group;
        }, '');
    })
    .substring(0, 16);
};
