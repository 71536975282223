import './IssueInProgress.scss';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { Icon } from 'lib/ui/icon';

const { block, element } = bem('IssueInProgress');

export default function IssueInProgress({ className }) {
  return (
    <div {...block({}, className)}>
      <Icon {...element('icon')} glyph="inprogress" /> {t('issues.is_in_progress')}
    </div>
  );
}
