import { Suspense, useCallback, useMemo, useState, UIEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import { Page } from 'lib/ui';
import { bem } from 'lib/bem';
import { NotificationSchemaOut } from 'shared/api/v4/swagger/data-contracts';
import {
  NotificationEnum,
  Notification,
  useMarkNotificationSeen,
  useMarkAllSeen,
  useNotificationsInfiniteQuery,
} from 'entities/notification';
import { openModal } from 'lib/ui/Modal';
import { TextButton } from 'shared/ui/text-button';
import { EmptyFeed } from 'shared/ui/empty-feed';
import { AnnouncementModal } from 'features/get-announcement-info';
import { IssueDetailsLazy } from 'app/issues/lazy';
import BusyIndicator from 'lib/ui/BusyIndicator';

import './NotificationPage.scss';

const { block, element } = bem('NotificationPage');

export const NotificationPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, isLoading, fetchNextPage, hasNextPage, isError, isFetching } =
    useNotificationsInfiniteQuery();
  const markAllSeen = useMarkAllSeen();
  const markNotificationSeen = useMarkNotificationSeen();
  // For getting announcement data
  const [notification, setNotification] = useState<NotificationSchemaOut | null>(null);

  const notifications: NotificationSchemaOut[] = useMemo(() => {
    if (!data?.pages) return [];

    return data.pages.reduce((previousValue, currentValue) => {
      if (!('response' in currentValue)) {
        return previousValue;
      }
      const notifications = currentValue.response.data.filter((i) =>
        Object.values(NotificationEnum).includes(i.entity_type as NotificationEnum),
      );
      return [...previousValue, ...notifications];
    }, [] as NotificationSchemaOut[]);
  }, [data]);

  const handleScroll = async (e: UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target as HTMLDivElement;
    if (!isFetching && scrollHeight - scrollTop <= clientHeight * 1.2) {
      if (hasNextPage) await fetchNextPage();
    }
  };

  const handleAllSeen = useCallback(async () => {
    try {
      await markAllSeen.mutateAsync(notifications[0].id);
    } catch (e) {
      console.error(e);
    }
  }, [markAllSeen, notifications]);

  const handleViewInfo = useCallback(
    (notification: NotificationSchemaOut) => {
      switch (notification.entity_type) {
        case NotificationEnum.ISSUE: {
          dispatch(openModal('issue', notification.entity_id));
          if (!notification.seen) markNotificationSeen.mutateAsync(notification.id);
          break;
        }
        case NotificationEnum.ANNOUNCEMENT:
          setNotification(notification);
          break;
        default:
          break;
      }
    },
    [dispatch, markNotificationSeen],
  );

  const handleAnnouncementClose = useCallback(() => {
    if (notification && !notification.seen) markNotificationSeen.mutateAsync(notification.id);
    setNotification(null);
  }, [markNotificationSeen, notification]);

  if (isError) {
    return (
      <Page {...block()} title={t('notification_page.title')}>
        <div>{t('notification_page.unable_to_load_events')}</div>
      </Page>
    );
  }

  if (isLoading) {
    return (
      <Page {...block()} title={t('notification_page.title')}>
        <BusyIndicator />
      </Page>
    );
  }

  return (
    <Page
      {...block()}
      onScroll={handleScroll}
      pageTitle={t('notification_page.title')}
      title={<div {...element('title')}>{t('notification_page.title')}</div>}
      actions={
        notifications?.length !== 0 && (
          <TextButton
            {...element('textButton')}
            onClick={handleAllSeen}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            {t('notification_page.seen_button')}
          </TextButton>
        )
      }
    >
      <Suspense fallback={<BusyIndicator />}>
        <IssueDetailsLazy listMode="my" />
      </Suspense>
      {notification && (
        <AnnouncementModal notificationId={notification.id} onClose={handleAnnouncementClose} />
      )}
      <div>
        {!isLoading && notifications.length === 0 ? (
          <EmptyFeed placeholder={t('notification_page.empty_events')} icon="bell" />
        ) : (
          <Box {...element('chunk')}>
            {notifications.map((item) => (
              <Notification key={item.id} notification={item} viewInfo={handleViewInfo} />
            ))}
          </Box>
        )}
      </div>
    </Page>
  );
};
