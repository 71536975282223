import { closeDictEditor } from 'app/common/DictEditor/closeDictEditor';
import { api } from 'lib/api';
import { createResource } from 'lib/resource';

export default createResource('announcements', {
  scopes: {
    list({ payload }) {
      return api.get('/v2/announcements/', {
        search: payload,
      });
    },
  },

  byId({ payload }) {
    return api.get(`/v2/announcements/${payload.id}/`);
  },

  mutators: {
    create: {
      request: ({ payload }) => {
        return api.post('/v2/announcements/', {
          body: payload,
        });
      },
      onSuccess: closeDictEditor,
    },

    delete({ payload }) {
      return api.delete(`/v2/announcements/${payload.id}/`);
    },
  },
});
