import { ReduxModal } from 'lib/ui';

import BookingItemForm from '../BookingItemForm';
import { BookingItemAction } from '../types';
import { managedObjects } from '../../managedObjects';

export default ReduxModal({
  name: 'book',
  routing: true,

  getTitle: () => t('bookings.pages.new_booking.title'),

  mapStateToProps: (state, { data }) => {
    return {
      bookingItem: (managedObjects as any).actions(state, { id: data, type: 'booking_item' }),
    };
  },
})(({ bookingItem, list }) => {
  return (
    <BookingItemForm bookingItem={bookingItem.data} action={BookingItemAction.BOOK} list={list} />
  );
});
