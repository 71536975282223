import { Box, Typography } from '@mui/material';

const mapStateToProps = (state) => ({
  links: state.users.current.data[''].data.external_links,
});

const ExternalLink = ({ links, location }) => {
  let data;
  const currentPath = location.search.replace('?', '');

  for (let i = 0; i < links.length; i++) {
    if (links[i].uuid === currentPath) {
      data = { ...links[i] };
      break;
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ fontSize: '1.5rem', padding: '.5rem 1rem' }}>{data.name}</Typography>
      <iframe
        width="100%"
        style={{
          height: '90vh',
        }}
        src={data.link}
        title={data.name}
      />
    </Box>
  );
};

export default connect(mapStateToProps)(ExternalLink);
