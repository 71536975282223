import { useMemo } from 'react';
import PhoneInput, { getCountries } from 'react-phone-number-input';
import useGeoLocation from 'react-ipgeolocation';
import 'react-phone-number-input/style.css';
import ru from 'react-phone-number-input/locale/ru.json';
import en from 'react-phone-number-input/locale/en.json';

import brandedData from 'config/brandedData';
import { isSafari } from 'shared/lib/check-browser';

import './Tel.scss';

const allowedCountries = ['AM', 'AZ', 'BY', 'KZ', 'KG', 'MD', 'RU', 'TJ', 'UZ'];

const lang = navigator.language.toLowerCase();
const isRuBrand = brandedData.defaultLang === 'ru';
const countries = isRuBrand ? allowedCountries : getCountries();
const labelsLang = isRuBrand ? ru : en;

const InternationalTel = ({ autoComplete, input, autofocus }) => {
  const location = useGeoLocation();

  const defaultCountry = useMemo(() => {
    if (location?.country && countries.includes(location.country)) {
      return location.country;
    }

    if (isRuBrand) return 'RU';
    switch (lang) {
      case 'de': {
        return 'DE';
      }
      case 'da': {
        return 'DK';
      }
      default:
        return 'GB';
    }
  }, [location.country]);

  return (
    <PhoneInput
      autoComplete={isSafari ? 'off' : autoComplete || 'tel'}
      {...input}
      autoFocus={autofocus}
      addInternationalOption={false}
      defaultCountry={defaultCountry}
      international
      limitMaxLength
      countries={countries}
      labels={labelsLang}
    />
  );
};

export default InternationalTel;
