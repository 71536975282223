import { PureComponent } from 'react';
import { isNil, isEmpty as _isEmpty } from 'lodash';

import { bem } from 'lib/bem';
import { Label } from 'lib/ui';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';

const { element } = bem('SimpleSelect');

interface Props {
  multiple?: boolean;
  value?: any;
  renderLabel?: (...args: any[]) => any;
  optionsIndex?: any;
  placeholder?: string;
  isLoading?: any;
  hiddenOptionsIndex: any;
  placeholderStyle?: Record<string, any>;
  getItemValue: (item) => any;
  onRemove: (item) => void;
  visibleOptions: any[];
  selectName?: string;
  addable?: boolean;
}

export default class SelectValue extends PureComponent<Props> {
  render() {
    const { value, placeholder, placeholderStyle } = this.props;
    return (
      <div {...element('value')}>
        {this.props.isLoading && (
          <BusyIndicator
            size={20}
            sx={{
              display: 'flex',
              ml: 'auto',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          />
        )}
        {!this.props.isLoading && (
          <>
            {this.isEmpty() && (
              <div style={placeholderStyle} {...element('placeholder')}>
                {placeholder}
              </div>
            )}
            {!this.isEmpty()
              ? this.props.multiple
                ? this.renderMultipleValue(value)
                : this.renderSingleValue(value)
              : null}
          </>
        )}
      </div>
    );
  }

  renderMultipleValue(value = []) {
    if (value.length === 0) return null;

    return (
      <div {...element('valuesWrapper')}>
        {(value || []).map((item) => (
          <Label
            removable
            onRemove={() => (this.props as any).onRemove(item)}
            key={this.getItemValue(item)}
          >
            {this.renderSingleValue(item)}
          </Label>
        ))}
      </div>
    );
  }

  isEmpty() {
    const { value, multiple, getItemValue, hiddenOptionsIndex, optionsIndex } = this.props;

    if (multiple) {
      return (value || []).length === 0;
    }

    const itemValue = getItemValue(value);

    if (hiddenOptionsIndex[itemValue || value]) {
      return true;
    }

    if (_isEmpty(optionsIndex)) {
      return true;
    }

    return isNil(value) || value === '';
  }

  getOptionByValue(value) {
    return this.props.optionsIndex[(this.props as any).getItemValue(value) || value] || value;
  }

  renderSingleValue = (value) => this.props.renderLabel?.(this.getOptionByValue(value));

  getItemValue(value) {
    return (this.props as any).getItemValue(this.getOptionByValue(value));
  }
}
