import { memo } from 'react';
import { FormBuilder } from 'lib/ui';
import { BookingSchedule } from '../types';

interface Props {
  name: string;
  title?: string;
  data: BookingSchedule[];
}

const BookingSchedulesSelect = ({
  name,
  title,
  data,
}: Props) => {
  const getItemLabel = (item: BookingSchedule) => {
    return item.label;
  };

  const getItemValue = (item: BookingSchedule) => {
    return item.id;
  };

  return (
    <FormBuilder.Select
      required
      clearable
      name={name}
      title={title || t('locations.fields.booking_schedule')}
      options={data}
      getItemLabel={getItemLabel}
      getItemValue={getItemValue}
    />
  );
};

export default memo(BookingSchedulesSelect);
