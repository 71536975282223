import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from '@mui/material';

import { ModalV2 } from 'shared/ui/modal-v2';
// eslint-disable-next-line import/no-cycle
import { useSetEmail } from 'features/update-profile-info';
import { Button, FormBuilder } from 'lib/ui';
import users from 'app/users/users.resource';

import './ConfirmEmailModal.scss';

const { block, element } = bem('ConfirmEmailModal');

const { email } = FormBuilder.validators;

interface ConfirmEmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  savedEmail: string;
  isEmailConfirmed: boolean;
}

export const ConfirmEmailModal = ({
  isOpen,
  onClose,
  savedEmail,
  isEmailConfirmed,
}: ConfirmEmailModalProps) => {
  const { t } = useTranslation();
  const [newEmail, setNewEmail] = useState(savedEmail);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { mutate, data, reset } = useSetEmail();

  const isEmailChanged = newEmail !== savedEmail;

  useEffect(() => {
    if (!isOpen) {
      reset();
      setErrorMessage(null);
    }
  }, [isOpen, reset]);

  const message = useMemo(
    () =>
      data && 'response' in data
        ? data.response.metadata.message || {
            title: 'Email',
            body: t('confirm_email_modal.empty_email_message'),
          }
        : null,
    [data, t],
  );

  const handleConfirm = useCallback(() => {
    mutate(
      { email: newEmail },
      {
        onSuccess: () => {
          users.current.invalidate();
        },
        onError: (err) => {
          setErrorMessage(err.message || t('confirm_email_modal.server_error'));
        },
      },
    );
  }, [mutate, newEmail, t]);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setIsEmailValid(!email()(value));
    setNewEmail(value);
  };

  return (
    <ModalV2 open={isOpen} onClose={onClose} small title={!message ? 'Email' : message.title}>
      <Box {...block()}>
        {message && (
          <>
            <Typography {...element('message')}>{message.body}</Typography>
            <Button {...element('button')} onClick={onClose}>
              {t('confirm_email_modal.ok_button')}
            </Button>
          </>
        )}

        {!data && (
          <>
            <TextField name="email" value={newEmail} onChange={handleChangeEmail} fullWidth />
            {(!isEmailConfirmed || isEmailChanged) && newEmail && isEmailValid && (
              <Typography sx={{ color: '#FF3B30' }}>{t('profile_form.confirm_message')}</Typography>
            )}

            {!isEmailValid && (
              <Typography sx={{ color: '#FF3B30' }}>
                {t('confirm_email_modal.error_message')}
              </Typography>
            )}

            <Button {...element('button')} disabled={!isEmailChanged} onClick={handleConfirm}>
              {t('confirm_email_modal.confirm_button')}
            </Button>
          </>
        )}

        {errorMessage && <Typography {...element('message')}>{errorMessage}</Typography>}
      </Box>
    </ModalV2>
  );
};
