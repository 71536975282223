import { memo } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { routesMap } from 'config/routesMap';
import { withRouter } from 'config/withRouter';
import { withUserAccessCheck } from 'config/withUserAccessCheck';
import { InternalApp } from 'app/InternalApp';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<InternalApp />}>
      {Object.values(routesMap).map((route) => {
        const Component = memo(
          withUserAccessCheck(route, memo(withRouter(memo(route.Component), route))),
        );

        return (
          <Route key={route.path} path={route.path} Component={Component}>
            {route.child && (
              <Route
                key={route.child.path}
                path={route.child.path}
                Component={route.child.Component}
              />
            )}
          </Route>
        );
      })}
    </Route>,
  ),
);
