import { PureComponent } from 'react';
import { without } from 'lodash';

import { Button } from 'lib/ui';
import { IconAsButton } from 'lib/ui/icon';

import './ListInput.scss';

const { block, element } = bem('ListInput');

type OwnProps = {
  value?: any;
  onChange?: (...args: any[]) => any;
};

type State = any;

type Props = OwnProps & typeof ListInput.defaultProps;

export default class ListInput extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.setInputRef = this.setInputRef.bind(this);
  }

  static defaultProps = {
    value: [],
    onChange() {},
  };

  input: any;

  state = {
    inputValue: '',
  };

  get value() {
    return this.props.value || [];
  }

  isValueAdded(value) {
    return this.value.indexOf(value) > -1;
  }

  isValidValue(value) {
    return value.trimStart() === '';
  }

  handleInputChange = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.addValue(this.state.inputValue);
    }
  };

  setInputRef(input) {
    this.input = input;
  }

  addValue(value) {
    if (this.isValueAdded(value)) return;

    this.setState({ inputValue: '' });

    this.props.onChange([...this.value, value]);

    setTimeout(() => {
      if (this.input) {
        this.input.focus();
      }
    }, 50);
  }

  removeValue(value) {
    this.props.onChange(without(this.props.value, value));
  }

  render() {
    const { inputValue } = this.state;

    return (
      <div {...block({ empty: this.value.length === 0 })}>
        <div {...element('inputWrapper')}>
          <input
            {...element('input')}
            ref={this.setInputRef}
            type="text"
            value={inputValue}
            onChange={this.handleInputChange}
            onKeyPress={this.handleInputKeyPress}
            placeholder={t('common.type_value_text')}
          />
          <Button
            ghost
            {...element('addValue')}
            onClick={(e) => this.addValue(inputValue)}
            disabled={this.isValueAdded(inputValue) || this.isValidValue(inputValue)}
          >
            {t('common.add')}
          </Button>
        </div>

        <div {...element('values')}>
          {this.value.map((value) => (
            <div {...element('value')} key={value}>
              <div {...element('valueText')}>{value}</div>
              <IconAsButton
                {...element('removeValue')}
                onClick={() => this.removeValue(value)}
                glyph="close"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
