import { all, call, put } from 'redux-saga/effects';
import { takeMap } from 'lib/saga-utils';
import { contextNotification } from 'app/notifications';
import { openModal } from 'lib/ui/Modal';

const notify = contextNotification('inventories');

export default function* issueSchedulesSaga() {
  yield all([
    call(takeMap({
      'INVENTORIES/CREATE_SUCCESS': function* ({ response }) {
        yield put(openModal('inventoryCreatedModal', response));
      },

      'INVENTORIES/UPDATE_SUCCESS': function* () {
        yield put(notify('updated'));
      },
    })),
  ]);
}
