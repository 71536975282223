import { put } from 'redux-saga/effects';

import { api } from 'lib/api';
// eslint-disable-next-line import/no-cycle
import { createResource } from 'lib/resource';
import { issueTypes as issueTypesResource } from 'app/issueTypes';
import { closeModal } from 'lib/ui/Modal';

import { SAVE_ISSUE_SCHEDULE_MODAL } from './config';

function fetchLinkedData(issueSchedulesData) {
  const { response } = issueSchedulesData;

  if (!response) return issueSchedulesData;

  return Promise.all([
    response.issue_type_id ? (issueTypesResource as any).byId.request(response.issue_type_id) : {},
  ]).then((results) => ({
    ...issueSchedulesData,
    response: {
      ...response,
      location_id: response.location_id.toString(),
      issue_type: results[0],
    },
  }));
}

function* closeOnSuccess() {
  yield put(closeModal(SAVE_ISSUE_SCHEDULE_MODAL));
}

export default createResource('issueSchedules', {
  scopes: {
    list({ payload }) {
      const { offset, location, issue_type, sort, count } = payload;

      // sort
      const [sortColumn, sortDirection] = sort.split('__');

      return api.get('/v2/issuetypes/schedules/', {
        search: {
          offset,
          count,

          sort: JSON.stringify({
            [sortColumn]: sortDirection,
          }),

          filter: JSON.stringify({
            issue_type: issue_type ? issue_type.split(',') : [],
            location: typeof location === 'string' ? location.split(',') : location ?? [],
          }),
        },
      });
    },
  },

  byId({ payload }) {
    return api.get(`/v2/issuetypes/schedules/${payload}/`).then(fetchLinkedData);
  },

  mutators: {
    create: {
      request: ({ payload }) => {
        return api.post('/v2/issuetypes/schedules/', {
          body: payload,
        });
      },
      onSuccess: closeOnSuccess,
    },

    update: {
      request: ({ payload }) => {
        const { id, ...data } = payload;

        return api.put(`/v2/issuetypes/schedules/${id}/`, {
          body: data,
        });
      },
      onSuccess: closeOnSuccess,
    },

    remove({ payload }) {
      return api.delete(`/v2/issuetypes/schedules/${payload.id}/`);
    },
  },
});
