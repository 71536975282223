import { Avatar as AvatarMui } from '@mui/material';

interface Props {
  url: string;
  width?: string;
  height?: string;
  border?: boolean;
  sx?: Record<string, string>;
}

const Avatar = ({ url, width, height, border, sx }: Props) => {
  return (
    <AvatarMui
      src={url}
      sx={{
        width: width || '25px',
        height: height || '25px',
        boxShadow: border ? '0 0 0 5px rgb(255, 255, 255)' : undefined,
        ...sx,
      }}
    />
  );
};

export default Avatar;
