import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { flow, isEmpty, isPlainObject } from 'lodash';
import { getFormValues, reduxForm } from 'redux-form';
import { routesMap } from 'config/routesMap';
import { FormBuilder } from 'lib/ui';
import { reconnect } from 'lib/resource';
import { bem } from 'lib/bem';
import { t, t_prefixed } from 'lib/i18n';
import { formatDate } from 'lib/helpers/dates';
import confirm from 'lib/ui/Modal/Confirm';
import { getLocationFullPath } from 'lib/helpers/location';
import { DayInfo, Slot } from 'lib/ui/FormBuilder/types/Slots/types';
import { closeModal } from 'lib/ui/Modal';
import { BOOKING_TIME_FORMAT } from 'lib/constants/dates';

import { BookingItemAction, BookingPageName } from '../types';
import { bookings as bookingsResource } from '../index';
import AttributesFormV2 from '../../issues/Attributes/AttributesForm/AttributesFormV2';
import './BookingItemForm.scss';
import { TranslationKeys } from 'i18next';

const FORM_NAME = 'bookingForm';
const formValuesSelector = getFormValues(FORM_NAME);

const { block } = bem('BookingItemForm');

const fieldLabel = t_prefixed('bookings.fields');

const DEFAULT_ATTR_ACTIONS = {
  read: {
    editable: false,
    required: false,
    show_empty: false,
  },
  update: {
    editable: false,
    required: false,
    show_empty: true,
  },
  new: {
    editable: true,
    required: false,
    show_empty: true,
  },
  book: {
    editable: false,
    required: false,
    show_empty: false,
  },
  booking_sharing: {
    editable: false,
    required: false,
    show_empty: false,
  },
};

// TODO: Для чего это?
function setAsyncRouteLeaveHook(router, route, hook) {
  let withinHook = false;
  let finalResult;
  let finalResultSet = false;
  router.setRouteLeaveHook(route, (nextLocation) => {
    withinHook = true;
    if (!finalResultSet) {
      hook(nextLocation).then((result) => {
        finalResult = result;
        finalResultSet = true;
        if (!withinHook && nextLocation) {
          // Re-schedule the navigation
          router.navigate(nextLocation);
        }
      });
    }
    const result = finalResultSet ? finalResult : false;
    withinHook = false;
    finalResult = undefined;
    finalResultSet = false;
    return result;
  });
}

const getSlotsAttrId = (action: BookingItemAction) => {
  if (action === BookingItemAction.BOOK) {
    return '_booking_slots';
  }
  if (action === BookingItemAction.SHARE) {
    return '_sharing_slots';
  }
};

const checkIfAttrsFormValid = (formValues, action: BookingItemAction) => {
  const slotsId = getSlotsAttrId(action);
  if (formValues && slotsId && formValues[slotsId]) {
    if (action === BookingItemAction.SHARE) {
      const { sharedSlots, unsharedSlots } = formValues[slotsId];
      const existSharedSlots = sharedSlots && sharedSlots.length > 0;
      const existUnsharedSlots = unsharedSlots && unsharedSlots.length > 0;
      return existSharedSlots || existUnsharedSlots;
    }
    if (action === BookingItemAction.BOOK) {
      return formValues[slotsId].length > 0;
    }
  }
  return false;
};

export default flow<any, any, any>(
  reconnect((state, props) => {
    const attrsFormValues = getFormValues('bookingItemAttributes')(state);
    return {
      attrsFormValues,
      values: formValuesSelector(state),
      valid: checkIfAttrsFormValid(attrsFormValues, props.action),
      slotsId: getSlotsAttrId(props.action),
    };
  }),
  reduxForm({
    form: FORM_NAME,
  }),
)(({
  bookingItem,
  bookingType = 'managed_object_type',
  action = BookingItemAction.READ,
  bookingSlots,
  valid,
  bookingId,
  onClose,
  bookingStatus,
  author,
  list,
  attrsFormValues,
  slotsId,
  router,
  route,
  company,
  comment,
}) => {
  const navigate = useNavigate();

  const typeAttributes = useMemo(() => {
    if (bookingItem && !isEmpty(bookingItem[bookingType]) && bookingItem[bookingType].attributes) {
      return bookingItem[bookingType].attributes;
    }
    return [];
  }, [bookingItem, bookingType]);

  const locationFullPath = useMemo(() => {
    if (
      bookingItem &&
      bookingItem.related_managed_object &&
      bookingItem.related_managed_object.parent
    ) {
      return getLocationFullPath(bookingItem.related_managed_object.parent);
    }
    return null;
  }, [bookingItem]);

  const defaultAttributes = useMemo(() => {
    if (bookingItem) {
      const result = [
        {
          name: fieldLabel('name'),
          renderer: 'comment',
          value: bookingItem.name,
          read_only: true,
          actions: DEFAULT_ATTR_ACTIONS,
        },
        {
          name: fieldLabel('parent'),
          renderer: 'comment',
          value: locationFullPath,
          read_only: true,
          actions: DEFAULT_ATTR_ACTIONS,
        },
      ];
      if (action === BookingItemAction.READ && bookingSlots && isPlainObject(bookingSlots)) {
        result.push(
          {
            name: fieldLabel('start_time'),
            renderer: 'comment',

            value: formatDate(bookingSlots.start, BOOKING_TIME_FORMAT),
            read_only: true,
            actions: DEFAULT_ATTR_ACTIONS,
          },
          {
            name: fieldLabel('end_time'),
            renderer: 'comment',

            value: formatDate(bookingSlots.end, BOOKING_TIME_FORMAT),
            read_only: true,
            actions: DEFAULT_ATTR_ACTIONS,
          },
        );
      }

      if (list === BookingPageName.ADMIN) {
        result.push({
          name: fieldLabel('author'),
          renderer: 'comment',
          value: author,
          read_only: true,
          actions: DEFAULT_ATTR_ACTIONS,
        });
      }

      if (list === BookingPageName.ADMIN && company) {
        result.push({
          name: fieldLabel('company'),
          renderer: 'comment',
          value: company.name,
          read_only: true,
          actions: DEFAULT_ATTR_ACTIONS,
        });
      }

      if (action === BookingItemAction.READ && bookingStatus) {
        result.push({
          name: fieldLabel('status'),
          renderer: 'comment',
          // TODO: #i18n Add translation key type check
          value: t(`bookings.status.${bookingStatus}`, bookingStatus),
          read_only: true,
          actions: DEFAULT_ATTR_ACTIONS,
        });
      }

      result.push({
        name: fieldLabel('comment'),
        renderer: 'comment',
        value: comment,
        read_only: true,
        actions: DEFAULT_ATTR_ACTIONS,
      });
      return result;
    }
    return [];
  }, [
    bookingItem,
    locationFullPath,
    action,
    bookingSlots,
    list,
    bookingStatus,
    author,
    company,
    comment,
  ]);

  const handleBookingCancel = useCallback(() => {
    confirm(t('bookings.cancel_booking_confirmation')).then(() => {
      if (list === BookingPageName.MY_BOOKINGS) {
        bookingsResource.cancelMyBooking(bookingId);
      } else if (list === BookingPageName.ADMIN) {
        bookingsResource.cancelUserBooking(bookingId);
      }
    });
  }, [bookingId, list]);

  const dispatch = useDispatch();

  const handleBook = useCallback(() => {
    if (valid) {
      let mappedSlots: Slot[] = [];
      attrsFormValues[slotsId].forEach((day: DayInfo) => {
        mappedSlots = mappedSlots.concat(day.slots);
      });
      let _comment;
      for (const key in attrsFormValues) {
        if (key !== '_booking_slots') {
          _comment = attrsFormValues[key];
          break;
        }
      }
      bookingsResource.create
        .request({ booking_item_id: bookingItem.id, booking_slots: mappedSlots, comment: _comment })
        .then(({ response: { data } }) => {
          if (list === BookingPageName.BOOK_FROM_BOOKINGS_SECTION) {
            dispatch(
              closeModal('book', {
                name: 'bookingItem',
                data: data.id,
                extraData: { list: BookingPageName.MY_BOOKINGS },
              }),
            );
          }
        });

      if (list === BookingPageName.BOOK_FROM_ACTIVITY_LIST) {
        navigate(routesMap.myBookings.path);
      }
    }
  }, [valid, attrsFormValues, slotsId, bookingItem, list, dispatch, navigate]);

  const [prompted, setPrompted] = useState(false);

  const handleBookingShare = useCallback(
    (pushHistory = true) => {
      if (valid) {
        const { sharedSlots, unsharedSlots } = attrsFormValues[slotsId];
        const payload = {
          booking_item_id: bookingItem.id,
          shared_slots: sharedSlots,
          unshared_slots: unsharedSlots,
        };
        setPrompted(true);

        bookingsResource.shareBooking.request(payload).then(() => {
          if (pushHistory) {
            navigate(`${routesMap.main.path}?location=${bookingItem.related_managed_object.id}`);
          }
        });
      }
    },
    [attrsFormValues, bookingItem, slotsId, valid, navigate],
  );

  const handlePageLeaving = useCallback(
    ({ pathname }) => {
      const onConfirmClose = () => {
        navigate(pathname);
      };

      if (valid && !prompted) {
        setPrompted(true);

        confirm(t('bookings.unsaved_sharing_confirmation'), {
          onClose: onConfirmClose,
          proceedText: t('common.save'),
          dismissText: t('common.do_not_save'),
        }).then(() => {
          handleBookingShare(false);
          navigate(pathname);
        });

        return false;
      }
      return null;
    },
    [handleBookingShare, prompted, valid, navigate],
  );

  // TODO: Переписать
  // useEffect(() => {
  //   if (router && route && action === BookingItemAction.SHARE) {
  //     router.setRouteLeaveHook(route, handlePageLeaving);
  //   }
  // }, [action, handleBookingShare, handlePageLeaving, route, router, valid]);

  const formButtons = useMemo(() => {
    const isCancelButton = bookingStatus === 'upcoming' || bookingStatus === 'in_progress';
    return {
      [BookingItemAction.READ]: isCancelButton && (
        <FormBuilder.ButtonsGroup
          hideCancel
          submitText={
            list === BookingPageName.ADMIN
              ? t('bookings.decline_button_text')
              : t('bookings.cancel_button_text')
          }
          onSubmit={handleBookingCancel}
        />
      ),
      [BookingItemAction.BOOK]: (
        <FormBuilder.ButtonsGroup
          hideCancel
          submitText={t('bookings.book_button_text')}
          onSubmit={handleBook}
          disabled={!valid}
        />
      ),
      [BookingItemAction.SHARE]: (
        <FormBuilder.ButtonsGroup
          hideCancel
          submitText={t('bookings.share_button_text')}
          onSubmit={handleBookingShare}
          disabled={!valid}
        />
      ),
    }[action];
  }, [bookingStatus, list, handleBookingCancel, handleBook, valid, handleBookingShare, action]);

  return (
    <div {...block()}>
      <AttributesFormV2
        form="bookingItemAttributes"
        action={action}
        actionAttributes={bookingItem ? bookingItem.attributes : null}
        managedObject={bookingItem ? bookingItem.related_managed_object : null}
        typeAttributes={typeAttributes}
        defaultAttributes={defaultAttributes}
        managedObjectTypeName={bookingType}
        editableOnly={action === BookingItemAction.BOOK}
      />
      {formButtons}
    </div>
  );
});
