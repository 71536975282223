import {
  currencyList,
  defaultCurrency,
} from 'lib/ui/FormBuilder/types/CurrencySelect/CurrencySelect';

import './MonetarySign.scss';
import { bem } from 'lib/bem';

const { block } = bem('MonetarySign');

export default function MonetarySign({ currency = defaultCurrency }) {
  const currentCurrency = currencyList.find((item) => item.code === currency);
  return <span {...block()}>{currentCurrency?.title}</span>;
}
