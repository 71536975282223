import { Component } from 'react';
import PhoneInput from 'react-phone-number-input/input';

import { isSafari } from 'shared/lib/check-browser';

import validators from '../../validators';

export default class InternationalTel extends Component {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  static validate = [validators.internationalMobilePhone()];

  static normalize(value) {
    if (!value) return value;
    return value.replace(/\s/g, '');
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoComplete' does not exist on type 'Re... Remove this comment to see the full error message
    const { autoComplete, input, autofocus } = this.props;

    return (
      <PhoneInput
        autoComplete={isSafari ? 'off' : autoComplete || 'on'}
        {...input}
        international
        placeholder="+44 7000 000000"
        maxLength={15}
        autoFocus={autofocus}
      />
    );
  }
}
