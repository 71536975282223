import { ComponentType, PureComponent } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../config/redux';

const NESTED_SELECTORS_PROP_NAME = 'nestedSelectors';

function forEachResourceProp(props, callback) {
  if (props && props[NESTED_SELECTORS_PROP_NAME]) {
    forEachResourceProp(props[NESTED_SELECTORS_PROP_NAME], callback);
  }
  for (const propName in props) {
    const prop = props[propName];

    if (prop && prop.$Resource) {
      callback(prop, propName);
    }
  }
}

export default function reconnect<ComponentProps = any>(
  mapStateToProps,
  mapDispatchToProps: any = null,
) {
  const connectedResources = {};

  const extendedMapStateToProps = (state: RootState, ownProps: ComponentProps) => {
    const connectedProps = mapStateToProps(state, ownProps);

    forEachResourceProp(connectedProps, (prop, propName) => {
      connectedProps[propName] = prop.result;
      connectedResources[propName] = {
        ...((prop.result || {}).options || {}),
        ...(prop.options || {}),
      };
    });

    return connectedProps;
  };

  const connectFn = connect(extendedMapStateToProps, mapDispatchToProps);

  return (ReconnectWrappedComponent: ComponentType<ComponentProps>) =>
    connectFn(
      class ReconnectContainer extends PureComponent<ComponentProps & Record<string, any>> {
        resources: any;

        UNSAFE_componentWillMount() {
          this.resources = {};
          this.fetchResources(this.props, true);
        }

        UNSAFE_componentWillReceiveProps(nextProps, nextState) {
          this.fetchResources(nextProps);

          Object.keys(this.resources).forEach((propName) => {
            if (!nextProps[propName] || !nextProps[propName].$Resource) {
              delete this.resources[propName];
            }
          });
        }

        fetchResources(props, forced = false) {
          forEachResourceProp(props, (resource, propName) => {
            const content = resource.content || (resource.result || {}).content;
            const { data, failed, fetching, outdated, action } = content;
            const options = connectedResources[propName];

            const shouldFetch =
              (failed && forced) ||
              (forced && !fetching && options?.forceFetchOnMount !== false) ||
              (!data && !failed) ||
              (outdated && !failed);

            if (data || !options?.skipFetchState) {
              const skipOutdated = options?.skipOutdated && outdated;

              this.resources[propName] = {
                ...resource.content,
                data: skipOutdated ? null : data,
                fetching: fetching || shouldFetch,
              };
            }

            if (shouldFetch && !fetching) {
              props.dispatch(action);
            }
          });
        }

        render() {
          const props = {
            ...this.props,
            ...this.resources,
          };

          return <ReconnectWrappedComponent {...props} />;
        }
      },
    );
}
