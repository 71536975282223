import moment, { MomentInput, unitOfTime } from 'moment';
import 'moment-timezone';

import { UTC_WITHOUT_TZ } from '../constants/dates';

const timezone = moment.tz.guess();

export const formatDate = (value: string, format?: string) => {
  if (!value) {
    return '';
  }

  return moment(value).format(format || UTC_WITHOUT_TZ);
};

// value in utc. need to parse in user timezone
export const convertDateToLocal = (value: string) => {
  if (!value) {
    return null;
  }

  return moment.utc(value).local();
};

export const convertDate = (value: string) => {
  if (!value) {
    return null;
  }

  return moment(value);
};

export const getStartOf = (momentObject, unit: unitOfTime.StartOf = 'day') => {
  return momentObject.startOf(unit).utc();
};

export const getEndOf = (momentObject, unit: unitOfTime.StartOf = 'day') => {
  return momentObject.endOf(unit).utc();
};

export const getStartOfDate = (value, unit: unitOfTime.StartOf = 'day') => {
  return getStartOf(moment.tz(value, timezone), unit);
};

export const getEndOfDate = (value, unit: unitOfTime.StartOf = 'day') => {
  return getEndOf(moment.tz(value, timezone), unit);
};

export const getStartOfUnit = (unit: unitOfTime.StartOf = 'day') => {
  return getStartOf(moment(), unit);
};

export const getEndOfUnit = (unit: unitOfTime.StartOf = 'day') => {
  return getEndOf(moment(), unit);
};

export const normalizeStartDate = (value: Date) => {
  if (!value) {
    return '';
  }

  return getStartOfDate(value).format(UTC_WITHOUT_TZ);
};

export const normalizeEndDate = (value: Date) => {
  if (!value) {
    return '';
  }

  return getEndOfDate(value).format(UTC_WITHOUT_TZ);
};

export const normalizeDateInUTC = (value: Date) => {
  if (!value) {
    return '';
  }
  return moment.utc(value).format(UTC_WITHOUT_TZ);
};

export const normalizeDate = (value: Date) => {
  if (!value) {
    return '';
  }
  return moment(value);
};

export const isDateIsoWeekend = (date: string) => {
  const dayNumber = moment(date).isoWeekday();
  return dayNumber === 6 || dayNumber === 7;
};

export const normalizeStartDateAsIs = (value: string, format?: string) => {
  if (!value) {
    return '';
  }
  return moment(value)
    .startOf('day')
    .format(format || UTC_WITHOUT_TZ);
};

export const normalizeEndDateAsIs = (value: string, format?: string) => {
  if (!value) {
    return '';
  }
  return moment(value)
    .endOf('day')
    .format(format || UTC_WITHOUT_TZ);
};

export const getDateAsString = (date: MomentInput = null, format = 'YYYY-MM-DD') => {
  if (date) return moment(date).format(format);
  return moment().format(format);
};

export const getFutureDate = (dateInterval: number) => {
  return moment().add(dateInterval, 'day');
};

export const getTimezoneOffset = () => {
  const today = new Date();
  return today.getTimezoneOffset() / 60;
};

export const setDateTime = (value: string, time: string) => {
  const timeParts = time.split(':');
  return moment(value)
    .set('hours', parseInt(timeParts[0], 10))
    .set('minutes', parseInt(timeParts[1], 10))
    .format(UTC_WITHOUT_TZ);
};
