import './PhotoUploader.scss';
import { PureComponent } from 'react';

import { bem } from 'lib/bem';
import { t_prefixed } from 'lib/i18n';
import FileUploader from 'lib/ui/FileUploader';

import PhotoPreview from '../PhotoPreview';

const { block, element } = bem('PhotoUploader');

const ACCEPT_FILES = '.jpg,.jpeg,.png';
const ACCEPT_MIME_TYPES = ['jpg', 'jpeg', 'png'];
const ACCEPT_FILES_TYPE = 'image';

export default class PhotoUploader extends PureComponent {
  // eslint-disable-next-line react/no-typos
  static PropTypes = {};

  static defaultProps = {};

  render() {
    const getLabel = t_prefixed('photo_uploader');

    return (
      <FileUploader
        {...block()}
        {...this.props}
        getLabel={getLabel}
        acceptFiles={ACCEPT_FILES}
        acceptMimeTypes={ACCEPT_MIME_TYPES}
        acceptFilesType={ACCEPT_FILES_TYPE}
        withResizing
        renderFilePreview={this.renderFilePreview}
      />
    );
  }

  renderFilePreview(file, onRemove) {
    return (
      <PhotoPreview
        {...element('photo')}
        key={file.md5}
        url={file.url}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: any; url: any; status: any; removable... Remove this comment to see the full error message
        status={file.processingStatus}
        removable
        onRemove={onRemove}
      />
    );
  }
}
