import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { flowRight, get, isEqual } from 'lodash';
import { getFormValues } from 'redux-form';

import { bem } from 'lib/bem';
import { reconnect } from 'lib/resource';
import { confirm, Page } from 'lib/ui';
import { checkIfLocationCardIsVisible, getLocationCardTitle } from 'lib/helpers/location';
import users from 'app/users/users.resource';
import { Roles } from 'app/userRoles/roles';
import { checkRoles } from 'app/users/permissions';
import { withRouter } from 'config/withRouter';

import { locations } from '../../../index';
import LocationFormV2 from '../../LocationForm';
import './LocationCardContent.scss';
import { managedObjects } from '../../../../managedObjects';

const { block } = bem('LocationCardContent');

const pathPattern = /([^.]+)?.?new$/;
const pathPatternCopy = /([^.]+)?.?copy$/;

const emptyFn = () => {};

const LocationCardContent = flowRight(
  withRouter,
  reconnect((state, props) => {
    const { data, params, readOnly, onClose, locationData } = props;
    const { locationId } = params || { locationId: null };
    const dataParts = String(data).split('.');
    const fetchedLocation = locations.byId(state, locationId || dataParts[0], {
      skipOutdated: true,
    });

    const currentUser = users.current(state, {});
    const booking = dataParts.length > 1 && dataParts[1] === 'booking';
    const bookingItemId = dataParts[2];
    return {
      locationData: locationData || fetchedLocation,
      readOnly: readOnly === undefined ? true : readOnly,
      onClose: onClose || emptyFn,
      data: locationId || data,
      currentUser,
      booking,
      locationFormValues: getFormValues('locationForm')(state),
      bookingItemId,
    };
  }),
)(({
  data,
  locationData,
  onClose,
  readOnly,
  currentUser,
  router,
  editable,
  booking,
  locationFormValues,
  bookingItemId,
  modalExtraData,
}) => {
  const navigate = useNavigate();
  const [location, setLocation] = useState<any>({});
  const [isNew, parentId] = useMemo(() => String(data).match(pathPattern) || [], [data]);
  const [isCopy] = useMemo(() => String(data).match(pathPatternCopy) || [], [data]);

  const [isLocationValid, setLocationValid] = useState(!!editable);
  useEffect(() => {
    if (!editable) {
      const redirectPath = currentUser.data.isGuest ? '/login' : '/';

      if (locationData && locationData.data) {
        if (!checkIfLocationCardIsVisible(true, locationData.data)) {
          navigate(redirectPath, { replace: true });
        } else {
          setLocationValid(true);
        }
      } else if (locationData && !locationData.data && !locationData.fetching) {
        navigate(redirectPath, { replace: true });
      }
    }
  }, [currentUser.data.isGuest, editable, locationData, navigate]);

  useEffect(() => {
    if (isNew) {
      setLocation(parentId ? { parent: locationData.data } : {});
    } else {
      setLocation(locationData.data);
    }
  }, [data, isNew, locationData, locationData.data, parentId]);

  const bookingEnabled = useMemo(() => {
    const managementCompanyPath = `${isNew ? 'parent.' : ''}management_company.booking_enable`;
    return location && get(location, managementCompanyPath);
  }, [isNew, location]);

  const [isLocCard, setLocCard] = useState(false);

  const handleSubmit = useCallback(
    (locationPayload, onlyBookingChanged, bookingActionDto) => {
      const initialBookingActionDto = {
        typeId: ((modalExtraData || {}).bookingItemType || {}).id,
        scheduleId: ((modalExtraData || {}).schedule || {}).id,
        attributes: (modalExtraData || {}).attributes || [],
      };
      const { id, data: payloadData } = locationPayload;
      const { parent } = location;

      const payload = {
        id,
        ...payloadData,
        parentId: parent && parent.id,
      };

      if (isCopy) {
        locations.create({
          ...payload,
          id: undefined,
          parent_id: locationData.data.parent_id ? locationData.data.parent_id : 0,
        });
        return;
      }

      if (bookingEnabled) {
        if (id) {
          if (!onlyBookingChanged) {
            if (isLocCard) {
              locations.updateLC(payload);
            } else {
              locations.update(payload);
            }
          }

          if (locationFormValues.is_booking) {
            const bookingActionPayload = {
              ...{ id: location.id, type: 'booking_item' },
              ...bookingActionDto,
            };
            if (!booking) {
              managedObjects.createAction(bookingActionPayload);
            } else if (!isEqual(initialBookingActionDto, bookingActionDto)) {
              managedObjects.updateAction(bookingActionPayload);
            }
          } else if (!locationFormValues.is_booking && booking) {
            const warning = (
              <Trans
                i18nKey="locations.modals.confirm_booking_object_remove"
                components={{
                  // TODO: Пофиксить a11y баг
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  bookingsLink: <a href={`/settings/bookings/?booking_item_id=${bookingItemId}`} />,
                }}
              />
            );
            confirm(warning).then(() => {
              managedObjects.deleteAction({ id: location.id, type: 'booking_item' });
            });
          }
        } else {
          locations.create.request(payload).then(({ response }) => {
            if (response && locationFormValues.is_booking) {
              const bookingActionPayload = {
                ...{ id: response.id, type: 'booking_item' },
                ...bookingActionDto,
              };
              managedObjects.createAction(bookingActionPayload);
            }
          });
        }
      } else if (id) {
        if (isLocCard) {
          locations.updateLC(payload);
        } else {
          locations.update(payload);
        }
      } else {
        locations.create(payload);
      }
    },
    [
      booking,
      bookingEnabled,
      bookingItemId,
      isCopy,
      isLocCard,
      location,
      locationData.data?.parent_id,
      locationFormValues?.is_booking,
      modalExtraData,
    ],
  );

  const [action, setAction] = useState(getAction({ isCopy, isNew, readOnly }));
  const [isReadOnly, setReadOnly] = useState(readOnly);

  useEffect(() => {
    setAction(getAction({ isCopy, isNew, readOnly }));
  }, [isCopy, isNew, readOnly]);

  const handleFormModeChange = useCallback(
    (formModeSettings) => () => {
      setAction(formModeSettings.action);
      setReadOnly(formModeSettings.readOnly);
      setLocCard(formModeSettings.lc || false);
    },
    [],
  );

  const initialReadOnlyRef = useRef(readOnly);

  const isUserAdmin = useMemo(() => checkRoles(Roles.ADMIN)(currentUser.data), [currentUser.data]);

  const renderContent = useCallback(() => {
    return (
      <div {...block()}>
        <LocationFormV2
          location={location}
          onCancel={
            initialReadOnlyRef.current
              ? handleFormModeChange({ action: 'read', readOnly: true })
              : onClose
          }
          onSubmit={handleSubmit}
          readOnly={isReadOnly}
          action={action}
          onFormModeChange={handleFormModeChange}
          isUserGuest={currentUser.data.isGuest}
          isUserAdmin={isUserAdmin}
          isEditable={editable}
          booking={booking}
          bookingEnabled={bookingEnabled}
          modalExtraData={modalExtraData}
          isCopy={isCopy}
        />
      </div>
    );
  }, [
    location,
    handleFormModeChange,
    onClose,
    handleSubmit,
    isReadOnly,
    action,
    currentUser.data.isGuest,
    isUserAdmin,
    editable,
    booking,
    bookingEnabled,
    modalExtraData,
  ]);

  const pageTitle = useMemo(() => {
    if (locationData && locationData.data) {
      return getLocationCardTitle(locationData.data, isReadOnly);
    }
    return getLocationCardTitle(null, isReadOnly);
  }, [isReadOnly, locationData]);

  if (!isLocationValid) {
    return null;
  }

  const isLocationDetailsRoute = router && router.location.pathname.endsWith('/details');

  if (!isLocationDetailsRoute) {
    return renderContent();
  }

  const isMiniApp = router && router.location.pathname.startsWith('/loc/');

  return (
    <Page title={pageTitle} backPath={isMiniApp ? `/loc/${data}` : `/?location=${data}`}>
      {renderContent()}
    </Page>
  );
});

function getAction(action: { readOnly: boolean; isNew?: string; isCopy?: string }) {
  let value = 'update';
  if ('readOnly' in action && action.readOnly) {
    value = 'read';
  } else if (('isNew' in action && action.isNew) || ('isCopy' in action && action.isCopy)) {
    value = 'new';
  }
  return value;
}

export default memo(LocationCardContent);
