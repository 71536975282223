import { memo, useCallback, useRef, useState } from 'react';
import { uniqueId } from 'lodash';

import { useMounting } from '../../hooks/useMounting';
import { bem } from '../../bem';
import './ConfirmCodeInput.scss';

const { block, element } = bem('ConfirmCodeInput');

const KEY_CODE = { backspace: 8, left: 37, up: 38, right: 39, down: 40 };

const ConfirmCodeInput = ({
  fields,
  values,
  onChange,
  type,
  fieldWidth,
  fieldHeight,
  autoFocus,
  disabled,
  required,
  placeholder,
}) => {
  const [currentValues, setCurrentValues] = useState(Array(fields).fill(''));
  const [currentFocusIndex, setCurrentFocusIndex] = useState(0);
  const refs = useRef<any[]>([]);

  const handleMounting = useCallback(() => {
    let newValues;
    let autoFocusIndex = 0;
    if (values && values.length) {
      newValues = [];
      for (let i = 0; i < fields; i++) {
        newValues.push(values[i] || '');
      }
      autoFocusIndex = values.length >= fields ? 0 : values.length;
    } else {
      newValues = Array(fields).fill('');
    }

    setCurrentValues(newValues);
    setCurrentFocusIndex(autoFocusIndex);
  }, [fields, values]);

  useMounting(handleMounting);

  const triggerChange = useCallback(
    (newValues) => {
      if (onChange) {
        onChange(newValues.join(''));
      }
    },
    [onChange],
  );

  const handleChange = useCallback(
    (e) => {
      const index = parseInt(e.target.dataset.id, 10);
      if (type === 'number') {
        e.target.value = e.target.value.replace(/[^\d]/gi, '');
      }

      if (e.target.value === '' || (type === 'number' && !e.target.validity.valid)) {
        return;
      }

      const { value } = e.target;
      const newValues = [...currentValues];
      if (value.length > 1) {
        let nextIndex = value.length + index - 1;
        if (nextIndex >= fields) {
          nextIndex = fields - 1;
        }
        const split = value.split('');
        split.forEach((item, i) => {
          const cursor = index + i;
          if (cursor < fields) {
            newValues[cursor] = item;
          }
        });
      } else {
        newValues[index] = value;
      }

      if (refs.current[index + 1]) {
        setCurrentFocusIndex(index + 1);
      }

      setCurrentValues(newValues);
      triggerChange(newValues);
    },
    [currentValues, fields, triggerChange, type],
  );

  const handleKeyDown = useCallback(
    (e) => {
      const index = parseInt(e.target.dataset.id, 10);
      const prevIndex = index - 1;
      const nextIndex = index + 1;
      const prev = refs.current[prevIndex];
      const next = refs.current[nextIndex];
      const newValues = [...currentValues];
      switch (e.keyCode) {
        case KEY_CODE.backspace:
          e.preventDefault();
          if (currentValues[index]) {
            newValues[index] = '';
          } else if (prev) {
            newValues[prevIndex] = '';
            setCurrentFocusIndex(prevIndex);
          }
          setCurrentValues(newValues);
          triggerChange(newValues);
          break;
        case KEY_CODE.left:
          e.preventDefault();
          if (prev) {
            setCurrentFocusIndex(prevIndex);
          }
          break;
        case KEY_CODE.right:
          e.preventDefault();
          if (next) {
            setCurrentFocusIndex(nextIndex);
          }
          break;
        case KEY_CODE.up:
        case KEY_CODE.down:
          e.preventDefault();
          break;
        default:
          break;
      }
    },
    [currentValues, triggerChange],
  );

  const handleFocus = useCallback((e) => {
    e.target.select(e);
  }, []);

  const INPUT_STYLE = {
    width: fieldWidth,
    height: fieldHeight,
  };

  const handleRefs = useCallback(
    (index) => (input) => {
      if (refs.current.length < fields) {
        refs.current = [...refs.current, input];
      }
    },
    [fields],
  );

  return (
    <div className="inputContainer">
      <div {...block()}>
        {currentValues.map((value, index) => (
          <input
            type={type === 'number' ? 'tel' : type}
            pattern={type === 'number' ? '[0-9]*' : undefined}
            autoFocus={autoFocus && index === currentFocusIndex}
            style={INPUT_STYLE}
            key={uniqueId()}
            data-id={index}
            value={value}
            ref={handleRefs(index)}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={index < 3 ? handleFocus : undefined}
            disabled={disabled}
            required={required}
            placeholder={placeholder[index]}
          />
        ))}
      </div>
    </div>
  );
};

ConfirmCodeInput.defaultProps = {
  type: 'number',
  fields: 4,
  fieldWidth: 58,
  fieldHeight: 54,
  autoFocus: true,
  disabled: false,
  required: false,
  placeholder: [],
};

export default memo(ConfirmCodeInput);
