import { isNil, isArray, mapValues, keyBy } from 'lodash';
import { fakeNull } from 'config/constants';
import { createValidator } from 'lib/ui/FormBuilder/validators';

import { filterAttrsByConditions, filterValuesByConditions } from './conditionalAttrs';

function isEmptyValue(value) {
  if (value === null) return true;
  if (value === undefined) return true;
  if (value === fakeNull) return true;
  if (value === '') return true;
  return isArray(value) && value.length === 0;
}

function byRank(a, b) {
  return a.rank - b.rank;
}

export const READ_ONLY_RENDERERS = [
  'inventory_audit',
  'label',
  'photo_readonly',
  'document_readonly',
];

export const isReadOnlyRenderer = (attr) => {
  return READ_ONLY_RENDERERS.includes(attr.renderer);
};

function actionAttributes(action, isIssue) {
  return (attr) => {
    if (isIssue) {
      if (attr.actions[action] !== undefined) {
        return true;
      }
      attr.read_only = true;
      return attr.value !== undefined && attr.value !== null;
    }
    return attr.actions[action] !== undefined;
  };
}

export function isAttrReadonly(attr) {
  if (isReadOnlyRenderer(attr)) {
    return true;
  }

  return attr.read_only;
}

function getAttrValue(managedObject, attr, action = '') {
  if (managedObject.issue_type) {
    const value = (managedObject.attributes || {})[attr.internal_name || attr.id];
    return isNil(value) ? undefined : value;
  }
  const isAttrEditable = action && attr.actions[action] && attr.actions[action].editable === true;

  if (isReadOnlyRenderer(attr)) {
    return attr.default_value;
  }

  return !isNil(attr.value) ? attr.value : isAttrEditable ? attr.default_value : '';
}

function mapAttribute(attr, action, managedObject) {
  const isIssue = managedObject.issue_type !== undefined;

  let isReadOnly = action === 'read';
  let isRequired;
  if (isIssue) {
    isReadOnly = isAttrReadonly(attr);
    isRequired = attr.actions[action] === true;
  } else if (attr.actions && attr.actions[action]) {
    if (action !== 'read') {
      isReadOnly = !attr.actions[action].editable;
      isRequired = attr.actions[action].required;
    } else {
      isReadOnly = true;
      isRequired = false;
    }
  }

  return {
    ...attr,
    read_only: isReadOnly,
    id: isIssue ? attr.internal_name || attr.id : attr.id || attr.internal_name,
    required: isRequired,
    value: getAttrValue(managedObject, attr, action),
    pattern: attr.options && attr.options.regex && new RegExp(attr.options.regex),
  };
}

export function getAttrsByAction(managedObject, action, initialAttributes) {
  let finalAction = action;
  if (action === 'submit') {
    finalAction = 'close';
  }
  if (action === 'return') {
    finalAction = 'assign';
  }

  const isIssue = managedObject.issue_type !== undefined;

  const filteredAttributes = initialAttributes
    .map((attr) => mapAttribute(attr, finalAction, managedObject))
    .filter(actionAttributes(finalAction, isIssue));

  if (isIssue) {
    const defaultAttrValues = initialAttributes.reduce((accum, attr) => {
      if (attr.default_value !== undefined) {
        return {
          ...accum,
          [attr.id]: attr.default_value,
        };
      }
      return accum;
    }, {});

    return filteredAttributes
      .map((attr) => {
        if (defaultAttrValues[attr.id] !== undefined && attr.value === undefined) {
          return {
            ...attr,
            value: defaultAttrValues[attr.id],
          };
        }
        return attr;
      })
      .sort(byRank);
  }

  return filteredAttributes;
}

export function areAllActionAttrsFilled(issue, action) {
  const attributes = getAttrsByAction(issue, action, issue.issue_type.attributes);
  const values = mapValues(keyBy(Object.values(attributes), 'id'), 'value');
  const visibleAttributes = filterAttrsByConditions(attributes, issue, values);
  return visibleAttributes.every((attr) => {
    if (attr.required) {
      return !isEmptyValue(attr.value);
    }
    return true;
  });
}

const validators = {
  pattern: createValidator(
    (attr) => (value) => (attr.pattern.test(value) ? undefined : 'Pattern mismatch'),
    'pattern',
  ),
};

export function getAttrValidators(attr) {
  return Object.keys(validators)
    .filter((validator) => attr[validator])
    .map((validator) => validators[validator](attr));
}

export function getInitialValues(attrs, currentUser, action) {
  const result = {};

  attrs.forEach((attr) => {
    // Read-only renderers should not send their values.
    if (!isReadOnlyRenderer(attr)) {
      result[attr.id] = attr.value;
    }

    if (attr.id === '_desired_date') {
      result[attr.id] = attr.default_value;
    }

    if (attr.renderer === 'checkbox' && isNil(attr.value)) {
      result[attr.id] = fakeNull;
    }

    if (action === 'new' && attr.renderer === 'mobile') {
      result[attr.id] = result[attr.id] || currentUser.data.mobile;
    }
  });

  return result;
}

export default {
  getAttrValidators,
  getAttrsByAction,
  getInitialValues,
  filterAttrsByConditions,
  filterValuesByConditions,
  getAttrValue,
  areAllActionAttrsFilled,
};
