import { EmployeeStatus } from 'app/users/types';

export const getEmploymentIndicator = (status: EmployeeStatus): string => {
  let employmentIndicator = '';

  switch (status) {
    case EmployeeStatus.BUSY:
      employmentIndicator = '_isBusy';
      break;
    case EmployeeStatus.AVAILABLE:
      employmentIndicator = '_isAvailable';
      break;
    case EmployeeStatus.VERY_BUSY:
      employmentIndicator = '_isVeryBusy';
      break;
    default:
      break;
  }

  return employmentIndicator;
};
