/**
 * Последовательно выполняет переданные промисы
 *
 * @param {*} funcs
 * @return {Promise}
 */
export default function promiseSeq(funcs) {
  return funcs.reduce((promise, func) => promise.then((result) => func().then(Array.prototype.concat.bind(result))),
    Promise.resolve([]));
}
