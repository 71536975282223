import { omit, flowRight, isFunction } from 'lodash';
import { initialize, reduxForm } from 'redux-form';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { FormBuilder, ReduxModal } from 'lib/ui';

import './DictEditorForm.scss';
import { useCallback } from 'react';
import { useMounting } from 'lib/hooks/useMounting';

import { DICT_EDITOR_MODAL } from '../config';

const { block } = bem('DictEditorForm');

function renderTitle(titleValue, data) {
  if (isFunction(titleValue)) return titleValue(data);
  return titleValue;
}

function promisify(func) {
  return function promiseFunc(options) {
    return new Promise((resolve, reject) => {
      func(options, (err, val) => {
        if (err) {
          return reject(err);
        }
        return resolve(val);
      });
    });
  };
}

const DictEditorForm = ({ modalProps, item, items, data, dispatch, invalid, onClose, submit }) => {
  const getItem = useCallback(() => {
    return (item ? item.data : items && items.byId(data)) || {};
  }, [data, item, items]);

  const handleInit = useCallback(() => {
    const { getFormValues } = modalProps;
    const currentItem = getItem();

    dispatch(
      initialize('dictEditorForm', {
        ...getFormValues(currentItem, modalProps),
        id: currentItem.id,
      }),
    );
  }, [dispatch, getItem, modalProps]);

  useMounting(handleInit);

  const { renderFormFields, editable } = modalProps;

  const isNew = () => {
    return !getItem().id;
  };

  return (
    <div {...block()}>
      <FormBuilder.Hidden name="id" />

      {renderFormFields(getItem(), modalProps)}

      {(isNew() || editable) && (
        <FormBuilder.ButtonsGroup
          submitText={isNew() ? t('common.add') : t('common.save')}
          disabled={invalid}
          onCancel={onClose}
          onSubmit={submit}
        />
      )}
    </div>
  );
};

export default flowRight(
  ReduxModal({
    name: DICT_EDITOR_MODAL,
    routing: true,

    getTitle: (id, { data, modalProps, item, items }) =>
      renderTitle(
        data === 'new' ? modalProps.newModalTitle : modalProps.editModalTitle,
        item ? item.data : items && items.byId(data),
      ),

    mapStateToProps: (state, props) => {
      const { connectItemResource, connectResource } = props.modalProps;

      return {
        item:
          connectItemResource && props.data !== 'new'
            ? connectItemResource(state, props.data)
            : null,

        items: connectItemResource ? null : connectResource(state, { forceFetchOnMount: false }),
      };
    },
  }),
  reduxForm({
    form: 'dictEditorForm',
    onSubmit(data, dispatch, props) {
      if (data.id) {
        props.modalProps.onUpdateItem(data, props.items.byId(props.data));
      } else {
        props.modalProps.onAddItem(omit(data, 'id'));
      }
    },
  }),
)(DictEditorForm);
