import { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';

import { bem } from 'lib/bem';
import { Icon } from 'lib/ui/icon';
import { t } from 'lib/i18n';

import './SearchField.scss';

const { block, element } = bem('SearchField');

interface Props {
  onChange: (searchValue: string) => void;
  debounceDelay?: number;
}

export const SearchField = ({ onChange, debounceDelay = 500 }: Props) => {
  const [value, setValue] = useState('');

  const debouncedChange = (searchValue: string) => {
    onChange(searchValue);
  };

  const debouncedChangeHandler = useCallback(debounce(debouncedChange, debounceDelay), []);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    debouncedChangeHandler(event.target.value);
  };

  const handleClear = () => {
    setValue('');
    onChange('');
  };

  return (
    <div {...block()}>
      <TextField
        id="search"
        type="search"
        placeholder={t('common.search')}
        fullWidth
        size="small"
        value={value}
        onChange={handleChange}
        {...element('search-field')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon glyph="search" />
            </InputAdornment>
          ),
          endAdornment: value && (
            <InputAdornment position="start" onClick={handleClear} {...element('clear-icon')}>
              <Icon glyph="close" />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
