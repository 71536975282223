import { keyBy } from 'lodash';

export default {
  selector(data, index) {
    return data && data.map((i) => index[i]);
  },

  normalizer(response) {
    let data: any[] = [];
    if (Array.isArray(response)) {
      data = response;
    } else {
      data = response.data;
    }
    data = data.filter((x) => x !== null);
    return {
      data: data.map((i) => i.id),
      index: keyBy(data, (i) => i.id),
    };
  },
};
