import { Layout } from 'react-grid-layout';
import { max } from 'lodash';

import { DASHBOARD_COLS, TILE_MARGIN } from '../../config';

interface Options {
  widgets: Layout[];
  currentBreakpoint: string;
  currentContainerWidth: number;
}

const calculateDashboardRowsNumber = (widgets: Layout[]) => {
  // Additional rows need to have possibility move widget down.
  const additionalRows = 5;
  return max(widgets.map((widget) => widget.y + widget.h)) ?? 0 + additionalRows;
};

const calculateDashboardColumnWidth = (containerWidth: number, columns: number) => {
  return (containerWidth - (columns - 1) * TILE_MARGIN) / columns;
};

export const useDashboardGrid = ({
  widgets,
  currentBreakpoint,
  currentContainerWidth,
}: Options) => {
  const gridColumns = DASHBOARD_COLS[currentBreakpoint];
  const gridRows = calculateDashboardRowsNumber(widgets);
  const gridColumnWidth = calculateDashboardColumnWidth(currentContainerWidth, gridColumns);

  return {
    gridColumns,
    gridRows,
    gridColumnWidth,
  };
};
