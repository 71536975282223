import { PropsWithChildren } from 'react';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

interface Props {
  sx?: SxProps<Theme>;
}

const Col = ({ children, sx }: PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Col;
