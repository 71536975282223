import { memo } from 'react';
import { Box } from '@mui/material';
import './DashboardGrid.scss';
import { TILE_HEIGHT, TILE_MARGIN } from '../../config';

const { block, element } = bem('DashboardGrid');

interface Props {
  dragging: boolean;
  resizing: boolean;
  columns: number;
  rows: number;
  columnWidth: number;
}

const DashboardGrid = ({
  dragging,
  resizing,
  columns,
  rows,
  columnWidth,
}: Props) => {
  return (
    <Box
      sx={{
        gridTemplateColumns: `repeat(${columns}, ${columnWidth}px)`,
        gridTemplateRows: `repeat(${rows}, ${TILE_HEIGHT}px)`,
        gridColumnGap: `${TILE_MARGIN}px`,
        gridRowGap: `${TILE_MARGIN}px`,
        display: dragging || resizing ? 'grid' : 'none',
      }}
      {...block()}
    >
      {
        Array(rows).fill('').map((x, rowIndex) => {
          return Array(columns).fill('').map((y, columnIndex) => {
            return (
              <div key={`${rowIndex}-${columnIndex}-cell`} {...element('cell')} />
            );
          });
        })
      }
    </Box>
  );
};

export default memo(DashboardGrid);
