import { api } from 'lib/api';

const endpoint = '/v3/auth/captcha/';

export const getCaptcha = () => api.get(endpoint);

export const createCaptcha = () => api.post(`${endpoint}create`);

export const validateCaptcha = (payload: { data: string; uuid: string }) =>
  api.post(`${endpoint}validate`, { body: payload });
