import { CheckCircle as CheckCircleIcon, QrCode as QrCodeIcon } from '@mui/icons-material';
import { t_prefixed } from 'lib/i18n';
import { Button, Typography } from '@mui/material';

const label = t_prefixed('issues.fields.qrcode_verify');

export type TStatus = 'success';

export enum QRcodeStatus {
  success = 'success',
}

export const getCameraStatus = (currentStatus: TStatus | null, handleClick) => {
  switch (currentStatus) {
    case QRcodeStatus.success: {
      return (
        <>
          <CheckCircleIcon sx={{ color: '#34C759' }} />
          <Typography>{label(QRcodeStatus.success)}</Typography>
        </>
      );
    }
    default: {
      return (
        <Button
          variant="text"
          onClick={handleClick}
          startIcon={<QrCodeIcon />}
          sx={{
            color: '#387AFF',
            background: 'transparent',
            padding: '5px',
            fontSize: '16px',
          }}
        >
          {label('default')}
        </Button>
      );
    }
  }
};
