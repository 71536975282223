import './IssueStatus.scss';
import { Icon } from 'lib/ui/icon';
import { t } from 'lib/i18n';
import { TranslationKeys } from 'i18next';

const getStatusLabel = (status, showInProgress): TranslationKeys<'issues.status'> =>
  ({
    closed: 'closed',
    opened: 'opened',
    moderation: 'moderation',
    in_progress: showInProgress ? 'in_progress' : undefined,
  })[status] || 'opened';

export default function IssueStatus({ value, showIcon = true, showInProgress, overdue = false }) {
  const isClosed = value === 'closed';
  const status = getStatusLabel(value, showInProgress);

  return (
    <div className={`IssueStatus _${status} ${overdue ? 'overdue' : ''}`}>
      {showIcon && isClosed && <Icon glyph="checkmark" />}
      <span className="IssueStatus__name">{t(`issues.status.${status}`)}</span>
    </div>
  );
}
