import { useTranslation } from 'react-i18next';
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import { Button, Date } from 'lib/ui';
import { Icon } from 'lib/ui/icon';
import { bem } from 'lib/bem';
import { NotificationSchemaOut } from 'shared/api/v4/swagger/data-contracts';
import { convertDateToLocal } from 'lib/helpers/dates';

import './Notification.scss';

const { block, element } = bem('Notification');

const TIME_FORMAT = 'HH:mm';

interface Props {
  notification: NotificationSchemaOut;
  viewInfo: (notification: NotificationSchemaOut) => void;
}

export const Notification = ({ notification, viewInfo }: Props) => {
  const { t } = useTranslation();

  const checkDate = () => {
    const date = moment(notification.created);
    const localDate = convertDateToLocal(notification.created);

    if (date.isSame(moment(), 'day')) {
      return `${t('notification_page.today')}, ${localDate?.format(TIME_FORMAT)}`;
    }

    if (date.isSame(moment().subtract(1, 'day'), 'day')) {
      return `${t('notification_page.yesterday')}, ${localDate?.format(TIME_FORMAT)}`;
    }

    return <Date value={notification.created} />;
  };

  return (
    <div {...block()} key={notification.id} style={{ display: 'flex' }}>
      <div {...element('content')}>
        <div style={{ display: 'flex' }}>
          <div {...element('indicator')}>
            {!notification.seen && <Icon glyph="unread-indicator" width="5px" height="24px" />}
          </div>

          <div {...element('body')}>
            <div {...element('title')}>{notification.title}</div>

            <div {...element('message')}>{notification.body}</div>
            {notification.entity_id && (
              <div>
                <Button onClick={() => viewInfo(notification)} {...element('button')}>
                  {/* TODO: #i18n Add translation key type check */}
                  {t(
                    `notification_page.view_button.${notification.entity_type}`,
                    notification.entity_type,
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div {...element('date')}>{checkDate()}</div>
      </div>
    </div>
  );
};
