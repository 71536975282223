import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  MyLocation as MyLocationIcon,
} from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { t_prefixed } from 'lib/i18n';

const label = t_prefixed('issues.fields.geolocation');

export type TStatus =
  | 'loading'
  | 'success'
  | 'out'
  | 'error'
  | 'blocked'
  | 'setting_error'
  | 'unavailable';

export enum GeoStatus {
  loading = 'loading',
  success = 'success',
  out = 'out',
  error = 'error',
  blocked = 'blocked',
  setting_error = 'setting_error',
  unavailable = 'unavailable',
}

export const getGeoStatus = (currentStatus: TStatus | null, handleClick) => {
  switch (currentStatus) {
    case GeoStatus.success: {
      return (
        <>
          <CheckCircleIcon sx={{ color: '#34C759' }} />
          <Typography>{label(GeoStatus.success)}</Typography>
        </>
      );
    }
    case GeoStatus.out: {
      return (
        <>
          <CancelIcon sx={{ color: '#FF3B30' }} />
          <Typography>{label(GeoStatus.out)}</Typography>
        </>
      );
    }
    case GeoStatus.error: {
      return (
        <>
          <ErrorIcon sx={{ color: '#FFCC00' }} />
          <Typography>{label(GeoStatus.error)}</Typography>
        </>
      );
    }
    case GeoStatus.loading: {
      return (
        <>
          <MyLocationIcon sx={{ color: '#387AFF' }} />
          <Typography sx={{ color: '#387AFF' }}>{label(GeoStatus.loading)}</Typography>
        </>
      );
    }
    case GeoStatus.blocked: {
      return (
        <>
          <ErrorIcon sx={{ color: '#FFCC00' }} />
          <Typography>{label(GeoStatus.blocked)}</Typography>
        </>
      );
    }
    case GeoStatus.setting_error: {
      return (
        <>
          <ErrorIcon sx={{ color: '#FFCC00' }} />
          <Typography>{label(GeoStatus.setting_error)}</Typography>
        </>
      );
    }
    default: {
      return (
        <Button
          variant="text"
          onClick={handleClick}
          startIcon={<MyLocationIcon />}
          sx={{
            color: '#387AFF',
            background: 'transparent',
            padding: '5px',
            fontSize: '16px',
          }}
        >
          {label('default')}
        </Button>
      );
    }
  }
};
