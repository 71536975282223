import { Button, confirm } from 'lib/ui/index';

import { label, modalProps } from '../config';
import { IUserRoles } from '../lib';

interface Props {
  item: IUserRoles;
}

const { element } = bem('UserRoles');

const UserRolesListItem = ({ item }: Props) => {
  const handleRemove = (role) => (e) => {
    confirm(label('remove_confirmation')).then(() => modalProps.onRemoveItem(role));
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div {...element('item')}>
      <div {...element('itemInfo')}>
        <p {...element('itemName')}>
          {item.name && item.name[0].toUpperCase().concat('', item.name.slice(1))}
        </p>
        <p {...element('itemDescription')}>{item.description}</p>
      </div>
      <div {...element('itemActions')}>
        {!item.system && (
          <Button small ghost>
            {t('common.edit')}
          </Button>
        )}

        {!item.system && (
          <Button small ghost onClick={handleRemove(item)}>
            {t('common.remove')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default UserRolesListItem;
