import './DateRangePicker.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { Datepicker } from 'lib/ui';

const { block } = bem('DateRangePickerWrapper');

export default class DateRangePicker extends PureComponent<any> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  endDatepicker: boolean | undefined;

  // eslint-disable-next-line react/no-unused-class-component-methods
  startDatepicker: boolean | undefined;

  state = {
    startDatePickerOpened: false,
    endDatePickerOpened: false,
  };

  constructor(props: any) {
    super(props);

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.getDates = this.getDates.bind(this);
  }

  handleStartDatePickerOpenedChange = (value) => {
    this.setState({ startDatePickerOpened: value });
  };

  handleEndDatePickerOpenedChange = (value) => {
    this.setState({ endDatePickerOpened: value });
  };

  handleStartDateChange(startDate) {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      start: startDate,
    });

    if (!startDate && !value.end) {
      this.handleStartDatePickerOpenedChange(false);
    } else if (!value.end) {
      this.handleStartDatePickerOpenedChange(false);
      this.handleEndDatePickerOpenedChange(true);
    }
  }

  handleEndDateChange(endDate) {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      end: endDate,
    });

    if (!endDate && !value.start) {
      this.handleEndDatePickerOpenedChange(false);
    } else if (!value.start) {
      this.handleEndDatePickerOpenedChange(false);
      this.handleStartDatePickerOpenedChange(true);
    }
  }

  getDates() {
    const { value } = this.props;
    return [value.start, value.end];
  }

  render() {
    const [startDate, endDate] = this.getDates();

    const { startDatePickerOpened, endDatePickerOpened } = this.state;

    return (
      <div {...block()}>
        <Datepicker
          name={this.props.name}
          externalOpen={startDatePickerOpened}
          value={startDate}
          onChange={this.handleStartDateChange}
          placeholder={t('common.date_from')}
          maxDate={endDate}
          startDate={startDate}
          endDate={endDate}
          disableCloseOnSelect={false}
          onExternalOpenChange={this.handleStartDatePickerOpenedChange}
          isRangePicker
        />
        <Datepicker
          name={this.props.name}
          externalOpen={endDatePickerOpened}
          value={endDate}
          onChange={this.handleEndDateChange}
          placeholder={t('common.date_to')}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          disableCloseOnSelect={false}
          onExternalOpenChange={this.handleEndDatePickerOpenedChange}
          isRangePicker
          isEndPicker
        />
      </div>
    );
  }
}
