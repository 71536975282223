import './IssueComment.scss';

export default function IssueComment({ issue }) {
  const comment = issue.attributes ? issue.attributes._comment : null;

  return (
    <div className="IssueComment">
      {comment}
    </div>
  );
}
