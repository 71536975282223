import { Skeleton } from '@mui/lab';
import { ReactNode } from 'react';
import {
  Card, CardContent, CardHeader,
} from '@mui/material';

import './BaseWidget.scss';

const { block } = bem('BaseWidget');

interface Props {
  children: ReactNode;
  className?: string;
  title?: string;
  loading?: boolean;
}

const BaseWidget = ({
  children,
  className,
  title,
  loading = false,
}: Props) => {
  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ height: '100%', width: '100%' }}
      />
    );
  }
  return (
    <Card {...block({}, className)}>
      {
        title ? (
          <CardHeader title={title} />
        ) : null
      }
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
};

export default BaseWidget;
