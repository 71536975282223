import { MouseEventHandler, PropsWithChildren } from 'react';

import { Icon } from 'lib/ui/icon';
import { bem } from 'lib/bem';
import { TagProps } from 'shared/ui/select/types';

import './tag.scss';

const { block, element } = bem('Tag');

export const Tag = ({ onDelete, children }: PropsWithChildren<TagProps>) => {
  const handleTagClose: MouseEventHandler = (e) => {
    e.stopPropagation();
    onDelete?.(e);
  };

  return (
    <div className={block().className}>
      <div className={element('close').className} onClick={handleTagClose}>
        <div className={element('icon-container').className}>
          <Icon glyph="close" />
        </div>
      </div>
      <span className={element('separator').className} />
      <div className={element('label').className}>{children}</div>
    </div>
  );
};
