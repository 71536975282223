import { memo } from 'react';

import declineWord from './declension';

// Children is delimited string with different forms of word.
// Separator is ';'.
const Decline = ({ children, number }: { children?: any; number: any }) => {
  if (children && children[0]) {
    const trimmedString = children[0].replace(/\s+/g, ' ');
    const [one, two, five] = trimmedString.split(';');
    return declineWord(number, one, two, five);
  }
  return '';
};

export default memo(Decline);
