import { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { isNil, flow } from 'lodash';
import { bem } from 'lib/bem';
import { reconnect } from 'lib/resource';
import {
  change,
  getFormSyncErrors,
  getFormValues,
  initialize,
  reduxForm,
  touch,
  unregisterField,
} from 'redux-form';
import { attrsService } from 'app/issues/services';
import users from 'app/users/users.resource';
import { READ_ONLY_RENDERERS } from 'app/issues/services/attrsService';
import animatedScrollTo from 'animated-scrollto';

import Attribute from '../Attribute/Attribute';
import { AttributeInputRendererType, getRenderers } from '../attributeInputRenderers';
import { getAttrData, getAttrTitle } from '../lib';
import './AttributesForm.scss';

function scrollToFirstInvalidField() {
  const firstInvalidField = document.querySelectorAll('.Modal._issue ._invalid')[0];
  const scrollContainer = document.querySelector('.Modal._issue');
  const duration = 300;
  if (!firstInvalidField) return;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'offsetTop' does not exist on type 'Eleme... Remove this comment to see the full error message
  animatedScrollTo(scrollContainer, firstInvalidField.offsetTop, duration);
}
const { block } = bem('AttributesForm');
const AttrsForm = flow(
  connect((state, props) => ({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'form' does not exist on type '{}'.
    values: getFormValues(props.form)(state),
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'form' does not exist on type '{}'.
    errors: getFormSyncErrors(props.form)(state),
  })),
  reduxForm({
    pure: true,
    enableReinitialize: true,
  }),
)(
  class AttrsForm extends PureComponent<any> {
    get supportedRenderers() {
      return getRenderers(AttributeInputRendererType.ISSUE);
    }

    getSupportedAttributes() {
      return this.props.attrs.filter((attr) => {
        if (this.supportedRenderers[attr.renderer]) {
          return true;
        }
        console.warn(`Unsupported attribute input: ${attr.renderer}`);
        return false;
      });
    }

    renderAttributeFormField(attr, value) {
      const AttributeFormField = this.supportedRenderers[attr.renderer];
      return (
        <AttributeFormField
          key={attr.id}
          name={attr.id}
          title={getAttrTitle(attr)}
          data={getAttrData(attr, this.props.issue)}
          value={value}
          validate={attrsService.getAttrValidators(attr)}
          required={attr.required}
          lastError={this.props.errors[attr.id]}
        />
      );
    }

    renderReadOnlyAttribute(attr, value) {
      if (isNil(value)) return null;
      return <Attribute key={attr.id} attrDescriptor={attr} value={value} />;
    }

    render() {
      const { issue, values, errors } = this.props;
      const attrs = this.getSupportedAttributes();
      if (attrs.length === 0) return null;
      const filteredAttrs = attrsService.filterAttrsByConditions(attrs, issue, values);
      return (
        <div {...block()}>
          {filteredAttrs.map((attr) =>
            attr.read_only
              ? this.renderReadOnlyAttribute(attr, attr.value)
              : this.renderAttributeFormField(attr, attr.value),
          )}
        </div>
      );
    }
  },
);

export default reconnect((state) => ({
  currentUser: (users as any).current(state, {}),
}))(
  class AttributesForm extends Component {
    static propTypes = {
      issue: PropTypes.object.isRequired,
      action: PropTypes.string.isRequired,
      editableOnly: PropTypes.bool,
      onInit: PropTypes.func,
      highlightErrors: PropTypes.bool,
      // form name
      form: PropTypes.string,
    };

    static defaultProps = {
      onInit: () => {},
      highlightErrors: false,
    };

    attrs: any;

    initialValues: any;

    UNSAFE_componentWillMount() {
      this.collectAttributes(this.props);
      this.touchFilledFields();
    }

    componentDidMount() {
      // Вызовем колбэк с задержкой - иначе reduxForm не успевает инициализировать форму
      setTimeout(() => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onInit' does not exist on type 'Readonly... Remove this comment to see the full error message
        this.props.onInit(this.initialValues);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'highlightErrors' does not exist on type ... Remove this comment to see the full error message
        if (this.props.highlightErrors) {
          this.touchAllFields();
          scrollToFirstInvalidField();
        }
      });
    }

    UNSAFE_componentWillUpdate(nextProps) {
      if (
        this.isIssueTypeChanged(nextProps) ||
        this.isAttrsChanged(nextProps) ||
        this.isActionChanged(nextProps)
      ) {
        this.collectAttributes(nextProps);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'dispatch' does not exist on type 'Readon... Remove this comment to see the full error message
        this.props.dispatch(initialize(this.props.form, this.initialValues));
      }
      if (this.isActionChanged(nextProps)) {
        setTimeout(() => {
          if (nextProps.highlightErrors) {
            this.highlightUnfilledFields();
          }
        });
      }
    }

    componentDidUpdate() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'action' does not exist on type 'Readonly... Remove this comment to see the full error message
      if (this.props.action === 'submit') {
        this.touchAllFields();
      }
    }

    highlightUnfilledFields() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dispatch' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.dispatch(initialize(this.props.form, this.initialValues));
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dispatch' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.dispatch(change(this.props.form, '__test', 1));
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dispatch' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.dispatch(unregisterField(this.props.form, '__test'));
      this.touchAllFields();
      scrollToFirstInvalidField();
    }

    isIssueTypeChanged(nextProps) {
      const newIssueType = nextProps.issue.issue_type;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'issue' does not exist on type 'Readonly<... Remove this comment to see the full error message
      const oldIssueType = this.props.issue.issue_type;
      return newIssueType && oldIssueType && newIssueType.id !== oldIssueType.id;
    }

    isAttrsChanged(nextProps) {
      const newAttrs = nextProps.issue.attributes;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'issue' does not exist on type 'Readonly<... Remove this comment to see the full error message
      const oldAttrs = this.props.issue.attributes;
      return newAttrs && oldAttrs && newAttrs !== oldAttrs;
    }

    isActionChanged(nextProps) {
      const newAction = nextProps.action;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'action' does not exist on type 'Readonly... Remove this comment to see the full error message
      const oldAction = this.props.action;
      return newAction && oldAction && newAction !== oldAction;
    }

    // Чтобы показать ошибки валидации в форме редактирования,
    // пометим все изначально заполненные поля как уже отредактированные.
    touchFilledFields() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'action' does not exist on type 'Readonly... Remove this comment to see the full error message
      const { action, values, dispatch } = this.props;
      if (action === 'update' || action === 'submit') {
        const filledDields = Object.keys(values || {}).filter(
          (field) => !isNil((values || {})[field]),
        );
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'form' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        dispatch(touch(this.props.form, ...filledDields));
      }
    }

    touchAllFields() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dispatch' does not exist on type 'Readon... Remove this comment to see the full error message
      const { dispatch } = this.props;
      const fields = this.attrs.map((attr) => attr.id);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'form' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      dispatch(touch(this.props.form, ...fields));
    }

    collectAttributes(props) {
      const { issue, action } = props;
      this.attrs = attrsService
        .getAttrsByAction(issue, action, issue.issue_type.attributes)
        .filter(
          (attr) =>
            !props.editableOnly || !attr.read_only || READ_ONLY_RENDERERS.includes(attr.renderer),
        );
      this.initialValues = attrsService.getInitialValues(
        this.attrs,
        (this.props as any).currentUser,
        (this.props as any).action,
      );
    }

    render() {
      const { issue, onChange, form } = this.props as any;
      return (
        /* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'AttrsForm' does not have any con... Remove this comment to see the full error message */
        <AttrsForm
          form={form}
          key={issue.issue_type.id}
          attrs={this.attrs}
          initialValues={this.initialValues}
          issue={issue}
          onChange={onChange}
        />
      );
    }
  },
);
