import { all, call, put } from 'redux-saga/effects';
import { takeMap } from 'lib/saga-utils';
import { contextNotification } from 'app/notifications';
import substitutions from './substitutions.resource';

const notify = contextNotification('substitutions');

export default function* substitutionsSaga() {
  yield all([
    call(substitutions.saga),

    call(takeMap({
      'SUBSTITUTIONS/REMOVE_SUCCESS': function* () {
        yield put(notify('removed'));
      },

      'SUBSTITUTIONS/CREATE_SUCCESS': function* () {
        yield put(notify('created'));
      },

      'SUBSTITUTIONS/UPDATE_SUCCESS': function* () {
        yield put(notify('updated'));
      },
    })),
  ]);
}
