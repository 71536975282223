import { memo, ReactNode, useCallback, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { IconButton } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Carousel.scss';

const Arrow = ({ className, style, onClick, onStatusChange }) => {
  if (!onClick) {
    if (onStatusChange) {
      onStatusChange('disabled');
    }
    return null;
  }
  if (onStatusChange) {
    onStatusChange('enabled');
  }

  return (
    <IconButton className={className} onClick={onClick} style={style}>
      <ChevronRightIcon />
    </IconButton>
  );
};

const defaultSettings: Settings = {
  infinite: false,
  speed: 500,
  swipe: true,
  swipeToSlide: true,
  slidesToShow: 8,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
      },
    },
  ],
};

const PrevArrow = (props) => <Arrow {...props} />;
const NextArrow = (props) => <Arrow {...props} />;

interface Props {
  children: ReactNode;
  settings?: Settings;
}

const Carousel = ({ children, settings = {} }: Props) => {
  const [isPrevArrowDisabled, setPrevArrowDisabled] = useState(true);
  const handlePrevArrowDisabledChange = useCallback((value: 'disabled' | 'enabled') => {
    setPrevArrowDisabled(value === 'disabled');
  }, []);

  const sliderSettings = { ...defaultSettings, ...settings };

  return (
    <Slider
      {...sliderSettings}
      className={isPrevArrowDisabled ? '' : 'with-padding-left'}
      prevArrow={<PrevArrow onStatusChange={handlePrevArrowDisabledChange} />}
      nextArrow={<NextArrow />}
    >
      {children}
    </Slider>
  );
};

export default memo(Carousel);
