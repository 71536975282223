import { useCallback, useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/system';
import { Button } from 'lib/ui';
import { Stack, TextField } from '@mui/material';
import { t } from 'lib/i18n/';
import DraggableChip from 'lib/ui/DraggableChip';

const MAX_SAMPLES_QTY = 3;

const TextSamples = ({ input, meta }) => {
  const { onChange } = input;
  const { initial } = meta;
  const [currentValue, setCurrentValue] = useState<string>('');
  const [textSamples, setTextSamples] = useState<Array<string>>(initial || []);

  const textChange = useCallback((evt) => {
    setCurrentValue(evt.target.value);
  }, []);

  const onAddClick = useCallback(() => {
    setTextSamples([...textSamples, currentValue]);
    setCurrentValue('');
  }, [currentValue, textSamples]);

  const deleteSample = useCallback(
    (item) => {
      setTextSamples(textSamples.filter((value) => value !== item));
    },
    [textSamples],
  );

  const moveTextSample = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = textSamples[dragIndex];
      const hoverItem = textSamples[hoverIndex];

      setTextSamples(() => {
        const updatedSamples = [...textSamples];
        updatedSamples[dragIndex] = hoverItem;
        updatedSamples[hoverIndex] = dragItem;
        return updatedSamples;
      });
    },
    [textSamples],
  );

  const showTextSamples = useMemo(() => {
    return (
      <Stack direction="row" flexWrap="wrap">
        {textSamples.map((item, index) => (
          <DraggableChip
            key={item}
            item={item}
            index={index}
            moveTextSample={moveTextSample}
            onDelete={deleteSample}
          />
        ))}
      </Stack>
    );
  }, [deleteSample, moveTextSample, textSamples]);

  const disableAddButton = useMemo(() => {
    const checkedValue = currentValue.trim();
    return !checkedValue || textSamples.length === MAX_SAMPLES_QTY;
  }, [currentValue, textSamples.length]);

  useEffect(() => {
    onChange(textSamples);
  }, [onChange, textSamples]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '15px',
          marginTop: '40px',
        }}
      >
        <TextField
          label={t('attributes.fields.options.text_samples')}
          value={currentValue}
          onChange={textChange}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: false,
            sx: {
              fontFamily: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              fontWeight: 'inherit',
              left: '-15px',
              top: '-40px',
              color: '#757575',
              '&.Mui-focused': {
                color: '#757575',
              },
            },
          }}
          inputProps={{ maxLength: 100 }}
        />
        <Button
          sx={{
            marginLeft: '10px',
          }}
          onClick={onAddClick}
          disabled={disableAddButton}
        >
          {t('common.add')}
        </Button>
      </Box>
      {textSamples.length !== 0 && showTextSamples}
    </>
  );
};

export default TextSamples;
