import { ChangeEvent, useState } from 'react';
import { Box, TextField } from '@mui/material';

import { Button } from 'lib/ui';
import { Icon } from 'lib/ui/icon';

const SearchWithButton = ({ input }) => {
  const [value, setValue] = useState('');

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
  };

  const handleEnterPress = (evt: any) => {
    if (evt.key === 'Enter') {
      input.onChange(value);
    }
  };

  return (
    <Box
      sx={{
        margin: '2rem -6px 1rem',
        display: 'flex',
        border: '6px solid rgba(0, 0, 0, 0.08)',
        '& .MuiOutlinedInput-input': {
          border: 'none !important',

          '&::placeholder': {
            opacity: '0.3',
          },
        },
      }}
    >
      <TextField
        value={value}
        onChange={handleChange}
        fullWidth
        type="text"
        placeholder={t('common.find_placeholder')}
        onKeyPress={handleEnterPress}
      />
      <Button
        sx={{
          color: 'black',
          background: 'white !important',
          borderLeft: '6px solid rgba(0, 0, 0, 0.08)',
        }}
        onClick={() => input.onChange(value)}
        on
      >
        <Icon glyph="search" />
      </Button>
    </Box>
  );
};

export default SearchWithButton;
