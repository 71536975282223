import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiClient_v4 } from 'shared/api/v4/client';

export const smsSettingsQueries = createQueryKeys('smsSettings', {
  settings: {
    queryKey: null,
    queryFn: () => apiClient_v4.smsSettings.getSmsSettings(),
  },
  providersList: {
    queryKey: null,
    queryFn: () => apiClient_v4.smsSettings.getSmsProviderNames(),
  },
});
