import { MouseEventHandler, RefObject, MouseEvent, ReactNode } from 'react';
import { Typography } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import { Label } from 'lib/ui';
import { GlyphName, Icon, IconAsButton } from 'lib/ui/icon';

import './SelectInput.scss';
import { TextButton } from '../text-button';

const { block, element } = bem('SelectInput');

interface SelectInputProps {
  onOpen: MouseEventHandler<HTMLDivElement>;
  isOpen: boolean;
  renderValue?: (item: string) => ReactNode;
  value: string[];
  label?: string;
  startIcon?: GlyphName;
  multiple?: boolean;
  onRemoveItem?: (item: string) => void;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
  ref?: RefObject<HTMLDivElement>;
  hasArrow?: boolean;
  width?: string;
  onClearValue?: (event?: MouseEvent<HTMLButtonElement>) => void;
}

export const SelectInput = ({
  onOpen,
  isOpen,
  renderValue,
  value,
  label,
  startIcon,
  multiple,
  onRemoveItem,
  placeholder,
  required,
  error,
  ref,
  hasArrow,
  width,
  onClearValue,
}: SelectInputProps) => {
  return (
    <div {...block()}>
      <div {...element('header')}>
        {label && (
          <div {...element('title')}>
            <Typography component="div" {...element('label')}>
              {label}
            </Typography>
            {required && <div {...element('requiredMark')}>{t('common.required_sign')}</div>}
          </div>
        )}
        {multiple && value?.length !== 0 && (
          <div {...element('clearButton')}>
            <TextButton onClick={onClearValue} color="primary" isUnderline>
              {t('common.clear')}
            </TextButton>
          </div>
        )}
      </div>
      <div ref={ref} style={{ width }} {...element('select', { error })} onClick={onOpen}>
        {startIcon && <Icon {...element('startIcon')} glyph={startIcon} />}
        {multiple ? (
          <div {...element('labelWrapper')}>
            {value.map((i) => (
              <Label removable onRemove={() => onRemoveItem?.(i)} key={i}>
                {renderValue ? renderValue(i) : i}
              </Label>
            ))}
          </div>
        ) : (
          <div>{renderValue ? renderValue(value[0]) : value[0]}</div>
        )}
        {placeholder && value.length === 0 && <div {...element('placeholder')}>{placeholder}</div>}

        {onClearValue && !multiple && value.length !== 0 && (
          <IconAsButton {...element('clear')} onClick={onClearValue} glyph="close" />
        )}

        {hasArrow && <Icon {...element('arrow', { expand: isOpen })} glyph="squareArrow" />}
      </div>
    </div>
  );
};
