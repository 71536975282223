import { Dialog, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';

import { IconAsButton } from 'lib/ui/icon';

import './ModalV2.scss';

const { block, element } = bem('ModalV2');

type MuiDialogProps = Omit<DialogProps, 'title'>;

interface ModalProps extends MuiDialogProps {
  title: string;
  uppercase?: boolean;
  small?: boolean;
}

// Can be improved in future
export const ModalV2 = ({ title, uppercase, children, onClose, small, ...props }: ModalProps) => {
  return (
    <Dialog {...block({ small })} {...props} onClose={onClose}>
      <DialogTitle {...element('header')}>
        <Typography {...element('title', { uppercase })} component="div">
          {title}
        </Typography>
        <IconAsButton
          {...element('close')}
          onClick={() => onClose?.({}, 'escapeKeyDown')}
          glyph="new_close"
        />
      </DialogTitle>
      <DialogContent {...element('content')}>{children}</DialogContent>
    </Dialog>
  );
};
