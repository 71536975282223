import { ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';
import { pipe, flowRight } from 'lodash/fp';
import { Box } from '@mui/material';

import { ManagedObject, ManagedObjectType } from 'app/managedObjects/types';
import {
  getDateAsString,
  normalizeEndDateAsIs,
  normalizeStartDateAsIs,
  setDateTime,
} from 'lib/helpers/dates';
// eslint-disable-next-line import/no-cycle
import { FormBuilder, List, Page } from 'lib/ui';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { reconnect } from 'lib/resource';
import bookings from 'app/bookings/bookings.resource';
import EmptyPlaceholder from 'lib/ui/EmptyPlaceholder';
import queryForm from 'lib/queryForm';
import TabsContainer from 'lib/ui/TabsContainer/TabsContainer';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';

import NewBookingModal from '../NewBooking/NewBookingModal';
import { createBookingItemCardWithCustomOptions } from '../BookingDetails/BookingDetails';
import './BookingItemsList.scss';

const filterForm = 'bookingItemsTypeFilter';
const { block, element } = bem('BookingItemsList');

const FilterForm = queryForm({ form: filterForm })(({ data, fetching, paginationOnly }) => {
  const { offset, total } = data;

  // eslint-disable-next-line radix
  let routerOffset = offset ? parseInt(offset) : undefined;
  if (routerOffset && Number.isNaN(routerOffset)) {
    routerOffset = undefined;
  }

  return (
    <div {...element('filters')} hidden={fetching}>
      {!paginationOnly && (
        <div {...element('time-interval')}>
          <FormBuilder.Time name="min_time" placeholder={t('time_picker.interval.from')} />
          <FormBuilder.Time name="max_time" placeholder={t('time_picker.interval.to')} />
        </div>
      )}
      <FormBuilder.Pagination
        total={total}
        normalize={String}
        format={Number}
        routerOffset={routerOffset}
        {...element('pagination')}
      />
    </div>
  );
});

interface BookingTypeItemsListProps {
  bookingType: ManagedObjectType;
  list: { data: ManagedObject[]; fetching?: boolean; outdated?: boolean };
  renderList: (data: ManagedObjectType[]) => ReactNode;
  isSelected: boolean;
}

const modifyFormValues =
  ({ bookingType, bookingDate }) =>
  (
    mut_filters: {
      strict_time_filter?: any;
      type_ids?: any;
      start_datetime?: any;
      end_datetime?: any;
      max_time?: any;
      min_time?: any;
    } = {},
  ) => {
    const { max_time, min_time } = mut_filters;
    mut_filters.strict_time_filter = !!(min_time && max_time);
    mut_filters.type_ids = bookingType.id;
    mut_filters.start_datetime = min_time
      ? setDateTime(bookingDate, min_time)
      : normalizeStartDateAsIs(bookingDate);
    mut_filters.end_datetime = max_time
      ? setDateTime(bookingDate, max_time)
      : normalizeEndDateAsIs(bookingDate);
    return mut_filters;
  };

const BookingTypeItemsList = flowRight(
  reconnect((state, { bookingType, isSelected }: BookingTypeItemsListProps) => {
    if (isSelected) {
      const filters = pipe(
        getFormValues(filterForm),
        modifyFormValues({ bookingType, bookingDate: state.bookingDate }),
      )(state);

      return { filters };
    }
  }),
  reconnect((state, { filters }) => {
    const list = bookings.bookingItemsList(state, filters, { skipFetchState: false });
    return { list };
  }),
)(({ list = { data: [] }, renderList }: BookingTypeItemsListProps): any => {
  return (
    <>
      {list.fetching && !list.outdated && <BusyIndicator sx={{ mt: 1 }} />}
      <Box hidden={list.fetching && !list.outdated}>
        <FilterForm data={list} />
        {renderList(list.data)}
        <FilterForm data={list} paginationOnly />
      </Box>
    </>
  );
});

export default flowRight(
  reconnect((state) => {
    const types = bookings.bookingItemTypes(state, {});
    return { types };
  }),
)(({ types, route }) => {
  const dispatch = useDispatch();

  const renderItem = (item) => {
    return (
      <div {...element('item')}>
        <p {...element('name')}>{item.name}</p>
        <p {...element('location')}>{item.related_managed_object.parent_names.join(', ')}</p>
      </div>
    );
  };

  const getLinkProps = (book) => {
    return {
      to: 'book',
      data: book.related_managed_object.id,
      overwriteLocationQuery: false,
      extraData: { bookingItem: book },
    };
  };

  const CustomPlaceholder = useMemo(
    () => (
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'className' is missing in type '{ placeho... Remove this comment to see the full error message
      <EmptyPlaceholder placeholder={t('bookings.no_bookings_objects_text')} />
    ),
    [],
  );

  const renderList = useCallback(
    (data) => {
      return (
        <List
          {...block()}
          data={data}
          showId={false}
          renderItem={renderItem}
          linkProps={getLinkProps}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          customPlaceholder={CustomPlaceholder}
        />
      );
    },
    [CustomPlaceholder],
  );

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = useCallback((event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    bookings.bookingItemsList.invalidate();
  }, []);

  const [date, setDate] = useState(getDateAsString());

  const handleDayChange = (newDate: string) => {
    setDate(newDate);
    bookings.bookingItemsList.invalidate();
    dispatch({
      type: 'PUT_BOOKING_DATE',
      name: 'bookingDate',
      value: newDate,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'PUT_BOOKING_DATE',
      name: 'bookingDate',
      value: date,
    });
  }, [date, dispatch]);

  const tabPanels = useMemo(() => {
    if (!types.fetching && types.data) {
      return types.data.map((typeInfo, index) => ({
        content: (
          <BookingTypeItemsList
            bookingType={typeInfo}
            renderList={renderList}
            isSelected={tabIndex === index}
          />
        ),
        label: typeInfo.name_ru,
      }));
    }
    return [];
  }, [renderList, tabIndex, types.data, types.fetching]);

  const modalContentStyle = {
    maxWidth: 900,
    width: 'unset',
  };

  const BookingItemCard = createBookingItemCardWithCustomOptions({ routing: false });

  return (
    <Page title={t('menu.bookings.book')}>
      {tabPanels.length ? (
        <>
          <BookingItemCard list={route.list} />
          <NewBookingModal modalProps={{ modalContentStyle }} list={route.list} />
          <Box>
            <TabsContainer
              tabPanels={tabPanels}
              value={tabIndex}
              onChange={handleTabChange}
              onDateChange={handleDayChange}
            />
          </Box>
        </>
      ) : (
        <EmptyPlaceholder
          className="booking"
          placeholder={t('bookings.no_bookings_objects_text')}
        />
      )}
    </Page>
  );
});
