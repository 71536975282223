import { api } from 'lib/api';
import { createResource } from 'lib/resource';
import { put } from 'redux-saga/effects';
import { closeModal } from 'lib/ui/Modal';

import { SAVE_LOCATION_TYPE_MODAL } from './config';

export const LOCATION_TYPES_ACTIONS_LIST = ['new', 'update', 'read'];

function* closeOnSuccess() {
  yield put(closeModal(SAVE_LOCATION_TYPE_MODAL));
}

export default createResource('locationTypes', {
  scopes: {
    list: () => api.get('/v3/location_types/'),
  },

  byId: ({ payload }) => api.get(`/location_types/${payload.id}/`),

  mutators: {
    create: {
      request: ({ payload }) => api.post('/location_types/create/', { body: payload }),
      onSuccess: closeOnSuccess,
    },

    update: {
      request: ({ payload }) => {
        const { id, ...body } = payload;
        return api.post(`/location_types/${id}/update`, { body });
      },
      onSuccess: closeOnSuccess,
    },

    remove: ({ payload }) => api.post(`/location_types/${payload.id}/delete/`),
  },
});
