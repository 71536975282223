import { parseToString } from 'shared/lib/json-big-numbers-parser';

// eslint-disable-next-line import/no-cycle
import { authRoutine, fetchWithContext, getOptions, getUrl, ResponseWithContext } from './utils';
import { ApiSendResponse } from './types/api-methods-types';

const dataPreparingRoutine = async <Data>(
  data: ResponseWithContext,
): Promise<ApiSendResponse<Data>> => {
  const {
    response,
    context: { options },
  } = data;
  if (response.status === 204) {
    return {
      request: options,
      response: null as Data,
    };
  }

  const status = response.status >= 200 && response.status < 300 ? 'response' : 'error';

  return response.text().then((text) => {
    const resp: Data = parseToString(text);
    const meta = (resp as unknown as { metadata?: unknown })?.metadata ?? {};
    const total =
      (meta as unknown as { pagination?: { total?: string } })?.pagination?.total ?? null;

    if (status === 'error') {
      throw resp;
    }

    return {
      request: options,
      total: response.headers.get('total') || total,
      responseHeaders: response.headers,
      httpStatusCode: response.status,
      response: resp,
    };
  });
};

const send = <Data = unknown>(url: string, options: RequestInit): Promise<ApiSendResponse<Data>> =>
  fetchWithContext(url, options)
    .then(authRoutine)
    .then(dataPreparingRoutine<Data>);

function getMethod(method) {
  return <Data = unknown>(url: string, options = {}) => {
    return send<Data>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getUrl(url, (options as any).search || (options as any).params),
      getOptions(method, options),
    );
  };
}

export const api = {
  get: getMethod('GET'),
  post: getMethod('POST'),
  put: getMethod('PUT'),
  delete: getMethod('DELETE'),
  patch: getMethod('PATCH'),
};
