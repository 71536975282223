import { PureComponent } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import escapeStringRegexp from 'escape-string-regexp';
import { xorBy, keyBy, isNil, isEmpty } from 'lodash';
import { InputAdornment } from '@mui/material';

import { Icon, IconAsButton, GlyphName } from 'lib/ui/icon';
// eslint-disable-next-line import/no-cycle
import { TreeView, Checkbox, Label, Search, Button } from 'lib/ui';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { TextButton } from 'shared/ui/text-button';

import BusyIndicator from '../BusyIndicator/BusyIndicator';
import './TreeViewSelect.scss';

const { block, element } = bem('TreeViewSelect');

export default enhanceWithClickOutside(
  class TreeViewSelect extends PureComponent<any> {
    static propTypes = (() => {
      const { object, number, func, bool, string, any } = PropTypes;
      return {
        tree: object,
        value: any,
        multiple: bool,
        expandLevel: number,
        getLabel: func,
        onChange: func,
        onBlur: func,
        onFocus: func,
        renderFilter: func,
        nodesFilter: func,
        searchable: bool,
        filterable: bool,
        withLocationCards: bool,
        renderTitle: func,
        parentNodesDisabled: bool,
        isDisplayFolders: bool,
        label: string,
        required: bool,
      };
    })();

    static defaultProps = {
      getItemFullPath: () => false,
      searchable: true,
      filterable: true,
      onSearch: () => null,
      parentNodesDisabled: false,
      isDisplayFolders: false,
    };

    filter: any;

    treeView: any;

    state = {
      dropdownIsVisible: false,
      filter: '',
      selectedNodes: {},
      expandedNodes: {},
    };

    componentDidMount() {
      this.collectLocalValues(this.props.value);
      this.autoSelectSingleOption();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.autoSelectSingleOption(nextProps);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type '{ dropdo... Remove this comment to see the full error message
      if (xorBy(this.state.values || [], nextProps.value || [], 'id').length > 0) {
        this.collectLocalValues(nextProps.value);
      }
    }

    autoSelectSingleOption(props = this.props) {
      const { tree, autoSelectSingleOption, multiple } = props;
      if (!autoSelectSingleOption) return;
      if (tree && tree.children.length === 1 && tree.children[0].children.length === 0) {
        props.onChange(multiple ? [tree.children[0]] : tree.children[0]);
      }
    }

    isEmpty = () => {
      const { value, values, multiple } = this.props;

      if (!multiple) {
        return isNil(value) || value === '';
      }

      return (values || []).length === 0;
    };

    render() {
      const { tree, multiple, clearable, startIcon, label, required, error, value } = this.props;
      const expanded = this.state.dropdownIsVisible;

      return (
        <div {...block()}>
          {multiple && value?.length !== 0 && (
            <div {...element('clear')}>
              <TextButton onClick={this.handleClear} color="primary" isUnderline>
                {t('common.clear')}
              </TextButton>
            </div>
          )}
          <div {...element('wrapper', { multiple, expanded })}>
            <div {...element('title')}>
              {label && <div {...element('label')}>{label}</div>}
              {required && <div {...element('requiredMark')}>{t('common.required_sign')}</div>}
            </div>
            <div {...element('value', { error })} onClick={() => this.toggleDropdown(undefined)}>
              {startIcon && <Icon glyph={startIcon} {...element('start-icon')} />}
              {this.renderValue()}
              {tree ? (
                (this.isEmpty() || !clearable) && (
                  <Icon {...element('expand')} glyph="squareArrow" />
                )
              ) : (
                <BusyIndicator
                  size={20}
                  sx={{
                    display: 'flex',
                    ml: 'auto',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                />
              )}
            </div>

            {tree && this.state.dropdownIsVisible && this.renderDropdown()}
          </div>
        </div>
      );
    }

    renderValue() {
      if (!this.props.tree) return null;
      if (this.props.multiple) {
        return this.renderMultipleValues();
      }
      if (!this.props.value) return this.renderPlaceholder();
      return this.renderLabel(this.props.value);
    }

    handleRemove = (value) => () => {
      this.handleChange(value, false);
    };

    handleClear = () => {
      this.props.onChange([]);
    };

    renderMultipleValues() {
      if (!this.props.tree) return null;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type '{ dropdo... Remove this comment to see the full error message

      const values = this.state.values || [];
      if (values.length === 0 || this.props.isDisplayFolders) {
        return this.renderPlaceholder();
      }

      return (
        <div {...element('valuesWrapper')}>
          {(values || []).map((value) => {
            if (value && this.renderLabel(value)) {
              return (
                <Label key={value.id} removable onRemove={this.handleRemove(value)}>
                  {this.renderLabel(value)}
                </Label>
              );
            }
            return null;
          })}
        </div>
      );
    }

    renderPlaceholder() {
      return (
        <div {...element('placeholder')}>
          {this.props.placeholder || t('common.select_placeholder')}
        </div>
      );
    }

    renderLabel(value) {
      return (
        value &&
        (
          this.props.getItemFullPath(value) || this.getItemFullPath(value.id).map(this.getLabel)
        ).join(' › ')
      );
    }

    getItemFullPath(id, tree = (this.props as any).tree) {
      const { byId } = tree;
      let item = byId[id];
      const result = [item];
      if (!item) return [tree];
      while (item.$parent && item.$parent.name) {
        item = item.$parent;
        result.push(item);
      }
      return result.reverse();
    }

    getLabel = (item) => (this.props.getLabel || ((value) => value.name))(item);

    clearFilter = () => {
      this.handleFilter({ target: { value: '' } });
    };

    renderDropdown() {
      const { tree, multiple, searchable, renderFilter, value, isDisplayFolders } = this
        .props as any;
      const search = this.state.filter;
      const expandedNodes = this.collectExpandedNodes(value);
      return (
        <div {...element('dropdown', { multiple })} onClick={this.keepFilterFocused}>
          <div {...element('filter')}>
            <div>
              {searchable && (
                <Search
                  iconGlyph={search ? 'close' : 'search'}
                  adornment={
                    <InputAdornment position="end">
                      <IconAsButton
                        onClick={this.clearFilter}
                        glyph={(search && 'close') as GlyphName}
                      />
                    </InputAdornment>
                  }
                  autoFocus
                  // eslint-disable-next-line no-return-assign
                  inputRef={(input) => (this.filter = input)}
                  value={search}
                  onChange={this.handleFilter}
                  invisibleFocus
                  startSearchIcon
                />
              )}
            </div>

            {renderFilter && <div {...element('filterExtra')}>{renderFilter()}</div>}

            {this.props.selectAll && (
              <div {...element('selectAll')}>
                <div {...element('selectAllText')}>{t('common.select_all_locations')}</div>
                <Checkbox checked={this.isAllChecked()} onChange={this.toggleAll} />
              </div>
            )}
          </div>

          {this.props.tree && (
            <TreeView
              selectable={!this.props.multiple}
              expandedLevel={this.props.expandedLevel}
              // eslint-disable-next-line no-return-assign
              ref={(treeView) => (this.treeView = treeView)}
              getTitle={this.renderTitle}
              tree={tree}
              filter={this.getTreeViewFilter()}
              onSelect={this.handleSelect}
              expandedNodes={expandedNodes}
              metaData={this.state.selectedNodes}
              isDisplayFolders={isDisplayFolders}
              onCheck={this.handleCheck}
            />
          )}
        </div>
      );
    }

    getTreeViewFilter() {
      const { nodesFilter, filterable } = this.props;
      if (nodesFilter) return (node) => this.getFilter(node);
      return filterable ? this.state.filter : null;
    }

    renderTitle = (node, nodeCmp) => {
      const hideCheckbox =
        (this.props.parentNodesDisabled && !isEmpty(node.children)) || node.type === 'folder';

      return (
        <div {...element('block')}>
          <p {...element('title')}>{node.name}</p>
          {this.props.multiple && !hideCheckbox && (
            <Checkbox {...element('checkbox')} checked={this.isChecked(node)} />
          )}
        </div>
      );
    };

    getFilter = (node) => {
      const { nodesFilter } = this.props;
      const pattern = new RegExp(escapeStringRegexp(this.state.filter), 'i');
      if (nodesFilter) {
        return this.nodesFilter(node) && pattern.test(node.name);
      }
      return pattern.test(node.name);
    };

    nodesFilter = (node) => this.props.nodesFilter(node, this.state.filter);

    handleClickOutside() {
      this.toggleDropdown(false);
    }

    handleFilter = (e) => {
      this.setState((state) => ({
        filter: e.target.value,
      }));
      this.props.onSearch(e.target.value);
    };

    toggleDropdown(isVisible) {
      if (!this.props.tree) return;
      const { dropdownIsVisible } = this.state;
      if (isVisible) {
        this.collectLocalValues(this.props.value);
      }
      if (isVisible !== undefined && !!isVisible === !!dropdownIsVisible) return;
      if (!isVisible && this.props.multiple) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
        this.props.onChange(this.state.values);
      }
      this.setState((state) => ({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'dropdownIsVisible' does not exist on typ... Remove this comment to see the full error message
        dropdownIsVisible: isVisible === undefined ? !state.dropdownIsVisible : isVisible,
      }));
    }

    isChecked(node) {
      return Boolean(this.state.selectedNodes[node.id]);
    }

    isAllChecked() {
      const treeIndex = this.props.tree.byId;
      return Object.keys(treeIndex)
        .filter((id) => treeIndex[id])
        .every((id) => id === '-1' || this.state.selectedNodes[id]);
    }

    toggleAll = () => {
      const selectedNodes = this.isAllChecked()
        ? {}
        : {
            ...this.props.tree.byId,
            '-1': null,
          };
      this.setState({
        selectedNodes,
        values: Object.values(selectedNodes).filter(Boolean),
      });
    };

    handleCheck = (node, isSelected, nodeCmp) => {
      this.keepFilterFocused();
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.setState(({ values = [] }) => {
        const newValues =
          isSelected && !values.find((i) => i.id === node.id)
            ? [...values, node]
            : values.filter((i) => i.id !== node.id);
        return {
          values: newValues,
          selectedNodes: keyBy(newValues, (i) => i.id),
        };
      });
      if (nodeCmp) {
        setTimeout(() => {
          nodeCmp.forceUpdate();
        });
      }
    };

    handleChange(node, isSelected) {
      if (!this.props.multiple) return;
      this.keepFilterFocused();
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type '{ dropdo... Remove this comment to see the full error message
      const { values } = this.state;
      let newValue;
      if (isSelected) {
        newValue = [...values, node];
      } else {
        newValue = values.filter((i) => i.id !== node.id);
      }
      this.props.onChange(newValue);
      this.collectLocalValues(newValue);
    }

    handleSelect = (node) => {
      if (this.props.multiple) return;
      if (node.childrenSelector || (node.children && node.children.length > 0)) return;
      this.props.onChange(node);
      this.toggleDropdown(false);
    };

    keepFilterFocused = () => {
      if (this.state.dropdownIsVisible) {
        this.filter.focus();
      }
    };

    normalizeValue(value) {
      const getLabel = this.props.getLabel || ((value) => value.name);
      return {
        id: value.id,
        name: getLabel(value),
      };
    }

    collectLocalValues(values) {
      this.setState((state) => ({
        values: values || [],
        selectedNodes: keyBy(values || [], 'id'),
      }));
    }

    collectExpandedNodes(values) {
      const expandedNodes = {};
      if (!this.props.multiple) {
        values = values ? [values] : [];
      }
      (values || []).forEach((value) => {
        const parents = this.getItemFullPath(value.id);
        // remove item itself
        parents.pop();
        parents.forEach((item) => {
          expandedNodes[item.id] = item;
        });
      });
      return expandedNodes;
    }
  },
);
