import { createResource, treeScope } from 'lib/resource';
import { api } from 'lib/api';

const versionPrefix = 'v3';

function wrapInRootNode(json) {
  return {
    response: {
      id: -1,
      children: json.response?.data,
    },
  };
}

export default createResource('roundReports', {
  scopes: {
    list({ payload }) {
      return api.get(`/${versionPrefix}/round_groups/`, { search: payload });
    },
    roundGroups: {
      request({ payload }) {
        return api.get(`/${versionPrefix}/round_groups/${payload}/`);
      },
      selector(data, index) {
        return data;
      },
      normalizer(response) {
        return {
          data: response,
        };
      },
    },
    issueTypes({ payload }) {
      return api.get(`/${versionPrefix}/round_groups/issuetypes/`, { search: payload });
    },

    locationsTree: {
      request({ payload }) {
        return api
          .get(`/${versionPrefix}/round_groups/locations/`, { search: payload })
          .then(wrapInRootNode)
          .catch((err) => {
            console.log(err);
          });
      },

      ...treeScope({}),
    },
  },
});
