import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, change } from 'redux-form';
import { useQuery } from '@tanstack/react-query';
import { flow } from 'lodash';

import { updateQuery } from 'lib/routerUtils';
import { AsyncTreeViewSelect } from 'shared/ui/async-tree-view-select';
import { bfsSearch } from 'shared/lib/bfs-search';
import { getName } from 'shared/lib/get-location-name';
import { locationsQueries } from 'entities/locations';
import {
  ROOT_NODE_ID,
  SUGGESTED_NODE_ID,
  expandNode,
  extractTree,
  getGrandParents,
} from 'shared/lib/tree-view-helpers';
import { TreeData, TreeNode } from 'shared/ui/async-tree-view';
import { withRouter } from 'config/withRouter';
import { useDebounceEffect } from 'lib/hooks/useDebounceEffect';

import './ActivitiesLocationSelect.scss';

const rootNode: TreeNode = {
  id: ROOT_NODE_ID,
  name: 'root',
  hasChildren: true,
};

export default flow<any, any, any>(
  withRouter,
  reduxForm({
    form: 'activitiesLocationSelect',
  }),
)(({ location, router, initialValues, selectedLocation }) => {
  const { t } = useTranslation();
  const [parentNode, setParentNode] = useState<TreeNode | null>(rootNode);
  const [treeData, setTreeData] = useState<TreeData>({});
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  // Триггер, когда следует отправить запрос locationsQueries.byParentId с дефолтными значениями
  const [shouldPerformQueryForParentNode, setShouldPerformQueryForParentNode] = useState(false);
  // Хук useDebounceField не работает, пока костыль
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const locationQueryByParentIdEnabled =
    !!parentNode && (shouldPerformQueryForParentNode || !treeData[parentNode.id]) && !search;

  const { data: locations, isSuccess } = useQuery({
    ...locationsQueries.byParentId({ parentId: parentNode?.id ?? 0 }),
    enabled:
      locationQueryByParentIdEnabled &&
      parentNode.hasChildren &&
      parentNode.id !== SUGGESTED_NODE_ID,
  });

  const { data: filteredLocations, isSuccess: filteredDataSuccess } = useQuery({
    ...locationsQueries.list({ search: debouncedSearch }),
    enabled: !!debouncedSearch,
  });

  const { data: suggestedLocations, isSuccess: suggestedLocationsSuccess } = useQuery(
    locationsQueries.suggestedLocationsList,
  );

  const dispatch = useDispatch();

  const initialLocation = initialValues.location;

  useDebounceEffect(
    () => {
      setDebouncedSearch(search);
    },
    300,
    [search],
  );

  useEffect(() => {
    if (!parentNode) return;

    const nodeId = parentNode.id.toString();

    if (nodeId === String(ROOT_NODE_ID) && suggestedLocationsSuccess && isSuccess) {
      const nodeLocations = [
        ...(suggestedLocations.children?.length ? [suggestedLocations] : []),
        ...locations.map((i) => expandNode(i, [String(ROOT_NODE_ID)])),
      ];
      setTreeData((prevState) => ({
        ...prevState,
        [nodeId]: { children: nodeLocations as TreeNode[] },
        [SUGGESTED_NODE_ID]: { children: suggestedLocations.children ?? [] },
      }));
    }
  }, [isSuccess, locations, parentNode, suggestedLocations, suggestedLocationsSuccess]);

  useEffect(() => {
    if (!parentNode) return;

    const nodeId = parentNode.id.toString();

    if (nodeId !== String(ROOT_NODE_ID) && locationQueryByParentIdEnabled && isSuccess) {
      setTreeData((prevState) => {
        const parents = [nodeId, ...getGrandParents(prevState, parentNode.parentId)];
        const children = locations.map((i) => expandNode(i, parents));
        return { ...prevState, [nodeId]: { parentId: parentNode.parentId, children } };
      });
      setShouldPerformQueryForParentNode(false);

      setExpandedNodes((prevState) =>
        !prevState.includes(nodeId) ? [...prevState, nodeId] : prevState,
      );
    }
  }, [isSuccess, locationQueryByParentIdEnabled, locations, parentNode]);

  useEffect(() => {
    if (search && filteredDataSuccess) {
      const tree = extractTree(filteredLocations.response.data);
      setTreeData(tree);
      setExpandedNodes([]);
    }
  }, [filteredDataSuccess, filteredLocations, search]);

  const changeSelectedNodeIds = useCallback(
    (data: string[]) => {
      setSelectedNodes(data);
      setOpen(false);

      if (!data[0]) {
        return;
      }

      const newLocation = bfsSearch(treeData, Number(data[0]));
      if (newLocation) {
        dispatch(change('activitiesLocationSelect', 'location', newLocation.data));
      }

      const queryParams = location.query;
      if (queryParams.locationCard) {
        updateQuery(router, { locationCard: queryParams.locationCard });
      } else {
        updateQuery(router, {
          location: data[0],
        });
      }
    },
    [router, location, treeData, dispatch],
  );

  const toggleNode = useCallback(
    (node: TreeNode) => {
      setParentNode(node);
      const nodeId = node.id.toString();

      setExpandedNodes((prevState) => {
        if (prevState.includes(nodeId)) {
          return prevState.filter((item) => item !== nodeId);
        }

        if (treeData[nodeId]) {
          return prevState.concat(nodeId);
        }

        return prevState;
      });
    },
    [treeData],
  );

  const handleChangeSearch = (searchValue: string) => {
    setSearch(searchValue);

    if (searchValue === '') {
      setParentNode(rootNode);
      setShouldPerformQueryForParentNode(true);
    } else {
      setParentNode(null);
      setShouldPerformQueryForParentNode(false);
    }
  };

  return (
    <AsyncTreeViewSelect
      label={getName(selectedLocation.location ?? initialLocation)}
      search={search}
      onSearchChange={handleChangeSearch}
      open={open}
      toggleSelect={setOpen}
      treeData={treeData}
      expandedNodeIds={expandedNodes}
      changeExpandedNodes={setExpandedNodes}
      selectedNodeIds={selectedNodes}
      changeSelectedNodeIds={changeSelectedNodeIds}
      searchable
      onToggleNode={toggleNode}
      startIcon="pin"
      placeholder={t('locations.select_placeholder')}
    />
  );
});
