import { memo, useCallback } from 'react';
import { Box, IconButton } from '@mui/material';

import { Icon } from 'lib/ui/icon';
import { brandedThemeOptions } from 'lib/ui/AppThemeProvider/theme';

interface Props {
  onClick: () => void;
  hidden: boolean;
}

const MenuToggleButton = ({ onClick, hidden }: Props) => {
  const handleClick = useCallback(
    (e) => {
      onClick();
      e.stopPropagation();
    },
    [onClick],
  );

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        sx={{
          width: '100%',
          borderRadius: 0,
          borderBottom: ({ palette }) => `1px solid ${palette.separators?.menu}`,
        }}
      >
        <Icon
          glyph="squareArrow"
          sx={{
            ml: hidden ? 0 : 'auto',
            fill: brandedThemeOptions.menuTextColor || '#FFF',
            transform: `rotate(${hidden ? '-' : ''}90deg)`,
            '&:hover': {
              fill: ({ palette }) => palette.active,
            },
          }}
        />
      </IconButton>
    </Box>
  );
};

export default memo(MenuToggleButton);
