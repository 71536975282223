import { api } from 'lib/api';
import { createResource } from 'lib/resource';
import { item } from 'lib/resource/endpointTypes';

export default createResource('meters', {
  scopes: {
    list: {
      request({ payload }) {
        return api.get('/v2/meters/', {
          search: payload,
        });
      },

      selector(data, index) {
        return data;
      },

      normalizer(response) {
        return {
          data: response,
          index: response,
        };
      },
    },

    byLocation({ payload }) {
      return api.get(`/v2/locations/${payload}/meters/`);
    },

    byEvent({ payload }) {
      return api.get(`/v2/users/me/events/${payload}/`)
        .then(({ response }) => {
          const { location_id } = response.payload.activity.params;
          const { expire_date } = response.payload;

          return api.get(`/v2/locations/${location_id}/meters/`)
            .then((data) => ({
              ...data,
              response: data.response.map((meter) => ({
                ...meter, expire_date,
              })),
            }));
        });
    },

    readings({ payload }) {
      return api.get(`/v2/meters/${payload}/readings/`);
    },

    withFullHistory: {
      ...item,

      request({ payload }) {
        return api.get(`/v2/meters/${payload}/`, {
          search: {
            include_history: 't',
            include_period_changes: 't',
            include_imported: 't',
          },
        });
      },
    },

    lastPeriod: {
      ...item,

      request() {
        return api.get('/v2/meters/readings/last-period/');
      },
    },
  },

  byId({ payload }) {
    return api.get(`/v2/meters/${payload}/`, {
      search: {
        include_history: 't',
      },
    });
  },

  mutators: {
    saveMetrics({ payload }) {
      return api.post(`/v2/meters/${payload.id}/`, {
        body: {
          value: payload.value,
        },
      });
    },
  },
});
