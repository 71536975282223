import { MouseEvent, RefObject } from 'react';
import { Button, Tooltip, Typography } from '@mui/material';

import { Icon, IconAsButton } from 'lib/ui/icon';

import './TextFieldButton.scss';

const { block, element } = bem('TextFieldButton');

interface TextFieldButtonProps {
  value: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  label?: string;
  icon?: string;
  placeholder?: string;
  multipleValues?: boolean;
  required?: boolean;
  error?: boolean;
  ref?: RefObject<HTMLButtonElement>;
  tooltip?: string;
  width?: string;
  onClearValue?: (event: MouseEvent<HTMLButtonElement>) => void;
}
export const TextFieldButton = ({
  value,
  onClick,
  label,
  icon,
  placeholder,
  required,
  error,
  ref,
  tooltip,
  width,
  onClearValue,
}: TextFieldButtonProps) => {
  return (
    <div {...block()}>
      {label && (
        <div {...element('title')}>
          <Typography component="div" {...element('label')}>
            {label}
          </Typography>
          {required && <div {...element('requiredMark')}>{t('common.required_sign')}</div>}
          {tooltip && (
            <Tooltip title={tooltip} arrow placement="top">
              <Icon glyph="info" />
            </Tooltip>
          )}
        </div>
      )}
      <Button ref={ref} style={{ width }} {...element('button', { error })} onClick={onClick}>
        {icon && <Icon {...element('icon')} glyph={icon} />}
        <div>{value}</div>
        {placeholder && !value && <div {...element('placeholder')}>{placeholder}</div>}
        {onClearValue && !!value && (
          <IconAsButton {...element('clear')} onClick={onClearValue} glyph="close" />
        )}
      </Button>
    </div>
  );
};
