import { useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';

import { t } from 'lib/i18n';
// eslint-disable-next-line import/no-cycle
import { ReactHookFormBuilder as FormBuilder } from 'shared/ui/form';
import { activitiesQueries } from 'entities/activities';

interface Props {
  element?: { className: string };
}
export const ActivityIconSelect = ({ element }: Props) => {
  const { data: response } = useQuery(activitiesQueries.icons);
  const { setValue } = useFormContext();

  const data = response && 'response' in response ? response.response.data : undefined;
  const options = data?.map((it) => ({ img: it.icon_url, value: it.icon_url, label: '' }));

  const getItemValue = (item: { value: string }) => {
    return item.value;
  };

  const handleChange = (item: { value: string }) => {
    setValue('icon_url', item.value, { shouldDirty: true });
  };

  return (
    <FormBuilder.Select
      required
      title={t('activities.fields.icon')}
      name="icon_url"
      options={options}
      getItemValue={getItemValue}
      onChange={handleChange}
      {...element}
    />
  );
};
