import { attrsService } from 'app/issues/services';

/**
 * @function getReviewableAttrs
 * @description Возвращает список атрибутов на проверку.
 *
 * @param {object} issue
 * @returns {array} attributes
 */
function getReviewableAttrs(issue) {
  const attrDescriptors = issue.issue_type.attributes || [];
  const attrValues = issue.attributes || {};

  // Исключаем атрибуты, которые должны быть скрыты по условиям в available_if.
  return attrsService.filterAttrsByConditions(attrDescriptors, issue, attrValues)
    // Оставляем только доступные на создание или редактирование.
    .filter((attr) => (attr.actions.new !== undefined
      || attr.actions.update !== undefined))
    .map((attr) => ({
      ...attr,
      value: attrsService.getAttrValue(issue, attr),
    }));
}

export default {
  getReviewableAttrs,
};
