import { CSSProperties, HTMLAttributes } from 'react';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

import { brandedThemeOptions } from 'lib/ui/AppThemeProvider/theme';
import { Icon } from 'lib/ui/icon';

interface Props extends HTMLAttributes<HTMLDivElement> {
  element: { className: string };
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
}

export const DraggableIcon = ({ attributes, listeners, element, style }: Props) => {
  return (
    <div
      {...element}
      {...attributes}
      {...listeners}
      style={
        {
          '--color': `${brandedThemeOptions.baseColor}`,
          ...style,
        } as CSSProperties
      }
    >
      <Icon glyph="drag_indicator" />
    </div>
  );
};
