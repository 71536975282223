import { useEffect, useRef } from 'react';

export const useMounting = (onMount) => {
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current) {
      if (onMount) {
        onMount();
      }
      initializedRef.current = true;
    }
  }, [onMount]);
};
