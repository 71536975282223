import './UsersSelect.scss';
import { bem } from 'lib/bem';
import { FormBuilder } from 'lib/ui';
import users from 'app/users/users.resource';
import { ComponentProps, useCallback } from 'react';
import { useSelector } from 'react-redux';

const { block } = bem('UsersSelect');

const isUserResponsible = (user) => user.roles.some((role) => role in {
  executive: true,
  responsible: true,
  manager: true,
});

function skipNoNameUsers(user) {
  return user.full_name;
}

type OwnUsersSelectProps = ComponentProps<'div'> & {
    multiple?: boolean;
    responsible?: boolean;
    labelUsers?: any[];
    filterUsers?: (...args: any[]) => any;
    required?: boolean;
    title?: string;
    name?: string;
    validate?: any;
    excludedIds?: any;
    owner?: any;
};

export default function UsersSelect({
  multiple, responsible, children, ...props 
}: any) {
  const Field = multiple ? FormBuilder.MultiSelect : FormBuilder.Select;
  const usersObj = useSelector((state) => (state as any).users.list.index);
  const getValue = (user) => {
    if (typeof user !== 'object') return user;
    return user && user.id;
  };

  const getLabel = useCallback((user) => {
    const { labelUsers } = props;
    if (typeof user === 'string') {
      const nameFromStore = ((Object.values(usersObj).find((i) => (i as any).id === parseInt(user, 10)) as any)?.full_name);
      if (nameFromStore) return nameFromStore;

      const userInfo = labelUsers && labelUsers.filter((obj) => obj.id === parseInt(user, 10));
      return userInfo && userInfo[0] && userInfo[0].full_name;
    }

    if (user && !user.full_name && props.owner && user === props.owner.id) {
      return props.owner.full_name;
    }

    return user && user.full_name;
  }, [props, usersObj]);

  const connectResource = (state, search, page) => {
    return (users as any).list(state, {
      search,
      offset: page * 20,
      count: 20,
    }, { forceFetchOnMount: false });
  };

  const filterUsers = (options = []) => {
    return options
      .filter(props.filterUsers ? props.filterUsers : () => true)
      .filter(skipNoNameUsers)
      .filter((user) => (responsible
        ? isUserResponsible(user)
        : true));
  };

  return (
    <Field
      {...block()}
      {...props}
      searchable
      connectResource={connectResource}
      getItemValue={getValue}
      getItemLabel={getLabel}
      filterOptions={filterUsers}
    >
      {children}
    </Field>
  );
}
