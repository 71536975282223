import './BusyIndicator.scss';
import { memo } from 'react';
import { Box, CircularProgress } from '@mui/material';

interface Props {
  sx?: Record<string, any>;
  size?: number;
}

const BusyIndicator = ({ sx, size }: Props) => {
  return (
    <Box sx={{ textAlign: 'center', ...sx }}>
      <CircularProgress
        color="primary"
        size={size}
      />
    </Box>
  );
};

export default memo(BusyIndicator);
