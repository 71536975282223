import { mapValues } from 'lodash';
import { api } from 'lib/api';
import { createResource } from 'lib/resource';
import download from 'lib/ui/DownloadButton/download';
import { put } from 'redux-saga/effects';
import { closeModal } from 'lib/ui/Modal';

import { UPDATE_REPORT_MODAL } from './config';

function* closeOnSuccess() {
  yield put(closeModal(UPDATE_REPORT_MODAL));
}

function moveElement(data, id, beforeId) {
  const dataWithoutMovingElement = data.filter((itemId) => itemId !== id);

  if (beforeId === null) {
    dataWithoutMovingElement.push(id);
  } else {
    const insertBeforeIndex = dataWithoutMovingElement.indexOf(beforeId);
    dataWithoutMovingElement.splice(insertBeforeIndex, 0, id);
  }

  return dataWithoutMovingElement;
}

function getUpdateBody(body) {
  return {
    ...body,
    updated: undefined,
    created: undefined,
    mcid: undefined,
    deleted: undefined,
  };
}

const hardcodedReports = {
  // Открытие опердня
  '1f9ppvqgifbg': {
    issue_type: 'l97v4y,l97yay,l97v4q,l97v4i,lph3jp,lph3jh,lt7g6p,knerqx',
  },
  // Работа в хранилище ценностей
  '1g2h3qpudptm': {
    issue_type: 'l97v4j,l97v4r,l97v4z,l97yaz,l4xnsm,lph3jq,lph3ji,lt7g6q',
  },
  // Формирование и хранение ценностей
  '1g2h3sk0ovln': {
    issue_type: 'l97v4s,l97v50,l97yb0,l4xntj,lph3jr,lph3jj,l97v4k,lt7g6r',
  },
  // Кассовая работа
  '1g2h3ty3nl6k': {
    issue_type: 'l97v4t,l97v51,l4xntz,l97yb1,lph3js,lph3jk,l97v4l,lt7g6s',
  },
  // Внутрисистемные операции
  '1g2h3x1n4d19': {
    issue_type: 'l97v4u,l97v52,l4xnud,l97yb2,lph3jt,lph3jl,l97v4m,lt7g6t',
  },
  // Работа с ценностями
  '1g2h3zgml2im': {
    issue_type: 'l97v4v,l4xnuq,l97yb3,l97v53,lph3ju,l97v4n,lph3jm,lt7g6u',
  },
  // Обслуживание клиентов
  '1g2h40n7uqrj': {
    issue_type: 'l97v4w,l4xnvb,l97yb4,l97v54,lph3jv,l97v4o,lph3jn,lt7g6v',
  },
  // Закрытие опердня
  '1g2h41mvekg0': {
    issue_type: 'l4xnvo,l97yb5,l97v4x,l97v55,lph3jw,l97v4p,lph3jo,lt7g6w',
  },
  // Отчет по отклонениям
  '1ex7nng1l09h': {
    issue_type: 'dlr1b2,dls38g,f94xyl,f95gxa,k1zdwo,k1zk8a,k1zqjv,k1ztpp,lw6xiw',
  },
};

function addHardcodedReportFilters(payload) {
  const hardcodedFilters = hardcodedReports[payload.id];

  if (hardcodedFilters) {
    return {
      ...payload,
      issue_type: [hardcodedFilters.issue_type, payload.issue_type].filter(Boolean).join(','),
    };
  }

  return payload;
}

function hideHardcodedReportsIssueTypeFilter(data) {
  const { response } = data;

  if (!response) return data;

  data.response = response.map((report) => {
    if (report.id in hardcodedReports) {
      report.filters = report.filters.filter((f) => f.name !== 'issue_type');
    }

    return report;
  });

  return data;
}

function getReportDownloadLink(id, search) {
  return api.get(`/v2/reports/${id}`, {
    search: {
      ...search,
      offset: 0,
      count: 9000,
      out: 'xlsx',
    },
  });
}

export default createResource('reports', {
  scopes: {
    list: {
      type: 'list',

      request: ({ payload }) =>
        api
          .get('/v2/reports', {
            search: {
              limit: 9000,
            },
          })
          .then(hideHardcodedReportsIssueTypeFilter),

      reducer: (state, action, resource) => {
        if (action.type === (resource.update || {}).actionType) {
          return {
            ...state,
            data: mapValues(state.data, (search) => {
              const { before_id, id } = action.payload;
              if (!before_id && before_id !== null) return search;

              return {
                ...search,
                data: moveElement(search.data, id, before_id),
              };
            }),
          };
        }

        return state;
      },
    },
  },

  byId: ({ payload }) => {
    const { id, ...search } = addHardcodedReportFilters(payload);
    return api.get(`/v2/reports/${id}`, { search });
  },

  mutators: {
    update: {
      request: ({ payload }) => {
        const { id, ...body } = payload;

        return api.put(`/v2/reports/${id}/`, {
          body: getUpdateBody(body),
        });
      },
      onSuccess: closeOnSuccess,
    },

    updateAndDownload: {
      request: ({ payload }) => {
        const { id, ...body } = payload.data;
        const { filters } = payload;

        return api
          .put(`/v2/reports/${id}/`, {
            body: getUpdateBody(body),
          })
          .then((result) =>
            getReportDownloadLink(result.response.id, filters).then(({ response }) => {
              if (response && response.name) {
                download(`/api/files/reports/${response.name}`);
              }

              return result;
            }),
          );
      },
      onSuccess: closeOnSuccess,
    },
  },
});
