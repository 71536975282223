import './EntryModal.scss';
import { ReduxModal } from 'lib/ui';
import { t } from 'lib/i18n';

import { Entry } from '../Entry';
import { ENTRY_MODAL } from './config';

export default ReduxModal({
  name: ENTRY_MODAL,
  routing: true,
  getTitle: () => ' ',
})((props) => {
  const actionText = {
    submitIssue: t('entry.action_text.submit_issue'),
  };

  return (
    <Entry
      showLogo={false}
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      actionText={actionText[props.data] || null}
    />
  );
});
