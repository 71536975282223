import { useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { Page } from 'lib/ui';
import { t } from 'lib/i18n';
import { bem } from 'lib/bem';
import { fieldsResources } from 'app/fieldsComparison';
import { FieldSection } from 'app/fieldsComparison/ui/FieldSection';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';
import { Col } from 'lib/ui/Col';
import { Box } from '@mui/material';
import { CarouselButtons } from 'app/fieldsComparison/ui/CarouselButtons';
import defaultPic from 'static/blank.png';

const { block } = bem('FieldsComparison');

const mapStateToProps = (state) => {
  return {
    fields: fieldsResources.list(state, {}),
  };
};

const FieldsComparison = reconnect(mapStateToProps)((props) => {
  const [currentView, setCurrentView] = useState('full');
  const [firstImageLink, setFirstImageLink] = useState('');
  const [secondImageLink, setSecondImageLink] = useState('');

  if (props.fields.fetching) {
    return <BusyIndicator sx={{ mt: 1 }} />;
  }
  const onButtonClick = (evt) => {
    setCurrentView((evt.target as any).dataset.view);
  };

  return (
    <Page fullWidth {...block()} title={t('menu.field_comparison')}>
      <Col>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
          }}
        >
          <Box sx={{ width: '50%' }}>
            <FieldSection view={currentView} num={1} getLink={setFirstImageLink} />
          </Box>
          <Box sx={{ width: '50%' }}>
            <FieldSection view={currentView} num={2} getLink={setSecondImageLink} />
          </Box>
        </Box>
        <CarouselButtons onButtonClick={onButtonClick} view={currentView} />
        {(firstImageLink || secondImageLink) && (
          <ReactCompareSlider
            boundsPadding={0}
            position={50}
            itemOne={<ReactCompareSliderImage src={firstImageLink || defaultPic} />}
            itemTwo={<ReactCompareSliderImage src={secondImageLink || defaultPic} />}
            style={{
              marginTop: '1rem',
              width: '100%',
              alignSelf: 'center',
            }}
          />
        )}
      </Col>
    </Page>
  );
});

export default FieldsComparison;
