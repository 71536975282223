import { all, call, put } from 'redux-saga/effects';
import { takeMap } from 'lib/saga-utils';
import { contextNotification } from 'app/notifications';
import users from 'app/users/users.resource';
import activities from './activities.resource';

const notify = contextNotification('activities');

export default function* companiesSaga() {
  yield all([
    call(activities.saga),

    call(takeMap({
      'LOCATIONS/VERIFY_SUCCESS': function* () {
        yield put(notify('verified'));
        (users as any).current.invalidate();
      },
    })),
  ]);
}
