import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import moment from 'moment';

import { DATE_WITH_TIME, TIME_ONLY, READABLE_DATE } from 'lib/constants/dates';

import './datetimepicker.scss';

interface Props {
  value?: any;
  onChange: any;
  placeholder?: string;
  minTime?: any;
  name: string;
  form: string;
}

export const DateTimePicker = ({ value, onChange, placeholder, minTime, name, form }: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isClickOutside, setClickOutside] = useState(false);

  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    if (minTime && value && value.isBefore(minTime, 'minutes')) {
      const newValue = value;
      newValue.set('hour', minTime.hours());
      newValue.set('minutes', 59);
      setCurrentValue(newValue || null);
    } else {
      setCurrentValue(value || null);
    }
  }, [value, minTime]);

  const handleClear = useCallback(
    (evt) => {
      evt.stopPropagation();
      onChange(null);
      setCurrentValue(null);
    },
    [onChange],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    if (isClickOutside) {
      return;
    }

    setOpen(false);
  }, [isClickOutside]);

  const handleChange = useCallback(
    (newVal) => {
      if (isClickOutside) {
        setClickOutside(false);
        return;
      }
      const newValue = newVal || null;
      onChange(newValue);
      setCurrentValue(newValue);
      setClickOutside(false);
    },
    [isClickOutside, onChange],
  );

  const handleClickAway = () => {
    handleClose();
  };

  const renderInput = useCallback(
    (props) => {
      // Для красивого отображения даты
      const label =
        props.value && props.value.indexOf('YYYY') === -1
          ? moment(props.value, DATE_WITH_TIME).format(
              `${READABLE_DATE.toUpperCase()}, ${TIME_ONLY}`,
            )
          : props.placeholder ?? '';

      return <TextField {...props} value={label} onClick={handleOpen} />;
    },
    [handleOpen],
  );

  // В mui висит PR, способ с патчем не подошел
  if (value && value._i === 'Invalid date') {
    dispatch({
      type: '@@redux-form/CHANGE',
      payload: null,
      meta: {
        form,
        field: name,
        touch: false,
        persistentSubmitErrors: false,
      },
    });
  }
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <MUIDateTimePicker
          views={['year', 'month', 'day', 'hours', 'minutes']}
          open={open}
          onOpen={handleOpen}
          ampm={false}
          format={DATE_WITH_TIME}
          slotProps={{
            popper: {
              placement: 'bottom-start',
              className: 'CalendarWrapper',
              keepMounted: true,
            },
            inputAdornment: {
              position: 'start',
              color: 'primary',
              style: { position: 'absolute', marginLeft: '8px' },
            },
            toolbar: {
              toolbarPlaceholder: '',
              sx: {
                '& .MuiPickersToolbar-content': {
                  button: {
                    backgroundColor: 'transparent',
                  },
                },
              },
            },
            clearButton: { style: { opacity: 1, marginLeft: '-40px' } },
            field: {
              clearable: true,
              onClear: handleClear,
              onKeyDown: (e) => {
                e.preventDefault();
              },
            },
            textField: { placeholder },
          }}
          onClose={handleClose}
          value={currentValue}
          onChange={handleChange}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
          }}
          slots={{ textField: renderInput }}
          minutesStep={5}
          minDateTime={minTime}
          sx={{
            '& .MuiInputBase-adornedStart': {
              paddingLeft: 0,
              paddingRight: 0,
              width: '250px',
            },
            '& .MuiInputBase-inputAdornedStart': {
              paddingLeft: '28px !important',
            },
            '& .DateTimePickerToolbar': {
              background: 'white',
            },
          }}
          localeText={{
            toolbarTitle: t('common.date_time_placeholder'),
            cancelButtonLabel: t('common.cancel'),
          }}
        />
      </div>
    </ClickAwayListener>
  );
};
