import { keyBy } from 'lodash';

export const checkRoles = (...roles) => (user) => {
  const userRoles = keyBy(user.roles, (role) => role);

  return roles.every((role) => {
    if (role && role.charAt(0) === '!') {
      return !userRoles[role];
    }

    return userRoles[role];
  });
};
