import { useMutation } from '@tanstack/react-query';

import { apiClient_v4 } from 'shared/api/v4/client';
import { apiClient_v3 } from 'shared/api/v3/client';

// TODO: implement mutation for user (userResources)
/* export const useUpdateUserProfile = () => {
  return useMutation({
    mutationFn: async ({ profileInfo }: { profileInfo: ProfileInfoUpdate }) =>
      /!*apiClient_v2.user.updateUserProfile(profileInfo),*!/
  });
}; */

export const useSetEmail = () => {
  return useMutation({
    mutationFn: async ({ email }: { email: string }) => apiClient_v4.users.setEmail({ email }),
  });
};

export const useDeleteProfile = () => {
  return useMutation({
    mutationFn: async () => apiClient_v3.user.deleteUser(),
  });
};
