import { api } from 'lib/api';
import { createResource, treeScope } from 'lib/resource';

function wrapInRootNode(json) {
  return {
    response: {
      id: -1,
      children: json.response?.data,
    },
  };
}

function createNewType(body) {
  if (!body.type_id || !body.type_id.new) return Promise.resolve(body);

  return api.post('/v2/inventory/types/', {
    body: {
      name: body.type_id.name,
    },
  }).then((json) => ({
    ...body,
    type_id: json.response.id,
  }));
}

export default createResource('inventories', {
  scopes: {
    list({ payload }) {
      return api.get('/v2/inventory/items/', {
        search: payload,
      });
    },

    types({ payload }) {
      return api.get('/v2/inventory/types/', {
        search: payload || {},
      });
    },

    history({ payload }) {
      return api.get(`/v2/inventory/items/${payload}/history/`)
        .then((json) => ({
          ...json,
          response: json.response.map((item, id) => ({ ...item, id })),
        }));
    },

    states() {
      return api.get('/v2/inventory/items/states/', {});
    },

    audit: {
      type: 'item',

      request({ payload }) {
        return api.get(`/v2/inventory/audit/${payload.id}/`);
      },
    },

    reportList({ payload }) {
      return api.get('/v3/assets_report/', { search: payload })
        .then((json) => {
          return {
            ...json,
            response: json.response.data.map((item) => ({ ...item, id: item.name })),
          };
        });
    },

    stocks: {
      request({ payload }) {
        return api.get('/v3/assets_report/stocks/', { search: payload })
          .then(wrapInRootNode)
          .catch((err) => {
            console.log(err);
          });
      },

      ...treeScope({}),
    },
  },

  byId({ payload }) {
    return api.get(`/v2/inventory/items/${payload}/`);
  },

  mutators: {
    create({ payload }) {
      return createNewType(payload)
        .then((body) => api.post('/v2/inventory/items', { body }));
    },

    update({ payload }) {
      return createNewType(payload)
        .then(({ id, ...body }) => api.put(`/v2/inventory/items/${id}`, { body }));
    },

    remove({ payload }) {
      return api.put(`/v2/inventory/${payload}/items`);
    },

    makeCopies({ payload }) {
      const { id, ...body } = payload;
      return api.post(`/v2/inventory/items/${id}/copy/`, { body });
    },
  },
});
