import { memo, useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import moment from 'moment';

import { DayInfo } from '../types';
import { bem } from '../../../../../bem';

interface Props {
  slotsByMonth: Record<string, DayInfo[]>;
  value: number;
  onChange: (value: number) => () => void;
}

const { block, element } = bem('DaySlots');

const MonthsSwitcher = ({ slotsByMonth, value, onChange }: Props) => {
  const [monthLabels, setMonthLabels] = useState<string[]>([]);

  const currentYear = moment().year();
  useEffect(() => {
    const labels = Object.keys(slotsByMonth).map((month) => {
      const parsedMonth = moment(month);
      return parsedMonth.format(parsedMonth.year() !== currentYear ? 'MMMM YYYY' : 'MMMM');
    });
    setMonthLabels(labels);
  }, [currentYear, slotsByMonth]);

  return (
    <Box {...element('title')}>
      <Box>{monthLabels[value]}</Box>
      <Box display="flex" ml="auto">
        <IconButton onClick={onChange(value - 1)} {...element('navigationArrow')}>
          <ChevronLeft />
        </IconButton>
        <IconButton onClick={onChange(value + 1)} {...element('navigationArrow')}>
          <ChevronRight />
        </IconButton>
      </Box>
    </Box>
  );
};

export default memo(MonthsSwitcher);
