import './UserAvatarEditor.scss';
import { useCallback, useRef, useState, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { openModal } from 'lib/ui/Modal';
import { notify } from 'app/notifications';
import { t } from 'lib/i18n';

const MAX_FILE_SIZE_AT_MB = 100;
const MAX_FILE_SIZE = MAX_FILE_SIZE_AT_MB * 1024 ** 2;

const UserAvatarEditor = ({ avatar, input: { onChange } }) => {
  const dispatch = useDispatch();
  const [avatarSrc, setAvatarSrc] = useState(avatar?.url);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUploadPhoto = useCallback(
    (evt) => {
      if (evt.target.files) {
        if (evt.target.files[0].size > MAX_FILE_SIZE) {
          const message = t('file_uploader.size_error', { size: MAX_FILE_SIZE_AT_MB });
          dispatch(notify({ id: message, text: message, type: 'error' }));
        } else {
          const reader = new FileReader();
          reader.addEventListener('load', () =>
            dispatch(
              openModal('avatarEditor', {
                imgSrc: reader.result?.toString(),
                onChange,
                setAvatarSrc,
              }),
            ),
          );
          reader.readAsDataURL(evt.target.files[0]);
        }
      }
    },
    [dispatch],
  );

  const onMenuClick = () => {
    fileInputRef.current?.click();
  };

  const onRemoveClick = () => {
    setAvatarSrc(null);
    onChange(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <IconButton
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar
          alt="User avatar"
          src={avatarSrc}
          sx={{
            width: '150px',
            height: '150px',
            padding: '5px',
            border: '1px dashed #CCC',
            '& .MuiAvatar-img': {
              borderRadius: '50%',
            },
          }}
        />
      </IconButton>
      <Typography
        sx={{
          maxWidth: '156px',
          color: '#A3A3A3',
          fontSize: '12px',
          lineHeight: '16px',
          marginTop: '8px',
        }}
      >
        {t('users.fields.avatar_info')}
      </Typography>
      <input
        type="file"
        accept="image/jpeg,image/png"
        multiple={false}
        ref={fileInputRef}
        onChange={handleUploadPhoto}
        style={{ display: 'none' }}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiMenu-list': {
            padding: '4px',
          },
          '& .MuiMenuItem-root: hover': {
            background: 'rgba(0, 0, 0, 0.08)',
            borderRadius: '6px',
          },
        }}
      >
        <MenuItem onClick={onMenuClick}>{t('common.upload')}</MenuItem>
        {avatarSrc && (
          <MenuItem sx={{ color: '#FF3B30' }} onClick={onRemoveClick}>
            {t('common.remove')}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default UserAvatarEditor;
