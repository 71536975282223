import { memo, useCallback } from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import { Icon } from 'lib/ui/icon';

import { HeaderCellProps, GridHeaderProps, DropTargetCellProps } from '../types';
import { bem } from '../../../bem';
import { useDragSourceCell } from '../hooks/useDragSourceCell';
import { useDropTargetCell } from '../hooks/useDropTargetCell';

const { element } = bem('Table');

const HeaderDropTarget = ({ column, dropBefore }: DropTargetCellProps) => {
  const [{ isOver }, drop] = useDropTargetCell({ column, dropBefore });

  return <div ref={drop} {...element('headerDropTarget', { dropBefore, isOver })} />;
};

const GridHeaderCell = ({ column, onSort, onColumnReorder, sorting }: HeaderCellProps) => {
  const [, drag] = useDragSourceCell({ column, onColumnReorder });

  let columnSorted = false;
  if (sorting) {
    columnSorted = column.sortableField
      ? sorting.column === column.sortableField
      : sorting.column === column.accessor;
  }

  const isColumnSortable = column.sortableField ? true : column.sortable;

  const renderSortIcon = useCallback(() => {
    const columnSortedByAsc = columnSorted && sorting?.direction === 'asc';
    return (
      <Icon
        glyph="squareArrow"
        sx={columnSortedByAsc ? { transform: 'rotate(180deg)' } : undefined}
      />
    );
  }, [columnSorted, sorting]);

  return (
    <TableCell
      key={column.accessor}
      ref={drag}
      onClick={onSort?.(column)}
      {...element('headerCell', {
        sortable: isColumnSortable,
        isSortedBy: columnSorted,
      })}
    >
      <HeaderDropTarget column={column} dropBefore />
      {column.sortable ? (
        <TableSortLabel IconComponent={renderSortIcon} active={columnSorted}>
          {column.header}
        </TableSortLabel>
      ) : (
        column.header
      )}
      <HeaderDropTarget column={column} dropBefore={false} />
    </TableCell>
  );
};

const GridHeader = ({
  columns,
  onSort,
  fixedHeader,
  headerRef,
  fixedHeaderAtTableTop,
  onColumnReorder,
  sorting,
}: GridHeaderProps) => {
  const handleColumnReorder = useCallback(
    ({ source, target, dropBefore }) => {
      const result: any[] = [];

      columns.forEach((column) => {
        if (column.accessor === target && dropBefore) {
          result.push(source);
        }

        if (column.accessor !== source) {
          result.push(column.accessor);
        }

        if (column.accessor === target && !dropBefore) {
          result.push(source);
        }
      });

      onColumnReorder?.(result);
    },
    [columns, onColumnReorder],
  );

  return (
    <TableHead ref={headerRef} {...element('header', { sticky: fixedHeader })}>
      <TableRow {...element('headerRow', { sticky: fixedHeaderAtTableTop })}>
        {columns.map((column) => (
          // TODO: Add key prop
          // eslint-disable-next-line react/jsx-key
          <GridHeaderCell
            column={column}
            onSort={onSort}
            onColumnReorder={handleColumnReorder}
            sorting={sorting}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

export default memo(GridHeader);
