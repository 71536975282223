import { PureComponent } from 'react';
import { throttle } from 'lodash';

export default function scrollThroughHOC({
  useWindowAsScrollContainer = false,
  onScrollThrough = () => {},
  offset = 200,
  className,
  once = true,
}) {
  return (DecoratedComponent) =>
    class ScrollThroughContainer extends PureComponent {
      container: any;

      fetcher: any;

      scrollHandler: any;

      constructor(props) {
        super(props);

        this.setContainerRef = this.setContainerRef.bind(this);
        this.setFetcherRef = this.setFetcherRef.bind(this);
      }

      setContainerRef(container) {
        this.container = container;
      }

      setFetcherRef(fetcher) {
        this.fetcher = fetcher;
      }

      getScrollContainer() {
        if (useWindowAsScrollContainer) {
          return window;
        }

        return this.container;
      }

      getContainerScrollTo() {
        if (useWindowAsScrollContainer) {
          return window.innerHeight + window.scrollY;
        }

        return this.container.clientHeight + this.container.scrollTop;
      }

      componentDidMount() {
        this.initInfiniteScroll();
      }

      componentWillUnmount() {
        this.destroyInfiniteScroll();
      }

      destroyInfiniteScroll() {
        this.getScrollContainer().removeEventListener('scroll', this.scrollHandler);
      }

      initInfiniteScroll() {
        const container = this.getScrollContainer();

        this.scrollHandler = throttle(() => {
          if (!this.fetcher) return;

          const isFetcherVisible = this.getContainerScrollTo() > this.fetcher.offsetTop - offset;

          if (isFetcherVisible) {
            if (once) {
              container.removeEventListener('scroll', this.scrollHandler);
            }

            // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
            onScrollThrough(this.props);
          }
        }, 100);

        container.addEventListener('scroll', this.scrollHandler);
      }

      componentDidUpdate(prevProps, prevState, snapshot?: any): void {
        if ((this.props as any).options?.length === 8 && !!(this.props as any).onScrollThrough) {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          onScrollThrough(this.props);
        }
      }

      render() {
        return (
          <div ref={this.setContainerRef} className={className}>
            <DecoratedComponent {...this.props} />
            <div ref={this.setFetcherRef} />
          </div>
        );
      }
    };
}
