import { keyBy, mapValues, flow } from 'lodash';

function modifyRequestData(data, modifier) {
  return mapValues(data, (value, key) => {
    if (key.charAt(0) === '?') {
      return {
        ...value,
        data: modifier(value.data),
      };
    }

    return value;
  });
}

function normalizeTree(tree, data: any = []) {
  const children = tree.children || [];

  data.push({
    ...tree,
    children: children.map((i) => i.id),
  });

  children.forEach((x) => normalizeTree(x, data));

  return data;
}

// eslint-disable-next-line default-param-last
function mapTree(rootNode, nodesChildren, nodesIndex, parentTreeNodeId = '', parent) {
  const $treeNodeId = `${parentTreeNodeId}_${rootNode.id}`;
  const children = nodesChildren[rootNode.id].children || [];

  return {
    ...rootNode,
    $treeNodeId,
    $parent: parent || {},
    byId: parent ? null : nodesIndex,
    children: (children || []).map((childId) =>
      mapTree(nodesIndex[childId], nodesChildren, nodesIndex, $treeNodeId, rootNode),
    ),
  };
}

function removeNode(parentId, childId) {
  return function (data) {
    return data.map((node) => {
      if (node.id === parentId) {
        return {
          ...node,
          children: node.children.filter((id) => id !== childId),
        };
      }

      return node;
    });
  };
}

function insertBefore(childId, beforeId, children) {
  const cloneChildren = [...children];
  cloneChildren.splice(children.indexOf(beforeId), 0, childId);
  return cloneChildren;
}

function addNode(parentId, childId, beforeId) {
  const append = (appendId, children) => [...children, appendId];

  return function (data) {
    return data.map((node) => {
      if (node.id === parentId) {
        const children = node.children.filter((id) => id !== childId);

        return {
          id: parentId,
          children: beforeId
            ? insertBefore(childId, beforeId, children)
            : append(childId, children),
        };
      }

      return node;
    });
  };
}

export default (options = {}) => ({
  normalizer(response) {
    const data = normalizeTree(response);

    return {
      data:
        data &&
        data.map((i) => ({
          id: i.id,
          children: i.children,
        })),

      index: keyBy(data, (i) => i.id),
    };
  },

  selector(data, index) {
    if (!data) return null;
    const rootNode = index[data[0].id];

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 3.
    return mapTree(
      rootNode,
      keyBy(data, (i) => i.id),
      index,
    );
  },

  reducer(state, action) {
    switch (action.type) {
      case (options as any).moveAction: {
        const { id, oldParentId, parentId, beforeId } = action.payload;
        return {
          ...state,
          data: modifyRequestData(
            state.data,
            flow(removeNode(oldParentId, id), addNode(parentId, id, beforeId)),
          ),
        };
      }
      case (options as any).removeAction: {
        const { id, parentId } = action.payload;
        return {
          ...state,
          data: modifyRequestData(state.data, flow(removeNode(parentId, id))),
        };
      }
      case (options as any).updateAction: {
        const { id, ...data } = action.payload;
        return {
          ...state,
          index: {
            ...state.index,
            [id]: {
              ...state.index.id,
              ...data,
            },
          },
        };
      }
      default:
        return state;
    }
  },
});
