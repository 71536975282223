import { Suspense, lazy } from 'react';

const QRcodeScannerLazy = lazy(() => import(/* webpackChunkName: "QRcodeScanner" */'./QRcodeScanner'));

export const QRcodeScanner = (props) => {
  return (
    <Suspense fallback={null}>
      <QRcodeScannerLazy {...props} />
    </Suspense>
  );
};
