import { stringify } from 'query-string';

import { api } from 'shared/api/api-methods';

import {
  getUrlEditIssueSchedule,
  getUrlDeleteLocation,
  getUrlGetLocationsByName,
  getUrlGetSuggestedLocations,
  getUrlIssueTypesAttributeById,
  getUrlIssueTypesAttributes,
  getUrlIssueTypesAttributeTree,
  getUrlIssueTypesByAttributeId,
  getUrlIssueScheduleFlows,
  getUrlIssueSchedules,
  getUrlIssueTypesTree,
  getUrlLocationById,
  getUrlUploadChildren,
} from './api-routes';
import {
  Attribute,
  AttributeCreateSchema,
  AttributeUpdateSchema,
  IssueTypeSchema,
  LocationApiSchema,
  LocationResponseSchema,
  FlowsApiSchema,
  IssueScheduleApiSchema,
  IssueScheduleListParams,
  IssueType,
  IssueScheduleResponseData,
} from './data-contracts';

class LocationApi {
  // Get locations by name
  getLocationsByName(payload: { name: string }) {
    return api.get<LocationResponseSchema>(getUrlGetLocationsByName(), { search: { ...payload } });
  }

  // Get locations by name
  getLocationsByNodeId(payload: { nodeId: string }) {
    return api.get<LocationResponseSchema>(getUrlGetLocationsByName(), { search: { ...payload } });
  }

  // Get suggested locations
  getSuggestedLocations() {
    return api.get<LocationApiSchema[]>(getUrlGetSuggestedLocations());
  }

  // Remove a location
  removeLocation(payload: { id: number }) {
    return api.post<never>(getUrlDeleteLocation(payload.id));
  }

  // Move a location
  moveLocation(payload: { id: number; parentId: number; beforeId: number | null }) {
    const { id, parentId, beforeId } = payload;
    return api.put<LocationApiSchema>(getUrlLocationById(id), {
      body: {
        parent_id: parentId,
        before_id: beforeId,
      },
    });
  }

  // Upload children for a location
  uploadChildren(payload: { id: number; file: Blob }) {
    const { id, file } = payload;
    const formData = new FormData();

    formData.set('locations_file', file);
    formData.set('parent_id', id.toString());

    return api.post<string>(getUrlUploadChildren(), {
      body: formData,
      headers: {
        'Content-Type': null,
      },
    });
  }
}

class IssueTypesApi {
  /** Get attribute tree */
  getAttributeTree(payload: {
    include_children?: boolean;
    short?: boolean;
    include_auto?: boolean;
    include_system?: boolean;
  }) {
    return api.get<Attribute[]>(`${getUrlIssueTypesAttributeTree()}?${stringify(payload)}`);
  }

  updateAttribute(payload: AttributeUpdateSchema) {
    return api.put<void>(getUrlIssueTypesAttributeById(payload.id), { body: payload });
  }

  createAttribute(payload: AttributeCreateSchema) {
    return api.post<void>(getUrlIssueTypesAttributes(), { body: payload });
  }

  getIssueTypes(attributeId: string) {
    return api.get<IssueTypeSchema[]>(getUrlIssueTypesByAttributeId(attributeId), {
      search: {
        limit: 9000,
      },
    });
  }

  deleteAttribute(attributeId: string) {
    return api.delete<void>(getUrlIssueTypesAttributeById(attributeId));
  }
}

// #TODO: состыковать с Юриным классом
class IssueScheduleApi {
  getIssueTypesTree() {
    return api.get<IssueType[]>(getUrlIssueTypesTree(), {
      search: {
        include_children: true,
        short: true,
        include_auto: true,
      },
    });
  }

  createIssueSchedule(payload: IssueScheduleApiSchema) {
    return api.post(getUrlIssueSchedules(), {
      body: payload,
    });
  }

  getIssueScheduleList(payload: IssueScheduleListParams) {
    const { locationIds } = payload;
    return api.get<IssueScheduleResponseData[]>(getUrlIssueSchedules(), {
      search: {
        filter: JSON.stringify({
          location: locationIds,
        }),
      },
    });
  }

  deleteIssueSchedule(payload: { id: number }) {
    const { id } = payload;
    return api.delete(getUrlEditIssueSchedule(id));
  }

  getFlows(payload: FlowsApiSchema) {
    return api.get(getUrlIssueScheduleFlows(), {
      search: {
        ...payload,
      },
    });
  }
}

// eslint-disable-next-line camelcase
export const apiClient_v2 = {
  locations: new LocationApi(),
  issueTypes: new IssueTypesApi(),
  issueSchedules: new IssueScheduleApi(),
};
