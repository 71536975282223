import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Attribute } from 'shared/api/v2/data-contracts';

import {
  buildTree,
  expand,
  expandByIds,
  findNextElement,
  flattenTree,
  getFilteredItemIds,
  getProjection,
  removeChildrenOf,
  setProperty,
} from './lib/utilities';
import {
  DragEndItem,
  ExpandIconType,
  FilteredTreeItems,
  DndItemFlat,
  DndItem,
  DndItemAction,
  ActionsComponentProps,
  DndComponentType,
} from './lib/types';
import { SortableItem } from './ui/SortableItem';

export interface Props<ItemData, TElement extends HTMLElement> {
  data?: DndItem<ItemData>[];
  filteredData?: FilteredTreeItems;
  draggable?: boolean;
  expandable?: boolean | ((itemData: ItemData) => boolean);
  depthLevel?: number;
  indentationWidth?: number;
  expandIconType?: ExpandIconType;
  getActions: (item: DndItemFlat<ItemData>) => DndItemAction[];
  onDragEnd?: (item: DragEndItem<ItemData>) => void;
  ContentComponent: DndComponentType<ItemData, TElement>;
  ActionsComponent?: DndComponentType<ActionsComponentProps, TElement>;
}

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

export const DndProvider = <ItemData, TElement extends HTMLElement = HTMLDivElement>({
  data,
  filteredData,
  draggable = false,
  expandable,
  indentationWidth = 36,
  depthLevel = 0,
  expandIconType,
  onDragEnd,
  getActions,
  ContentComponent,
  ActionsComponent,
}: Props<ItemData, TElement>) => {
  const [items, setItems] = useState<DndItem<ItemData>[] | null>(null);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [expandedIds, setExpandedIds] = useState<UniqueIdentifier[]>([]);

  const expandableRef = useRef(expandable);

  useEffect(() => {
    expandableRef.current = expandable;
  }, [expandable]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (depthLevel >= 0) {
      const expandByDepthLevel = expand(data, depthLevel);
      return setItems(expandByDepthLevel);
    }

    if (expandedIds.length > 0) {
      const expandedByIds = expandByIds(data, expandedIds);
      return setItems(expandedByIds);
    }

    setItems(data);
  }, [data, depthLevel, expandedIds]);

  const flattenedItems = useMemo(() => {
    const filteredIds = getFilteredItemIds(filteredData);
    const flattenedTree = flattenTree(items, filteredIds);
    const collapsedItems = flattenedTree
      .filter(({ children, expanded }) => !expanded && children?.length)
      .map(({ id }) => id);
    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    );
  }, [activeId, filteredData, items]);

  const projected = useMemo(
    () =>
      activeId && overId
        ? getProjection(
            flattenedItems,
            activeId,
            overId,
            offsetLeft,
            indentationWidth,
            expandableRef.current,
          )
        : null,
    [activeId, flattenedItems, indentationWidth, offsetLeft, overId],
  );

  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);

  const activeItem = useMemo(
    () => (activeId ? flattenedItems.find(({ id }) => id === activeId) : null),
    [activeId, flattenedItems],
  );

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const sensors = useSensors(useSensor(PointerSensor));
  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);

    document.body.style.setProperty('cursor', '');
  };
  const handleExpand = (id: UniqueIdentifier) => {
    setExpandedIds((prev) => {
      let newExpandItems = prev;
      const currentItemIndex = newExpandItems.indexOf(id);
      if (currentItemIndex !== -1) {
        newExpandItems.splice(currentItemIndex, 1);
      } else {
        newExpandItems = [...prev, id];
      }
      return newExpandItems;
    });
    setItems((treeItems) => setProperty(treeItems, id, 'expanded', (value) => !value) ?? null);
  };
  const handleDragStart = ({ active: { id: newActiveId } }: DragStartEvent) => {
    if (newActiveId != null) {
      setActiveId(newActiveId);
      setOverId(newActiveId);
    }

    document.body.style.setProperty('cursor', 'grabbing');
  };

  const handleDragMove = ({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  const handleDragOver = ({ over }: DragOverEvent) => {
    if (over?.id != null) {
      setOverId(over.id);
    }
  };

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      resetState();

      if (projected && over) {
        const { depth, parent_id } = projected;
        const activeDepth = flattenedItems.find(({ id }) => id === active.id)?.depth;
        if (over.id === active.id && depth === activeDepth) {
          return;
        }

        const clonedFlattenItems = flattenTree(items);
        const overIndex = clonedFlattenItems.findIndex(({ id }) => id === over.id);
        const activeIndex = clonedFlattenItems.findIndex(({ id }) => id === active.id);
        const activeTreeItem = clonedFlattenItems[activeIndex];

        clonedFlattenItems[activeIndex] = {
          ...activeTreeItem,
          depth,
          parent_id: parent_id ?? undefined,
        };
        const sortedItems = arrayMove(clonedFlattenItems, activeIndex, overIndex);
        const newItems = buildTree(sortedItems);
        const newOverId = over.id;
        const beforeId =
          activeIndex < overIndex ? findNextElement(items, newOverId)?.id ?? null : newOverId;

        setItems(newItems ?? null);
        onDragEnd?.({
          parentId: parent_id ?? null,
          itemId: activeTreeItem.id,
          beforeId,
          itemData: activeTreeItem.itemData,
        });
      }
    },
    // TODO: Будет отрефакторено позже
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, onDragEnd, projected],
  );
  const handleDragCancel = () => {
    resetState();
  };

  return (
    <DndContext
      sensors={sensors}
      autoScroll
      collisionDetection={closestCenter}
      onDragStart={draggable ? handleDragStart : undefined}
      onDragMove={draggable ? handleDragMove : undefined}
      onDragOver={draggable ? handleDragOver : undefined}
      onDragEnd={draggable ? handleDragEnd : undefined}
      onDragCancel={draggable ? handleDragCancel : undefined}
    >
      <SortableContext
        items={sortedIds}
        strategy={draggable ? verticalListSortingStrategy : undefined}
      >
        {flattenedItems.map((item) => {
          const { id, children, expanded, depth, itemData } = item;
          const parent_id = projected?.parent_id;

          const isItemExpandable =
            (typeof expandable === 'boolean' && !!expandable && children && children.length > 0) ||
            (typeof expandable === 'function' && itemData != null && expandable(itemData));

          const editActionForClick = getActions(item).filter(
            (i) => i.name.toLowerCase() === 'edit',
          )[0];
          return (
            <SortableItem
              data-item={id}
              key={id}
              id={id}
              projected={id === parent_id}
              depth={id === activeId && projected ? projected.depth : depth}
              expandIconType={expandIconType}
              expanded={Boolean(expanded && children?.length)}
              indentationWidth={indentationWidth}
              draggable={draggable}
              onExpand={isItemExpandable ? () => handleExpand(id) : undefined}
              last={item.id === activeId && projected ? projected.isLast : item.last}
              content={
                item.itemData && (
                  <ContentComponent {...item.itemData} editAction={editActionForClick} />
                )
              }
              actions={
                ActionsComponent && activeId == null ? (
                  <ActionsComponent itemId={item.id} actions={getActions(item)} />
                ) : undefined
              }
            />
          );
        })}
        {createPortal(
          <DragOverlay dropAnimation={dropAnimationConfig}>
            {activeId && activeItem ? (
              <SortableItem
                id={activeId}
                depth={activeItem.depth}
                clone
                draggable
                indentationWidth={indentationWidth}
                content={activeItem.itemData && <ContentComponent {...activeItem.itemData} />}
                onExpand={
                  activeItem.children?.length ||
                  (activeItem.itemData as Attribute)?.type === 'folder'
                    ? () => handleExpand(activeId)
                    : undefined
                }
                expandIconType={expandIconType}
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );
};
