import { reconnect } from 'lib/resource';
import { FormBuilder } from 'lib/ui';
import inventoriesResource from 'app/inventories/inventories.resource';
import { formatTreeValue, normalizeTreeValue } from 'lib/queryForm';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';
import { isEmpty } from 'lodash';

interface Props {
  title: string;
  name: string;
  multiple?: boolean;
  forQueryForm?: boolean;
  required?: boolean;
  stocks: any;
  parentNodesDisabled?: boolean;
}

export default reconnect((state) => ({
  stocks: inventoriesResource.stocks(state, {}, { skipFetchState: true }),
}))((props: Props) => {
  const { stocks, multiple, forQueryForm } = props;

  if (stocks?.fetching) return <BusyIndicator />;

  if (isEmpty(stocks.data?.children)) return null;

  return (
    <FormBuilder.TreeViewSelect
      tree={stocks.data}
      getLabel={(item) => item.name}
      normalize={forQueryForm ? normalizeTreeValue(multiple) : undefined}
      format={forQueryForm ? formatTreeValue(multiple, stocks.data) : undefined}
      {...props}
    />
  );
});
