import numeral from 'numeral';

import { resources } from './i18n/resources';
import i18n from './i18n/i18n';

Object.keys(resources).forEach((language) => {
  if (numeral.locales[language]) {
    delete numeral.locales[language];
  }
  numeral.register('locale', language, resources[language].translation.numeralLocale);
});

numeral.locale(i18n.language);

export default (value, format?: string) => {
  return numeral(value).format(format);
};
