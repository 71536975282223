import { PropsWithChildren, PureComponent } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { ModalLink } from 'lib/ui';
import { Icon } from 'lib/ui/icon';

import './List.scss';

const { block, element } = bem('List');

const SortableHandler = SortableHandle(() => (
  <div {...element('sortHandler')}>
    <Icon glyph="sortHandler" />
  </div>
));

const SortableListItem = SortableElement<PropsWithChildren>(({ children }) => children);

const SortableList = SortableContainer<any>(({ items, renderItem }) => (
  <ul>
    {items.map((item, index) => (
      <SortableListItem key={item.id} index={index}>
        {renderItem(item)}
      </SortableListItem>
    ))}
  </ul>
));

type OwnProps = {
  data?: any;
  renderItem?: (...args: any[]) => any;
  getId?: (...args: any[]) => any;
  linkProps?: (...args: any[]) => any;
  showId?: boolean;
  sortable?: boolean;
  className?: string;
  isItemDisabled?: (...args: any[]) => any;
  onSortEnd?: (...args: any[]) => any;
  filters?: any;
};

type Props = OwnProps & typeof List.defaultProps;

export default class List extends PureComponent<Props> {
  static defaultProps = {
    showId: true,
    isItemDisabled: () => false,
    onSortEnd() {},
    filters: {},
  };

  renderPlaceholder = () => {
    if (Object.keys(this.props.filters).length !== 0 || !(this.props as any).customPlaceholder) {
      return <div {...element('noResults')}>{t('common.no_results')}</div>;
    }
    return (this.props as any).customPlaceholder;
  };

  render() {
    const { data, showId, className, sortable } = this.props;

    if (!data) return null;

    return (
      <div {...block({ withId: showId }, className)}>
        {data.length === 0 && this.renderPlaceholder()}

        {sortable ? (
          <SortableList
            useDragHandle
            items={data}
            renderItem={this.renderItem}
            lockAxis="y"
            onSortEnd={this.handleSortEnd}
          />
        ) : (
          data.map(this.renderItem)
        )}
      </div>
    );
  }

  handleSortEnd = (sortData) => {
    const { oldIndex, newIndex } = sortData;
    const indexOffset = newIndex < oldIndex ? 0 : 1;

    this.props.onSortEnd({
      ...sortData,
      item: this.props.data[oldIndex],
      beforeId: (this.props.data[newIndex + indexOffset] || {}).id || null,
    });
  };

  renderItem = (item, index) => {
    const { renderItem, getId, linkProps, showId, sortable } = this.props;

    const listItemModifiers = {
      disabled: this.props.isItemDisabled(item),
    };

    return (
      <ModalLink {...element('item', listItemModifiers)} {...linkProps?.(item)} key={item.id}>
        {sortable && <SortableHandler />}

        <div {...element('itemContent')}>
          {showId && <div {...element('itemId')}>{getId ? getId(item) : item.id}</div>}

          {renderItem?.(item, index)}
        </div>
      </ModalLink>
    );
  };
}
