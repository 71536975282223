import { Helmet } from 'react-helmet';
import MetaTags from './MetaTags';
import AppFavicons from './AppFavicons';
import { BrandName } from '../../types/brand';

interface Props {
  brand: BrandName;
}

const AppHead = ({ brand }: Props) => {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <link rel="manifest" href={`/brands/${brand}/manifest.json`} />
      </Helmet>
      <MetaTags brand={brand} />
      <AppFavicons brand={brand} />
    </>
  );
};

export default AppHead;
