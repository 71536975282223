import { closeDictEditor } from 'app/common/DictEditor/closeDictEditor';
import { api } from 'lib/api';
import { createResource } from 'lib/resource';

export default createResource('userRoles', {
  scopes: {
    list: ({ payload }) =>
      api.get('/v2/roles/', {
        search: {
          ...payload,
          count: 9000,
        },
      }),
  },

  mutators: {
    create: {
      request: ({ payload }) => {
        return api.post('/v2/roles/', {
          body: payload,
        });
      },
      onSuccess: closeDictEditor,
    },

    update: {
      request: ({ payload }) => {
        const { id, ...data } = payload;

        return api.put(`/v2/roles/${id}/`, {
          body: data,
        });
      },
      onSuccess: closeDictEditor,
    },

    remove({ payload }) {
      return api.delete(`/v2/roles/${payload.id}/`);
    },
  },
});
