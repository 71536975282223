import { Icon } from 'lib/ui/icon';
import { bem } from 'lib/bem';
import { SelectedValueProps, SelectValue } from 'shared/ui/select/types';

import './selected-value.scss';

const { element } = bem('SelectedValue');

const CaretIcon = ({ isOpen }: { isOpen?: boolean }) => {
  return (
    <div className={element('caret-icon-container', { isOpen }).className}>
      <Icon glyph="squareArrow" />
    </div>
  );
};

export const SelectedValue = <T extends SelectValue | null>({
  value,
  onClick,
  getTagProps,
  showCaret,
  isOpen,
  placeholder,
  elementRef,
  TagComponent,
}: SelectedValueProps<T>) => {
  const showPlaceholder = placeholder && Array.isArray(value) ? value.length === 0 : value === null;

  return (
    <div ref={elementRef} className={element('value-box').className} onClick={onClick}>
      {showPlaceholder && <span className={element('placeholder').className}>{placeholder}</span>}
      {Array.isArray(value) ? (
        <div className={element('tags-container').className}>
          {value.map((_value, index) => {
            const tagProps = getTagProps?.({ index });

            return (
              <TagComponent
                key={typeof _value === 'object' ? _value?.id : _value}
                onDelete={tagProps?.onDelete}
              >
                {typeof _value === 'object' ? _value?.label : _value}
              </TagComponent>
            );
          })}
        </div>
      ) : (
        <span>{typeof value === 'object' ? value?.label : value}</span>
      )}
      {showCaret && (
        <div className={element('caret-container').className}>
          <CaretIcon isOpen={isOpen} />
        </div>
      )}
    </div>
  );
};
