import { Suspense, lazy } from 'react';

const JsonEditorLazy = lazy(() => import(/* webpackChunkName: "JsonEditor" */'./JsonEditor'));

export const JsonEditor = (props) => {
  return (
    <Suspense fallback={null}>
      <JsonEditorLazy {...props} />
    </Suspense>
  );
};
