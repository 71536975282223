import { Component } from 'react';
import { connect } from 'react-redux';

const loadingStart = () => ({
  type: 'LAZY_LOAD_START',
});

const loadingComplete = () => ({
  type: 'LAZY_LOAD_SUCCESS',
});

const loadingFailed = () => ({
  type: 'LAZY_LOAD_FAILED',
});

export default function lazyComponent(importFn) {
  return connect()(class extends Component<any> {
    state = {};

    componentDidMount() {
      const { dispatch } = this.props;

      dispatch(loadingStart());

      importFn()
        // Если модуль возвращает promise, то дождемся сначала его выполнения.
        // Это позволяет загружать модули которые в свою очередь загружают другие модули динамически.
        .then((module) => module.default)
        .then((Component) => {
          dispatch(loadingComplete());
          this.setState({ Component });
        }).catch((e) => {
          dispatch(loadingFailed());
          console.error(e);
        });
    }

    render() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'Component' does not exist on type '{}'.
      const { Component } = this.state;

      if (Component) {
        return <Component {...this.props} />;
      }

      return null;
    }
  });
}
