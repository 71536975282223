import { createSelector } from 'reselect';
import { isArray, isObject } from 'lodash';
import { mapValues, get } from 'lodash/fp';

export { default as Attribute } from './Attribute/Attribute';
export { default as AttributesView } from './AttributesView';
export { default as AttributesForm } from './AttributesForm';

function normalizeAttrValue(value) {
  if (value === undefined || value === null) return value;

  if (isObject(value) && (value as any).value !== undefined) {
    return (value as any).value;
  }

  return value;
}

export const attributeValuesSelector = (formName) => createSelector(
  get(`form.${formName}.values`),

  mapValues((value) => {
    if (isArray(value)) {
      return value.map(normalizeAttrValue);
    }

    return normalizeAttrValue(value);
  }),
);
