import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fieldsResources } from 'app/fieldsComparison';
import attachmentsResource from 'app/attachments.resource';
import { Box } from '@mui/material';
import { SimpleSelect } from 'lib/ui';
import {
  getDateLabel,
  getFieldLabel,
  getFieldValue,
} from 'app/fieldsComparison/ui/FieldSection/model';
import defaultPic from 'static/blank.png';

interface Props {
  view: string;
  num: number;
  getLink: (url) => void;
}

const FieldSection = ({ view, num, getLink }: Props) => {
  const fields = Object.values(useSelector((state) => (state as any).footballFields.list.index));

  const [field, setField] = useState();
  const [dates, setDates] = useState();
  const [date, setDate] = useState<any>();
  const [photosId, setPhotosId] = useState();

  useEffect(() => {
    if (field) {
      if (date) {
        setDate(null);
        getLink(defaultPic);
      }
      (fieldsResources as any).dates.request((field as any).id).then((resp) => {
        setDates(resp.data);
      });
    }
  }, [field]);

  useEffect(() => {
    if (date) {
      (fieldsResources as any).photos.request({ id: (field as any).id, date }).then((resp) => {
        setPhotosId(resp.data);
      });
    }
  }, [date]);

  useEffect(() => {
    if (photosId) {
      const photoId = [photosId[`${view}_photo_id`]];
      attachmentsResource.byIds
        .request(photoId)
        .then((resp) => {
          getLink(resp[0].url);
        })
        .catch(() => {
          getLink(defaultPic);
        });
    }
  }, [getLink, photosId, view]);

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ alignSelf: 'left' }}>{`${t('fields_comparison.fields')} ${num}`}</Box>
        <SimpleSelect
          name="field"
          options={fields}
          getItemLabel={getFieldLabel}
          getItemValue={getFieldValue}
          value={field}
          onChange={(evt) => {
            setField(evt);
          }}
        />
      </Box>

      {field && dates && (
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: '1rem 0' }}>
          <Box sx={{ alignSelf: 'left' }}>{t('fields_comparison.dates')}</Box>
          <SimpleSelect
            name="date"
            options={dates}
            getItemLabel={getDateLabel}
            value={date}
            onChange={(evt) => setDate(evt)}
          />
        </Box>
      )}
    </div>
  );
};

export default FieldSection;
