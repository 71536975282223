import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { flow, debounce } from 'lodash';
import moment from 'moment';

import device from 'lib/device';
import users from 'app/users/users.resource';
import { bem } from 'lib/bem';
import { t, t_prefixed } from 'lib/i18n';
import { Icon } from 'lib/ui/icon';
import { Button, FormBuilder } from 'lib/ui';
import { formatPhone } from 'lib/helpers/formatters';
import brandedData from 'config/brandedData';

import './ConfirmCodePage.scss';

const { block, element } = bem('ConfirmCodePage');

const valueSelector = formValueSelector('confirmCode');

const label = t_prefixed('quick_login');

const RepeatButton = (props) => (
  <Button invisible onClick={props.onSubmit} {...element('repeatButton')}>
    {t('login.recieve_new_sms')}
  </Button>
);

const TimerString = (props) => {
  const duration = moment.duration(props.value, 'seconds');
  return (
    <div {...element('timer')}>
      {t('quick_login.timer_string', {
        duration: moment.utc(duration.as('milliseconds')).format('mm:ss'),
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  value: state.login.confirmCode,
  codeInfo: state.login.pushCode,
  nextTry: state.login.repeatCode,
  confirmation_code: valueSelector(state, 'confirmation_code'),
});

export default flow<any, any, any>(
  connect(mapStateToProps),
  reduxForm({
    form: 'confirmCode',
  }),
)(
  class ConfirmCodePage extends PureComponent<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        maxLength: props.codeInfo ? props.codeInfo.code_length : 4,
        timerValue: props.codeInfo ? props.codeInfo.next_retry_timer : 60,
        isCodeRepeat: false,
        prevCode: null,
      };
    }

    inputRef = createRef();

    static propTypes = {
      value: PropTypes.object,
    };

    startTimer = () => {
      const timer = setInterval(async () => {
        // eslint-disable-next-line react/no-access-state-in-setstate
        const timeLeft = (this.state as any).timerValue - 1;
        if (timeLeft === 0) {
          clearInterval(timer);
          this.setState({
            isCodeRepeat: true,
          });
        }

        this.setState({
          timerValue: timeLeft,
        });
      }, 1000);
    };

    handleRepeat = () => {
      this.props.showCaptchaModal().then((captcha) => {
        (users as any).pushConfirmationCode({
          mobile: (this.props as any).value.mobile,
          action: 'confirm_mobile',
          captcha_uuid: captcha.uuid,
          captcha_data: captcha.data,
        });
        const timerValue = (this.props as any).codeInfo
          ? (this.props as any).codeInfo.next_retry_timer
          : 60;

        this.setState({
          isCodeRepeat: false,
          timerValue,
        });

        this.startTimer();
      });
    };

    componentDidMount() {
      if ((this.props as any).nextTry && (this.state as any).timerValue) {
        this.setState({ timerValue: (this.props as any).nextTry });
      }

      this.startTimer();

      (this.inputRef as any)?.current?.focus();
    }

    onKeyPress = (evt) => {
      if (
        evt.key === 'Enter' &&
        (this.props as any).confirmation_code?.length === 4 &&
        (this.props as any).confirmation_code !== this.state.prevCode
      ) {
        this.handleSubmit();
      }
      if (evt.key === 'Escape') {
        this.handleCancel();
      }
    };

    componentDidUpdate(prevProps: Readonly<any>): void {
      if (
        this.props.confirmation_code?.length === 4 &&
        (this.props as any).confirmation_code !== this.state.prevCode
      ) {
        this.handleSubmit();
      }
    }

    renderSteps = () => {
      const { step } = this.props as any;

      return <div {...element('steps')}>{t('issues.steps', { step, totalSteps: step })}</div>;
    };

    render() {
      const codeRepeat = (this.state as any).isCodeRepeat ? (
        <RepeatButton onSubmit={this.handleRepeat} />
      ) : (
        <TimerString value={(this.state as any).timerValue} />
      );

      const {
        isMiniApp,
        value: { mobile },
      } = this.props;

      return (
        <div {...block()} onKeyDown={(evt) => this.onKeyPress(evt)}>
          {isMiniApp && this.renderSteps()}

          <div {...element('label')}>
            {`${t('users.confirmation_code_description')} ${formatPhone(mobile)}`}
            <Icon onClick={this.handleCancel} glyph="edit_number" width="16px" height="16px" />
          </div>
          <FormBuilder.ConfirmCode
            required
            withoutText
            {...element('confirmCode')}
            name="confirmation_code"
            normalize={limitCodeInput}
            autoComplete={device.is.phone ? 'one-time-code' : 'off'}
            ref={this.inputRef}
          />

          {!isMiniApp ? (
            <Button
              {...element('submitButton')}
              disabled={(this.props as any).confirmation_code?.length !== 4}
              onClick={(this.props as any).submit}
            >
              {t(`accept_terms.${brandedData.poweredBy}.accept`)}
            </Button>
          ) : (
            <Button
              onClick={this.handleSubmit}
              disabled={(this.props as any).confirmation_code?.length !== 4}
            >
              {label('send_request')}
            </Button>
          )}

          {codeRepeat}
        </div>
      );
    }

    handleSubmit = debounce(() => {
      this.setState({ prevCode: (this.props as any).confirmation_code });
      (this.props as any).onSubmit({
        data: {
          ...(this.props as any).value,
          code: String((this.props as any).confirmation_code),
        },
      });
    }, 200);

    handleCancel = () => {
      (this.props as any).reset();
      (this.props as any).onClose();
    };
  },
);

function limitCodeInput(value = '') {
  return value.replace(/[^\d]/g, '');
}
