import { useDrop } from 'react-dnd';
import { DropTargetCellProps } from '../types';

function isSameItem(headerA, headerB) {
  return headerA && headerB && headerA.accessor === headerB.accessor;
}

export const useDropTargetCell = ({ column, dropBefore }: DropTargetCellProps) => {
  return useDrop(() => ({
    accept: 'TableHeaderCell',
    drop: (source, monitor) => {
      if (isSameItem(column, source)) return;

      return {
        column,
        dropBefore,
      };
    },
    hover: (source, monitor) => {
      if (!monitor.canDrop()) return false;
    },
    canDrop: (source, monitor) => {
      return !isSameItem(column, monitor.getItem());
    },
    collect: ((monitor) => ({
      isOver: monitor.canDrop() && monitor.isOver(),
    })),
  }));
};
