/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export const getUrlActivities = () => `/v4/activities`;

export const getUrlActivitiesByActivityIdIssueTypes = ({
  activityId,
}: {
  /** Activity Id */
  activityId: number;
}) => `/v4/activities/${activityId}/issue_types`;

export const getUrlActivitiesByActivityId = ({
  activityId,
}: {
  /** Activity Id */
  activityId: number;
}) => `/v4/activities/${activityId}`;

export const getUrlActivitiesIcons = () => `/v4/activities/icons`;

export const getUrlManagementCompaniesRegister = () => `/v4/management_companies/register`;

export const getUrlSmsSettings = () => `/v4/sms_settings`;

export const getUrlSmsSettingsBySmsSettingsId = ({
  smsSettingsId,
}: {
  /** Sms Settings Id */
  smsSettingsId: number;
}) => `/v4/sms_settings/${smsSettingsId}`;

export const getUrlSmsSettingsProviderNames = () => `/v4/sms_settings/provider_names`;

export const getUrlDashboardLayouts = () => `/v4/dashboard/layouts`;

export const getUrlDashboardWidgetsAllIssuesCount = () => `/v4/dashboard/widgets/all_issues_count`;

export const getUrlDashboardWidgetsOverdueIssues = () => `/v4/dashboard/widgets/overdue_issues`;

export const getUrlDashboardWidgetsInProgressIssues = () => `/v4/dashboard/widgets/in_progress_issues`;

export const getUrlDashboardWidgetsIssuesAnalytics = () => `/v4/dashboard/widgets/issues_analytics`;

export const getUrlDashboardWidgetsTopIssuesByIssueTypes = () => `/v4/dashboard/widgets/top_issues_by_issue_types`;

export const getUrlHealthcheck = () => `/v4/healthcheck`;

export const getUrlChatsGroup = () => `/v4/chats/group`;

export const getUrlChatsPrivate = () => `/v4/chats/private`;

export const getUrlChatsIssue = () => `/v4/chats/issue`;

export const getUrlChatsAvailableParticipants = () => `/v4/chats/available_participants`;

export const getUrlChatsAvailableIssueTypes = () => `/v4/chats/available_issue_types`;

export const getUrlChatsByChatId = ({
  chatId,
}: {
  /** Chat Id */
  chatId: number;
}) => `/v4/chats/${chatId}`;

export const getUrlChats = () => `/v4/chats`;

export const getUrlChatsByChatIdMessages = ({
  chatId,
}: {
  /** Chat Id */
  chatId: number;
}) => `/v4/chats/${chatId}/messages`;

export const getUrlChatsByChatIdDeletedMessageIds = ({
  chatId,
}: {
  /** Chat Id */
  chatId: number;
}) => `/v4/chats/${chatId}/deleted_message_ids`;

export const getUrlChatsByChatIdMessagesByMessageId = ({
  chatId,
  messageId,
}: {
  /** Chat Id */
  chatId: number;
  /** Message Id */
  messageId: number | string;
}) => `/v4/chats/${chatId}/messages/${messageId}`;

export const getUrlChatsByChatIdAvailableParticipants = ({
  chatId,
}: {
  /** Chat Id */
  chatId: number;
}) => `/v4/chats/${chatId}/available_participants`;

export const getUrlChatsByChatIdLeave = ({
  chatId,
}: {
  /** Chat Id */
  chatId: number;
}) => `/v4/chats/${chatId}/leave`;

export const getUrlChatsByChatIdBlock = ({
  chatId,
}: {
  /** Chat Id */
  chatId: number;
}) => `/v4/chats/${chatId}/block`;

export const getUrlChatsByChatIdUpdateLastReadMessage = ({
  chatId,
}: {
  /** Chat Id */
  chatId: number;
}) => `/v4/chats/${chatId}/update_last_read_message`;

export const getUrlChatsIssuesByIssueId = ({
  issueId,
}: {
  /** Issue Id */
  issueId: number;
}) => `/v4/chats/issues/${issueId}`;

export const getUrlChatsByChatIdNotifications = ({
  chatId,
}: {
  /** Chat Id */
  chatId: number;
}) => `/v4/chats/${chatId}/notifications`;

export const getUrlManagedObjectHierarchyByMoInternalName = ({
  moInternalName,
}: {
  /** Mo Internal Name */
  moInternalName: string;
}) => `/v4/managed_object_hierarchy/${moInternalName}`;

export const getUrlManagedObjectHierarchyByMoInternalNameByParentId = ({
  moInternalName,
  parentId,
}: {
  /** Mo Internal Name */
  moInternalName: string;
  /** Parent Id */
  parentId: number;
}) => `/v4/managed_object_hierarchy/${moInternalName}/${parentId}`;

export const getUrlManagedObjectHierarchyByMoInternalNameByParentIdDirectChildren = ({
  moInternalName,
  parentId,
}: {
  /** Mo Internal Name */
  moInternalName: string;
  /** Parent Id */
  parentId: number;
}) => `/v4/managed_object_hierarchy/${moInternalName}/${parentId}/direct_children`;

export const getUrlNotifications = () => `/v4/notifications`;

export const getUrlNotificationsByNotificationId = ({
  notificationId,
}: {
  /** Notification Id */
  notificationId: number;
}) => `/v4/notifications/${notificationId}`;

export const getUrlNotificationsByNotificationIdSeen = ({
  notificationId,
}: {
  /** Notification Id */
  notificationId: number;
}) => `/v4/notifications/${notificationId}/seen`;

export const getUrlNotificationsSeenByLastNotificationId = ({
  lastNotificationId,
}: {
  /** Last Notification Id */
  lastNotificationId: number;
}) => `/v4/notifications/seen/${lastNotificationId}`;

export const getUrlNotificationsUnseenCount = () => `/v4/notifications/unseen/count`;

export const getUrlNotificationsByNotificationIdAnnouncement = ({
  notificationId,
}: {
  /** Notification Id */
  notificationId: number;
}) => `/v4/notifications/${notificationId}/announcement`;

export const getUrlUsersConfirmEmailByToken = ({
  token,
}: {
  /** Token */
  token: string;
}) => `/v4/users/confirm_email/${token}`;

export const getUrlUsersSetEmail = () => `/v4/users/set_email`;
