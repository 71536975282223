import locationsResource from 'app/locations/locations.resource';
import { FormBuilder } from 'lib/ui';
import { formatTreeValue, normalizeTreeValue } from 'lib/queryForm';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';

interface Props {
  title: string;
  name: string;
  multiple?: boolean;
  forQueryForm?: boolean;
  required?: boolean;
  locations: any;
  parentNodesDisabled?: boolean;
}

export default reconnect((state) => ({
  locations: locationsResource.byRole(state, {}, { skipFetchState: true }),
}))((props: Props) => {
  const { locations, forQueryForm, multiple } = props;

  if (locations?.fetching) return <BusyIndicator />;

  return (
    <FormBuilder.TreeViewSelect
      tree={locations.data}
      normalize={forQueryForm ? normalizeTreeValue(multiple) : undefined}
      format={forQueryForm ? formatTreeValue(multiple, locations.data) : undefined}
      {...props}
    />
  );
});
