import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { resources, defaultNS, fallbackLng } from './resources';
import { momentInit } from '../../config/moment';

i18n.use(initReactI18next).init({
  resources,
  ns: [defaultNS],
  defaultNS,
  fallbackLng,
  returnObjects: true,
  interpolation: {
    escapeValue: false,
    prefix: '{',
    suffix: '}',
  },
});

momentInit();

const shortBrowserLang = window.navigator.language.toLowerCase().split('-')[0];

i18n.changeLanguage(shortBrowserLang);

export default i18n;
