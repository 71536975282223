import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { connect } from 'react-redux';
import { isEmpty, flow, isNil, uniqueId, unionWith, isArray } from 'lodash';
import { getFormSyncErrors, getFormValues, reduxForm, touch } from 'redux-form';
import animatedScrollTo from 'animated-scrollto';
import users from 'app/users/users.resource';
import { bem } from 'lib/bem';
import { BookingItemAction } from 'app/bookings/types';
import { reconnect } from 'lib/resource';

import { getAttrsByAction, getInitialValues } from '../../services/attrsService';
import { attrsService } from '../../services';
import Attribute from '../Attribute/Attribute';
import './AttributesForm.scss';
import { AttributeInputRendererType, getRenderers } from '../attributeInputRenderers';

function scrollToFirstInvalidField() {
  const firstInvalidField = document.querySelectorAll('.Modal._issue ._invalid')[0];
  const scrollContainer = document.querySelector('.Modal._issue');
  const duration = 300;

  if (!firstInvalidField) return;

  animatedScrollTo(scrollContainer, (firstInvalidField as any).offsetTop, duration);
}

const { block } = bem('AttributesForm');

const AttrsFormV2 = flow<any, any, any>(
  connect((state, props: any) => ({
    values: getFormValues(props.form)(state),
    errors: getFormSyncErrors(props.form)(state),
  })),
  reduxForm({
    pure: true,
  }),
)(
  // eslint-disable-next-line react/display-name
  memo(({ attributes, managedObject, values, action, errors, form }: any) => {
    const renderers = useMemo(() => {
      return getRenderers(
        Object.values(BookingItemAction).includes(action)
          ? AttributeInputRendererType.BOOKING_ITEM
          : AttributeInputRendererType.LOCATION,
      );
    }, [action]);

    const renderAttributeFormField = useCallback(
      (attr, value) => {
        const AttributeFormField = renderers[attr.renderer];

        return (
          <AttributeFormField
            key={attr.id}
            name={attr.id}
            title={attr.cta || attr.name}
            data={attr}
            value={value}
            validate={attrsService.getAttrValidators(attr)}
            required={attr.required}
            lastError={errors[attr.id]}
          />
        );
      },
      [errors, renderers],
    );

    const renderReadOnlyAttribute = useCallback((attr, value) => {
      return <Attribute key={attr.id} attrDescriptor={attr} value={value} />;
    }, []);

    if (
      managedObject &&
      managedObject.attributes?.length &&
      !(form === 'bookingItemAttributes' && action === 'read')
    ) {
      const comparator = (arrVal, othVal) => {
        if (!('id' in arrVal)) {
          return false;
        }

        return arrVal.id === othVal.id;
      };

      attributes = unionWith(attributes, managedObject.attributes, comparator);
    }

    const getSupportedAttributes = useCallback(() => {
      if (attributes) {
        return attributes.filter((attr) => {
          if (renderers[attr.renderer]) {
            return true;
          }

          console.warn(`Unsupported attribute input: ${attr.renderer}`);
          return false;
        });
      }
      return [];
    }, [attributes, renderers]);

    const supportedAttributes = useMemo(() => getSupportedAttributes(), [getSupportedAttributes]);

    const filteredAttributes = useMemo(() => {
      if (supportedAttributes.length !== 0) {
        if (managedObject && managedObject.issue_type) {
          return attrsService.filterAttrsByConditions(supportedAttributes, managedObject, values);
        }
        return supportedAttributes;
      }
      return [];
    }, [managedObject, supportedAttributes, values]);

    if (filteredAttributes.length === 0) {
      return null;
    }

    return (
      <div {...block()}>
        {filteredAttributes.map((attr) => {
          const isEmptyValue =
            isNil(attr.value) ||
            attr.value === '' ||
            (isArray(attr.value) && attr.value.length === 0);
          if (!attr.actions[action]) return null;
          if (!attr.actions[action].show_empty && isEmptyValue) return null;

          return attr.read_only
            ? renderReadOnlyAttribute(attr, attr.value)
            : renderAttributeFormField(attr, attr.value);
        })}
      </div>
    );
  }),
);

const AttributesFormV2 = ({
  action,
  actionAttributes,
  editableOnly,
  onInit,
  highlightErrors,
  managedObject,
  form,
  onChange,
  currentUser,
  dispatch,
  values,
  typeAttributes,
  isEditable,
  defaultAttributes,
  managedObjectTypeName = 'location_type',
}) => {
  const [attributes, setAttributes] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>();

  const touchAllFields = useCallback(() => {
    if (attributes) {
      const fields = attributes.map((x) => x.id);
      dispatch(touch(form, ...fields));
    }
  }, [attributes, dispatch, form]);

  const highlightUnfilledFields = useCallback(() => {
    if (initialValues) {
      touchAllFields();
      scrollToFirstInvalidField();
    }
  }, [initialValues, touchAllFields]);

  const initializeReduxForm = useCallback(() => {
    if (initialValues) {
      if (onInit) {
        onInit(initialValues);
      }

      if (highlightErrors) {
        highlightUnfilledFields();
      }
    }
  }, [highlightErrors, highlightUnfilledFields, initialValues, onInit]);

  useEffect(() => {
    initializeReduxForm();
  }, [initializeReduxForm]);

  const existingAttributes = useMemo(() => {
    if (actionAttributes) {
      return actionAttributes;
    }
    if (managedObject && !isEmpty(managedObject)) {
      return managedObject.attributes;
    }
    return [];
  }, [actionAttributes, managedObject]);

  const mergedLocationTypeAttributes = useMemo(() => {
    if (managedObject && !isEmpty(managedObject) && typeAttributes) {
      return typeAttributes.map((attr) => {
        if (existingAttributes) {
          const foundAttr = existingAttributes.find((x) => x.id === attr.id);
          if (foundAttr) {
            return {
              ...attr,
              value: foundAttr.value,
            };
          }
        }
        return attr;
      });
    }
  }, [managedObject, typeAttributes, existingAttributes]);

  const extendedManagedObjectAttributes = useMemo(() => {
    if (!isEditable && managedObject && !isEmpty(managedObject)) {
      const { attributes: typeAttrs } = managedObject[managedObjectTypeName];
      if (typeAttrs) {
        if (existingAttributes) {
          return existingAttributes.map((attr) => {
            const foundAttr = typeAttrs.find((x) => x.id === attr.id);
            if (foundAttr) {
              return {
                ...attr,
                actions: foundAttr.actions,
              };
            }
            return attr;
          });
        }
        return [];
      }
    }
  }, [existingAttributes, isEditable, managedObject, managedObjectTypeName]);

  const collectAttributes = useCallback(() => {
    let handledObject = null;
    if (managedObject && !isEmpty(managedObject)) {
      handledObject = managedObject;
    }

    if (handledObject) {
      let finalAttrs = [];
      if (editableOnly && mergedLocationTypeAttributes) {
        finalAttrs = getAttrsByAction(handledObject, action, mergedLocationTypeAttributes);
      } else if (!editableOnly && extendedManagedObjectAttributes) {
        finalAttrs = getAttrsByAction(
          handledObject,
          action,
          extendedManagedObjectAttributes,
        ).filter((x) => !editableOnly || !x.read_only);
      }

      if (defaultAttributes && defaultAttributes.length > 0 && !isEditable) {
        finalAttrs = defaultAttributes.concat(finalAttrs);
      }
      setAttributes(finalAttrs);
    }
  }, [
    action,
    defaultAttributes,
    editableOnly,
    extendedManagedObjectAttributes,
    isEditable,
    managedObject,
    mergedLocationTypeAttributes,
  ]);

  useEffect(() => {
    if (attributes) {
      setInitialValues(getInitialValues(attributes, currentUser, action));
    }
  }, [action, attributes, currentUser]);

  const touchFilledFields = useCallback(() => {
    if (action === 'update' || action === 'submit') {
      const filledFields = Object.keys(values || {}).filter(
        (field) => !isNil((values || {})[field]),
      );

      dispatch(touch(form, ...filledFields));
    }
  }, [action, dispatch, form, values]);

  useEffect(() => {
    collectAttributes();
    touchFilledFields();
  }, [collectAttributes, touchFilledFields]);

  useEffect(() => {
    if (action === 'submit') {
      touchAllFields();
    }
  }, [action, touchAllFields]);

  return (
    <AttrsFormV2
      form={form}
      key={managedObject ? managedObject.id : uniqueId()}
      attributes={attributes}
      managedObject={managedObject}
      onChange={onChange}
      action={action}
    />
  );
};

export default reconnect((state) => ({
  currentUser: users.current(state, {}),
}))(memo(AttributesFormV2));
