import { takeMap } from 'lib/saga-utils';
import { all, call, put } from 'redux-saga/effects';
import { closeModal } from 'lib/ui/Modal';
import reports from './reports.resource';

export default function* reportsSaga() {
  yield all([
    call(reports.saga),

    call(takeMap({
      'REPORTS/UPDATEANDDOWNLOAD_SUCCESS': function* () {
        yield put(closeModal('reportColumns'));
      },

      'REPORTS/UPDATEANDDOWNLOAD_FAIL': function* () {
        yield put(closeModal('reportColumns'));
      },
    })),
  ]);
}
