import { api } from 'lib/api';
import { put } from 'redux-saga/effects';
import { closeModal } from 'lib/ui/Modal';
import { createResource } from 'lib/resource';

import { BOOKING_DETAILS_MODAL } from './config';

const versionPrefix = 'v3';

export const getBookings = ({ payload }) => api.get('v3/bookings/', { search: payload });

function* closeOnSuccess() {
  yield put(closeModal(BOOKING_DETAILS_MODAL));
}

export default createResource('bookings', {
  scopes: {
    list({ payload }) {
      return api.get(`/${versionPrefix}/bookings/`, { search: payload });
    },

    myList({ payload }) {
      return api.get(`/${versionPrefix}/bookings/my/`, { search: payload });
    },

    myBooking: {
      request: ({ payload }) => api.get(`/${versionPrefix}/bookings/my/${payload}/`),
      type: 'item',
    },

    authorList({ payload }) {
      return api.get(`/${versionPrefix}/bookings/authors/`, { search: payload });
    },

    bookingItemsListSimplified({ payload }) {
      return api.get(`/${versionPrefix}/bookings/booking_items/`, { search: payload });
    },

    bookingItemsList({ payload }) {
      return api.get(`/${versionPrefix}/booking_items/`, { search: payload });
    },

    bookingItemTypes({ payload }) {
      return api.get(`/${versionPrefix}/booking_item_types/`, { search: payload });
    },
  },
  byId: ({ payload }) => api.get(`/${versionPrefix}/bookings/${payload}/`),
  mutators: {
    create: ({ payload }) => {
      const { booking_item_id, ...body } = payload;
      return api.post(`/${versionPrefix}/booking_items/${booking_item_id}/book/`, { body });
    },
    cancelMyBooking: {
      request: ({ payload }) => api.put(`/${versionPrefix}/bookings/my/${payload}/cancel/`),
      onSuccess: closeOnSuccess,
    },
    cancelUserBooking: {
      request: ({ payload }) => api.put(`/${versionPrefix}/bookings/${payload}/cancel/`),
      onSuccess: closeOnSuccess,
    },
    shareBooking: ({ payload }) => {
      const { booking_item_id, ...body } = payload;
      return api.post(`/${versionPrefix}/booking_items/${booking_item_id}/share/`, { body });
    },
  },
});
