import { closeDictEditor } from 'app/common/DictEditor/closeDictEditor';
import { api } from 'lib/api';
import { createResource } from 'lib/resource';

export default createResource('companies', {
  scopes: {
    list: ({ payload }) => api.get('/companies/', { search: payload }),

    byIds: ({ payload }) => api.get('/companies/', { search: payload }),
  },

  mutators: {
    create: {
      request: ({ payload }) => {
        return api.post('/v2/companies/', {
          body: payload,
        });
      },
      onSuccess: closeDictEditor,
    },

    update: {
      request: ({ payload }) => {
        const { id, ...data } = payload;

        return api.put(`/v2/companies/${id}/`, {
          body: data,
        });
      },
      onSuccess: closeDictEditor,
    },

    remove({ payload }) {
      return api.delete(`/v2/companies/${payload.id}/`);
    },
  },
});
