import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { openModal, closeModal } from 'lib/ui/Modal';

const ModalContainer = (props) => {
  const { router, name, routing, isOpened, component: ModalComponent } = props;
  const isInitializedRef = useRef(false);
  const dispatch = useDispatch();
  const isOpenedRef = useRef(isOpened);

  useEffect(() => {
    isOpenedRef.current = isOpened;
  }, [isOpened]);

  const open = useCallback(
    (data: unknown) => {
      dispatch(openModal(name, data));
    },
    [dispatch, name],
  );

  const close = useCallback(() => {
    dispatch(closeModal(name));
  }, [dispatch, name]);

  const initialData = useMemo(() => router.query[name], [name, router.query]);

  useEffect(() => {
    if (isInitializedRef.current || !routing) {
      return;
    }

    if (initialData && !isOpenedRef.current) {
      open(initialData);
    }

    isInitializedRef.current = true;
  }, [initialData, isOpened, open, routing]);

  useEffect(() => {
    if (!isInitializedRef.current) {
      return;
    }

    const isOpenedValue = isOpenedRef.current;

    if (initialData && !isOpenedValue) {
      open(initialData);
    }

    if (!initialData && isOpenedValue) {
      close();
    }
  }, [close, initialData, open]);

  if (isOpened) {
    return <ModalComponent {...props} />;
  }

  return null;
};

export default ModalContainer;
