import { isPlainObject } from 'lodash';
import { fakeNull } from 'config/constants';
import { operators } from './operators';

const isValue = (value) => !isPlainObject(value);
const isAttr = (value) => value.attr;
const isLocation = (value) => value.location;
const isLocationType = (value) => value.location_type;

const getOperatorId = (asTree) => Object.keys(asTree)[0];

const normalizeValue = (value) => {
  if (value === undefined || value === fakeNull) {
    return null;
  }

  return value;
};

const getOperator = (asTree) => {
  const operatorId = getOperatorId(asTree);
  const operator = operators[operatorId];

  if (!operator) {
    throw new Error(`Conditional attrs: operator '${operatorId}' is not defined.`);
  }

  return operator;
};

const getOperands = (asTree) => {
  const operatorId = getOperatorId(asTree);
  return normalizeValue(asTree[operatorId]);
};

const processCondition = (issue, values) => (asTree) => {
  if (isValue(asTree)) return asTree;
  if (isAttr(asTree)) return normalizeValue(values[asTree.attr]);
  if (isLocation(asTree)) return issue.location.id;
  if (isLocationType(asTree)) return issue.location.location_type_id;

  return getOperator(asTree)(...getOperands(asTree).map(processCondition(issue, values)));
};

export default processCondition;
