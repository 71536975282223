import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Button, ReduxModal } from 'lib/ui';
import { Icon, GlyphName } from 'lib/ui/icon';
import { QuickStart, SocialMedia } from 'app/entry/lib';
import { t } from 'lib/i18n';
import { isProduction } from 'config/constants';
import brandedData from 'config/brandedData';

type SocialMediaLinkProps = {
  item: SocialMedia;
};
const SocialMediaLink = ({ item }: SocialMediaLinkProps) => {
  return (
    <div>
      {/* TODO: Исправить a11y баг */}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <a href={item.link} target="_blank" rel="noreferrer">
        <Icon glyph={item.name as GlyphName} />
      </a>
    </div>
  );
};

type QuickStartCardsProps = {
  item: QuickStart;
};
const QuickStartCards = ({ item }: QuickStartCardsProps) => {
  return (
    <Box
      component="a"
      href={item.link}
      target="_blank"
      rel="noreferrer"
      sx={{
        textDecoration: 'none',
        width: '300px',
        color: 'inherit',
        borderRadius: '8px',
        padding: '12px',
        border: '1px solid rgba(231, 238, 255, 0.72)',
        '&:hover': {
          boxShadow: '0px 1px 12px -2px rgba(231, 238, 255, 0.64)',
        },
      }}
    >
      <Box component="img" src={`/images/${item.img}`} alt="" sx={{ width: '100%' }} />
      <Typography
        sx={{
          textTransform: 'uppercase',
          color: 'rgb(56 120 255)',
          marginTop: '8px',
        }}
      >
        {item.label}
      </Typography>
      <Typography sx={{ fontSize: '16px', fontWeight: '600', marginTop: '16px' }}>
        {item.title}
      </Typography>
      <Typography sx={{ marginTop: '16px' }}>{item.text}</Typography>
    </Box>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: '0px',
  },

  marginTop: '-30px',
  position: 'relative',
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  marginTop: '16px',
  display: 'flex',
  gap: '24px',
}));

export default ReduxModal({
  name: 'onboardModal',
  getTitle: () => t('onboarding.title'),
  backdropClick: false,
})(() => {
  const quickStart = t('onboarding.quick_start.cards') as unknown as QuickStart[];
  const socialMedia = t('onboarding.social_media') as unknown as SocialMedia[];
  const buttons = t('onboarding.contacts.buttons') as unknown as string[];

  const contactsUrl = `https://${isProduction ? '' : 'dev.'}${brandedData.landing}/contacts/`;
  const onButtonClick = () => {
    window.open(contactsUrl, '_blanc');
  };

  return (
    <Wrapper>
      <Typography>{t('onboarding.subtitle')}</Typography>
      <Typography sx={{ marginTop: '24px' }}>{t('onboarding.free_access')}</Typography>
      <Typography>{t('onboarding.text')}</Typography>

      <Box sx={{ marginTop: '24px' }}>
        <Typography>{t('onboarding.quick_start.title')}</Typography>
        <Box sx={{ display: 'flex', gap: '32px', marginTop: '16px' }}>
          {quickStart.map((item) => (
            <QuickStartCards item={item} key={item.img} />
          ))}
        </Box>
      </Box>

      <Box sx={{ marginTop: '24px' }}>
        <Typography>{t('onboarding.contacts.title')}</Typography>
        <ButtonsWrapper>
          {buttons.map((item, i) => (
            <Button key={i} onClick={onButtonClick} sx={{ borderRadius: '8px' }}>
              {item}
            </Button>
          ))}
        </ButtonsWrapper>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '-24px',
          right: '60px',
          display: 'flex',
          gap: '16px',
        }}
      >
        {socialMedia.map((item) => (
          <SocialMediaLink item={item} key={item.name} />
        ))}
      </Box>
    </Wrapper>
  );
});
