import { all, call, put } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { get } from 'lodash';
import brandedData from 'config/brandedData';
import { takeMap } from 'lib/saga-utils';
import { contextNotification } from 'app/notifications';
import { openModal, closeModal } from 'lib/ui/Modal';
import { router } from 'config/router';

const notify = contextNotification('issues');
const closeIssueModal = closeModal('issue');

function goToMyFeed() {
  // TODO: Unsafe solution
  // See: https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
  router.navigate('/feed/');
}

function* issuesNotificationsSaga() {
  yield call(
    takeMap({
      'ISSUES/REOPEN_SUCCESS': function* () {
        if (brandedData.clientApp === 'smartbc') {
          yield put(notify('reopened_teorema'));
        } else {
          yield put(notify('reopened'));
        }
      },

      'ISSUES/RATE_SUCCESS': function* () {
        yield put(notify('rated'));
      },

      'ISSUES/UPDATE_SUCCESS': function* () {
        yield put(notify('updated'));
      },

      'ISSUES/REMOVE_FROM_LIST_SUCCESS': function* () {
        yield put(notify('removed'));
        yield put(closeIssueModal);
      },

      'ISSUES/CLOSE_SUCCESS': function* () {
        yield put(notify('closed'));
      },

      'ISSUES/ASSIGN_SUCCESS': function* () {
        yield put(notify('assigned'));
      },

      'ISSUES/UPDATESLA_SUCCESS': function* () {
        yield put(notify('updated'));
      },

      'ISSUES/PASS_SUCCESS': function* () {
        yield put(notify('passed'));
      },

      'ISSUES/ESCALATE_SUCCESS': function* () {
        yield put(notify('escalated'));
      },

      'ISSUES/RETURN_SUCCESS': function* ({ id }) {
        yield put(notify('returned'));
      },

      'ISSUES/SUBMIT_SUCCESS': function* () {
        yield put(notify('checklist_sent'));
      },

      'ISSUES/REVIEW_CHECKLIST_SUCCESS': function* () {
        yield put(notify('checklist_reviewed'));
      },

      'ISSUES/EMAILPDF_SUCCESS': function* () {
        yield put(notify('pdf_emailed'));
      },
    }),
  );
}

export function* issuesSaga() {
  yield all([
    call(issuesNotificationsSaga),

    call(
      takeMap({
        'ISSUES/CREATE': function* () {
          yield put(startSubmit('newIssueForm'));
        },

        'ISSUES/CREATE_SUCCESS': function* ({ requestAction, response }) {
          yield put(stopSubmit('newIssueForm'));

          const meta = get(requestAction, 'payload.meta');
          if (meta && meta.onSuccess && response) {
            yield put(meta.onSuccess);
            yield put(
              openModal('newIssueSuccess', {
                title: response.metadata.message.title,
                body: response.metadata.message.body,
                actions: response.metadata.message.actions,
                anonymous: meta.onSuccess.anonymous,
              }),
            );
          }
        },

        'ISSUES/CREATE_FAIL': function* () {
          yield put(stopSubmit('newIssueForm'));
        },

        'ISSUES/CREATEGROUP': function* () {
          yield put(startSubmit('newIssueForm'));
        },

        'ISSUES/CREATEGROUP_SUCCESS': function* () {
          yield put(notify('created'));
          goToMyFeed();
        },

        'ISSUES/CREATEGROUP_FAIL': function* () {
          yield put(startSubmit('newIssueForm'));
        },
      }),
    ),
  ]);
}
