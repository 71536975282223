interface Response {
  data: unknown;
  metadata: {
    http_code: number;
  };
}

export default {
  selector(data, index) {
    if (data === 0) return '0';
    return data;
  },

  normalizer(response: Response) {
    return response;
  },
};
