import { all, call, put } from 'redux-saga/effects';

import { takeMap } from 'lib/saga-utils';
import { contextNotification } from 'app/notifications';

const notification = contextNotification('locations');

export function* locationsSaga() {
  yield all([
    call(takeMap({
      'LOCATIONS/CREATE_SUCCESS': function* ({ response }) {
        yield put(notification('created'));
      },

      'LOCATIONS/UPDATE_SUCCESS': function* () {
        yield put(notification('updated'));
      },

      'LOCATIONS/REMOVE_SUCCESS': function* () {
        yield put(notification('removed'));
      },

      'LOCATIONS/MOVE_SUCCESS': function* () {
        yield put(notification('moved'));
      },

      'LOCATIONS/UPLOADCHILDREN_SUCCESS': function* () {
        yield put(notification('childrenUploaded'));
      },
    })),
  ]);
}
