/* eslint-disable camelcase */
import { forwardRef } from 'react';

// eslint-disable-next-line import/no-cycle
import { ActivityTypeEnum } from 'pages/activities-settings';
import { bem } from 'lib/bem';
import { Icon, UrlIcon } from 'lib/ui/icon';
import { DndItemAction } from 'shared/ui/dnd-provider';

import './ContentBlock.scss';

type ContentBlockProps = {
  name: string;
  icon_url?: string;
  subtitle?: string;
  editAction?: DndItemAction;
  type?: ActivityTypeEnum;
};

const { element } = bem('ContentBlock');

export const ContentBlock = forwardRef<HTMLDivElement, ContentBlockProps>(
  function ContentBlockComponent({ icon_url, name, subtitle, editAction, type }, ref) {
    return (
      <div ref={ref}>
        <div className={element('wrapper').className}>
          {icon_url && <UrlIcon url={icon_url} className={element('icon').className} />}
          <div className={element('title').className} onClick={editAction?.onClick}>
            <div className={element('title--primary__wrapper').className}>
              <h1 className={element('title--primary').className}>{name}</h1>
              {type === ActivityTypeEnum.OPEN_URL && (
                <Icon glyph="open_url" className={element('title--primary__icon').className} />
              )}
            </div>
            <p className={element('title--secondary').className}>{subtitle}</p>
          </div>
        </div>
      </div>
    );
  },
);
