import { Suspense } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { getFormValues } from 'redux-form';
import { Skeleton } from '@mui/lab';
import { Box } from '@mui/material';

import { ScopeResult } from 'lib/resource';
import { t } from 'lib/i18n';
import { Page, Loader } from 'lib/ui';

import { DASHBOARD_COLS, TILE_MARGIN, TILE_WIDTH } from '../config';
import './ReportsDashboard.scss';
import { usePageModel } from '../model/usePageModel';
import { useReactGridLayout } from '../model/useReactGridLayout';
import { DashboardLayoutModel, dashboardsResource } from '../model/dashboards.resource';
import {
  DashboardFilters,
  dashboardFiltersFormConfig,
  mapDashboardFilters,
} from '../ui/DashboardFilters';
import { DashboardGrid, useDashboardGrid } from '../ui/DashboardGrid';
import { DASHBBOARD_WIDGETS } from '../ui/widgets';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface Props {
  userLayouts: ScopeResult<DashboardLayoutModel>;
  issuesTotal: ScopeResult<any>;
  issuesInProgress: ScopeResult<any>;
  issuesOverdue: ScopeResult<any>;
}

const mapStateToProps = (state) => {
  const filterValues = getFormValues(dashboardFiltersFormConfig.form)(state);
  const widgetFilters = mapDashboardFilters(filterValues);
  return {
    userLayouts: dashboardsResource.layouts(state, {}),
    issuesTotal: dashboardsResource.issuesTotal(state, widgetFilters),
    issuesInProgress: dashboardsResource.issuesInProgress(state, widgetFilters),
    issuesOverdue: dashboardsResource.issuesOverdue(state, widgetFilters),
    // topIssuesByIssueTypes: dashboardsResource.topIssuesByIssueTypes(state, {}),
  };
};

const { block } = bem('ReportsDashboard');

const ReportsDashboard = reconnect(mapStateToProps)(({
  userLayouts,
  issuesTotal,
  issuesInProgress,
  issuesOverdue,
}: Props) => {
  const { ref, currentContainerWidth, currentBreakpoint, onBreakpointChange, onWidthChange } =
    usePageModel();

  const { dragging, resizing, layouts, ...rglProps } = useReactGridLayout(userLayouts);

  const widgets = layouts && layouts[currentBreakpoint] ? layouts[currentBreakpoint] : [];

  const { gridColumnWidth, gridRows, gridColumns } = useDashboardGrid({
    widgets,
    currentContainerWidth,
    currentBreakpoint,
  });

  const loadLayoutsFirstTime = userLayouts.fetching && !userLayouts.data;

  const widgetData = {
    issuesTotal: issuesTotal?.data,
    issuesInProgress: issuesInProgress?.data,
    issuesOverdue: issuesOverdue?.data,
  };

  return (
    <Page
      title={t('menu.analytical_reports')}
      fullWidth
      ref={ref}
      contentSx={{ display: 'flex', flexDirection: 'column' }}
      {...block({ interactive: dragging || resizing })}
    >
      <DashboardFilters />
      {loadLayoutsFirstTime ? <Skeleton variant="rectangular" sx={{ height: '100%' }} /> : null}
      {widgets.length !== 0 && !loadLayoutsFirstTime ? (
        <Box sx={{ position: 'relative', mt: 2 }}>
          <DashboardGrid
            dragging={dragging}
            resizing={resizing}
            columns={gridColumns}
            rows={gridRows}
            columnWidth={gridColumnWidth}
          />
          <ResponsiveReactGridLayout
            layouts={layouts}
            onBreakpointChange={onBreakpointChange}
            measureBeforeMount
            compactType={null}
            preventCollision
            rowHeight={TILE_WIDTH}
            cols={DASHBOARD_COLS}
            onWidthChange={onWidthChange}
            containerPadding={[0, 0]}
            margin={[TILE_MARGIN, TILE_MARGIN]}
            {...rglProps}
          >
            {layouts[currentBreakpoint].map((widget) => {
              const Widget = DASHBBOARD_WIDGETS[widget.i];
              if (!Widget) {
                return null;
              }
              return (
                <div key={widget.i}>
                  <Suspense fallback={<Loader />}>
                    <Widget.Component data={widgetData[widget.i]} />
                  </Suspense>
                </div>
              );
            })}
          </ResponsiveReactGridLayout>
        </Box>
      ) : null}
    </Page>
  );
});

export default ReportsDashboard;
