export const getUrlGetLocationsByName = () => '/v3/locations/';

export const getUrlLocationById = (id: number) => `/locations/${id}/`;

export const getUrlUploadChildren = () => '/v2/locations/upload/';

export const getUrlGetSuggestedLocations = () => '/v2/locations/suggested/';

export const getUrlDeleteLocation = (id: number) => `/locations/${id}/delete/`;

export const getUrlIssueTypesAttributeTree = () => '/v2/issuetypes/attribute_tree/';

export const getUrlIssueTypesAttributeById = (id: string) => `/v2/issuetypes/attributes/${id}/`;

export const getUrlIssueTypesAttributes = () => '/v2/issuetypes/attributes/';

export const getUrlIssueTypesByAttributeId = (attributeId: string) =>
  `/v2/attributes/${attributeId}/issuetypes/`;

export const getUrlIssueTypesTree = () => '/v2/issuetypes/';

export const getUrlIssueSchedules = () => '/v2/issuetypes/schedules/';

export const getUrlEditIssueSchedule = (id: number) => `/v2/issuetypes/schedules/${id}/`;

export const getUrlIssueScheduleFlows = () => '/v2/issuetypes/schedules/flows/';
