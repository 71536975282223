import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient_v4 } from 'shared/api/v4/client';
import { UpdateActivityDataSchema } from 'shared/api/v4/swagger/data-contracts';
import { mutationKeysFactory } from 'shared/lib/react-query/mutationKeysFactory';

import { activitiesQueries } from './queries';

const mutationKey = mutationKeysFactory('activities');

export const activitiesMutationKeys = {
  create: mutationKey('create'),
  update: mutationKey('update'),
  delete: mutationKey('delete'),
};

export const useDeleteActivity = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: activitiesMutationKeys.delete,
    mutationFn: async (activityId: number) => {
      await apiClient_v4.activities.deleteActivity({ activityId });
      return { activityId };
    },

    onSuccess: () => queryClient.invalidateQueries({ queryKey: activitiesQueries._def }),
  });
};

export const useUpdateActivity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: activitiesMutationKeys.update,
    mutationFn: ({ activityId, item }: { activityId: number; item: UpdateActivityDataSchema }) => {
      return apiClient_v4.activities.updateActivity({ activityId, item });
    },

    onSuccess: () => queryClient.invalidateQueries({ queryKey: activitiesQueries._def }),
  });
};
export const useCreateActivity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: activitiesMutationKeys.create,
    mutationFn: ({ item }: { item: UpdateActivityDataSchema }) => {
      return apiClient_v4.activities.createActivity({ item });
    },

    onSuccess: () => queryClient.invalidateQueries({ queryKey: activitiesQueries._def }),
  });
};
