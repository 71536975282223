import { isEqual, isArray, difference } from 'lodash';

const equal = (a, b) => {
  if (isArray(a) && isArray(b)) {
    return isEqual(a.sort(), b.sort());
  }

  return a === b;
};

export const operators = {
  and: (...args) => args.every(Boolean),

  or: (...args) => args.some(Boolean),

  in: (a, b = []) => {
    const arrA = isArray(a) ? a : [a];
    return difference(arrA, b).length === 0;
  },

  '==': (a, b) => equal(a, b),

  '!=': (a, b) => !equal(a, b),

  '>': (a, b) => {
    if (a === null || b === null) return false;
    return a > b;
  },

  '>=': (a, b) => {
    if (a === null || b === null) return false;
    return a >= b;
  },

  '<': (a, b) => {
    if (a === null || b === null) return false;
    return a < b;
  },

  '<=': (a, b) => {
    if (a === null || b === null) return false;
    return a <= b;
  },
};
