import { Layout } from 'react-grid-layout';
import { api, BaseModel } from 'lib/api';
import { createResource } from 'lib/resource';
import { EndpointType } from 'lib/resource/endpointTypes';

const endpoint = '/v4/dashboard/';
const layoutsEndpoint = `${endpoint}/layouts/`;
const widgetsEndpoint = `${endpoint}/widgets/`;
const issuesTotalEndpoint = `${widgetsEndpoint}all_issues_count/`;
const issuesInProgressEndpoint = `${widgetsEndpoint}in_progress_issues/`;
const issuesOverdueEndpoint = `${widgetsEndpoint}overdue_issues/`;

export interface DashboardLayoutModel extends BaseModel {
  settings: Record<string, Layout[]>;
}

export const dashboardsResource = createResource('dashboards', {
  scopes: {
    layouts: {
      request: () => api.get(layoutsEndpoint),
      type: EndpointType.ITEM_V2,
    },
    issuesTotal: {
      request: ({ payload }) => api.get(issuesTotalEndpoint, { search: payload }),
      type: EndpointType.ITEM_V2,
    },

    issuesInProgress: {
      request: ({ payload }) => api.get(issuesInProgressEndpoint, { search: payload }),
      type: EndpointType.ITEM_V2,
    },

    issuesOverdue: {
      request: ({ payload }) => api.get(issuesOverdueEndpoint, { search: payload }),
      type: EndpointType.ITEM_V2,
    },

    topIssuesByIssueTypes: () => api.get(`${widgetsEndpoint}top_issues_by_issue_types`),
  },
  mutators: {
    saveLayouts: ({ payload }) => api.post(layoutsEndpoint, { body: payload }),
  },
});
