import { keyBy } from 'lodash';
import { api } from 'lib/api';
import { createResource, treeScope } from 'lib/resource';
import { put } from 'redux-saga/effects';
import { closeModal } from 'lib/ui/Modal';

import { SAVE_ISSUE_TYPE_MODAL } from './config';

function wrapInRootNode(json) {
  return {
    ...json,
    response: {
      id: -1,
      children: json.response,
    },
  };
}

function* closeOnSuccess() {
  yield put(closeModal(SAVE_ISSUE_TYPE_MODAL));
}

export default createResource('issueTypes', {
  scopes: {
    list({ payload }) {
      return api.get('/v2/issuetypes/', { search: payload });
    },

    tree: {
      request({ payload = {} }) {
        return api
          .get('/v2/issuetypes/', {
            search: {
              include_children: true,
              short: true,
              include_auto: true,
              ...payload,
            },
          })
          .then(wrapInRootNode);
      },

      ...treeScope({
        moveAction: 'ISSUETYPES/MOVE',
        removeAction: 'ISSUETYPES/REMOVE',
      }),
    },

    byAttribute: {
      request({ payload = {} }) {
        return api
          .get(`/v2/attributes/${(payload as any).id}/issuetypes/`, {
            search: {
              limit: 9000,
            },
          })
          .then();
      },

      ...treeScope({}),

      normalizer(response) {
        return {
          data:
            response &&
            response.map((i) => ({
              id: i.id,
              children: i.children,
            })),

          index: keyBy(response, (i) => i.id),
        };
      },
    },

    byInventory: {
      type: 'item',

      request({ payload }) {
        return api.get(`/v2/inventory/items/${payload}/actionissuetype/`);
      },
    },

    theoremaPass() {
      return api.get('/v2/issuetypes/', {
        search: {
          include_children: true,
          role: 'teorema_pass',
        },
      });
    },

    byLocation: {
      request({ payload }) {
        return api
          .get(`/v2/objects/${payload.id}/issuetypes/`, {
            search: {
              include_children: true,
              short: true,
              activity_id: payload.activity_id,
            },
          })
          .then((json) => ({
            ...json,
            response: {
              id: `${payload.id}_${payload.activity_id}`,
              children: json.response,
            },
          }));
      },

      ...treeScope({}),
    },
  },

  byId({ payload }) {
    return api.get(`/v2/issuetypes/${payload}/`);
  },

  mutators: {
    create: {
      request: ({ payload }) => {
        const { parentId, id, ...body } = payload;
        return api.post(`/v2/issuetypes/${parentId || ''}`, { body });
      },
      onSuccess: closeOnSuccess,
    },

    move({ payload }) {
      const { id, parentId, beforeId } = payload;
      return api.put(`/v2/issuetypes/${id}`, {
        body: {
          parent_id: parentId === -1 ? null : parentId,
          before_id: beforeId,
        },
      });
    },

    remove({ payload }) {
      return api.delete(`/v2/issuetypes/${payload.id}`);
    },

    update: {
      request: ({ payload }) => {
        const { id, ...body } = payload;
        return api.put(`/v2/issuetypes/${id}/`, { body });
      },
      onSuccess: closeOnSuccess,
    },

    uploadRoundGrounds({ payload }) {
      const { formData } = payload;

      return api.post('/v3/round_groups/upload/', {
        body: formData,
        headers: {
          'Content-Type': null,
        },
      });
    },
  },
});
