import './AppVersionChecker.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { Button } from 'lib/ui';

const { block } = bem('AppVersionChecker');

type State = any;

export default class AppVersionChecker extends PureComponent<Record<string, never>, State> {
  loadedBuildDate: any;

  state = {
    isAppOutdated: false,
  };

  componentDidMount() {
    getActualBuildDate().then((buildDate) => {
      this.loadedBuildDate = buildDate;

      this.onAppOutdated(() => {
        this.setState({
          isAppOutdated: true,
        });

        document.querySelector('.app')?.classList?.add('_withUpdateBar');
      });
    });
  }

  onAppOutdated(callback) {
    window.addEventListener('focus', () => {
      getActualBuildDate().then((buildDate) => {
        if (this.loadedBuildDate !== buildDate) {
          callback(buildDate);
        }
      });
    });
  }

  render() {
    const { isAppOutdated } = this.state;

    if (!isAppOutdated) return null;

    return (
      <div {...block()}>
        {t('common.notifications.new_version')}
        <Button small ghost onClick={this.handleUpdateClick}>
          {t('common.update')}
        </Button>
      </div>
    );
  }

  handleUpdateClick() {
    window.location.reload();
  }
}

function getActualBuildDate() {
  return fetch(`/version.json?timestamp=${Date.now()}`)
    .then((response) => response.json())
    .then((buildData) => buildData.version.buildDate);
}
