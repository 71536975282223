import { HTMLAttributes, PropsWithChildren } from 'react';
import { bem } from 'lib/bem';

import './list-item.scss';

const { block } = bem('ListItem');

export const ListItem = (props: PropsWithChildren<HTMLAttributes<HTMLLIElement>>) => {
  return <li className={block().className} {...props} />;
};
