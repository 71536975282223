import { bem } from 'lib/bem';
import { GlyphName, Icon } from 'lib/ui/icon';

import './EmptyFeed.scss';

const { block, element } = bem('EmptyFeed');

interface EmptyFeedProps {
  placeholder: string;
  icon: GlyphName;
  className?: string;
}

export const EmptyFeed = ({ placeholder, icon, className = '' }: EmptyFeedProps) => {
  return (
    <div {...block({}, className)}>
      <div>
        <Icon glyph={icon} />
      </div>
      <div {...element('text')}>{placeholder}</div>
    </div>
  );
};
