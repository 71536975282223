import { lazy } from 'react';

export const IncomingIssuesLazy = lazy(
  () => import(/* webpackChunkName: "IncomingIssues" */ 'app/issues/_pages/IncomingIssues'),
);
export const IssuesInProgressLazy = lazy(
  () => import(/* webpackChunkName: "IssuesInProgress" */ 'app/issues/_pages/IssuesInProgress'),
);
export const IssuesOnCheckLazy = lazy(
  () => import(/* webpackChunkName: "IssuesOnCheck" */ 'app/issues/_pages/IssuesOnCheck'),
);
export const ClosedIssuesLazy = lazy(
  () => import(/* webpackChunkName: "ClosedIssues" */ 'app/issues/_pages/ClosedIssues'),
);
export const OfficeIssuesLazy = lazy(
  () => import(/* webpackChunkName: "OfficeIssues" */ 'app/issues/_pages/OfficeIssues'),
);
export const AccidentsLazy = lazy(
  () => import(/* webpackChunkName: "Accidents" */ 'app/issues/_pages/Accidents'),
);
export const NewIssueLazy = lazy(
  () => import(/* webpackChunkName: "NewIssue" */ 'app/issues/_pages/NewIssue'),
);
export const NewQRIssueLazy = lazy(
  () => import(/* webpackChunkName: "NewQRIssue" */ 'app/issues/_pages/NewQRIssue'),
);
export const NewOunceIssueLazy = lazy(
  () => import(/* webpackChunkName: "NewOunceIssue" */ 'app/issues/_pages/NewOunceIssue'),
);
export const IssueDetailsLazy = lazy(
  () => import(/* webpackChunkName: "IssueDetails" */ 'app/issues/IssueDetails'),
);

export const IssuesCreatedByMeLazy = lazy(() =>
  import(/* webpackChunkName: "IssuesCreatedByMe" */ 'app/issues/_pages/CreatedByMe').then(
    ({ IssuesCreatedByMe }) => ({
      default: IssuesCreatedByMe,
    }),
  ),
);
