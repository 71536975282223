import './Sandbox.scss';
import { Component } from 'react';
import { reconnect } from 'lib/resource';

export default reconnect((state) => ({
}))(class Sandbox extends Component {
  render() {
    return (
      <div className="Sandbox" />
    );
  }
});
