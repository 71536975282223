import { Paper } from '@mui/material';
import { Table } from 'lib/ui';

const NewDesignTable = ({
  data,
  columns,
  onRowClick = undefined,
  fixedHeaderAtTableTop = false,
  fixedLeftColumn = false,
  fixedRightColumn = false,
}) => {
  return (
    <Paper
      sx={{
        borderRadius: '8px',
        padding: '0',
        border: '1px solid rgba(231, 238, 255, 0.72)',
        boxShadow: '0px 1px 12px -2px rgba(231, 238, 255, 0.64)',
        margin: '1rem 0',
        width: '100%',
        height: 'calc(100vh - 200px)',
        overflow: 'auto',
      }}
    >
      <Table
        data={data}
        columns={columns}
        onRowClick={onRowClick}
        fixedHeaderAtTableTop={fixedHeaderAtTableTop}
        sx={{
          maxWidth: 300,
          border: 'none !important',
          '& .MuiTableCell-head': {
            borderRight: 'none',
            borderBottom: '1px solid rgba(231, 238, 255, 0.72) !important',
            color: '#667790',
            padding: '14px 24px',
            textAlign: 'center',

            '&:first-child': fixedLeftColumn
              ? {
                  position: 'sticky',
                  left: 0,
                  background: 'white',
                  zIndex: 1,
                  textAlign: 'left',
                }
              : {},

            '&:last-child': fixedRightColumn
              ? {
                  position: 'sticky',
                  right: 0,
                  background: 'white',
                  zIndex: 1,
                }
              : {},
          },
          '& .MuiTableCell-body': {
            color: '#112950',
            padding: '14px 24px',
            fontSize: '14px',
            lineHeight: '24px',
            textAlign: 'center',

            '&:first-child': fixedLeftColumn
              ? {
                  position: 'sticky',
                  left: 0,
                  background: 'white',
                  textAlign: 'left',
                }
              : {},
            '&:last-child': fixedRightColumn
              ? {
                  position: 'sticky',
                  right: 0,
                  background: 'white',
                  zIndex: 1,
                }
              : {},
          },
          '& .MuiTableRow-root': {
            '&:last-child td, &:last-child th': { border: 0 },
            '&:hover': onRowClick
              ? {
                  backgroundColor: '#fff !important',
                  cursor: 'pointer',
                }
              : {},
          },
        }}
      />
    </Paper>
  );
};

export default NewDesignTable;
