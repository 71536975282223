import { memo, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import DaysCarouselFilter from 'lib/ui/FormBuilder/types/DaysCarouselFilter';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

export interface TabPanelContent {
  content: ReactNode;
  label: string;
}

interface Props {
  tabPanels: TabPanelContent[];
  value: number;
  onChange: (e, value) => void;
  onDateChange?: (value: string) => void;
}

const TabsContainer = ({ tabPanels, value, onChange, onDateChange }: Props) => {
  const location = useLocation();

  const isPageWithFilter = useMemo(() => {
    const [, , page] = location.pathname.split('/');
    return page === 'book';
  }, [location.pathname]);

  return (
    <>
      <Tabs
        value={value}
        onChange={onChange}
        sx={{
          mb: 2,
          '& .Mui-selected': {
            backgroundColor: '#FFF',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          },
          '& .MuiTab-root': {
            fontFamily: 'inherit',
            textTransform: 'none',
          },
        }}
      >
        {tabPanels.map((tabPanelInfo, index) => {
          return <Tab key={tabPanelInfo.label} label={tabPanelInfo.label} {...a11yProps(index)} />;
        })}
      </Tabs>
      {isPageWithFilter && <DaysCarouselFilter handleDaySelection={onDateChange} />}
      {tabPanels.map((tabPanelInfo, index) => {
        return (
          <TabPanel key={tabPanelInfo.label} value={value} index={index}>
            {tabPanelInfo.content}
          </TabPanel>
        );
      })}
    </>
  );
};

export default memo(TabsContainer);
