import { t } from 'lib/i18n';
import { Checkbox } from 'lib/ui';
import { fakeNull } from 'config/constants';

export function YesNo({ input }) {
  const getProps = (value) => ({
    checked: input.value === value,
    // Приходится использовать fakeNull вместо null из-за бага в redux-form,
    // при котором изменение значения поля с false на null не приводит к ререндерингу поля.
    // Позже при отправке формы поля со значением fakeNull удаляются.
    // Все это очень некрасиво, но пока красивого решения нет.
    onChange: (inputValue) => (input.onChange(inputValue ? value : fakeNull)),
  });

  return (
    <div className="YesNo">
      <Checkbox label={t('common.yes')} {...getProps(true)} />
      <Checkbox label={t('common.no')} {...getProps(false)} />
    </div>
  );
}
