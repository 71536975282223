import { checkRoles } from 'app/users/permissions';
import { Roles } from 'app/userRoles/roles';

const oneOf = (item, list) => list.some((i) => i === item);

const isResponsible = checkRoles(Roles.RESPONSIBLE);
const isManager = checkRoles(Roles.MANAGER);
const isExecutive = checkRoles(Roles.EXECUTIVE);

const hasSameManagementCompany = (user, issue) =>
  user.management_company.id === issue.management_company_id;

export default {
  overdue: ({ issue, user, listMode }) => {
    if (isManager(user)) return false;
    if (issue.current_status === 'closed') return false;
    if (listMode === 'report') return false;

    const isVisibleForUser =
      (isResponsible(user) || isExecutive(user)) && hasSameManagementCompany(user, issue);

    if (listMode === 'my') return isVisibleForUser;
    if (listMode === 'created_by_me') return isVisibleForUser;
    if (listMode === 'closed') return isVisibleForUser && issue.overdue;

    return true;
  },

  accident: ({ listMode, user }) =>
    (listMode !== 'my' || listMode !== 'created_by_me') && !isManager(user),

  author_mobile: ({ listMode, user }) =>
    checkRoles(Roles.PHONE_AGENT)(user) &&
    oneOf(listMode, ['incoming', 'in_progress', 'on_check', 'office', 'report', 'closed']),

  status: ({ listMode }) => oneOf(listMode, ['my', 'created_by_me']),

  rating: ({ issue, listMode }) =>
    oneOf(listMode, ['closed', 'my', 'report', 'created_by_me']) &&
    (!issue || issue.issue_type.metatype !== 'checklist'),

  close_date: ({ listMode }) => oneOf(listMode, ['closed', 'report']),

  close_date_expected: ({ issue, listMode, user }) =>
    oneOf(listMode, ['incoming', 'in_progress', 'on_check']),

  responsible: ({ listMode }) => oneOf(listMode, ['in_progress', 'on_check', 'accidents']),

  assigned_by: ({ listMode }) => listMode !== 'my',

  in_progress: () => true,
};
