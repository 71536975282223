export { default } from './QuickLogin';

export function pushMobile(value) {
  return {
    type: 'PUSH_CONFIRMATION_CODE_SUCCESS',
    data: value,
    name: 'confirmCode',
  };
}

export function confCode(value) {
  return {
    type: 'PUSH_MOBILE',
    data: value.data,
    name: 'pushCode',
  };
}

export function cancelMobile() {
  return {
    type: 'CANCEL_MOBILE',
    name: ['repeatCode', 'isCodeSent'],
  };
}

export function saveMobile(data) {
  return {
    type: 'SAVE_MOBILE',
    data,
    name: 'mobileNumber',
  };
}
