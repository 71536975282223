export type Breakpoints = Record<string, number>

function sortBreakpoints(breakpoints: Breakpoints): Array<string> {
  const keys: Array<string> = Object.keys(breakpoints);
  return keys.sort((a, b) => {
    return breakpoints[a] - breakpoints[b];
  });
}

export function calculateCurrentBreakpoint(
  breakpoints: Breakpoints,
  width: number,
): string {
  const sorted = sortBreakpoints(breakpoints);
  let matching = sorted[0];
  for (let i = 1, len = sorted.length; i < len; i++) {
    const breakpointName = sorted[i];
    if (width > breakpoints[breakpointName]) matching = breakpointName;
  }
  return matching;
}
