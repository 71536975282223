import { memo } from 'react';
import { t_prefixed } from 'lib/i18n';
import { DashboardWidget } from '../../BaseWidget';
import './IssuesTotalWidget.scss';

const tr = t_prefixed('analytical_reports.widgets.issues_total');

const { block, element } = bem('IssuesTotalWidget');

const IssuesTotalWidget = ({ data }) => {
  return (
    <DashboardWidget
      title={tr('title')}
      {...block()}
    >
      <div {...element('issues-number')}>
        {data}
      </div>
    </DashboardWidget>
  );
};

export default memo(IssuesTotalWidget);
