import { PureComponent } from 'react';
import scroll from 'scroll';
import keycode from 'keycode';

import { bem } from 'lib/bem';
import { Loader } from 'lib/ui';
import scrollThroughHOC from 'lib/ui/scrollThroughHOC';

import { ValueDisplayConfigProps } from './types';

const { element } = bem('SimpleSelect');

type SelectMenuProps = {
  options?: any;
  renderLabel?: () => void;
  onSelect?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  valueDisplayConfig: ValueDisplayConfigProps;
};

export default scrollThroughHOC({
  className: element('options').className,
  once: false,
  offset: 100,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(props: any) => any' is not assignable to ty... Remove this comment to see the full error message
  onScrollThrough: (props) => (props.onScrollThrough ? props.onScrollThrough() : () => {}),
})(
  class SelectMenu extends PureComponent<SelectMenuProps> {
    // static propTypes = {
    //   options: PropTypes.any,
    //   renderLabel: PropTypes.func,
    //   onSelect: PropTypes.func,
    //   onCancel: PropTypes.func,
    //   isLoading: PropTypes.bool,
    //   valueDisplayConfig: PropTypes.shape({
    //     isShowAvatar: PropTypes.bool,
    //     isShowIndicator: PropTypes.bool,
    //     isShowFavorite: PropTypes.bool,
    //   }),
    // };

    optionsElement: any;

    state = {
      focusedOptionIndex: 0,
    };

    componentDidMount() {
      window.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
      window.removeEventListener('keydown', this.handleKeyDown);
    }

    render() {
      const { options, onSelect, renderLabel, isLoading, valueDisplayConfig } = this.props;
      const { focusedOptionIndex } = this.state;

      return (
        // eslint-disable-next-line no-return-assign
        <div ref={(el) => (this.optionsElement = el)}>
          {options.map((option, index) => (
            <SelectOption
              key={(this.props as any).getItemValue(option)}
              option={option}
              isActive={focusedOptionIndex === index}
              renderLabel={renderLabel}
              checkOptionVisibility={this.checkOptionVisibility}
              onSelect={onSelect}
              onFocus={this.focusOption}
              valueDisplayConfig={valueDisplayConfig}
            />
          ))}

          {isLoading && (
            <div {...element('loader')}>
              <Loader />
            </div>
          )}
        </div>
      );
    }

    handleKeyDown = (event) => {
      const keymap = {
        down: () => this.navigate(+1),
        up: () => this.navigate(-1),
        enter: () =>
          (this.props as any).onSelect((this.props as any).options[this.state.focusedOptionIndex]),
        esc: () => (this.props as any).onCancel(),
      };

      const key = keycode(event);

      if (key in keymap) {
        event.preventDefault();
        keymap[key]();
      }
    };

    navigate(offset) {
      const { options } = this.props;

      this.setState((state) => {
        let focusedOptionIndex = (state as any).focusedOptionIndex + offset;

        if (focusedOptionIndex >= options.length) {
          focusedOptionIndex = 0;
        }

        if (focusedOptionIndex < 0) {
          focusedOptionIndex = options.length - 1;
        }

        return { focusedOptionIndex };
      });
    }

    focusOption = (option) => {
      this.setState({
        focusedOptionIndex: (this.props as any).options.indexOf(option),
      });
    };

    checkOptionVisibility = (option, optionRect) => {
      setTimeout(() => {
        if (!this.optionsElement) return;

        const containerRect = this.optionsElement.getBoundingClientRect();
        const bottomOffset = containerRect.bottom - optionRect.bottom;
        const topOffset = optionRect.top - containerRect.top;

        if (bottomOffset <= 0) {
          scroll.top(this.optionsElement, this.optionsElement.scrollTop - bottomOffset, {
            duration: 100,
          });
        }

        if (topOffset <= 0) {
          scroll.top(this.optionsElement, this.optionsElement.scrollTop + topOffset, {
            duration: 100,
          });
        }
      });
    };
  },
);

type Props = {
  option?: any;
  isActive?: boolean;
  onSelect?: (...args: any[]) => any;
  onFocus?: (...args: any[]) => any;
  renderLabel?: (...args: any[]) => any;
  valueDisplayConfig?: ValueDisplayConfigProps;
  checkOptionVisibility?: (...args: any[]) => any;
};

class SelectOption extends PureComponent<Props> {
  element: any;

  componentDidUpdate() {
    if (this.props.isActive) {
      (this.props as any).checkOptionVisibility(
        this.props.option,
        this.element.getBoundingClientRect(),
      );
    }
  }

  render() {
    const { option, isActive, onSelect, onFocus, renderLabel, valueDisplayConfig } = this.props;

    return (
      <div
        {...element('option', { isActive })}
        // eslint-disable-next-line no-return-assign
        ref={(_element) => (this.element = _element)}
        onClick={() => onSelect?.(option)}
        onMouseMove={() => !isActive && onFocus?.(option)}
      >
        {renderLabel?.(option, valueDisplayConfig)}
      </div>
    );
  }
}
