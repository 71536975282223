export default {
  selector(data, index) {
    return data;
  },

  normalizer(response) {
    return {
      data: response,
    };
  },
};
