import './IssueLocation.scss';
import LocationFullPath from 'app/locations/LocationFullPath';
import { bem } from 'lib/bem';

const { block } = bem('IssueLocation');

export default function IssueLocation({
  issue, className, short = false, ...props 
}) {
  if (!issue.location_id) { return null; }

  if (short) {
    return (
      <div {...block({}, className)} {...props}>
        {issue.location.name}
      </div>
    );
  }

  return (
    <div {...block({}, className)} {...props}>
      <LocationFullPath location={issue.location} />
    </div>
  );
}
