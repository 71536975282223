import { Page } from 'lib/ui/index';
import { bem } from 'lib/bem';

import './TenantDocsPage.scss';

const { block, element } = bem('TenantDocsPage');

const docsList = [
  {
    title: 'Заявка на парковочные места',
    link: 'https://storage.teorema.info/parking_request_blank.xls',
  },
  {
    title: 'Бланк назначения ответственного за пожарную безопасность',
    link: 'https://storage.teorema.info/order_arendator_2023_blank.doc',
  },
  {
    title: 'Инструкция для пользователей приложения',
    link: 'https://storage.teorema.info/teorema_app_instruction.pdf',
  },
  {
    title: 'Бланк ответственного за личный кабинет',
    link: 'https://storage.teorema.info/teorema_personal_acc_blank.doc',
  },
  {
    title: 'Бронирование переговорных комнат',
    link: 'https://api.sbcs.ru/static/docs/teorema_app_meeting_room.pdf',
  },
];

const TenantDocsPage = () => {
  return (
    <Page {...block()} title="Полезная информация">
      <ul>
        {docsList.map((item, index) => {
          return (
            <li key={index} {...element('list-item')}>
              <a {...element('link')} href={item.link} target="_blank" rel="noreferrer">
                {item.title}
              </a>
            </li>
          );
        })}
      </ul>
    </Page>
  );
};

export default TenantDocsPage;
