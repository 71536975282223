import { identity } from 'lodash';

import { t } from 'lib/i18n';
// eslint-disable-next-line import/no-cycle
import { ReactHookFormBuilder as FormBuilder } from 'shared/ui/form';
import { ActivityTypeEnum } from 'pages/activities-settings';

export const ActivityTypeSelect = ({ disableControl }) => {
  return (
    <FormBuilder.Select
      required
      title={t('activities.fields.type')}
      name="type"
      options={Object.values(ActivityTypeEnum)}
      getItemValue={identity}
      getItemLabel={(label: ActivityTypeEnum) => t(`activities.types.${label}`)}
      disabled={disableControl}
    />
  );
};
