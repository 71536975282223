/**
 * Условные атрибуты.
 */

import { isNil, pick } from 'lodash';
import processCondition from './processCondition';

/**
 * Фильтрует атрибуты в соответствии с условиями
 *
 * @param {Array} attrs Массив атрибутов из типа заявки
 * @param {Object} issue Объект заявки
 * @param {Object} values Значения атрибутов
 */
export function filterAttrsByConditions(attrs, issue, values = {}) {
  const check = processCondition(issue, values || {});

  return attrs
    .filter((attr) => {
      const condition = attr.available_if;
      if (isNil(condition)) return true;
      return check(condition);
    });
}

/**
 * Возвращает значения атрибутов в соответствии с условиями
 *
 * @param {Array} attrs Массив атрибутов из типа заявки
 * @param {Object} issue Объект заявки
 * @param {Object} values Значения атрибутов
 */
export function filterValuesByConditions(attrs, issue, values = {}) {
  const filteredAttrs = filterAttrsByConditions(attrs, issue, values)
    .map((attr) => attr.internal_name || attr.id);

  return pick(values, ...filteredAttrs);
}
