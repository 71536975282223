import { BookingStatus, DayBusyness, DayInfo } from '../types';

export const calculateBusynessForEachDay = (initialSlots: DayInfo[]) => {
  const getBusyness = (day: DayInfo) => {
    if (day.slots.length === 0) {
      return DayBusyness.HIGH;
    }

    const bookedSlotsNumber = day.slots.filter((x) => x.status === BookingStatus.BOOKED).length;
    const ratio = bookedSlotsNumber / day.slots.length;

    if (ratio <= 0.33) {
      return DayBusyness.LOW;
    }
    if (ratio >= 0.67) {
      return DayBusyness.HIGH;
    }
    return DayBusyness.AVERAGE;
  };
  
  const result: Record<string, DayBusyness> = {};
  return initialSlots.reduce((acc, day) => {
    return { ...acc, [day.date]: getBusyness(day) };
  }, result);
};
