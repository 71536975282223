import { useTranslation } from 'react-i18next';

import { bem } from 'lib/bem';

import './EmptyBlock.scss';

const { block } = bem('EmptyBlock');
interface Props {
  textKey: string;
}
export const EmptyBlock = ({ textKey }: Props) => {
  const { t } = useTranslation();
  // TODO: #i18n Add translation key type check
  return <div {...block()}>{t(textKey, textKey)}</div>;
};
