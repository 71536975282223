import './MenuLoginButton.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { ModalLink } from 'lib/ui';
import { Icon } from 'lib/ui/icon';

const { block, element } = bem('MenuLoginButton');

export default class MenuLoginButton extends PureComponent {
  render() {
    return (
      <ModalLink to="login" data="1" {...block()}>
        {t('login.title')}

        <div {...element('logout')}>
          <Icon glyph="logout" />
        </div>
      </ModalLink>
    );
  }
}
