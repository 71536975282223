import { useCallback, useEffect, useState } from 'react';
import { Box, useAutocomplete, Popper, UseAutocompleteProps } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { unstable_useForkRef as useForkRef } from '@mui/utils';

import { ScrollableVerticalList, ListItem } from 'shared/ui/scrollable-list';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';

import { SelectProps, SelectValue } from './types';
import { SelectedValue } from './ui/selected-value/SelectedValue';
import { SearchInput } from './ui/search-input/SearchInput';
import { Tag } from './ui/tag/Tag';
import './select.scss';
import { TextButton } from '../text-button';

const { element } = bem('Select');

export const Select = <T extends SelectValue | null, Multiple extends boolean = false>({
  multiple,
  options,
  placeholder,
  value: selectValue,
  onChange,
  isLoading,
  scrollBottom,
  filterOptions,
  className,
  title,
}: SelectProps<T, Multiple>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback<
    NonNullable<UseAutocompleteProps<T, Multiple, false, false>['onChange']>
  >(
    (_, _value: T[] | T | null) => {
      if (_value == null) {
        return (onChange as SelectProps<T | null>['onChange'])?.(null);
      }

      if (multiple) {
        return (onChange as SelectProps<T | null, true>['onChange'])?.(
          Array.isArray(_value)
            ? _value.map((i) => (typeof i !== 'object' ? i : (i?.id as T)))
            : [_value],
        );
      }

      return (onChange as SelectProps<T | null, false>['onChange'])?.(
        Array.isArray(_value) ? _value.at(0) ?? null : _value,
      );
    },
    [multiple, onChange],
  );

  const handleClear = useCallback(() => {
    (onChange as SelectProps<T | null, true>['onChange'])?.([]);
  }, [onChange]);

  const {
    anchorEl,
    setAnchorEl,
    getInputProps,
    value,
    groupedOptions,
    getListboxProps,
    getOptionProps,
    getTagProps,
    getRootProps,
  } = useAutocomplete({
    options,
    multiple,
    disableCloseOnSelect: true,
    filterSelectedOptions: true,
    autoComplete: false,
    autoHighlight: false,
    open: isOpen,
    onClose: () => {
      setIsOpen(false);
    },
    inputValue,
    value: selectValue,
    onChange: handleChange,
    filterOptions,
    // getOptionLabel,
  });

  const rootRef = useForkRef(setAnchorEl);

  const inputProps = getInputProps();

  useEffect(() => {
    if (isOpen && inputProps.ref && typeof inputProps.ref === 'object') {
      inputProps.ref.current?.focus();
    }
  }, [isOpen, inputProps.ref]);

  useEffect(() => {
    setInputValue('');
  }, [value, isOpen]);

  return (
    <Box {...getRootProps()} ref={rootRef} className={className}>
      {/* Возможно другое решение верстки в будущей */}
      <Box {...element('header')}>
        <p className="FormField__label">{title}</p>
        {multiple && Array.isArray(value) && value.length !== 0 && (
          <div {...element('clear')}>
            <TextButton onClick={handleClear} color="primary" isUnderline>
              {t('common.clear')}
            </TextButton>
          </div>
        )}
      </Box>
      <SelectedValue
        placeholder={placeholder}
        value={value}
        onClick={() => setIsOpen((prevState) => !prevState)}
        getTagProps={getTagProps}
        isOpen={isOpen}
        TagComponent={Tag}
        showCaret
      />
      {/* useAutocomplete требует какой-нибудь input при рендере */}
      {!anchorEl && <input {...inputProps} hidden />}
      {anchorEl && (
        <Popper
          open={isOpen}
          keepMounted
          anchorEl={anchorEl}
          style={{
            width: anchorEl?.clientWidth,
            zIndex: 2000,
          }}
        >
          <Box className={element('dropdown-container').className}>
            <SearchInput
              {...inputProps}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.currentTarget.value);
              }}
              onBlur={(e) => {
                inputProps.onBlur?.(e);
                setIsOpen(false);
              }}
              // inputRef={inputProps.ref}
            />
            {groupedOptions.length > 0 && (
              <ScrollableVerticalList
                {...getListboxProps()}
                isLoading={isLoading}
                onScrollBootom={!inputValue ? scrollBottom?.onScrollBottom : undefined}
                bottomOffset={scrollBottom?.bottomOffset}
              >
                {groupedOptions.map((option, index) => (
                  <ListItem key={option.id ?? option} {...getOptionProps({ option, index })}>
                    {option.label ?? option}
                  </ListItem>
                ))}
              </ScrollableVerticalList>
            )}
          </Box>
        </Popper>
      )}
    </Box>
  );
};
