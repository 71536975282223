import { MutableRefObject, ReactNode } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { bem } from 'lib/bem';
import { Icon, GlyphName } from 'lib/ui/icon';

import './Search.scss';

const { block } = bem('Search');

interface Props {
  autoFocus?: boolean;
  value?: string;
  onChange: (e) => void;
  inputRef?: MutableRefObject<any> | ((input) => void);
  className?: string;
  invisibleFocus?: boolean;
  maxLength?: number;
  iconGlyph?: GlyphName;
  inputClassName?: string;
  adornment?: ReactNode;
  startSearchIcon?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '0 !important',
    },
  },
}));

export default function Search({
  autoFocus,
  value,
  onChange,
  inputRef,
  className,
  invisibleFocus,
  iconGlyph = 'search',
  maxLength,
  inputClassName,
  adornment,
  startSearchIcon,
}: Props) {
  const { root } = useStyles();

  const DefaultAdornment = (
    <InputAdornment position="end">
      <Icon glyph={iconGlyph} />
    </InputAdornment>
  );

  const defaultInputProps = { className: inputClassName, autoComplete: 'off' };

  return (
    <div {...block({ searchGlyph: iconGlyph === 'search' }, className)}>
      <TextField
        inputRef={inputRef}
        type="text"
        fullWidth
        autoFocus={autoFocus}
        value={value}
        onChange={onChange}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <>
              <input
                type="text"
                name="block-autocomplete"
                autoComplete="text"
                style={{
                  opacity: 0.0,
                  width: '1px',
                  height: '1px',
                  position: 'absolute',
                }}
                tabIndex={-1}
              />
              {adornment || (!startSearchIcon && DefaultAdornment)}
            </>
          ),
          startAdornment: startSearchIcon && <Icon glyph="search" />,
          classes: invisibleFocus ? { root } : {},
        }}
        inputProps={maxLength ? { ...defaultInputProps, maxLength } : defaultInputProps}
      />
    </div>
  );
}
