import './location-select.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { Checkbox, TreeViewSelect } from 'lib/ui';
import { keyBy } from 'lodash';

const { block } = bem('location-select');

type State = any;

export class LocationSelect extends PureComponent<{}, State> {
  myLocations: any;

  state = {
    onlyMyLocations: false,
  };

  componentDidMount() {
    this.createMyLocationsIndex((this.props as any).myLocations);

    if ((this.props as any).forceOnlyManagerLocations) {
      this.setState({
        onlyMyLocations: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.createMyLocationsIndex(nextProps.myLocations);
  }

  createMyLocationsIndex(locations) {
    this.myLocations = keyBy(locations || [], (i) => i);
  }

  render() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'locations' does not exist on type 'Reado... Remove this comment to see the full error message
      locations, value, onChange, myLocations, forceOnlyManagerLocations,
    } = this.props;
    const { onlyMyLocations } = this.state;

    if (!locations || !locations.children) {
      return null;
    }

    return (
      <div {...block({
        withMy: myLocations && myLocations.length,
        onlyMy: forceOnlyManagerLocations,
      })}
      >
        <TreeViewSelect
          tree={locations}
          value={value}
          onChange={onChange}
          renderFilter={this.renderFilter}
          nodesFilter={onlyMyLocations ? this.getNodesFilter : null}
        />
      </div>
    );
  }

  renderFilter = () => {
    if (!(this.props as any).myLocations || (this.props as any).myLocations.length === 0) {
      return null;
    }

    return (
      <Checkbox
        label={t('issues.forms.new_issue.only_my_locations')}
        checked={this.state.onlyMyLocations}
        onChange={this.handleFilterChange}
      />
    );
  };

  handleFilterChange = (value) => {
    this.setState((state) => ({
      onlyMyLocations: value,
    }));
  };

  getNodesFilter = (node) => this.myLocations[node.id];
}
