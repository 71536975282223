import { combineReducers } from 'redux';
import { omit } from 'lodash';

// VENDOR REDUCERS
import { reducer as formReducer } from 'redux-form';

// APP REDUCERS
import { notificationsReducer } from 'app/notifications';
import { reportsReducer } from 'app/reports';
// import { checkinsReducers } from 'app/checkins';
import { listReducer } from 'lib/redux-utils';
import { substitutionsReducer } from 'app/substitutions';
import { companiesReducer } from 'app/companies';

// RESOURCES
import { activities } from 'app/activities';
import { locationTypes } from 'app/locationTypes';
import { issues } from 'app/issues';
import { checkins } from 'app/checkins';
import { locations } from 'app/locations';
import { issueTypes } from 'app/issueTypes';
import { managedObjects } from 'app/managedObjects';
import { bookings } from 'app/bookings';
import users from 'app/users/users.resource';
import employees from 'app/users/employees.resource';
import { metaflows } from 'app/metaflows';
import { events } from 'app/events';
import { meters } from 'app/meters';
import dicts from 'app/dicts.resource';
import attachmentsResource from 'app/attachments.resource';
import { issueSchedules } from 'app/issueSchedules';
import inventories from 'app/inventories/inventories.resource';
import attributes from 'app/attributes/attributes.resource';
import userRoles from 'app/userRoles/userRoles.resource';
import announcements from 'app/announcements/announcements.resource';
import { sms } from 'app/sms';
import { fieldsResources } from './fieldsComparison';
import { dashboardsResource } from './analyticalReports';
import session from './session.reducer';
import { bookingItemTypes } from './bookingItemTypes';
import { schedules } from './schedules';
import { roundReports } from './roundsReports';

export const appReducer = combineReducers({
  session,

  form: formReducer,
  issues: issues.reducer,
  reports: reportsReducer,
  checkins: checkins.reducer,
  users: users.reducer,
  employees: employees.reducer,
  issueTypes: issueTypes.reducer,
  notifications: notificationsReducer,
  locations: locations.reducer,
  metaflows: metaflows.reducer,
  substitutions: substitutionsReducer,
  companies: companiesReducer,
  activities: activities.reducer,
  locationTypes: locationTypes.reducer,
  dicts: dicts.reducer,
  events: events.reducer,
  meters: meters.reducer,
  attachments: attachmentsResource.reducer,
  issueSchedules: issueSchedules.reducer,
  inventories: inventories.reducer,
  attributes: attributes.reducer,
  userRoles: userRoles.reducer,
  announcements: announcements.reducer,
  managedObjects: managedObjects.reducer,
  bookings: bookings.reducer,
  bookingItemTypes: bookingItemTypes.reducer,
  schedules: schedules.reducer,
  sms: sms.reducer,
  roundReports: roundReports.reducer,
  dashboards: dashboardsResource.reducer,
  footballFields: fieldsResources.reducer,

  menu(state = { expanded: false, hidden: false }, action) {
    switch (action.type) {
      case 'EXPAND_MENU':
        return { expanded: true };
      case 'COLLAPSE_MENU':
        return { expanded: false };
      case 'HIDE_MENU':
        return { hidden: true };
      case 'SHOW_MENU':
        return { hidden: false };
      default:
        return state;
    }
  },

  files: listReducer('GET_FILES'),

  login(state = {}, action) {
    switch (action.type) {
      case 'PUSH_CONFIRMATION_CODE_SUCCESS':
        return {
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...state,
          [action.name]: action.data,
        };
      case 'PUSH_MOBILE':
        return {
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...state,
          [action.name]: action.data,
          isCodeSent: true,
        };
      case 'REPEAT_REQUEST':
        return {
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...state,
          [action.name]: action.data.next_try,
          isCodeSent: true,
        };
      case 'CANCEL_MOBILE':
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return omit(state, action.name);

      case 'REQUEST_ERROR':
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return omit(state, action.name);

      case 'SAVE_MOBILE':
        return {
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...state,
          [action.name]: action.data,
        };

      default:
        return state;
    }
  },

  modal(state: Record<string, any> = {}, action) {
    const openModalState = {
      ...state,
      [action.name]: action.data,
    };
    switch (action.type) {
      case 'OPEN_MODAL':
        if (action.extraData) {
          openModalState[`${action.name}ExtraData`] = action.extraData;
        }
        return openModalState;
      case 'CLOSE_MODAL':
        return omit(state, [action.name, `${action.name}ExtraData`]);
      case 'SET_EXTRA_DATA':
        return {
          [`${action.name}ExtraData`]: action.extraData,
        };
      default:
        return state;
    }
  },

  tabChecker(state = {}, action) {
    switch (action.type) {
      case 'TAB_INDEX':
        return {
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...state,
          [action.name]: action.value,
        };
      default:
        return state;
    }
  },

  bookingDate(state = '', action) {
    switch (action.type) {
      case 'PUT_BOOKING_DATE': {
        return action.value;
      }
      default:
        return state;
    }
  },

  avaUrl(state = '', action) {
    switch (action.type) {
      case 'PUT_AVATAR_URL': {
        return action.value;
      }
      default:
        return state;
    }
  },

  loading(state = 0, action) {
    let result = state;

    if (action.type === 'STOP_LOADING') {
      return 0;
    }

    if (action.type.match(/_START$/)) {
      // @ts-expect-error ts-migrate(2365) FIXME: Operator '+' cannot be applied to types 'unknown' ... Remove this comment to see the full error message
      result = state + 1;
    } else if (action.type.match(/_SUCCESS$/)) {
      // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      result = state - 1;
    } else if (action.type.match(/_FAIL$/)) {
      // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      result = state - 1;
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    return Math.max(0, result);
  },

  qrcodeScanner(state = '', action) {
    switch (action.type) {
      case 'PUT_QRCODE_INFO': {
        return action.value;
      }
      default:
        return state;
    }
  },
});

export default function rootReducer(state, action) {
  if (action.type === 'LOGIN_SUCCESS') {
    state = undefined;
  }

  return appReducer(state, action);
}
