import { useCallback, useEffect, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import SparkMD5 from 'spark-md5';
import { uniqBy } from 'lodash';

import { t } from 'lib/i18n';
import { ReduxModal, Button, Loader } from 'lib/ui';
import { Icon } from 'lib/ui/icon';
import { bem } from 'lib/bem';
import promiseSeq from 'lib/promiseSeq';
import resize from 'app/photos/services/resizeService';
import uploadPhoto from 'app/photos/services/uploadService';
import attachmentsResource from 'app/attachments.resource';
import { notify } from 'app/notifications';

import './FileUploader.scss';

const { block, element } = bem('FileUploader');
const MAX_DIMENSION_SIZE = 1024;
const MAX_FILE_SIZE_AT_MB = 10;
const MAX_FILE_SIZE = MAX_FILE_SIZE_AT_MB * 1024 ** 2;

const md5 = (buffer) => SparkMD5.ArrayBuffer.hash(buffer);

export default ReduxModal({
  name: 'fileUploader',
  className: 'fileUploader',
  getTitle: (data, props) => props.getLabel('title'),
  routing: false,
})(({
  maxCount = Infinity,
  withResizing = true,
  // TODO: #i18n Add translation key type check
  getLabel = (key, options = {}) => t(`file_uploader.${key}`, { defaultValue: key, ...options }),
  ...props
}) => {
  // TODO: убрать вот это https://medium.com/doctolib/react-stop-checking-if-your-component-is-mounted-3bb2568a4934
  const [_isMounted, setIsMounted] = useState<boolean>();
  const [files, setFiles] = useState<any[]>([]);

  const {
    onClose,
    disableGallery,
    acceptFiles,
    acceptMimeTypes,
    acceptFilesType,
    minCount,
    renderFilePreview,
    dispatch,
  } = props;

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (
      files.length &&
      files.length === files.filter((file) => file.processingStatus === 'ready').length
    ) {
      const processingsUpload = files.map((f) => () => {
        return (attachmentsResource as any).save.request({
          id: f.file.id,
          md5: f.resized ? f.resized.md5 : f.md5,
          type: f.type,
          user_file_name: f.name,
        });
      });

      promiseSeq(processingsUpload).then(() => {
        const dataFiles = files.map(({ file }) => file);
        onClose(dataFiles);
      });
    }
  }, [files, onClose]);

  const ACCEPT_PATTERN = useMemo(
    () =>
      acceptFiles
        ? new RegExp(acceptMimeTypes.map((el) => `${acceptFilesType}/${el}`).join('|'))
        : '',
    [acceptFiles, acceptFilesType, acceptMimeTypes],
  );

  const getProcessingProgress = () => {
    const total = files.length;
    const ready = files.filter((file) => file.processingStatus === 'ready').length;
    const processing = files.filter((file) => file.processingStatus === 'processing').length;

    return {
      total,
      ready,
      processing,
      progress: total > 0 ? ready / total : 1,
    };
  };

  const handleFilesSelect = useCallback(
    (_files, e) => {
      [..._files].forEach((file) => {
        if (!file.type.match(ACCEPT_PATTERN)) return;

        const reader = new FileReader();

        reader.onload = (_e) => {
          const imageBuffer = _e.target?.result;
          const imageBase64Url = URL.createObjectURL(
            new Blob(imageBuffer ? [imageBuffer] : undefined),
          );

          setFiles((__files) =>
            uniqBy(
              [
                ...__files,
                {
                  buffer: imageBuffer,
                  url: imageBase64Url,
                  md5: md5(imageBuffer),
                  name: file.name,
                  type: file.type,
                  processingStatus: 'initial',
                  minified: false,
                },
              ],
              'md5',
            ),
          );
        };
        reader.readAsArrayBuffer(file);
      });

      if (e && e.target) {
        e.target.value = '';
      }
    },
    [ACCEPT_PATTERN],
  );

  const setAllFilesProcessing = () => {
    setFiles((_files) =>
      _files.map((file) => ({
        ...file,
        processingStatus: 'processing',
      })),
    );
  };

  const removeFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  const updateFileByIndex = useCallback((indexToUpdate, fields = {}) => {
    setFiles((_files) =>
      _files.map((file, index) => {
        if (index === indexToUpdate) {
          return { ...file, ...fields };
        }
        return file;
      }),
    );
  }, []);

  const setFileResizedBuffer = useCallback(
    (index, resizedBuffer) => {
      const file = {
        ...files[index],

        resized: {
          buffer: resizedBuffer,
          md5: md5(resizedBuffer),
        },
      };

      updateFileByIndex(index, file);
      return file;
    },
    [files, updateFileByIndex],
  );

  const _isProcessing = () => {
    return getProcessingProgress().processing > 0;
  };

  const isMaxLimitReached = () => {
    return files.length >= maxCount;
  };

  const isValid = () => {
    if (!files.length) return false;
    if (files.length > maxCount) return false;
    if (files.length < minCount) return false;

    return true;
  };

  const processFile = useCallback(
    (file, index) => {
      return new Promise((resolve) => {
        if (withResizing) {
          resize(file.url, MAX_DIMENSION_SIZE).then((resultBuffer) => {
            resolve(setFileResizedBuffer(index, resultBuffer));
          });
        } else {
          resolve(file);
        }
      })
        .then((_file) => uploadPhoto(_file, () => _isMounted))
        .then(({ response }) => {
          updateFileByIndex(index, {
            ...file,
            file: response,
            processingStatus: 'ready',
          });
        });
    },
    [_isMounted, setFileResizedBuffer, updateFileByIndex, withResizing],
  );

  const handleSave = useCallback(() => {
    setAllFilesProcessing();

    const processings = files.map((file, index) => () => {
      if (!_isMounted) return Promise.resolve(null);
      return processFile(file, index);
    });

    return promiseSeq(processings);
  }, [_isMounted, files, processFile]);

  const renderModalButtons = () => {
    const progress = getProcessingProgress();
    const isProcessing = progress.processing > 0;

    return (
      <div className="Modal__buttons">
        <Button ghost onClick={(e) => onClose()}>
          {t('common.cancel')}
        </Button>

        <Button
          {...element('save', { isProcessing })}
          onClick={handleSave}
          disabled={!isValid() || isProcessing}
        >
          {isProcessing ? (
            <div>
              <Loader />
              {`${progress.ready} / ${progress.total}`}
            </div>
          ) : (
            t('common.save')
          )}
        </Button>
      </div>
    );
  };

  const renderFiles = () => {
    if (files.length === 0) return null;
    return (
      <div {...element('files')}>
        {files.map((file) => renderFilePreview(file, () => removeFile(file)))}
      </div>
    );
  };

  const renderLimits = () => {
    const isLimitExceeded = files.length > maxCount;

    const limitsText = isLimitExceeded
      ? `${getLabel('limits_text_warning', { maxCount })}.`
      : `${getLabel('limits_text', {
          uploaded: files.length > 0 ? maxCount - files.length : maxCount,
          maxCount,
        })}.`;

    return (
      <div {...element('limits')}>
        <Icon
          width={16}
          height={16}
          {...element('icon')}
          glyph="warning_circle"
          sx={{
            fill: isLimitExceeded ? '#FF3B30' : '#FF8E25',
          }}
        />
        <p dangerouslySetInnerHTML={{ __html: limitsText }} />
      </div>
    );
  };

  const capture: any = disableGallery ? { accept: 'image/*', capture: 'environment' } : {};

  return (
    <div>
      <Dropzone
        {...block({ isProcessing: _isProcessing() })}
        onDrop={handleFilesSelect}
        accept={acceptFiles}
        maxSize={MAX_FILE_SIZE}
        onDropRejected={(fr) => {
          const isFileBig = fr.some((item) => item.errors[0].code === 'file-too-large');
          const message = getLabel(
            isFileBig ? 'size_error' : 'type_error',
            isFileBig ? { size: MAX_FILE_SIZE_AT_MB } : {},
          );
          dispatch(
            notify({
              text: message,
              type: 'error',
              id: message,
            }),
          );
        }}
        noClick
      >
        {({ getRootProps, getInputProps, open }) => (
          <section {...block()}>
            <div {...getRootProps({ ...element('dropzone') })}>
              <input {...getInputProps()} {...capture} />
              <p {...element('text')}>
                <span onClick={open} {...element('textAction', { disabled: isMaxLimitReached() })}>
                  {getLabel(disableGallery ? '' : 'drop_placeholder_action')}
                </span>
                {getLabel(disableGallery ? 'make_photo_placeholder' : 'drop_placeholder')}
              </p>
              {!disableGallery && <p {...element('notification')}>{getLabel('allowed_types')}</p>}
              {!disableGallery && (
                <p {...element('notification')}>
                  {getLabel('max_file_size', { max: MAX_FILE_SIZE_AT_MB })}
                </p>
              )}
            </div>
            {renderFiles()}
            {renderLimits()}
          </section>
        )}
      </Dropzone>
      {renderModalButtons()}
    </div>
  );
});
