import { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

import { bem } from 'lib/bem';
import { Icon } from 'lib/ui/icon';
import brandedData from 'config/brandedData';

// eslint-disable-next-line import/no-cycle
import SideBanner from '../SideBanner';
import './EntryPage.scss';
import { Entry } from '../Entry';

const { block, element } = bem('EntryPage');

export const EntryPage = ({ className }: PropsWithChildren<{ className?: string }>) => {
  const footerLinks = t(`sidebanner.footer_links.${brandedData.poweredBy}`);

  const renderFooter = (
    <div {...element('footer')}>
      <div {...element('footerText')}>
        <Icon glyph={brandedData.poweredByIcon} />
        <Typography>{t(`sidebanner.footer.${brandedData.poweredBy}`)}</Typography>
      </div>
      <div>
        {footerLinks.map((item) => (
          <a {...element('subtext')} href={item.link} key={item.text}>
            {item.text}
          </a>
        ))}
      </div>
    </div>
  );

  return (
    <div {...block({}, className)}>
      <SideBanner footer={renderFooter} />
      <Entry footer={renderFooter} />
    </div>
  );
};
