import { call, delay, put } from 'redux-saga/effects';
import { takeMap } from 'lib/saga-utils';
import { NotificationsObserver, SHOW_DURATION_MS } from 'features/notify';

import notify from './notify';

const IGNORING_ERROR_CODES = {
  1: 'auth_required',
};

function hideNotification(message) {
  return {
    type: 'HIDE_NOTIFICATION',
    message,
  };
}

// TODO: Временное решениие для перехода с вызова нотификашек через dispatch на вызов через хук useNotifications
export function* notificationsSaga() {
  yield call(
    takeMap({
      *SHOW_NOTIFICATION({ message }) {
        yield call(() => {
          NotificationsObserver.addNotification(message);
        });

        if (message?.persistent) {
          return;
        }

        yield delay(SHOW_DURATION_MS);
        yield put(hideNotification(message));
      },

      *HIDE_NOTIFICATION({ message }) {
        yield call(() => {
          NotificationsObserver.removeNotification(message.id);
        });
      },

      *HTTP_ERROR({ error, code }) {
        if (code in IGNORING_ERROR_CODES) return;

        yield put(
          notify({
            id: `HTTP_ERROR_${code}`,
            text: error,
            type: 'error',
          }),
        );
      },
    }),
  );
}
