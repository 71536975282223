import { useCallback, useState } from 'react';

export const useImagesHandling = (): [string[], (e) => void] => {
  const [handledImages, setHandledImages] = useState<any[]>([]);

  const handleImgLoad = useCallback(
    (e) => {
      const newHandledImages = handledImages.slice();
      if (!newHandledImages.includes(e.target.src)) {
        newHandledImages.push(e.target.src);
      }
      setHandledImages(newHandledImages);
    },
    [handledImages],
  );

  return [handledImages, handleImgLoad];
};
