import { NotificationMessage, NotificationSubscriber } from './types';

export const SHOW_DURATION_MS = 5000;

export class NotificationsObserver {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    //
  }

  private static activeNotifications: Map<NotificationMessage['id'], NotificationMessage> =
    new Map();

  private static activeNotificationsTimers: Map<NotificationMessage['id'], number> = new Map();

  private static subscribers: Set<NotificationSubscriber> = new Set();

  private static notifySubscribers() {
    const notifications = Array.from(this.activeNotifications.values());
    this.subscribers.forEach((cb) => {
      cb(notifications);
    });
  }

  public static addNotification(message: NotificationMessage) {
    const { id, persistent } = message;
    this.activeNotifications.set(id, message);
    this.notifySubscribers();

    if (!persistent) {
      this.activeNotificationsTimers.set(
        id,
        window.setTimeout(() => {
          this.removeNotification(id);
        }, SHOW_DURATION_MS),
      );
    }
  }

  public static removeNotification(notificationId: NotificationMessage['id']) {
    this.activeNotificationsTimers.delete(notificationId);
    if (this.activeNotifications.delete(notificationId)) {
      this.notifySubscribers();
    }
  }

  public static subscribe(cb: NotificationSubscriber) {
    this.subscribers.add(cb);
    cb(Array.from(this.activeNotifications.values()));

    return () => {
      this.subscribers.delete(cb);
    };
  }
}
