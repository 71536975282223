import './Button.scss';
import { bem } from 'lib/bem';
import { Icon } from 'lib/ui/icon';
import { Button as ButtonMUI } from '@mui/material';

const { block, element } = bem('Button');

const defaultSx = { '&:hover': { bgcolor: 'primary.main' } };

export default function Button({
  children,
  ghost,
  small,
  invisible,
  icon,
  noTopMargin,
  hrefColor,
  withoutHover = false,
  sx = {},
  ...props
}: any) {
  return (
    <ButtonMUI
      {...props}
      sx={{
        ...((invisible || ghost) && { color: ({ palette }) => `${palette.primary.main}` }),
        ...sx,
      }}
      {...block(
        {
          small,
          invisible,
          hrefColor,
          withoutHover,
          ghost,
        },
        props.className,
      )}
      variant={ghost ? 'outlined' : 'contained'}
      startIcon={icon && <Icon glyph={icon} {...element('icon')} />}
    >
      {children}
    </ButtonMUI>
  );
}
