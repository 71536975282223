import './VerifyLocation.scss';
import { PureComponent } from 'react';
import { flow } from 'lodash';
import { reduxForm } from 'redux-form';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { FormBuilder, ReduxModal } from 'lib/ui';
import { locations } from 'app/locations';
import { VERIFY_LOCATION_MODAL } from '../../locations/config';

const { block, element } = bem('VerifyLocation');
const { validators } = FormBuilder;

export default flow(
  reduxForm({
    form: 'verifyLocation',
    onSubmit(values, dispatch, props) {
      (locations as any).verify({
        id: props.data.id,
        code: values.code,
      });
    },
  }),
  ReduxModal({
    name: VERIFY_LOCATION_MODAL,
    getTitle: () => t('verify_location.title'),
  }),
)(class VerifyLocation extends PureComponent {
  static propTypes = {
    // location objcet
    data: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div {...block()}>
        <div {...element('description')}>{t('verify_location.description')}</div>

        <FormBuilder.Text required name="code" validate={[validators.maxLength(20)]} normalize={filterCodeInput} />

        <FormBuilder.ButtonsGroup submitText={t('common.save')} disabled={(this.props as any).invalid} onCancel={this.cancel} onSubmit={(this.props as any).submit} />
      </div>
    );
  }

  cancel = () => {
    (this.props as any).reset();
    (this.props as any).onClose();
  };
});

function filterCodeInput(str) {
  return str.replace(/[^+\d]/g, '');
}
