import { queryClient } from 'shared/lib/react-query';
import { apiClient_v1 } from 'shared/api/v1/client';
import { contextNotification } from 'app/notifications';
import { NotificationsObserver } from 'features/notify';

export const saveIssueChanges = (payload: { id: number; attributes: Record<string, unknown> }) => {
  queryClient
    .getMutationCache()
    .build(queryClient, {
      mutationFn: () => {
        return apiClient_v1.issues.saveIssueChanges(payload);
      },
      onSuccess: () =>
        NotificationsObserver.addNotification(contextNotification('issues')('updated').message),
    })
    .execute({});
};
