import { PureComponent } from 'react';

type OwnProps = {
  issue: any;
  listMode?: string;
  type?: 'column' | 'line';
};

type Props = OwnProps & typeof IssueResponsible.defaultProps;
export default class IssueResponsible extends PureComponent<Props> {
  static defaultProps = {
    type: 'line',
  };

  get responsible() {
    const { issue, listMode } = this.props;

    if (listMode === 'on_check') {
      return issue.prev_responsible;
    }
    return issue.responsible;
  }

  render() {
    if (this.props.type === 'line') {
      return (
        <span>{this.responsible.map((user) => (user ? user.full_name : null)).join(', ')}</span>
      );
    }

    if (this.props.type === 'column') {
      return (
        <div>
          {this.responsible.map((user) => {
            if (user) {
              return <div key={user.id}>{user.full_name}</div>;
            }
            return null;
          })}
        </div>
      );
    }
  }
}
