import { isPushApiSupported } from 'lib/helpers/firebase/firebase';

export const registerFcmSW = () => {
  if ('serviceWorker' in navigator && isPushApiSupported()) {
    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then((registration) => {
        return registration.scope;
      })
      .catch((err) => {
        return err;
      });
  }
};

export const unregisterFcmSW = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
  }
};
