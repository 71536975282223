import './IssueCloseDate.scss';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { Date } from 'lib/ui';
import { Icon } from 'lib/ui/icon';

const { block } = bem('IssueCloseDate');

export default function IssueCloseDate({ issue, format = 'long', short = false }) {
  return (
    <div {...block()}>
      <Icon glyph="checkmark" />

      {short && t('issues.fields.close_date')}

      <Date value={issue.close_date} format={format} />
    </div>
  );
}
