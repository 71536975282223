import { omit } from 'lodash';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiClient_v4 } from 'shared/api/v4/client';
import { GetUserNotificationsNotificationsGetParams } from 'shared/api/v4/swagger/data-contracts';

export const notificationsQueries = createQueryKeys('notifications', {
  list: (params: GetUserNotificationsNotificationsGetParams, infiniteQuery?: boolean) => ({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: infiniteQuery ? ['infinite', omit(params, ['last_id'])] : [params],
    queryFn: (ctx) => {
      const last_id = 'pageParam' in ctx ? (ctx.pageParam as number) : params.last_id;
      return apiClient_v4.notifications.getNotifications({
        params: {
          ...params,
          last_id,
        },
      });
    },
  }),
  unseenCount: {
    queryKey: null,
    queryFn: () => apiClient_v4.notifications.getUnseenCount(),
  },
  announcementDetail: (notificationId: number) => ({
    queryKey: [notificationId],
    queryFn: () => apiClient_v4.notifications.getAnnouncementById({ notificationId }),
  }),
});

export const useNotificationsInfiniteQuery = (
  params?: GetUserNotificationsNotificationsGetParams,
) => {
  const { last_id = 0, limit = 10, type_filter = '' } = params ?? {};
  const { queryFn, queryKey } = notificationsQueries.list({ limit, type_filter }, true);

  return useInfiniteQuery({
    queryKey,
    queryFn: (ctx) => queryFn(ctx),
    initialPageParam: last_id,
    getNextPageParam: (lastPage) => {
      if (!('response' in lastPage)) {
        return undefined;
      }

      const lastObject = lastPage.response.data.at(-1);
      return lastObject ? lastObject.id : undefined;
    },
  });
};
