import { ComponentType, memo, useCallback, useContext, useMemo, useState } from 'react';
import { flow, get } from 'lodash';

// eslint-disable-next-line import/no-cycle
import { reconnect } from 'lib/resource';
import { bem } from 'lib/bem';

import NodeTitle from './NodeTitle';
import { getTreeContext } from './TreeContext';
import { useDragSourceNode } from './hooks/useDragSourceNode';
import { useDropTargetNode } from './hooks/useDropTargetNode';
import DropBetweenTargetV2 from './DropBetweenTarget';

const { element } = bem('TreeView');

const TreeContext = getTreeContext();

const TreeViewNode: ComponentType<any> = flow(
  reconnect((state, props) => {
    const { childrenSelector } = props.node;
    const { expanded } = props;

    if (childrenSelector) {
      return { childNodes: expanded && childrenSelector(state) };
    }

    return {};
  }),
)(({
  node,
  level,
  root = false,
  childNodes,
  expanded,
  selected,
  onDragBeforeEnter,
  onDragBeforeLeave,
  isDisplayFolders,
}) => {
  const contextValue = useContext(TreeContext);
  const {
    getNodeTitle,
    isNodeVisible,
    handleDrop,
    handleDropBefore,
    selectNode,
    isNodeSelected,
    selectedNode,
    visibleNodes,
    renderContent,
    filter,
    isTreeDraggable,
    selectable,
    expandedNodes,
    onToggleExpand,
    onCheck,
  } = contextValue;
  const expandedLevel =
    (contextValue as any).expandedLevel === undefined ? -1 : (contextValue as any).expandedLevel;

  const [{ isDragging }, drag] = useDragSourceNode({ node });
  const [{ isOver }, drop] = useDropTargetNode({ node, onDrop: handleDrop });

  const children = useMemo(() => {
    if (node.childrenSelector) {
      return get(childNodes, 'data.children', []);
    }

    return node.children || [];
  }, [childNodes, node.children, node.childrenSelector]);

  const { hasChildren, areChildrenLoading } = useMemo(
    () => ({
      hasChildren: node.childrenSelector || children.length > 0,
      areChildrenLoading: node.childrenSelector ? childNodes.fetching : false,
    }),
    [childNodes, children.length, node.childrenSelector],
  );

  const [droppable, setDroppable] = useState(false);

  const modifiers = useMemo(
    () => ({
      leaf: !hasChildren,
      root,
      expanded,
      selected,
      dragging: isDragging,
      dragOver: isOver,
      droppable,
      [`level_${level}`]: true,
    }),
    [droppable, expanded, hasChildren, isDragging, isOver, level, root, selected],
  );

  const handleCheck = useCallback(
    (e) => {
      const isChecked = e.target.children[0]?.classList.contains('Mui-checked');
      onCheck(node, !isChecked);
    },
    [node, onCheck],
  );

  const handleToggle = useCallback(
    (e) => {
      if (expanded) {
        if (selected || selectable === false) {
          onToggleExpand(node, false);
        }
      } else {
        onToggleExpand(node, true);
      }

      selectNode(node);
      e.stopPropagation();
    },
    [expanded, node, onToggleExpand, selectNode, selectable, selected],
  );

  const handleSelect = useCallback(
    (e) => {
      selectNode(node);
      e.stopPropagation();
    },
    [node, selectNode],
  );

  const isNodeExpanded = useCallback(
    (child, childLevel) => {
      if (filter) {
        const isTooMuchResults = Object.keys(visibleNodes).length > 100;
        const isNodeFoundByFilter = visibleNodes[child.id];
        const expandedNode = expandedNodes[child.id];

        if (!expandedNode && expandedNode !== undefined) {
          return false;
        }

        if (!isTooMuchResults && isNodeFoundByFilter) {
          return true;
        }
      }

      if (child.$forceExpanded) return true;

      const expandedNode = expandedNodes[child.id];

      if (expandedNode === undefined) {
        return childLevel <= expandedLevel;
      }

      return expandedNode;
    },
    [expandedLevel, expandedNodes, filter, visibleNodes],
  );

  const handleDroppableChange = useCallback(
    (value) => () => {
      setDroppable(value);
    },
    [],
  );

  const NodeChildren = useMemo(
    () => () => {
      const childLevel = level + 1;

      if (!expanded || children.length === 0) {
        return null;
      }

      return (
        <div {...element('node-children')}>
          {children.map(
            (child, i) =>
              isNodeVisible(child) && (
                <TreeViewNode
                  key={child.id}
                  selected={isNodeSelected(child)}
                  expanded={isNodeExpanded(child, childLevel)}
                  node={child}
                  level={childLevel}
                  onDrop={handleDrop}
                  selectedNode={selectedNode}
                  visibleNodes={visibleNodes}
                  onDragBeforeEnter={handleDroppableChange(true)}
                  onDragBeforeLeave={handleDroppableChange(false)}
                  isDisplayFolders={isDisplayFolders}
                />
              ),
          )}

          {isTreeDraggable && (
            <DropBetweenTargetV2
              {...element('dropAfter')}
              node={node}
              onDrop={handleDrop}
              onDragEnter={handleDroppableChange(true)}
              onDragLeave={handleDroppableChange(false)}
            />
          )}
        </div>
      );
    },
    [
      children,
      expanded,
      handleDrop,
      handleDroppableChange,
      isDisplayFolders,
      isNodeExpanded,
      isNodeSelected,
      isNodeVisible,
      isTreeDraggable,
      level,
      node,
      selectedNode,
      visibleNodes,
    ],
  );

  return (
    <div {...element('node', modifiers)}>
      {isTreeDraggable && !root && (
        <DropBetweenTargetV2
          {...element('dropBefore')}
          node={node}
          onDrop={handleDropBefore}
          onDragEnter={onDragBeforeEnter}
          onDragLeave={onDragBeforeLeave}
        />
      )}

      {!root && (
        <NodeTitle
          title={getNodeTitle(node)}
          leaf={!hasChildren}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ title: any; leaf: boolean; level: any; isL... Remove this comment to see the full error message
          level={level}
          isLoading={areChildrenLoading}
          drag={drag}
          drop={drop}
          draggable={isTreeDraggable}
          expanded={expanded}
          onTitleClick={selectable ? (hasChildren ? handleToggle : handleSelect) : handleCheck}
          onToggle={handleToggle}
          nodeSettings={{
            isDisplayIconFolder: isDisplayFolders,
            isNodeTypeFolder: node.type === 'folder',
          }}
        />
      )}

      {selected && renderContent && <div {...element('node-content')}>{renderContent(node)}</div>}

      <NodeChildren />
    </div>
  );
});

export default memo(TreeViewNode);
