import { memo, useCallback, useMemo } from 'react';
import { Settings } from 'react-slick';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { capitalize } from 'lodash';
import moment from 'moment';

import Carousel from '../../../../Carousel';
import { convertDate } from '../../../../../helpers/dates';
import Button from '../../../../Button';
import { bem } from '../../../../../bem';
import { DayInfo } from '../types';
import { getNeededStylesFromModifiers } from '../../../../../helpers/styles';
import { calculateBusynessForEachDay } from './utils';

const sliderSettings: Settings = {
  slidesToShow: 12,
  slidesToScroll: 7,
  initialSlide: 0,
};

const { block, element } = bem('TimeSlots');

interface Props {
  selectedDayIndex: number;
  onChangeDay: (value: number) => () => void;
  initialSlots: DayInfo[];
  isBusyness?: boolean;
  titleMonthOnly?: boolean;
}

const DaysCarousel = ({
  selectedDayIndex,
  onChangeDay,
  initialSlots,
  isBusyness = true,
  titleMonthOnly = false,
}: Props) => {
  const currentMonth = useMemo(() => {
    const currentYear = moment().year();

    if (initialSlots[selectedDayIndex]) {
      const convertedDate = moment(initialSlots[selectedDayIndex].date);
      const format = currentYear !== convertedDate.year() ? 'MMMM YYYY' : 'MMMM';
      return convertedDate.format(format);
    }
    return '';
  }, [initialSlots, selectedDayIndex]);

  const getDayButtonStyles: any = useCallback(
    (modifiers) =>
      ({ theme }) => {
        const selectors = {
          selected: {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: '#FFF',
          },
        };
        return getNeededStylesFromModifiers(selectors, modifiers);
      },
    [],
  );

  const busynessForEachDay = useMemo(() => {
    return calculateBusynessForEachDay(initialSlots);
  }, [initialSlots]);

  return (
    <>
      <div {...element('title')}>
        {`${titleMonthOnly ? '' : t('bookings.fields.slots')} ${capitalize(currentMonth)}`}
      </div>
      <Carousel settings={sliderSettings}>
        {(initialSlots || []).map(({ date }, index) => {
          const convertedDate = convertDate(date);
          const isDaySelected = index === selectedDayIndex;
          const modifiers = { selected: isDaySelected };
          sliderSettings.initialSlide =
            selectedDayIndex < 5
              ? 0
              : selectedDayIndex + 5 > initialSlots.length
                ? initialSlots.length - (sliderSettings?.slidesToShow ?? 0)
                : selectedDayIndex - 5;
          const StyledButton = styled(Button)(getDayButtonStyles(modifiers));
          return (
            // TODO: Add key prop
            // eslint-disable-next-line react/jsx-key
            <div {...element('day')}>
              <div {...element('dayName')}>
                <div>{convertedDate?.format('dd')}</div>
              </div>
              <div {...element('dayButtonWrapper')}>
                <StyledButton
                  {...element('dayButton', modifiers)}
                  ghost={!isDaySelected}
                  withoutHover
                  onClick={onChangeDay(index)}
                >
                  <Box {...element('dayButtonContent')}>
                    <Box>{convertedDate?.date()}</Box>
                    {isBusyness && (
                      <Box display="flex" justifyContent="center">
                        <Box {...element('dayBusyness', { [busynessForEachDay[date]]: true })} />
                      </Box>
                    )}
                  </Box>
                </StyledButton>
              </div>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default memo(DaysCarousel);
