import { keyBy } from 'lodash';
import { api } from 'lib/api';
import { createResource, treeScope } from 'lib/resource';
import { closeDictEditor } from 'app/common/DictEditor/closeDictEditor';

function wrapInRootNode(json) {
  return {
    ...json,
    response: {
      id: -1,
      children: json.response,
    },
  };
}

export default createResource('attributes', {
  scopes: {
    list({ payload }) {
      return api.get('/v2/issuetypes/attributes/', {
        search: {
          ...payload,
          limit: payload.limit || 10000,
        },
      });
    },

    tree: {
      request({ payload = {} }) {
        return api
          .get('/v2/issuetypes/attribute_tree/', {
            search: {
              include_children: true,
              short: true,
              include_auto: true,
              ...payload,
            },
          })
          .then(wrapInRootNode);
      },

      ...treeScope({
        moveAction: 'ATTRIBUTES/MOVE',
        removeAction: 'ATTRIBUTES/DELETE',
      }),
    },

    byReport({ payload }) {
      const { id, filters } = payload;

      return api.get(`/v2/reports/${id}/attributes`, {
        search: {
          ...filters,
          limit: 10000,
        },
      });
    },

    issueTypes: {
      request({ payload = {} }) {
        return api.get(`/v2/attributes/${(payload as any).id}/issuetypes/`, {});
      },

      ...treeScope({}),

      normalizer(response) {
        return {
          data: response.map((i) => ({
            id: i.id || -1,
            children: i.children,
          })),
          index: keyBy(response, (i) => i.id),
        };
      },
    },
  },

  mutators: {
    create: {
      request: ({ payload }) => {
        return api.post('/v2/issuetypes/attributes/', { body: payload });
      },
      onSuccess: closeDictEditor,
    },

    move({ payload }) {
      const { id, parentId, beforeId, body } = payload;
      return api.put(`/v2/issuetypes/attributes/${id}/`, {
        body: {
          ...body,
          parent_id: parentId === -1 ? null : parentId,
          before_id: beforeId,
        },
      });
    },

    update: {
      request: ({ payload }) => {
        const { id, ...body } = payload;
        return api.put(`/v2/issuetypes/attributes/${id}/`, { body });
      },
      onSuccess: closeDictEditor,
    },

    delete({ payload }) {
      const { id } = payload;
      return api.delete(`/v2/issuetypes/attributes/${id}/`);
    },
  },
});
