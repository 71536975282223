const initialState = {
  fetching: true,
  creating: false,
  destroying: false,
  currentUser: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_CURRENT_USER_START':
      return {
        ...state,
      };
    case 'GET_CURRENT_USER_SUCCESS':
      return {
        ...state,
        fetching: false,
        currentUser: action.response,
      };
    case 'GET_CURRENT_USER_FAIL':
      return {
        ...state,
        fetching: false,
        currentUser: null,
      };

    case 'LOGIN_START':
      return {
        ...state,
        creating: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        creating: false,
        fetching: false,
        currentUser: action.response,
      };
    case 'LOGIN_FAIL':
      return {
        ...state,
        creating: false,
        currentUser: null,
      };

    case 'LOGOUT_START':
      return {
        ...state,
        destroying: true,
      };
    case 'LOGOUT_FAIL':
      return {
        ...state,
        destroying: false,
      };

    default:
      return state;
  }
}
