import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, keyBy, isEqual, flow } from 'lodash';
import {
  getFormSyncErrors,
  getFormValues,
  initialize as initializeForm,
  reduxForm,
  reset,
  isDirty,
} from 'redux-form';

// eslint-disable-next-line import/no-cycle
import UserRolesSelect from 'app/users/UserRolesSelect';
import { t, t_prefixed } from 'lib/i18n';
import { bem } from 'lib/bem';
import { reconnect } from 'lib/resource';
import { attributeValuesSelector } from 'app/issues/Attributes';
import { FieldsBuilder, FormBuilder } from 'lib/ui';

import LocationFullPath from '../../LocationFullPath';
import {
  locationTypes as locationTypesResource,
  LocationTypesSelect,
} from '../../../locationTypes';
import AttributesFormV2 from '../../../issues/Attributes/AttributesForm/AttributesFormV2';
import { bookingItemTypes as bookingItemTypesResource } from '../../../bookingItemTypes';
import { schedules as schedulesResource } from '../../../schedules';
import BookingItemTypesSelect from '../../../bookingItemTypes/BookingItemTypesSelect/BookingItemTypesSelect';
import BookingSchedulesSelect from '../../../schedules/BookingSchedulesSelect/BookingSchedulesSelect';
import UsersSelect from '../../../users/UsersSelect';
import './LocationForm.scss';

const { validators } = FormBuilder;

const { block, element } = bem('LocationForm');
const fieldLabel = t_prefixed('locations.fields');

const isPublicOptions = [
  { value: 'public', label: t('common.yes') },
  { value: 'private', label: t('common.no') },
];

const isBookingOptions = [
  { value: true, label: t('common.yes') },
  { value: false, label: t('common.no') },
];

const isAnonymousOptions = [
  { value: true, label: t('common.yes') },
  { value: false, label: t('common.no') },
];

function normalizeLocationType(locationType) {
  return (
    locationType && {
      value: locationType.id,
      label: locationType.name_ru,
    }
  );
}

function getFormData(location, locationTypes, booking, modalExtraData, isRootObject = false) {
  const locationTypesMap = keyBy(locationTypes, 'id');
  const typeId = location.managed_object_type_id;

  if (!location.id) {
    if (isRootObject) {
      return {
        is_public: 'private',
        is_booking: false,
        allow_anonymous: false,
        location_type: normalizeLocationType(locationTypesMap[1]),
      };
    }

    return {
      is_public: 'public',
      is_booking: false,
      allow_anonymous: false,
    };
  }

  const type = normalizeLocationType(locationTypesMap[typeId]) || {
    value: typeId,
    label: location.locations_type,
  };

  const formData: Record<string, any> = {
    name: location.name,
    location_type: type,
    is_public: location.private ? 'private' : 'public',
    require_roles: location.require_roles,
    is_booking: booking,
    owner_id: location.owner_id,
    allow_anonymous: location.allow_anonymous,
    exclude_from_suggested: location.exclude_from_suggested,
  };

  if (modalExtraData) {
    formData.booking_item_type = modalExtraData.managed_object_type;
    formData.booking_schedule = modalExtraData.schedule;
  }

  if (location.coordinates) {
    formData.coordinates = `${location.coordinates.lat}, ${location.coordinates.lon}`;
  }

  return formData;
}

const FORM_NAME = 'locationForm';
const formValuesSelector = getFormValues(FORM_NAME);

const DEFAULT_ATTR_ACTIONS = {
  read: {
    editable: false,
    required: false,
    show_empty: false,
  },
  update: {
    editable: false,
    required: false,
    show_empty: true,
  },
  new: {
    editable: false,
    required: false,
    show_empty: true,
  },
};

const LocationForm = ({
  location,
  locationTypes,
  initialize,
  dirty,
  values,
  onSubmit,
  invalid,
  onCancel,
  readOnly,
  action,
  attributes,
  dispatch,
  locationErrors,
  attributeErrors,
  onFormModeChange,
  isUserGuest,
  isEditable,
  isUserAdmin,
  booking,
  bookingEnabled,
  bookingItemTypes,
  schedules,
  modalExtraData,
  bookingAttributes,
  attribureFormDirty,
  isRootObject,
  isCopy,
}) => {
  const initializedRef = useRef(false);
  const initialValuesRef = useRef<Record<string, any> | null>(null);
  const [initialAttrValues, setInitialAttrValues] = useState({});
  const [initialBookingAttrValues, setInitialBookingAttrValues] = useState({});
  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const [isFormInitialized, setIsFormInitialized] = useState(false);

  const [areErrorsVisible, setErrorsVisible] = useState(false);

  const locationTypeAttributes = useMemo(() => {
    if (locationTypes.data && values && values.location_type) {
      const foundLocationType = locationTypes.data.find((x) => x.id === values.location_type.value);
      if (foundLocationType) {
        return foundLocationType.attributes;
      }
    }
  }, [locationTypes.data, values]);

  const fillAttributes = useCallback(() => {
    if (location && location.attributes && locationTypeAttributes) {
      const initValues = {};
      location.attributes.forEach((attr) => {
        initValues[attr.id] = attr.value;
      });
      dispatch(initializeForm('locationAttributes', initValues));
      setInitialAttrValues(initValues);
    }
  }, [dispatch, location, locationTypeAttributes]);

  const bookingTypeAttributes = useMemo(() => {
    if (bookingItemTypes && bookingItemTypes.data && values && values.booking_item_type) {
      const foundBookingType = bookingItemTypes.data.find(
        (x) => x.id === values.booking_item_type.id,
      );
      if (foundBookingType) return foundBookingType.attributes;
    }
  }, [bookingItemTypes, values]);

  const fillBookingItemAttributes = useCallback(() => {
    const initValues = {};

    if ((action === 'new' || action === 'book') && bookingTypeAttributes) {
      bookingTypeAttributes.forEach((attr) => {
        initValues[attr.id] = attr.default_value;
      });
    }
    if (modalExtraData && modalExtraData.attributes && bookingTypeAttributes) {
      modalExtraData.attributes.forEach((attr) => {
        initValues[attr.id] = attr.value;
      });
    }
    dispatch(initializeForm('bookingItemAttributes', initValues));
    setInitialBookingAttrValues(initValues);
  }, [action, bookingTypeAttributes, dispatch, modalExtraData]);

  useEffect(() => {
    fillAttributes();
    fillBookingItemAttributes();
  }, [fillAttributes, fillBookingItemAttributes]);

  useEffect(() => {
    if (locationTypes.data && !initializedRef.current) {
      if (action === 'read' || action === 'update' || isCopy) {
        if (location && !isEmpty(location)) {
          const formData = getFormData(location, locationTypes.data, booking, modalExtraData);
          initialize(formData);
          initializedRef.current = true;
          initialValuesRef.current = formData;
        }
      } else {
        const formData = getFormData(
          location,
          locationTypes.data,
          booking,
          modalExtraData,
          isRootObject,
        );
        initialize(formData);
        initializedRef.current = true;
        initialValuesRef.current = formData;
      }
    }
  }, [
    action,
    booking,
    fillAttributes,
    initialize,
    isCopy,
    isRootObject,
    location,
    locationTypes,
    modalExtraData,
  ]);

  const handleSubmit = useCallback(() => {
    if (!isEmpty(locationErrors) || !isEmpty(attributeErrors)) {
      setErrorsVisible(true);
    } else if (values) {
      const changedFields = Object.keys(values)
        .map((field) => {
          if (initialValuesRef.current?.[field] === undefined) {
            return field;
          }
          if (!isEqual(initialValuesRef.current[field], values[field])) {
            return field;
          }
          return null;
        })
        .filter((x): x is NonNullable<typeof x> => x != null);

      const bookingActionKeys = ['is_booking', 'booking_item_type', 'booking_schedule'];
      const isLocationAttrChanged = !isEqual(initialAttrValues, attributes);
      const isBookingItemAttrChanged = !isEqual(initialBookingAttrValues, bookingAttributes);
      const existOtherChangedFields =
        (changedFields.length && changedFields.some((x) => !bookingActionKeys.includes(x))) ||
        isLocationAttrChanged;

      const onlyCreateBookingAction =
        (changedFields.length <= bookingActionKeys.length || isBookingItemAttrChanged) &&
        !existOtherChangedFields;

      const validBookingAttr = {};
      if (bookingAttributes) {
        Object.keys(bookingAttributes).forEach((id) => {
          const foundAttribute = bookingTypeAttributes.find((x) => x.id === id);
          if (foundAttribute) {
            const { actions: attributeActions } = foundAttribute;
            if (attributeActions[action] && attributeActions[action].editable) {
              validBookingAttr[id] = bookingAttributes[id];
            }
          }
        });
      }

      const bookingActionDto =
        values.booking_item_type && values.booking_schedule
          ? {
              typeId: values.booking_item_type.id,
              scheduleId: values.booking_schedule.id,
              attributes: validBookingAttr,
            }
          : null;

      if (onlyCreateBookingAction && bookingActionDto) {
        onSubmit({ id: location.id }, true, bookingActionDto);
      } else {
        const validAttributes = {};
        if (locationTypeAttributes) {
          Object.keys(attributes).forEach((attributeId) => {
            const foundAttribute = locationTypeAttributes.find((x) => x.id === attributeId);
            if (foundAttribute) {
              const { actions: attributeActions } = foundAttribute;
              if (attributeActions[action] && attributeActions[action].editable) {
                validAttributes[attributeId] =
                  attributes[attributeId] ||
                  attributes[attributeId] === false ||
                  attributes[attributeId] === 0
                    ? attributes[attributeId]
                    : null;
              }
            }
          });
        }

        let lat: number | null = null;
        let lon: number | null = null;

        if (values.coordinates) {
          const [latStr, lonStr] = values.coordinates.split(',');
          lat = parseFloat(latStr);
          lon = parseFloat(lonStr);
        }

        onSubmit(
          {
            id: location.id,
            data: {
              name: values.name,
              location_type_id: values.location_type
                ? values.location_type.value === 'new'
                  ? { ...values.location_type, new: true }
                  : values.location_type.value
                : null,
              private: values.is_public === 'private',
              require_roles: values.is_public === 'private' ? values.require_roles : [],
              parent_id: location.parent && location.parent.id,
              owner_id:
                values.owner_id != null && typeof values.owner_id === 'object'
                  ? values.owner_id.id
                  : values.owner_id,
              attributes: validAttributes,
              allow_anonymous: values.is_public === 'public' ? values.allow_anonymous : false,
              lat,
              lon,
              exclude_from_suggested: values.exclude_from_suggested,
            },
          },
          false,
          bookingActionDto,
        );

        if (!isEditable) {
          onCancel();
        }
      }
    }
  }, [
    locationErrors,
    attributeErrors,
    values,
    initialAttrValues,
    attributes,
    initialBookingAttrValues,
    bookingAttributes,
    bookingTypeAttributes,
    action,
    onSubmit,
    location,
    locationTypeAttributes,
    isEditable,
    onCancel,
  ]);

  const isNew = location && !location.id;

  const locationTypeRef = useRef();
  useEffect(() => {
    if (values && values.location_type) {
      if (locationTypeRef && locationTypeRef.current) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        if (values.location_type.value !== locationTypeRef.current.value) {
          dispatch(reset('locationAttributes'));
          fillAttributes();
        }
      }
      locationTypeRef.current = values.location_type;
    }
  }, [dispatch, fillAttributes, location, values]);

  const isChangeButtonVisibleRef = useRef(undefined);

  const isFormModeChangeButtonVisible = useMemo(() => {
    if (!isEditable && location && !isEmpty(location) && action !== 'update' && !isUserGuest) {
      const { attributes: typeAttributes } = location.location_type;

      const locationHasEditableAttributes = typeAttributes.some((attr) => {
        const { actions } = attr;
        if (actions && actions.update) {
          if (actions.update.editable) {
            return true;
          }
        }

        return false;
      });

      return locationHasEditableAttributes;
    }
    return false;
  }, [isEditable, location, action, isUserGuest]);

  useEffect(() => {
    if (isChangeButtonVisibleRef.current === undefined) {
      isChangeButtonVisibleRef.current = isFormModeChangeButtonVisible;
    }
  }, [isFormModeChangeButtonVisible]);

  const defaultAttributes = useMemo(() => {
    if (location) {
      return [
        {
          name: fieldLabel('name'),
          renderer: 'comment',
          value: location.name,
          read_only: true,
          actions: DEFAULT_ATTR_ACTIONS,
        },
      ];
    }
    return [];
  }, [location]);

  const normalizeYesNoValue = useCallback((value) => {
    return value === 'true';
  }, []);

  const handleAttributesInit = useCallback(
    (initialAttrs) => {
      dispatch(initializeForm('locationAttributes', initialAttrs));
      setTimeout(() => {
        setIsFormInitialized(true);
      }, 750);
    },
    [dispatch],
  );

  const [internalBooking, setInternalBooking] = useState(booking);
  const handleBookingChange = useCallback((value) => {
    setInternalBooking(value === 'true');
  }, []);

  const [showAnonymous, setShowAnonymous] = useState<boolean | null>();
  const [showRoles, setShowRoles] = useState<boolean | null>();

  useEffect(() => {
    const isPrivate = location && location.private;
    setShowAnonymous(!isPrivate);
    setShowRoles(isPrivate);
  }, [location]);

  useEffect(() => {
    if (isRootObject && !initializedRef.current) {
      const formData = getFormData(
        location,
        locationTypes.data,
        booking,
        modalExtraData,
        isRootObject,
      );
      initialize(formData);
      initialValuesRef.current = formData;
      setShowAnonymous(false);
      setShowRoles(true);
    }
  }, [booking, initialize, isRootObject, location, locationTypes.data, modalExtraData]);

  const handlePublicChange = useCallback((value) => {
    setShowAnonymous(value === 'public');
    setShowRoles(value === 'private');
  }, []);

  useEffect(() => {
    if (isFormInitialized && nameInputRef.current && isCopy) {
      nameInputRef.current.focus();
      nameInputRef.current.setSelectionRange(0, -1);
    }
  }, [isCopy, isFormInitialized]);

  const showBookingGroupsAndSchedules = isUserAdmin && bookingEnabled && internalBooking;

  const objectWithBookingAttributes = useMemo(() => {
    if (action === 'new') {
      return {
        managed_object_type: { attributes: bookingTypeAttributes },
        attributes: bookingTypeAttributes,
      };
    }
    if (!values || (values && !values.booking_item_type)) return modalExtraData;

    if (!modalExtraData) {
      return {
        managed_object_type: { attributes: bookingTypeAttributes },
        attributes: bookingTypeAttributes,
      };
    }

    return modalExtraData.managed_object_type_id === values.booking_item_type.id
      ? modalExtraData
      : { ...modalExtraData, attributes: bookingTypeAttributes };
  }, [action, bookingTypeAttributes, modalExtraData, values]);

  return (
    <div {...block()}>
      {!readOnly && location && location.parent && !isRootObject && (
        <FieldsBuilder.Text title={fieldLabel('parent')} value={null}>
          <LocationFullPath location={location.parent} />
        </FieldsBuilder.Text>
      )}
      {!readOnly && isEditable && (
        <>
          <FormBuilder.Text
            required
            name="name"
            title={fieldLabel('name')}
            maxLength={60}
            inputRef={nameInputRef}
          />

          <LocationTypesSelect
            required
            allowsAddNewItem
            name="location_type"
            title={fieldLabel('location_type')}
          />

          <UsersSelect
            clearable
            name="owner_id"
            title={fieldLabel('location_holder')}
            owner={location.owner}
            tooltip="location.owner"
          />

          <FormBuilder.Checkbox
            label={fieldLabel('exclude_from_suggested')}
            name="exclude_from_suggested"
            tooltip="location.exclude_from_suggested"
          />

          <FormBuilder.RadioButtons
            name="is_public"
            title={fieldLabel('is_public')}
            options={isPublicOptions}
            onChange={handlePublicChange}
            tooltip="location.is_public"
          />

          {showRoles && (
            <UserRolesSelect multiple name="require_roles" title={fieldLabel('require_roles')} />
          )}

          {showAnonymous && (
            <FormBuilder.RadioButtons
              name="allow_anonymous"
              title={fieldLabel('allow_anonymous')}
              options={isAnonymousOptions}
              normalize={normalizeYesNoValue}
              tooltip="location.allow_anonymous"
            />
          )}

          {isUserAdmin && bookingEnabled && (
            <FormBuilder.RadioButtons
              onChange={handleBookingChange}
              name="is_booking"
              title={fieldLabel('is_booking')}
              options={isBookingOptions}
              normalize={normalizeYesNoValue}
            />
          )}
          {showBookingGroupsAndSchedules && (
            <>
              <BookingItemTypesSelect
                required
                name="booking_item_type"
                data={(bookingItemTypes || {}).data || []}
              />
              <BookingSchedulesSelect name="booking_schedule" data={(schedules || {}).data || []} />
              {!isEmpty(bookingTypeAttributes) && (
                <AttributesFormV2
                  form="bookingItemAttributes"
                  action={action}
                  managedObject={objectWithBookingAttributes}
                  managedObjectTypeName="managed_object_type"
                  editableOnly={readOnly}
                  typeAttributes={bookingTypeAttributes}
                />
              )}
            </>
          )}
          <FormBuilder.Text
            name="coordinates"
            title={fieldLabel('coordinates')}
            validate={[validators.coordinates()]}
            {...element('coordinates')}
          />
        </>
      )}
      <AttributesFormV2
        form="locationAttributes"
        action={action}
        managedObject={location}
        typeAttributes={locationTypeAttributes}
        editableOnly={!readOnly}
        highlightErrors={areErrorsVisible}
        isEditable={isEditable}
        defaultAttributes={defaultAttributes}
        onInit={handleAttributesInit}
      />
      {!readOnly && (
        <FormBuilder.ButtonsGroup
          submitText={isNew ? t('common.create') : t('common.save')}
          disabled={invalid || (!isCopy && !(dirty || attribureFormDirty))}
          onCancel={onCancel}
          onSubmit={handleSubmit}
        />
      )}
      {(isFormModeChangeButtonVisible ||
        (isChangeButtonVisibleRef.current && action === 'read' && isUserAdmin)) && (
        <FormBuilder.ButtonsGroup
          submitText={t('common.edit')}
          onSubmit={onFormModeChange({ action: 'update', readOnly: false, lc: true })}
          hideCancel
        />
      )}
    </div>
  );
};

export default flow<any, any, any>(
  reconnect((state, { isUserGuest, bookingEnabled, readOnly, location }) => {
    const isRootObject = location?.parent?.id === 0;
    const locationErrors = getFormSyncErrors(FORM_NAME)(state);
    const attributeErrors = getFormSyncErrors('locationAttributes')(state);
    const attribureFormDirty = isDirty('locationAttributes')(state);
    const errorsNumber = Object.keys({ ...locationErrors, ...attributeErrors }).length;
    const bookingEnabledParam = isRootObject ? true : bookingEnabled;
    return {
      values: formValuesSelector(state),
      locationTypes:
        !isUserGuest && !readOnly
          ? (locationTypesResource as any).list(state, {})
          : { data: undefined },
      attributes: attributeValuesSelector('locationAttributes')(state),
      locationErrors: getFormSyncErrors(FORM_NAME)(state),
      attributeErrors: getFormSyncErrors('locationAttributes')(state),
      invalid: errorsNumber > 0,
      bookingItemTypes:
        bookingEnabledParam &&
        !readOnly &&
        bookingItemTypesResource.list(state, { used_only: false }),
      schedules: bookingEnabledParam && !readOnly && schedulesResource.list(state, {}),
      bookingAttributes: attributeValuesSelector('bookingItemAttributes')(state),
      attribureFormDirty,
      bookingEnabled: bookingEnabledParam,
      isRootObject,
    };
  }),
  reduxForm({
    form: FORM_NAME,
  }),
)(memo(LocationForm));
