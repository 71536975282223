import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient_v2 } from 'shared/api/v2/client';

import { locationsQueries } from './queries';

export const useRemoveLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      await apiClient_v2.locations.removeLocation({ id });
      return { id };
    },
    // eslint-disable-next-line no-underscore-dangle
    onSuccess: () => queryClient.invalidateQueries({ queryKey: locationsQueries._def }),
  });
};

export const useMoveLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { id: number; parentId: number; beforeId: number | null }) => {
      return apiClient_v2.locations.moveLocation(payload);
    },
    // eslint-disable-next-line no-underscore-dangle
    onSuccess: () => queryClient.invalidateQueries({ queryKey: locationsQueries._def }),
  });
};

export const useUploadChildren = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { id: number; file: Blob }) => {
      return apiClient_v2.locations.uploadChildren(payload);
    },
    // eslint-disable-next-line no-underscore-dangle
    onSuccess: () => queryClient.invalidateQueries({ queryKey: locationsQueries._def }),
  });
};
