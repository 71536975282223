import { useFormContext, Controller } from 'react-hook-form';

import { FilterTreeViewMultiSelect } from './FilterTreeViewMultiSelect';

interface LocationTreeSelectProps {
  name?: string;
  multiSelect?: boolean;
  hasStartIcon?: boolean;
  required?: boolean;
  className?: string;
  title?: string;
}

export const LocationTreeSelect = ({
  name,
  multiSelect,
  hasStartIcon,
  required,
  className,
  title,
}: LocationTreeSelectProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name ?? 'location'}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FilterTreeViewMultiSelect
          className={className}
          multiSelect={multiSelect}
          hasStartIcon={hasStartIcon}
          required={required}
          value={value}
          onChange={onChange}
          customTitle={title}
        />
      )}
    />
  );
};
