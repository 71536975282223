import { memo, useEffect } from 'react';
import { useDropTargetNode } from './hooks/useDropTargetNode';
import { usePrevious } from '../../hooks/usePrevious';

const DropBetweenTarget = ({
  node,
  className,
  onDrop,
  onDragEnter,
  onDragLeave, 
}) => {
  const [{ isOver }, drop] = useDropTargetNode({ node, onDrop });
  const prevIsOver = usePrevious(isOver);
  
  useEffect(() => {
    if (isOver && !prevIsOver) {
      onDragEnter();
    }
    
    if (!isOver && prevIsOver) {
      onDragLeave();
    }
  }, [isOver, onDragEnter, onDragLeave, prevIsOver]);
  
  const modifier = isOver ? ' _dragOver' : '';
  return <div ref={drop} className={`${className}${modifier}`} />;
};

export default memo(DropBetweenTarget);
