import { SetStateAction, useEffect, useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { flatten, isEqual, uniq } from 'lodash/fp';

import { usePrevious } from 'lib/hooks/usePrevious';
import { TreeData, TreeNode } from 'shared/ui/async-tree-view';
import { expandNode, getGrandParents } from 'shared/lib/tree-view-helpers';

import { locationsQueries } from '../api/queries';

export const rootNode: TreeNode = {
  id: 0,
  name: 'root',
  hasChildren: true,
};

export const useGetInitialSelectValues = (
  initialLocationIds: number[],
  setTreeData: (value: SetStateAction<TreeData>) => void,
  setExpandedNodes: (value: SetStateAction<string[]>) => void,
  setLoading: (value: SetStateAction<boolean>) => void,
) => {
  const [initParents, setInitParents] = useState<(number | undefined)[]>([]);

  const locQueries = useQueries({
    queries: initialLocationIds.map((item) => {
      return {
        ...locationsQueries.byId({ id: item }),
      };
    }),
    combine(results) {
      return {
        data: results.map((result) => result.data?.response.parents),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  const prevLocQueries = usePrevious(locQueries.data);

  useEffect(() => {
    if (
      !locQueries.pending &&
      locQueries.data.length &&
      !isEqual(prevLocQueries, locQueries.data)
    ) {
      const union = uniq(flatten(locQueries.data));
      setInitParents(union);
    }
  }, [locQueries, prevLocQueries]);

  const initialParents = useQueries({
    queries: initParents.map((item) => {
      return {
        ...locationsQueries.byParentId({ parentId: item as number }),
      };
    }),
    combine: (result) => ({
      data: result.map((res) => res.data),
      pending: result.some((i) => i.isPending),
    }),
  });

  const prevInitParents = usePrevious(initialParents.data);

  useEffect(() => {
    if (
      !initialParents.pending &&
      initialParents.data.length &&
      !isEqual(prevInitParents, initialParents.data)
    ) {
      initialParents.data?.forEach((item) => {
        if (!item) return;

        if (item[0].parent_id === rootNode.id) {
          return;
        }

        const nodeId = item[0].parent_id?.toString();

        if (nodeId) {
          setTreeData((prevState) => {
            const parents = [nodeId, ...getGrandParents(prevState, undefined)];
            const children = item.map((i) => expandNode(i, parents));

            return { ...prevState, [nodeId]: { parentId: undefined, children } };
          });

          setExpandedNodes((prevState) =>
            !prevState.includes(nodeId) ? [...prevState, nodeId] : prevState,
          );
        }
      });
      setLoading(false);
    }
  }, [initialParents, prevInitParents, setExpandedNodes, setLoading, setTreeData]);
};
