import './IssueShortStatus.scss';
import { bem } from 'lib/bem';
import { Icon } from 'lib/ui/icon';
import { reconnect } from 'lib/resource';
import users from 'app/users/users.resource';
import { useMemo } from 'react';

const { block } = bem('IssueShortStatus');
const showOverudStatus = (user) =>
  user.roles.some((role) => role in { responsible: true, executive: true });
const getStatus = (issue, currentUser) => {
  if (issue.current_status === 'closed') return 'closed';
  if (issue.overdue && showOverudStatus(currentUser)) return 'overdue';
  if (issue.current_status !== 'closed' && issue.reopen_date) return 'reopened';
  return 'open';
};
const statusIconMap = {
  overdue: 'overdue',
  closed: 'checkmark',
  open: 'open',
  reopened: 'reopen',
} as const;

export default reconnect((state) => ({
  currentUser: (users as any).current(state, {}),
}))(({ className, issue, currentUser }) => {
  const status = getStatus(issue, currentUser.data);
  const icon = statusIconMap[status];

  const iconSx = useMemo(() => {
    return {
      reopened: {
        fill: (theme) => theme.palette.active,
      },
    }[icon];
  }, [icon]);

  if (!icon) return null;
  return (
    <div {...block({ [status]: true }, className)}>
      <Icon glyph={icon} sx={iconSx} />
    </div>
  );
});
