import './PhotoPreview.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { SquareBlock } from 'lib/ui';
import { IconAsButton } from 'lib/ui/icon';
import NonSquareBlock from 'lib/ui/NonSquareBlock';

type Props = {
  url?: string;
  className?: string;
  removable?: boolean;
  onRemove?: (...args: any[]) => any;
  nonSquare?: boolean;
  onClick: any;
};

type State = any;

export default class PhotoPreview extends PureComponent<Props, State> {
  photo: any;

  constructor(props: Props) {
    super(props);

    this.setPhotoRef = this.setPhotoRef.bind(this);
  }

  state = {
    isLoaded: false,
  };

  componentDidMount() {
    this.photo.onload = () => {
      this.setState({ isLoaded: true });
    };
  }

  setPhotoRef(photo) {
    this.photo = photo;
  }

  render() {
    const { block, element } = bem(this.props.nonSquare ? 'PhotoPreviewNonSquare' : 'PhotoPreview');
    const { isLoaded } = this.state;
    const { className, url, removable, onClick, nonSquare } = this.props;

    const contentStyle = url ? { backgroundImage: `url(${url})` } : undefined;

    const children = (
      <div {...element('content')} style={contentStyle}>
        <img src={url} alt="" ref={this.setPhotoRef} width={nonSquare ? '100%' : 'auto'} />

        {removable && (
          <IconAsButton {...element('remove')} onClick={this.handleRemove} glyph="close" />
        )}
      </div>
    );

    return nonSquare ? (
      <NonSquareBlock
        {...block({ isLoaded, clickable: onClick, nonsquare: nonSquare }, className)}
        onClick={onClick}
      >
        {children}
      </NonSquareBlock>
    ) : (
      <SquareBlock
        {...block({ isLoaded, clickable: onClick, nonsquare: nonSquare }, className)}
        onClick={onClick}
      >
        {children}
      </SquareBlock>
    );
  }

  handleRemove = (e) => {
    this.props.onRemove?.(e);
    e.stopPropagation();
  };
}
