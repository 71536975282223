import moment from 'moment';
// @ts-expect-error moment imports
import enLocale from 'moment/locale/en-gb';
// @ts-expect-error moment imports
import ruLocale from 'moment/locale/ru';
// @ts-expect-error moment imports
import daLocale from 'moment/locale/da';

import brandedData from 'config/brandedData';

export const momentInit = () => {
  moment.defaultFormat = 'LL';

  // выставляем дефолтнyю локаль, если юзер использует язык, который мы не поддерживает
  if (brandedData.defaultLang === 'en') {
    moment.locale('en-gb', enLocale);
  } else {
    moment.locale('ru', ruLocale);
  }

  switch (window.navigator.language.split('-')[0].toLocaleLowerCase()) {
    case 'ru':
      moment.locale('ru', ruLocale);
      break;
    case 'en':
      moment.locale('en-gb', enLocale);
      break;
    case 'da':
      moment.locale('da', daLocale);
      break;
    default:
      break;
  }
};
