import { ReactNode } from 'react';
import { Badge as BadgeMui, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props extends Omit<BadgeProps, 'content'> {
  children?: ReactNode;
  content?: number;
  top?: string;
  right?: string;
  sx?: Record<string, string>;
}

const Badge = ({ children, content, top, right, sx, ...otherProps }: Props) => {
  const StyledBadge = styled(BadgeMui)<BadgeProps>(() => ({
    '&': {
      display: 'block',
    },

    '& .MuiBadge-badge': {
      right: right || '20px',
      top: top || '22px',
      width: '26px',
      height: '16px',
      backgroundColor: '#FF3B30',
      fontSize: '11px',
      fontWeight: '500',
      lineHeight: '12px',
      textShadow: '0px 1px 4px #BE1A11',
      borderRadius: '8px',
      border: '1px solid transparent',
    },
  }));

  return (
    <StyledBadge badgeContent={content} sx={{ ...sx }} {...otherProps}>
      {children}
    </StyledBadge>
  );
};

export default Badge;
