import './ExpiredIssue.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { Icon } from 'lib/ui/icon';

const { block, element } = bem('ExpiredIssue');

export default class ExpiredIssue extends PureComponent {
  render() {
    return (
      <div {...block()}>
        <Icon {...element('icon')} glyph="clock" />
        {t('issues.expired')}
      </div>
    );
  }
}
