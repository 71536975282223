import { ComponentType } from 'react';

import {
  IncomingIssuesLazy,
  IssuesInProgressLazy,
  IssuesOnCheckLazy,
  ClosedIssuesLazy,
  NewIssueLazy,
  NewOunceIssueLazy,
  NewQRIssueLazy,
  IssuesCreatedByMeLazy,
} from 'app/issues/lazy';
import { Substitutions } from 'app/substitutions/lazy';
import { IssueTypes } from 'app/issueTypes/lazy';
import { UsersPage, EmployeesPage } from 'app/users/lazy';
import { Metaflows } from 'app/metaflows/lazy';
import { LocationTypes } from 'app/locationTypes/lazy';
import { UserRoles } from 'app/userRoles';
import { Companies } from 'app/companies/lazy';
import { Reports, ReportsEditor } from 'app/reports/lazy';
import { IssueSchedules } from 'app/issueSchedules/lazy';
import { MetersList } from 'app/meters/lazy';
import { InventoriesList } from 'app/inventories/lazy';
import { AnnouncementsEditor } from 'app/announcements/lazy';
import { UserModel } from 'app/users/types';
import { Checkins } from 'app/checkins/lazy';
import { Bookings, MyBookings } from 'app/bookings/lazy';
import { brandName } from 'config/constants';
import Sandbox from 'app/Sandbox/Sandbox';
import { ActivitiesList } from 'app/activities';
import QRcodeScanner from 'lib/ui/QRcodeScanner';
import RoundReports from 'app/roundsReports/RoundReports';
import InventoryReport from 'app/inventories/InventotyReport';
import { Roles } from 'app/userRoles/roles';
import { ReportsDashboard } from 'app/analyticalReports';
import { FieldsComparison } from 'app/fieldsComparison';
import { ExternalLink } from 'app/externalLinks';
import { TenantDocsPage } from 'app/users/pages/TenantDocsPage';
import { EntryPage } from 'app/entry';
import CaptchaForm from 'app/captcha/CaptchaForm';
import { NotificationPage } from 'pages/notification';
import { Locations } from 'app/locations/Locations';
import { ActivitiesSettingsPage } from 'pages/activities-settings';
import { ProfilePage } from 'pages/profile';
import { AttributePageLazy } from 'pages/attributes';

import MiniApp from '../app/miniApp/MiniApp';
import LocationCardContent from '../app/locations/Locations/LocationCard/LocationCardContent';
import NewBooking from '../app/bookings/NewBooking/NewBooking';
import BookingObjectsList from '../app/bookings/BookingItemsList';
import { BookingPageName } from '../app/bookings/types';
import NewSharing from '../app/bookings/NewSharing/NewSharing';

export type RouteInfo = {
  path: string;
  linkPath?: string;
  indexRoute?: true;
  Component: ComponentType<any>;
  allowedRoles: Roles[];
  isAllowedForUser?: (user: UserModel) => boolean;
  child?: Pick<RouteInfo, 'path' | 'Component'>;
  [prop: string]: unknown;
};

export const routesMap: Record<string, RouteInfo> = {
  main: {
    path: '/',
    indexRoute: true,
    Component: ActivitiesList,
    allowedRoles: [Roles.USER],
  },

  login: {
    path: '/login',
    Component: EntryPage,
    allowedRoles: [Roles.GUEST, Roles.USER],
  },

  captcha: {
    path: '/captcha',
    Component: CaptchaForm,
    allowedRoles: [Roles.GUEST, Roles.USER],
  },

  miniApp: {
    path: '/loc',
    Component: MiniApp,
    allowedRoles: [Roles.GUEST, Roles.USER],
  },

  miniAppActivities: {
    path: '/loc/:locationId',
    Component: ActivitiesList,
    allowedRoles: [Roles.GUEST],
  },

  sandbox: {
    path: '/sandbox',
    Component: Sandbox,
    allowedRoles: [Roles.USER],
  },

  qrLanding: {
    path: '/qr/:qrCode',
    Component: ActivitiesList,
    allowedRoles: [Roles.USER, Roles.GUEST],
  },

  newQRIssue: {
    path: '/qr/:qrCode/issues/new',
    Component: NewQRIssueLazy,
    allowedRoles: [Roles.USER, Roles.GUEST],
  },

  newIssue: {
    path: '/issues/new',
    Component: NewIssueLazy,
    allowedRoles: [Roles.USER],
  },

  miniAppLocationDetails: {
    path: '/loc/:locationId/details',
    Component: LocationCardContent,
    allowedRoles: [Roles.GUEST],
  },

  locationDetails: {
    path: '/locations/:locationId/details',
    Component: LocationCardContent,
    allowedRoles: [Roles.USER],
  },

  miniAppNewBooking: {
    path: '/loc/:locationId/bookings/new',
    Component: NewBooking,
    allowedRoles: [Roles.USER],
    list: BookingPageName.BOOK_FROM_ACTIVITY_LIST,
  },

  newBooking: {
    path: '/locations/:locationId/bookings/new',
    Component: NewBooking,
    allowedRoles: [Roles.USER],
    list: BookingPageName.BOOK_FROM_ACTIVITY_LIST,
  },

  newIssueMiniApp: {
    path: '/loc/:locationId/issues/new',
    Component: NewIssueLazy,
    allowedRoles: [Roles.USER, Roles.GUEST],
  },

  newOunceIssue: {
    path: '/issues/group/new',
    Component: NewOunceIssueLazy,
    allowedRoles: [Roles.USER],

    isAllowedForUser(user) {
      const OUNCE_MC_ID = 8;
      return user && user.management_company_id === OUNCE_MC_ID;
    },
  },

  createdByMeIssues: {
    path: '/issues/created_by_me',
    Component: IssuesCreatedByMeLazy,
    allowedRoles: [Roles.USER],
  },

  incomingIssues: {
    path: '/issues/incoming',
    Component: IncomingIssuesLazy,
    allowedRoles: [Roles.RESPONSIBLE, Roles.EXECUTIVE, Roles.BOSS, Roles.MANAGER],
  },

  issuesInProgress: {
    path: '/issues/in_progress',
    Component: IssuesInProgressLazy,
    allowedRoles: [Roles.RESPONSIBLE, Roles.BOSS, Roles.WATCHER],
  },

  issuesOnCheck: {
    path: '/issues/on_check',
    Component: IssuesOnCheckLazy,
    allowedRoles: [Roles.RESPONSIBLE, Roles.BOSS],
  },

  closedIssues: {
    path: '/issues/closed',
    Component: ClosedIssuesLazy,
    allowedRoles: [Roles.EXECUTIVE, Roles.RESPONSIBLE, Roles.MANAGER],
  },

  notifications: {
    path: '/notifications/',
    Component: NotificationPage,
    allowedRoles: [Roles.USER],
  },

  substitutions: {
    path: '/settings/substitutions/',
    Component: Substitutions,
    allowedRoles: [Roles.ADMIN],
  },

  issueTypes: {
    path: '/settings/issue_types/',
    Component: IssueTypes,
    allowedRoles: [Roles.ADMIN],
  },

  attributes: {
    path: '/settings/attributes/',
    Component: AttributePageLazy,
    allowedRoles: [Roles.ADMIN],
  },

  activities: {
    path: '/settings/activities',
    Component: ActivitiesSettingsPage,
    allowedRoles: [Roles.ADMIN],
  },

  users: {
    path: '/settings/users/',
    Component: UsersPage,
    allowedRoles: [Roles.ADMIN],
  },

  metaflows: {
    path: '/settings/metaflows/',
    Component: Metaflows,
    allowedRoles: [Roles.ADMIN],
  },

  locationTypes: {
    path: '/settings/location_types',
    Component: LocationTypes,
    allowedRoles: [Roles.ADMIN],
  },

  userRoles: {
    path: '/settings/roles',
    Component: UserRoles,
    allowedRoles: [Roles.ADMIN],
  },

  locations: {
    path: '/settings/locations/',
    Component: Locations,
    allowedRoles: [Roles.ADMIN],
  },

  companies: {
    path: '/settings/companies/',
    Component: Companies,
    allowedRoles: [Roles.ADMIN],
  },

  issueSchedules: {
    path: '/settings/issue_schedules',
    Component: IssueSchedules,
    allowedRoles: [Roles.ADMIN],
  },

  announcements: {
    path: '/settings/announcements',
    Component: AnnouncementsEditor,
    allowedRoles: [Roles.ADMIN, Roles.ANNOUNCEMENT_EDITOR],
  },

  profile: {
    path: '/profile/',
    Component: ProfilePage,
    allowedRoles: [Roles.USER],
  },

  reports: {
    path: '/reports/:id?',
    linkPath: '/reports',
    Component: Reports,
    allowedRoles: [Roles.USER],
    // child: {
    //   path: ':id',
    //   Component: Report,
    // },
    isAllowedForUser({ reports }) {
      return reports != null && reports?.length > 0;
    },
  },

  // report: {
  //   path: '/reports/:id',
  //   Component: Report,
  //   allowedRoles: [Roles.USER],
  //   isAllowedForUser(user) {
  //     return user.reports?.length > 0;
  //   },
  // },

  analyticalReports: {
    path: '/analytical-reports',
    Component: ReportsDashboard,
    allowedRoles: [Roles.DASHBOARD_WATCHER],
    isAllowedForUser: ({ reports }) => reports != null && reports?.length > 0,
  },

  reportsSettings: {
    path: '/reports-settings',
    Component: ReportsEditor,
    allowedRoles: [Roles.ANALYST],
  },

  metrics: {
    path: '/metrics',
    Component: MetersList,
    allowedRoles: [Roles.METERS_MANAGER],
  },

  inventory: {
    path: '/inventory',
    Component: InventoriesList,
    allowedRoles: [Roles.USER],
    isAllowedForUser: (user) => {
      if (user.roles.includes(Roles.INVENTORY_ACCOUNTANT)) {
        return true;
      }

      return !!user.isResponsibleForInventory;
    },
  },

  checkins: {
    path: '/checkins',
    Component: Checkins,
    allowedRoles: [Roles.CHECK_IN_WATCHER],
  },

  bookings: {
    path: '/settings/bookings',
    Component: Bookings,
    allowedRoles: [Roles.ADMIN, Roles.BOOKING_ADMIN],
    list: BookingPageName.ADMIN,
  },

  myBookings: {
    path: '/bookings/my_bookings',
    Component: MyBookings,
    allowedRoles: [Roles.BOOKINGS],
    list: BookingPageName.MY_BOOKINGS,
  },

  book: {
    path: '/bookings/book',
    Component: BookingObjectsList,
    allowedRoles: [Roles.BOOKINGS],
    list: BookingPageName.BOOK_FROM_BOOKINGS_SECTION,
  },

  share: {
    path: '/locations/:locationId/share',
    Component: NewSharing,
    allowedRoles: [Roles.USER],
  },

  qrcodeScanner: {
    path: '/qrcode/scanner',
    Component: QRcodeScanner,
    allowedRoles: [Roles.USER],
  },

  roundsReports: {
    path: '/rounds-reports',
    Component: RoundReports,
    allowedRoles: [Roles.ROUNDS_MONITOR],
  },

  inventoryReport: {
    path: '/inventory-report',
    Component: InventoryReport,
    allowedRoles: [Roles.SUPPLY_MANAGER],
  },

  employeesPermissions: {
    path: '/settings/employees-permissions',
    Component: EmployeesPage,
    allowedRoles: [Roles.TENANT_REPRESENTATIVE, Roles.ADMIN],
    isAllowedForUser() {
      return brandName === 'teorema';
    },
  },

  tenantDocs: {
    path: '/settings/tenant-documents',
    Component: TenantDocsPage,
    allowedRoles: [Roles.TENANT_REPRESENTATIVE, Roles.ADMIN],
    isAllowedForUser() {
      return brandName === 'teorema';
    },
  },

  fieldComparison: {
    path: '/field_comparison',
    Component: FieldsComparison,
    allowedRoles: [Roles.FIELD_COMPARISON],
  },

  externalLink: {
    path: '/external',
    Component: ExternalLink,
    allowedRoles: [Roles.USER],
  },

  qrcodeBooking: {
    path: '/book',
    Component: MiniApp,
    allowedRoles: [Roles.BOOKINGS, Roles.USER],
  },
};
