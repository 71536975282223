import allAttributeInputRenderers from './allAttributeInputRenderers';

export enum AttributeInputRendererType {
  ISSUE = 'issue',
  LOCATION = 'location',
  BOOKING_ITEM = 'bookingItem'
}

const getSupportedRenderers = (unsupportedTypes) => {
  return Object.keys(allAttributeInputRenderers).reduce((acc, renderer) => {
    if (unsupportedTypes.includes(renderer)) {
      return acc;
    }
    return { ...acc, [renderer]: allAttributeInputRenderers[renderer] };
  }, {});
};

export const slotsRenderers = ['time_slots', 'time_slots_by_days', 'sharing_slots_by_days', 'sharing_slots'];
const issueUnsupportedTypes = [...slotsRenderers];
const locationUnsupportedTypes = [...slotsRenderers];

const renderers = {
  [AttributeInputRendererType.ISSUE]: getSupportedRenderers(issueUnsupportedTypes),
  [AttributeInputRendererType.LOCATION]: getSupportedRenderers(locationUnsupportedTypes),
  [AttributeInputRendererType.BOOKING_ITEM]: getSupportedRenderers([]),
};
export const getRenderers = (rendererType: AttributeInputRendererType) => renderers[rendererType];
