import { useCallback } from 'react';
import { FormControlLabel, Checkbox as MUICheckbox } from '@mui/material';

const { block } = bem('Chackbox');

type Props = {
  checked: boolean;
  label?: string;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
};

export const Checkbox = ({ checked, label, onChange, disabled, className }: Props): JSX.Element => {
  const handleChange = useCallback(() => {
    onChange?.(!checked);
  }, [checked, onChange]);

  const CheckboxComponent = (
    <MUICheckbox
      className="Checkbox"
      value={checked}
      checked={checked}
      onChange={handleChange}
      sx={{ color: ({ palette }) => palette.primary.main }}
      disabled={disabled}
    />
  );

  return (
    <FormControlLabel {...block({}, className)} control={CheckboxComponent} label={label || null} />
  );
};
