import { Component } from 'react';
import { TextField } from '@mui/material';

import { isSafari } from 'shared/lib/check-browser';

import validators from '../../validators';
import { formatPhone } from '../../../../helpers/formatters';
import './Tel.scss';

export default class Tel extends Component<any, any> {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  static validate = [validators.mobilePhone()];

  input: any;

  selection: any;

  static format(value = '', previousValue = '') {
    if (!value) return value;
    return formatPhone(value);
  }

  static normalize(value) {
    return value
      .replace(/^(8)/, '+7')
      .replace(/^(7)/, '+7')
      .replace(/[^+\d]/g, '')
      .replace(/(\+\d{11}).+/, '$1');
  }

  render() {
    const { input, autoComplete, autofocus, fullWidth } = this.props;

    /* next string for Chrome autofill disable */
    const hiddenInput =
      autoComplete === 'off' ? (
        <input type="text" style={{ display: 'none' }} autoComplete="username" />
      ) : null;

    return (
      <>
        {hiddenInput}
        <TextField
          fullWidth={!!fullWidth}
          // type="tel"
          {...input}
          autoComplete={isSafari ? false : autoComplete || 'on'}
          // eslint-disable-next-line no-return-assign
          inputRef={(input) => (this.input = input)}
          placeholder="+7 123 123-45-67"
          onChange={this.handleChange}
          onKeyDown={this.saveCaretPosition}
          autoFocus={autofocus}
        />
      </>
    );
  }

  handleChange = (e) => {
    (this.props as any).input.onChange(e);
    this.loadCaretPosition();
  };

  saveCaretPosition = (e) => {
    const { selectionStart } = e.target;
    const strLen = (e.target.value || '').length;

    if (selectionStart < strLen) {
      let caretOffset = 0;

      if (e.key === 'Backspace') {
        caretOffset = -1;
      } else if (e.key === 'Delete') {
        caretOffset = 0;
      } else {
        caretOffset = 1;
      }

      this.selection = {
        selectionStart: selectionStart + caretOffset,
        selectionEnd: selectionStart + caretOffset,
      };
    }
  };

  loadCaretPosition() {
    requestAnimationFrame(() => {
      if (this.input && this.selection) {
        if (this.input.value.match(/\+\d$/)) {
          this.selection = {
            selectionStart: 2,
            selectionEnd: 2,
          };
        }

        if (this.input.value === '+7 9') {
          this.selection = {
            selectionStart: 4,
            selectionEnd: 4,
          };
        }

        Object.assign(this.input, this.selection || {});
      }

      this.selection = {};
    });
  }
}
