import { PureComponent } from 'react';
import { isNil } from 'lodash';

import './IssueRemainingTime.scss';
import { bem } from 'lib/bem';
import { Date, Duration } from 'lib/ui';
import { Icon } from 'lib/ui/icon';
import moment from 'moment';

const { block, element } = bem('IssueRemainingTime');

type OwnProps = {
  issue: any;
  short?: boolean;
  inprogress?: boolean;
};

type Props = OwnProps & typeof IssueRemainingTime.defaultProps;

export default class IssueRemainingTime extends PureComponent<Props> {
  static defaultProps = {
    short: false,
  };

  getDiff() {
    const { issue } = this.props;
    const expCloseDate = moment.utc(issue.close_date_expected);
    const diff = moment.utc(issue.close_date || undefined).diff(expCloseDate);
    return moment.duration(Math.abs(diff));
  }

  render() {
    const { issue, short, inprogress } = this.props;

    if (isNil(issue.close_date_expected)) return null;

    const diff = this.getDiff();

    return (
      <div {...block({ overdue: issue.overdue })}>
        <Icon
          {...element('icon')}
          glyph={!issue.overdue ? 'open' : inprogress ? 'overdue_inprogress' : 'overdue'}
        />

        {short && issue.overdue && (
          <div {...element('wrapper')}>
            <Date value={issue.close_date_expected} />
            <span {...element('status')}>{t('issues.issue_remaining_time_0')}</span>
          </div>
        )}

        {!issue.overdue && diff.asMinutes() >= 1 && <Duration duration={diff} />}
      </div>
    );
  }
}
