import { PureComponent } from 'react';
import { reconnect } from 'lib/resource';
import { openModal } from 'lib/ui/Modal';
import inventoriesResource from 'app/inventories/inventories.resource';
import './InventoryAttributeView.scss';

const { block, element } = bem('InventoryAttributeView');
export default reconnect((state, ownProps) => ({
  inventory: (inventoriesResource as any).byId(state, ownProps.inventoryId),
}))(class InventoryAttributeView extends PureComponent {
    static propTypes = {
      inventoryId: PropTypes.number.required,
      inventory: PropTypes.object.required,
    };

    render() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'inventory' does not exist on type 'Reado... Remove this comment to see the full error message
      const { data } = this.props.inventory;
      return (
        <div {...block()}>
          {data && (
          <div {...element('link')} onClick={this.handleOpenInventory}>
            {[data.type.name, data.inventory_number ? `№ ${data.inventory_number}` : ''].filter(Boolean).join(', ')}
          </div>
          )}
        </div>
      );
    }

    handleOpenInventory = () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dispatch' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.dispatch(openModal('inventory', this.props.inventory.data.id));
    };
});
