import './DocumentPreview.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { IconAsButton } from 'lib/ui/icon';
import { styled } from '@mui/material/styles';

const { block, element } = bem('DocumentPreview');

type OwnProps = {
  id?: number;
  url?: string;
  className?: string;
  removable?: boolean;
  name?: string;
  type?: string;
  onRemove?: (...args: any[]) => any;
  savable?: boolean;
};

type Props = OwnProps & typeof DocumentPreview.defaultProps;

export default class DocumentPreview extends PureComponent<Props> {
  static defaultProps = {
    savable: true,
  };

  render() {
    const { name, id, removable, savable, url } = this.props;

    const href = `/${url?.split('/').slice(3).join('/')}`;

    const StyledLink = styled('a')(({ theme }) => ({
      color: theme.palette.primary.main,
    }));

    return (
      <div {...block()}>
        <div {...element('content')}>
          <StyledLink {...element('link')} href={savable ? href : undefined} download={name}>
            {name}
          </StyledLink>
          {removable && (
            <IconAsButton {...element('remove')} onClick={this.handleRemove} glyph="close" />
          )}
        </div>
      </div>
    );
  }

  handleRemove = (e) => {
    this.props.onRemove?.(e);
    e.stopPropagation();
  };
}
