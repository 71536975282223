import { createResource } from 'lib/resource';
import { api } from 'lib/api';

const versionPrefix = 'v4';

export default createResource('sms', {
  scopes: {
    providersList: {
      request: () => api.get(`/${versionPrefix}/sms_settings/provider_names`),
      type: 'simpleList',
    },

    smsSettings: {
      request: () => api.get(`/${versionPrefix}/sms_settings/`),
      type: 'item',
    },
  },
  mutators: {
    changeSmsSettings: ({ payload }) => {
      const { id, ...body } = payload;
      return api.put(`/${versionPrefix}/sms_settings/${id}`, { body });
    },
  },
});
