import { memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import { ModalLink } from '..';
import './UniversalLink.scss';

interface Props {
  to:
    | string
    | {
        pathname: string;
        query: Record<string, any>;
      };
  external?: boolean;
  children: ReactNode;
  className?: string;
}

const { block } = bem('UniversalLink');

const UniversalLink = ({ to, external, className, children }: Props) => {
  if (external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...block({}, className)}>
        {children}
      </a>
    );
  }

  if (!external && typeof to !== 'string') {
    return (
      <ModalLink to="open_html" data={to.query.open_html} {...block({}, className)}>
        {children}
      </ModalLink>
    );
  }

  return (
    <Link to={to} {...block({}, className)}>
      {children}
    </Link>
  );
};

export default memo(UniversalLink);
