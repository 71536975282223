import { memo } from 'react';
import { TextField } from '@mui/material';
import { FieldValue } from '../../../FieldValue/FieldValue';
import './TextArea.scss';

const { block } = bem('TextArea');

const Textarea = ({ input, data }) => {
  const { maxLength, placeholder } = data;

  const readOnlyAtr = data.data ? data.data.read_only_on_create : false;
  if (readOnlyAtr) {
    return (
      <FieldValue>
        {input.value}
      </FieldValue>
    );
  }
  return (
    <TextField
      InputProps={block()}
      multiline
      rows={1}
      {...input}
      inputProps={{ maxLength, placeholder }}
      fullWidth
      size="small"
      variant="outlined"
      sx={{
        '& .MuiInputBase-multiline': {
          padding: 0,
        },
      }}
    />
  );
};

export default memo(Textarea);
