let initialId = 1;
function getId() {
  return initialId++;
}

export default function notify(
  message: {
    id: string;
    text: any;
    type?: string;
    persistent?: boolean;
  },
  options: { position?: 'center' | 'top-right' } = { position: 'top-right' },
) {
  return {
    type: 'SHOW_NOTIFICATION',
    message: {
      ...message,
      id: getId(),
    },
    ...options,
  };
}

export function hideNotification(message: { id: string; text: any; type?: string }) {
  return {
    type: 'HIDE_NOTIFICATION',
    message,
  };
}
