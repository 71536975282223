import { all, call, put } from 'redux-saga/effects';
import { takeMap } from 'lib/saga-utils';
import { contextNotification } from 'app/notifications';
import { issueSchedules } from 'app/issueSchedules';

const notify = contextNotification('issue_schedules');

export default function* issueSchedulesSaga() {
  yield all([
    call(issueSchedules.saga),

    call(takeMap({
      'ISSUESCHEDULES/UPDATE_SUCCESS': function* () {
        yield put(notify('updated'));
      },

      'ISSUESCHEDULES/CREATE_SUCCESS': function* () {
        yield put(notify('created'));
      },

      'ISSUESCHEDULES/REMOVE_SUCCESS': function* () {
        yield put(notify('removed'));
      },
    })),
  ]);
}
