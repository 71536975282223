import { createResource } from 'lib/resource';
import { api } from 'lib/api';

export default createResource('bookingItemTypes', {
  scopes: {
    list: ({ payload }) => {
      return api.get('/v3/booking_item_types/', { search: payload });
    },
  },
});
