import { PureComponent } from 'react';
import { keyBy } from 'lodash';
import { reconnect } from 'lib/resource';
import { FormBuilder } from 'lib/ui';
import userRoles from 'app/userRoles/userRoles.resource';
import './UserRolesSelect.scss';

const { block } = bem('UserRolesSelect');
export default reconnect((state) => ({
  roles: (userRoles as any).list(state, {
    include_system: 'true',
  }),
}))(class UserRolesSelect extends PureComponent<any> {
    static propTypes = {
      required: PropTypes.bool,
      multiple: PropTypes.bool,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      className: PropTypes.string,
    };

    static defaultProps = {
      className: '',
    };

    get roleOptions() {
      const { roles } = this.props;
      return roles.data && roles.data.map((item) => item.role);
    }

    render() {
      const {
        multiple, required, name, title, className, tooltip,
      } = this.props;
      const Component = multiple
        ? FormBuilder.MultiSelect
        : FormBuilder.Select;
      const validate = (multiple && required)
        ? [FormBuilder.validators.notEmpty()]
        : null;
      return (
        <Component
          {...block({}, className)}
          clearable
          searchable
          required={required}
          name={name}
          title={title}
          validate={validate}
          options={this.roleOptions}
          getItemValue={this.getItemValue}
          getItemLabel={this.getItemLabel}
          normalize={this.normalizeValue}
          tooltip={tooltip}
        />
      );
    }

    normalizeValue = (value) => (this.props.multiple
      ? (value || []).map((item) => item.role || item)
      : value && value);

    getItemValue(item) {
      return item;
    }

    getItemLabel = (item) => {
      const rolesIndex = keyBy(this.props.roles.data, 'role');
      const label = rolesIndex[item] ? rolesIndex[item].name : item;
      return label.charAt(0).toUpperCase() + label.slice(1);
    };
});
