import { keyBy } from 'lodash';
import { api } from 'lib/api';
import { createResource } from 'lib/resource';


const calculateContent = (checkin) =>
    checkin.location ? [...checkin.location.parent_names, checkin.location.name].join(', ') : checkin.data;


const addCheckinContent = (data) => {
  data.response.data && data.response.data.forEach((checkin) => {
    checkin.content = calculateContent(checkin);
  });
  return data
}


export const getCheckins = ({ payload }) => api.get('v3/check_ins/', { search: payload }).then(addCheckinContent);

export default createResource('checkins', {
  scopes: {
    checkins: {

      request: getCheckins,

      reducer: (state) => state,

      selector(data, index) {
        return data && data.map((i) => index[i]);
      },

      normalizer({ data }) {
        return {
          data: data.map((i) => i.id),
          index: keyBy(data, (i) => i.id),
        };
      },
    },
  },

});
