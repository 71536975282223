import './GeolocationStatus.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { Icon } from 'lib/ui/icon';

const { block } = bem('GeolocationStatus');

type Props = {
  status: string;
};

export default class GeolocationStatus extends PureComponent<Props> {
  render() {
    const { status } = this.props;

    return <Icon {...block({ [status]: true })} glyph="pin" />;
  }
}
