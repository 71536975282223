import { SyntheticEvent } from 'react';
import { Box, Typography } from '@mui/material';

const fieldViews = ['left', 'full', 'right'];

interface Props {
  onButtonClick: (evt: SyntheticEvent) => void;
  view: string;
}

const CarouselButtons = ({ onButtonClick, view }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '2rem',
        alignSelf: 'center',
        marginTop: '1rem',
      }}
      onClick={onButtonClick}
    >
      {fieldViews.map((item) => (
        <Typography
          data-view={item}
          sx={{
            cursor: 'pointer',
            textDecoration: ({ palette }) => (item === view ? `underline ${palette.success.main} 3px` : 'none'),
          }}
        >
          {/* TODO: #i18n Add translation key type check */}
          {t(`fields_comparison.buttons.${item}`, item)}
        </Typography>
      ))}
    </Box>
  );
};

export default CarouselButtons;
