import { api } from 'lib/api';
import { createResource } from 'lib/resource';

export default createResource('attachments', {
  scopes: {
    byIds({ payload }) {
      return api.get('/files/', {
        search: {
          ids: payload.join(','),
        },
      });
    },
  },

  mutators: {
    save({ payload }) {
      return api.post(`/files/${payload.id}/`, {
        body: {
          file_hash: payload.md5,
          mime_type: payload.type,
          user_file_name: payload.user_file_name,
        },
      });
    },
  },
});
