import { isEmpty, isObjectLike } from 'lodash';

import { t, t_prefixed } from '../i18n';

export const checkIfLocationCardIsVisible = (withLocationCards, location) => {
  if (isObjectLike(location) && !isEmpty(location)) {
    let result = withLocationCards && location.id !== 'suggested';
    if (result) {
      const { attributes: locationTypeAttributes } = location.location_type;
      if (locationTypeAttributes.length > 0) {
        locationTypeAttributes.some((attr) => {
          const { actions } = attr;
          result = actions.read || actions.update;
          return result;
        });
      } else {
        result = false;
      }
      return result;
    }
    return false;
  }
  return false;
};

const label = t_prefixed('locations.pages.edit_locations');

export const getLocationCardTitle = (location, readOnly, data = '') => {
  let id = '';
  let action = '';
  if (data && Number.isNaN(Number(data))) {
    [id, action] = data.split('.');
  }

  if (readOnly) {
    return t('locations.pages.location_card.title');
  }
  if (data && action === 'new') {
    return label('new_location');
  }
  if (location && location.name && action === 'copy') {
    return label('copy_location');
  }
  if (location && location.name) {
    return label('edit_location');
  }
  return label('new_location');
};

export const getLocationFullPath = (location) => {
  if (location) {
    const path = [...(location.parent_names || []), location.name];
    return path && path.length ? path.join(', ') : null;
  }
  return null;
};
