import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import DOMPurify from 'dompurify';
import { isNil, snakeCase } from 'lodash';
import { Box } from '@mui/material';

import { t, t_prefixed } from 'lib/i18n';
import { bem } from 'lib/bem';
import { Icon, IconAsButton } from 'lib/ui/icon';
import { getInputHints } from 'lib/helpers/formFields';
import Tooltip from 'lib/ui/Tooltip';
import { FormattedText } from 'lib/ui/FormattedText';

import Decline from '../../i18n/Decline';

const { block, element } = bem('FormField');

const tooltipTextKey = t_prefixed('tooltips');

const displayedErrors = [
  {
    errorName: 'maxLength',
    modelProp: 'maxCount',
    components: ({ maxCount }) => ({ decline: <Decline number={maxCount} /> }),
    values: ({ maxCount }) => ({ maxCount }),
  },
];

export const FormField = ({
  className,
  title,
  hint,
  isInvalid,
  isTouched,
  disabled,
  required,
  withoutText,
  icon,
  clearable,
  input,
  children,
  hintModifiers,
  errorName,
  labelStyle,
  labelStyleText,
  maxLength,
  tooltip,
  tooltipText,
  sx,
  options,
  adornment,
  hideMark,
  error,
  actionTitle,
  ...rest
}) => {
  const lastErrorInfo = !isNil(errorName) && displayedErrors.find((x) => x.errorName === errorName);
  const displayError = lastErrorInfo && !isNil(rest[lastErrorInfo.modelProp]);

  const errorText = displayError ? (
    <Trans
      i18nKey={`field_errors.${snakeCase(errorName)}`}
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Readonly<{}> & Readonly<{ childr... Remove this comment to see the full error message
      components={lastErrorInfo.components(rest)}
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Readonly<{}> & Readonly<{ childr... Remove this comment to see the full error message
      values={lastErrorInfo.values(rest)}
    />
  ) : null;

  const limit = useMemo(() => {
    if (maxLength) {
      return getInputHints({ value: { maxLength, value: input.value } }, 0)?.value;
    }
    return '';
  }, [input.value, maxLength]);

  const showClearButton = clearable && !!input.value && !(options || {}).isLoading;

  return (
    <Box
      {...block(
        {
          required,
          disabled,
          withoutText,
          invalid: isInvalid,
          touched: isTouched,
        },
        className,
      )}
      sx={sx}
    >
      {title && (
        <div {...element('label')} style={labelStyle}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <FormattedText text={title} {...element('labelText')} commonStyle={labelStyleText} />
            </Box>
            {(tooltip || tooltipText) && (
              <Tooltip {...element('tooltip')}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(tooltipText ?? tooltipTextKey(tooltip)),
                  }}
                />
              </Tooltip>
            )}
          </Box>

          {!hideMark && required && !withoutText && (
            <div {...element('requiredMark')}>{t('common.required_sign')}</div>
          )}

          {!!actionTitle && <div {...element('action')}>{actionTitle}</div>}
        </div>
      )}

      <div {...element('input')}>
        {adornment && <Icon glyph={adornment} {...element('adornment')} />}
        {showClearButton && (
          <IconAsButton
            className={element('clear').className}
            onClick={() => input.onChange(null)}
            glyph="close"
          />
        )}
        {children}
        {!title && (tooltip || tooltipText) && (
          <Tooltip {...element('tooltip-inline')}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(tooltipText ?? tooltipTextKey(tooltip)),
              }}
            />
          </Tooltip>
        )}
      </div>

      {(hint || limit) && (
        <div {...element('hint', maxLength ? { right: true } : hintModifiers)}>{hint || limit}</div>
      )}
      {icon && !input.value && <Icon glyph={icon} {...element('icon')} />}
      {errorText && <div {...element('error')}>{errorText}</div>}
      {error && <div {...element('loginError')}>{error}</div>}
    </Box>
  );
};
