/**
 * Detects current user device
 * see https://github.com/hgoebl/mobile-detect.js/
 */

import MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);

export default {
  is: {
    phone: md.phone(),
    tablet: md.tablet(),
    desktop: !md.phone() && !md.tablet(),
    iphone: md.is('iPhone'),
    ipad: md.is('iPad'),
  },
  on: {
    android: md.is('AndroidOS'),
  },
};
