import brandedData from 'config/brandedData';

import './AcceptTermsText.scss';
import { bem } from 'lib/bem';
import { Trans } from 'react-i18next';

const { block } = bem('AcceptTermsText');

const LicenseLink = ({ children = undefined, href }) => {
  return (
    <a href={href} target="_blank" onClick={(e) => e.stopPropagation()} rel="noreferrer">
      {children}
    </a>
  );
};

type AcceptTermsTextProps = {
  type?: 'registration' | 'external_login' | 'quick_login';
  isMiniApp?: boolean;
};

const eulaLink = '/api/eula/';
const privacyLink = '/api/privacy/';

const AcceptTermsText = ({ type, isMiniApp }: AcceptTermsTextProps) => {
  return (
    <div {...block({ miniApp: isMiniApp })}>
      <Trans
        i18nKey={`accept_terms.${brandedData.poweredBy}.${type}`}
        components={{
          eula: <LicenseLink href={eulaLink} />,
          privacy: <LicenseLink href={privacyLink} />,
        }}
      />
    </div>
  );
};

export default AcceptTermsText;
