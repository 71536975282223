import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import usersResource from 'app/users/users.resource';
import { UserModel } from 'app/users/types';

type UserType = { data?: UserModel };

type AuthContext = {
  userData?: UserModel;
};

const authContext = createContext<AuthContext>({});

export const AuthProvider = reconnect((state) => ({
  currentUser: usersResource.current(state, {}),
}))(({
  children,
  currentUser: { data: userData },
}: PropsWithChildren<{ currentUser: UserType }>) => {
  const value = useMemo<AuthContext>(
    () => ({
      userData,
    }),
    [userData],
  );

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
});

export const useAuthContext = () => useContext(authContext);
