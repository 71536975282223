import { useNavigate } from 'react-router-dom';

import { bem } from 'lib/bem';
import { t, t_prefixed } from 'lib/i18n';
import { Button, ReduxModal } from 'lib/ui';
import { Icon } from 'lib/ui/icon';
import { closeModal } from 'lib/ui/Modal';
import i18n from 'lib/i18n/i18n';

import './NewIssueSuccessModal.scss';

const { block, element } = bem('NewIssueSuccess');
const label = t_prefixed('issues.modals.new_issue_success');

type actionsList = 'open_profile';

export default ReduxModal({
  name: 'newIssueSuccess',
  getTitle: () => '',
  backdropClock: false,
})(({ dispatch, modalData: { anonymous, title, body, actions } }) => {
  const onButtonClick = () => {
    dispatch(closeModal('newIssueSuccess'));
  };

  const navigate = useNavigate();

  const actionsByName: Record<actionsList, () => void> = {
    open_profile: () => {
      dispatch(closeModal('newIssueSuccess'));
      navigate('/profile');
    },
  };

  const lang = i18n.language.toLowerCase();

  return (
    <div {...block()}>
      <Icon {...element('icon')} glyph="done" />
      <p {...element('title')}>
        {title} {body}
      </p>
      <Button {...element('button')} onClick={onButtonClick}>
        {anonymous ? t('common.ok') : label('submit_text')}
      </Button>

      {actions &&
        actions.map((item) => {
          if (actionsByName.hasOwnProperty(item.action)) {
            return (
              <Button
                ghost
                key={item.action}
                onClick={actionsByName[item.action]}
                {...element('button')}
              >
                {item.label[lang] || item.label.en}
              </Button>
            );
          }
          return undefined;
        })}
    </div>
  );
});
