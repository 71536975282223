import './LocationLabel.scss';
import { bem } from 'lib/bem';
import LocationFullPath from 'app/locations/LocationFullPath';

const { block } = bem('LocationLabel');

export default function LocationLabel({ location }) {
  return (
    <div {...block()}>
      <LocationFullPath location={location} beforeIcon="pin" />
    </div>
  );
}
