import { useMemo } from 'react';
import { bem } from 'lib/bem';
import { ReduxModal } from 'lib/ui';

import './BookingDetails.scss';
import BookingItemForm from '../BookingItemForm';
import { bookings } from '../index';
import { BookingItemAction, BookingPageName } from '../types';
import { BOOKING_DETAILS_MODAL } from '../config';

const { block } = bem('BookingDetails');

const defaultModalOptions = {
  name: BOOKING_DETAILS_MODAL,
  routing: true,
  getTitle: () => {
    return t('bookings.modals.details.title');
  },
  mapStateToProps: (state, { data, list, modalExtraData }) => {
    const finalList = (modalExtraData || {}).list || list;
    return {
      bookingItem:
        finalList === BookingPageName.MY_BOOKINGS
          ? (bookings as any).myBooking(state, data)
          : (bookings as any).byId(state, data),
      list: finalList,
    };
  },
};

const BookingDetails = ({ bookingItem, onClose, list }) => {
  const { data } = bookingItem.data;

  const bookingSlots = useMemo(() => {
    if (data) {
      return {
        start: data.actual_start_datetime,
        end: data.actual_end_datetime,
      };
    }
    return null;
  }, [data]);

  return (
    <div {...block()}>
      <BookingItemForm
        bookingItem={data.booking_item}
        bookingSlots={bookingSlots}
        action={BookingItemAction.READ}
        list={list}
        bookingId={data.id}
        onClose={onClose}
        bookingStatus={data.status.internal_name}
        author={data.author.full_name}
        company={data.author.company}
        comment={data.comment}
      />
    </div>
  );
};

export const createBookingItemCardWithCustomOptions = (customOptions) => {
  return ReduxModal({ ...defaultModalOptions, ...customOptions })(BookingDetails);
};

export default ReduxModal(defaultModalOptions)(BookingDetails);
