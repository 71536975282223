import { api } from '../api-methods';
import { getUrlDeleteUser, getUrlLocationTypesList } from './api-routes';
import { DeleteProfileResponseSchema, GetLocationTypesResponseSchema } from './data-contracts';

class UserApi {
  // Remove a location
  deleteUser() {
    return api.post<DeleteProfileResponseSchema>(getUrlDeleteUser());
  }
}

class LocationTypesApi {
  // Get location types list
  getLocationTypes() {
    return api.get<GetLocationTypesResponseSchema>(getUrlLocationTypesList());
  }
}

export const apiClient_v3 = {
  user: new UserApi(),
  locationTypes: new LocationTypesApi(),
};
