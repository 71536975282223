import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormGroup, Typography } from '@mui/material';

import { ProfileInfo, profileInfoSchema, SubmitProfileData } from 'entities/profile';
// eslint-disable-next-line import/no-cycle
import {
  ReactHookFormBuilder as FormBuilder,
  Form,
  useYupValidationResolver,
} from 'shared/ui/form';
import { t, t_prefixed } from 'lib/i18n';
import { Button, Checkbox } from 'lib/ui';
import { ConfirmEmailButton } from 'features/update-profile-info';

import './ProfileInfoForm.scss';

const { block, element } = bem('ProfileInfoForm');

interface ProfileInfoFormProps {
  initValues: ProfileInfo;
  onSubmit: (formData: SubmitProfileData) => void;
  email: string;
  isEmailConfirmed: boolean;
}

const label = t_prefixed('profile_form');

const notificationOptions = [
  {
    value: 'push',
    label: label('notification_types.push'),
  },
  {
    value: 'sms',
    label: label('notification_types.sms'),
  },
  {
    value: 'email',
    label: label('notification_types.email'),
  },
];

const CheckBoxGroup = () => {
  const { control } = useFormContext();
  const handleSelect = (value: string, fieldValue: string[] = []) => {
    return fieldValue.includes(value)
      ? fieldValue.filter((i) => i !== value)
      : [...fieldValue, value];
  };

  return (
    <Controller
      name="notifications"
      control={control}
      render={({ field }) => (
        <FormControl {...element('checkboxController')}>
          <Typography {...element('checkboxLabel')}>{label('notifications')}</Typography>
          <FormGroup {...element('checkboxGroup')}>
            {notificationOptions.map((item) => {
              return (
                <Checkbox
                  key={item.label}
                  label={item.label}
                  checked={!!field.value?.includes(item.value)}
                  onChange={() => field.onChange(handleSelect(item.value, field.value))}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      )}
    />
  );
};

const SubmitButton = () => {
  const {
    formState: { isDirty, isSubmitting },
  } = useFormContext();

  return (
    <Button type="submit" disabled={!isDirty || isSubmitting}>
      {t('common.save')}
    </Button>
  );
};

export const ProfileInfoForm = ({
  initValues,
  onSubmit,
  email,
  isEmailConfirmed,
}: ProfileInfoFormProps) => {
  const { resolver } = useYupValidationResolver(profileInfoSchema);

  return (
    <div {...block()}>
      <Form initValues={initValues} resolver={resolver} onSubmit={onSubmit}>
        <FormBuilder.Text required title={label('surname')} name="surname" />
        <FormBuilder.Text required title={label('name')} name="name" />
        <FormBuilder.Text title={label('patronymic')} name="patronymic" />
        <FormBuilder.Tel title={label('mobile')} name="mobile" />
        <ConfirmEmailButton savedEmail={email} isEmailConfirmed={isEmailConfirmed} />
        <CheckBoxGroup />
        <FormBuilder.Buttons left>
          <SubmitButton />
        </FormBuilder.Buttons>
      </Form>
    </div>
  );
};
