import './FileSelect.scss';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { bem } from 'lib/bem';
import { FormBuilder } from 'lib/ui';
import { Icon } from 'lib/ui/icon';
import { openModal } from 'lib/ui/Modal';

const { block, element } = bem('FileSelect');

const FileSelect = connect()(
  class FileSelect extends PureComponent<any> {
    static propTypes = {
      value: PropTypes.array,
      uploaderComponent: PropTypes.func,
      filePreviewList: PropTypes.func,
      uploaderText: PropTypes.string,
      onChange: PropTypes.func,
      maxCount: PropTypes.number,
      minCount: PropTypes.number,
      readonly: PropTypes.bool,
      isPhoto: PropTypes.bool,
    };

    static defaultProps = {
      uploaderText: t('file_uploader.button_text'),
    };

    render() {
      const {
        maxCount,
        minCount,
        readonly,
        uploaderText,
        uploaderComponent,
        disableGallery = false,
        isPhoto,
      } = this.props;

      const uploadedFiles =
        typeof (this.props as any).value === 'number'
          ? [(this.props as any).value]
          : (this.props as any).value || [];
      const isMaxLimitReached = maxCount - uploadedFiles.length <= 0;

      const Uploader = uploaderComponent;

      return (
        <div {...block()}>
          <Uploader
            disableGallery={disableGallery}
            {...this.props}
            onClose={this.handleClose}
            name={this.getModalName()}
            maxCount={maxCount - uploadedFiles.length}
            minCount={minCount - uploadedFiles.length}
          />

          {(this.props as any).filePreviewList(this.props, this.handleRemove)}

          <div {...element('title')}>
            {!isMaxLimitReached && !readonly && (
              <button onClick={this.openUploader} {...element('addButton')}>
                <Icon
                  {...element('icon')}
                  glyph={isPhoto ? 'add_photo' : 'add'}
                  width={24}
                  height={24}
                  sx={{
                    fill: '#3878FF',
                  }}
                />
              </button>
            )}
          </div>
        </div>
      );
    }

    openUploader = () => {
      (this.props as any).dispatch(openModal(this.getModalName(), true));
    };

    getModalName() {
      return `fileUploader_${(this.props as any).name}`;
    }

    handleClose = (files) => {
      const uploadedFiles =
        typeof (this.props as any).value === 'number'
          ? [(this.props as any).value]
          : (this.props as any).value || [];
      if (files) {
        (this.props as any).onChange([...uploadedFiles, ...files.map((file) => file.id)]);
      }
    };

    handleRemove = (fileId) => {
      const uploadedFiles =
        typeof (this.props as any).value === 'number'
          ? [(this.props as any).value]
          : (this.props as any).value || [];
      (this.props as any).onChange(uploadedFiles.filter((p) => p !== fileId));
    };
  },
);

export default function (props) {
  return <FormBuilder.Custom {...props} component={FileSelect} />;
}
