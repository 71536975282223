import { ReduxModal } from '../Modal';
import './OpenHtmlModal.scss';

function isImageUrl(url) {
  return /jpg|jpeg|png|gif|svg/.test(url);
}

export default ReduxModal({
  name: 'open_html',
  routing: true,
  getTitle: () => '',
})(({ data }) => {
  if (isImageUrl(data)) {
    return <img className="responsive-img" src={data} alt="" />;
  }

  return <iframe title="Open URL" src={data} />;
});
