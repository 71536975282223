import './Gallery.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { connect } from 'react-redux';

import { Icon } from 'lib/ui/icon';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Box } from '@mui/material';

const { block, element } = bem('Gallery');
export default connect<any, any, any>((state, ownProps) => ({
  files: (ownProps as any).files.filter(Boolean).map((i) => ({ original: i.url })),
}))(
  class Gallery extends PureComponent<any, any> {
    static propTypes = {
      fileIds: PropTypes.array,
      files: PropTypes.array,
      title: PropTypes.string,
      startFrom: PropTypes.number,
    };

    state = {
      currentSlide: 0,
    };

    componentDidMount() {
      this.handleSlide(this.props.startFrom || 0);
    }

    render() {
      const { files, startFrom } = this.props;
      if (!this.hasFiles()) return null;
      return (
        <div {...block()}>
          {this.renderTitle()}

          <div {...element('content')}>
            <ImageGallery
              items={files}
              startIndex={startFrom}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              slideDuration={200}
              onSlide={this.handleSlide}
              renderLeftNav={this.renderLeftNav}
              renderRightNav={this.renderRightNav}
            />
          </div>
        </div>
      );
    }

    renderTitle() {
      return (
        <div {...element('title')}>
          {this.props.title} {this.renderCounter()}
        </div>
      );
    }

    renderCounter() {
      const { files } = this.props;
      const { currentSlide } = this.state;
      if (files.length <= 1) return '';
      return `${currentSlide + 1}/${files.length}`;
    }

    renderLeftNav(onClick) {
      return (
        <Box bgcolor="primary.main" {...element('prev')} onClick={onClick}>
          <Icon glyph="arrow" />
        </Box>
      );
    }

    renderRightNav(onClick) {
      return (
        <Box bgcolor="primary.main" {...element('next')} onClick={onClick}>
          <Icon glyph="arrow" />
        </Box>
      );
    }

    hasFiles() {
      const { files } = this.props;
      return files && files.length > 0;
    }

    handleSlide = (slideIndex) => {
      this.setState({
        currentSlide: slideIndex,
      });
    };
  },
);
