import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';

import { bem } from 'lib/bem';
import brandedData from 'config/brandedData';

import './PrivacyFooter.scss';

type Props = {
  isChecked: boolean;
  handleChangeCheckbox: () => void;
};

const { block, element } = bem('privacy-footer');

const LicenseLink = ({ children, href }: { children?: ReactNode; href: string }) => {
  return (
    <a href={href} target="_blank" onClick={(e) => e.stopPropagation()} rel="noreferrer">
      {children}
    </a>
  );
};

export const PrivacyFooter = ({ isChecked, handleChangeCheckbox }: Props) => {
  return (
    <div {...block()}>
      <div>
        <FormControlLabel
          label={
            <Trans
              i18nKey={`accept_terms.${brandedData.poweredBy}.checkbox_text`}
              components={{
                saas: <LicenseLink href={t(`accept_terms.${brandedData.poweredBy}.links.saas`)} />,
                privacy: (
                  <LicenseLink href={t(`accept_terms.${brandedData.poweredBy}.links.privacy`)} />
                ),
              }}
            />
          }
          control={<Checkbox checked={isChecked} onChange={handleChangeCheckbox} />}
        />
      </div>
      <div {...element('footer')}>{t(`accept_terms.${brandedData.poweredBy}.footer_text`)}</div>
    </div>
  );
};
