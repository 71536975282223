import { PureComponent } from 'react';
import { Button } from 'lib/ui';
import './Switch.scss';

const { block, element } = bem('Switch');

type Props = {
    value?: any;
    options: any[];
    onChange: (...args: any[]) => any;
};

export default class Switch extends PureComponent<Props> {
  render() {
    const { options, value, onChange } = this.props;

    return (
      <div {...block()}>
        {options.map((option) => (
          <Button
            small
            ghost
            {...element('option', { active: value === option.value })}
            key={option.value}
            onClick={(e) => onChange(option.value)}
          >
            {(option.renderer || this.defaultRenderer)(option)}
          </Button>
        ))}
      </div>
    );
  }

  defaultRenderer = (option) => option.label;
}
