import { useCallback } from 'react';
import { TreeView as MuiTreeView } from '@mui/x-tree-view/TreeView';

import { bfsSearch } from 'shared/lib/bfs-search';
import { ROOT_NODE_ID, getAllChildren, isAllChildrenChecked } from 'shared/lib/tree-view-helpers';
import { ManagedObjectTreeNode } from 'shared/api/v4/swagger/data-contracts';
import { bem } from 'lib/bem';

import { AsyncTreeViewItem } from './ui/async-tree-view-item/AsyncTreeViewItem';
import './AsyncTreeView.scss';

const { block } = bem('AsyncTreeView');

export interface TreeNode<T = ManagedObjectTreeNode> {
  id: number;
  name: string;
  hasChildren: boolean;
  data?: T;
  parentId?: number;
  parentIds?: string[];
  children?: TreeNode[];
}

export interface TreeData {
  [nodeId: string]: {
    parentId?: number;
    children: TreeNode[];
  };
}

interface Props {
  treeData: TreeData;
  expandedNodeIds: string[];
  selectedNodeIds: string[];
  changeSelectedNodes: (nodes: string[]) => void;
  onToggleNode: (node: TreeNode) => void;
  multiSelect?: boolean;
  isSearch?: boolean;
}

export const AsyncTreeView = ({
  treeData,
  expandedNodeIds,
  onToggleNode,
  selectedNodeIds,
  changeSelectedNodes,
  multiSelect,
  isSearch,
}: Props) => {
  const onSelectNode = useCallback(
    (node: TreeNode) => {
      if (!multiSelect) {
        changeSelectedNodes([node.id.toString()]);
        return;
      }

      const allChild = getAllChildren(treeData, node.id) ?? [];

      if (selectedNodeIds.includes(node.id.toString())) {
        changeSelectedNodes(
          selectedNodeIds.filter((id) => !allChild.concat(node.parentIds ?? []).includes(id)),
        );
        return;
      }

      const toBeChecked = allChild.filter((i) => !selectedNodeIds.includes(i));

      node.parentIds?.forEach((parent) => {
        const searchedNode = bfsSearch(treeData, Number(parent));
        if (
          searchedNode &&
          isAllChildrenChecked(treeData, searchedNode, toBeChecked, selectedNodeIds) &&
          !isSearch
        ) {
          toBeChecked.push(parent);
        }
      });

      changeSelectedNodes([...selectedNodeIds].concat(toBeChecked));
    },
    [changeSelectedNodes, multiSelect, selectedNodeIds, treeData, isSearch],
  );

  const isIndeterminate = useCallback(
    (node: TreeNode, list: string[] = []) => {
      if (!multiSelect) return false;

      const allChild = getAllChildren(treeData, node.id) ?? [];

      const nodeIdIndex = allChild.indexOf(String(node.id));
      allChild.splice(nodeIdIndex, 1);

      const selectedNodesArray = selectedNodeIds.concat(list);
      return allChild.some((nodeId) => selectedNodesArray.includes(String(nodeId)));
    },
    [multiSelect, treeData, selectedNodeIds],
  );

  const renderTree = (children: TreeNode[]) => {
    return children.map((child) => {
      const childrenNodes =
        treeData[child.id] && treeData[child.id].children?.length > 0
          ? renderTree(treeData[child.id].children)
          : [<div key={child.id} />];

      return (
        <AsyncTreeViewItem
          key={child.id}
          node={child}
          onToggle={onToggleNode}
          onSelectNode={onSelectNode}
          childNodes={childrenNodes}
          selected={selectedNodeIds.includes(String(child.id))}
          isIndeterminate={isIndeterminate(child)}
          multiple={multiSelect}
        />
      );
    });
  };

  return (
    <MuiTreeView {...block()} expanded={expandedNodeIds}>
      {treeData?.[String(ROOT_NODE_ID)]
        ? renderTree(treeData[String(ROOT_NODE_ID)].children)
        : 'no data'}
    </MuiTreeView>
  );
};
