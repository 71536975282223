import { PropsWithChildren } from 'react';
import { Button as ButtonMUI, ButtonProps } from '@mui/material';

import { bem } from 'lib/bem';

import './TextButton.scss';

const { block } = bem('TextButton');

interface TextButtonProps extends ButtonProps {
  color?: 'info' | 'primary' | 'error';
  isUnderline?: true;
}

export const TextButton = (textButtonProps: PropsWithChildren<TextButtonProps>) => {
  const { color = 'info', isUnderline, children, ...props } = textButtonProps;
  return (
    <ButtonMUI {...props} {...block({ [color]: true, underline: isUnderline })} variant="text">
      {children}
    </ButtonMUI>
  );
};
