import './Date.scss';
import { bem } from 'lib/bem';

import { convertDateToLocal } from '../../helpers/dates';

const { block, element } = bem('Date');

const FORMATS = {
  long: 'D MMMM YYYY',
  short: 'DD.MM.YY',
};

const TIME = ' HH:mm';

export default function Date({
  value,
  showIcon = true,
  showTime = true,
  format = 'long',
  className,
}: any) {
  if (!value) {
    return null;
  }

  const date = convertDateToLocal(value);

  return (
    <span {...block({}, className)}>
      {date?.format(FORMATS[format])}
      {showTime && format === 'long' && <span>,</span>}
      {showTime && <span {...element('time')}>{date?.format(TIME)}</span>}
    </span>
  );
}
