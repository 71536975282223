import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import { TextFieldButton } from 'shared/ui/text-field-button';

// eslint-disable-next-line import/no-cycle
import { ConfirmEmailModal } from './confirm-email-modal/ConfirmEmailModal';

interface ConfirmEmailButtonProps {
  savedEmail: string;
  isEmailConfirmed: boolean;
}

export const ConfirmEmailButton = ({ savedEmail, isEmailConfirmed }: ConfirmEmailButtonProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const openConfirmModal = () => {
    setOpen(true);
  };

  return (
    <>
      <ConfirmEmailModal
        isOpen={open}
        onClose={() => setOpen(false)}
        savedEmail={savedEmail}
        isEmailConfirmed={isEmailConfirmed}
      />
      <Box>
        <TextFieldButton
          onClick={openConfirmModal}
          value={savedEmail}
          label={t('profile_form.email')}
        />
        {!isEmailConfirmed && savedEmail && (
          <Typography sx={{ color: '#FF3B30' }}>{t('profile_form.confirm_message')}</Typography>
        )}
      </Box>
    </>
  );
};
