import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';
// eslint-disable-next-line import/no-cycle
import { Divider } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import { Date, FieldValue, Page } from 'lib/ui';
import { bem } from 'lib/bem';
import { Avatar } from 'lib/ui/Avatar';
import { useAuthContext } from 'app/providers';
import { DeleteProfileButton, ProfileInfoForm } from 'features/update-profile-info';
import ChangePassword from 'app/users/Profile/ChangePassword/ChangePassword';
import CreatePassword from 'app/users/Profile/CreatePassword/CreatePassword';
import ConfirmCodeModal from 'app/users/ConfirmCodeModal/ConfirmCodeModal';
import { ProfileInfo, ProfileInfoUpdate, SubmitProfileData } from 'entities/profile';
import { CaptchaModel } from 'app/captcha/types';
import users from 'app/users/users.resource';
import { openModal } from 'lib/ui/Modal';
import { useCaptchaModal } from 'app/captcha/lib/useCaptchaModal';
import brandedData from 'config/brandedData';

import './ProfilePage.scss';

const { block, element } = bem('ProfilePage');

export const ProfilePage = () => {
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const dispatch = useDispatch();
  const showCaptchaModal = useCaptchaModal();

  const initValues: ProfileInfo = useMemo(() => {
    return {
      surname: userData?.surname ?? '',
      name: userData?.name || '',
      patronymic: userData?.patronymic ?? '',
      notifications: userData?.notifications ?? [],
      mobile: userData?.mobile ?? '',
      email: userData?.email ?? '',
    };
  }, [userData]);

  const handleSubmitProfileForm = useCallback(
    async (formData: SubmitProfileData) => {
      if (!userData) return;

      const isMobileChanged = formData.mobile !== userData.mobile;
      if (!formData.confirmation_code && isMobileChanged) {
        showCaptchaModal()
          .then((captcha: CaptchaModel) => {
            users.pushConfirmationCode({
              mobile: (formData.mobile || '').replace(/^(8)/, '+7').replace(/[^+\d]/g, ''),
              action: 'confirm_mobile',
              captcha_uuid: captcha.uuid,
              captcha_data: captcha.data,
            });
          })
          .then(() => {
            const data = {
              id: userData.id,
              ...formData,
            };
            dispatch(openModal('confirmCode', data));
          });
      } else {
        // TODO: перевести в react-query
        const data: ProfileInfoUpdate = {
          name: formData.name ?? '',
          patronymic: formData.patronymic,
          surname: formData.surname ?? '',
          notifications: formData.notifications ?? [],
          mobile: formData.mobile ?? '',
          confirmation_code: formData.confirmation_code,
        };
        await users.updateProfile(data);
      }
    },
    [dispatch, showCaptchaModal, userData],
  );

  return (
    <Page {...block()} pageTitle={t('profile_page.title')} title={t('profile_page.title')}>
      {userData && (
        <>
          <Box {...element('header')}>
            <Avatar url={userData.photo?.url} border width="56px" height="56px" />
            <FieldValue name={t('profile_form.created')} {...element('createdDate')}>
              <Date value={userData.created} />
            </FieldValue>
          </Box>
          <ConfirmCodeModal onSubmit={handleSubmitProfileForm} />
          <ProfileInfoForm
            initValues={initValues}
            onSubmit={handleSubmitProfileForm}
            email={userData.email ?? ''}
            isEmailConfirmed={!!userData.is_email_confirmed}
          />

          {brandedData.loginOptions.hasPassword &&
            (userData.has_password ? <ChangePassword /> : <CreatePassword />)}
          <Divider {...element('divider')} />
          <DeleteProfileButton />
        </>
      )}
    </Page>
  );
};
