export enum NotificationsPositionEnum {
  CENTER = 'center',
  TOP_RIGHT = 'top-right',
}

export type NotificationMessage = {
  id: string | number;
  text: string;
  type?: string;
  persistent?: boolean;
};

export type NotificationSubscriber = (notifications: NotificationMessage[]) => void;
