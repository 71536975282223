import { parseToString } from 'shared/lib/json-big-numbers-parser';
// eslint-disable-next-line import/no-cycle
import { authRoutine, fetchWithContext, getOptions, getUrl } from 'shared/api/utils';

const dataPreparingRoutine = async (data) => {
  const {
    response,
    context: { options },
  } = data;

  const status = response.status >= 200 && response.status < 300 ? 'response' : 'error';

  return response
    .text()
    .then((text) => {
      const resp = parseToString(text);
      const meta = resp?.metadata ?? {};
      const total = meta?.pagination?.total ?? null;
      return {
        request: options,
        total: response.headers.get('total') || total,
        responseHeaders: response.headers,
        httpStatusCode: response.status,
        [status]: resp || {},
      };
    })
    .catch(() => ({
      request: options,
      [status]: {},
    }));
};

const send = (url: string, options: RequestInit) =>
  fetchWithContext(url, options).then(authRoutine).then(dataPreparingRoutine);

function getMethod(method) {
  return function (url, options = {}) {
    return send(
      getUrl(url, (options as any).search || (options as any).params),
      getOptions(method, options),
    );
  };
}

export const api = {
  get: getMethod('GET'),
  post: getMethod('POST'),
  put: getMethod('PUT'),
  delete: getMethod('DELETE'),
  patch: getMethod('PATCH'),
};
