import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';
import { FormBuilder } from 'lib/ui';
import { formatTreeValue, normalizeTreeValue } from 'lib/queryForm';

import roundReportsResource from '../roundReports.resource';

const getName = (location) => location.name;

export default reconnect((state, props) => ({
  locations: roundReportsResource.locationsTree(
    state,
    {
      search: props.search,
    },
    { skipFetchState: true },
  ),
}))((props) => {
  const { locations } = props;

  if (locations?.fetching) return <BusyIndicator />;

  return (
    <FormBuilder.TreeViewSelect
      name="location_ids"
      tree={locations.data}
      getLabel={getName}
      normalize={normalizeTreeValue(true)}
      format={formatTreeValue(true, locations.data)}
      multiple
      {...props}
    />
  );
});
