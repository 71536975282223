import './TreeNodeLabel.scss';
import { PureComponent } from 'react';
import { bem } from 'lib/bem';
import { Label } from 'lib/ui';

const { block } = bem('TreeNodeLabel');

type Props = {
    item: any;
    tree: any;
    getLabel?: (...args: any[]) => any;
};

export default class TreeNodeLabel extends PureComponent<Props> {
  render() {
    return (
      <Label {...block()}>
        {getItemFullPath(this.props.item.id, this.props.tree).map(this.getLabel).join(' › ')}
      </Label>
    );
  }

  getLabel = (item) => (this.props.getLabel || ((value) => value.name))(item);
}

function getItemFullPath(id, tree) {
  const { byId } = tree;

  let item = byId[id];
  const result = [item];

  while (item.$parent && item.$parent.name) {
    item = item.$parent;
    result.push(item);
  }

  return result.reverse();
}
