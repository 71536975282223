import './LocationTypesSelect.scss';
import { PureComponent } from 'react';
import { reconnect } from 'lib/resource';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { FormBuilder } from 'lib/ui';
import locationTypes from '../locationTypes.resource';

const { block } = bem('LocationTypesSelect');
export default reconnect((state, search) => ({
  locationTypes: (locationTypes as any).list(state, { search }),
}))(class LocationTypesSelect extends PureComponent<any, any> {
    static propTypes = {
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      multiple: PropTypes.bool,
    };

    render() {
      const {
        name, title, multiple, locationTypes, normalized,
      } = this.props;
      const Field = multiple ? FormBuilder.MultiSelect : FormBuilder.Select;
      return (
        <Field
          {...block()}
          {...this.props}
          name={name}
          title={title || t('location_types.title')}
          options={locationTypes.data ? this.getOptions() : []}
          getItemLabel={this.getItemLabel}
          disabled={!locationTypes.data}
          searchable
          clearable
          modifyOptions={this.addNewOptionItem}
          getItemValue={this.getItemValue}
          maxSearchLength={30}
          addable
          normalize={normalized ? this.normalize : undefined}
          isLoading={locationTypes.fetching}
        />
      );
    }

    getItemLabel(item) {
      if (item.label === 'bc') { return t('location_types.bc'); }
      if (item.value === 'new') { return `${t('common.new_type')} ${item.name}`; }
      return item.label;
    }

    getItemValue = (item) => {
      if (typeof item !== 'object') return item;
      return item.value;
    };

    getOptions = () => {
      const { data } = this.props.locationTypes;
      return (data || [])
        .map((i) => ({
          value: i.id,
          label: i.name_ru,
        }));
    };

    addNewOptionItem = (options, search) => {
      const { allowsAddNewItem } = this.props;
      if (!allowsAddNewItem) return options;
      if (!search) return options;
      if (options.find(({ label }) => label.toLowerCase() === search.toLowerCase())) return options;
      return [
        {
          value: 'new',
          name: search,
        },
        ...options,
      ];
    };

    normalize = (value) => {
      const { multiple } = this.props;
      if (!value) {
        return value;
      }
      if (multiple) {
        return value.map((x) => this.getItemValue(x));
      }
      return this.getItemValue(value);
    };
});
