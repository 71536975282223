import { memoize } from 'lodash';
import i18n from 'i18next';

const Typograf = require('typograf');

const tp = new Typograf({ locale: [i18n.language.startsWith('en') ? 'en-US' : 'ru'] });

tp.disableRule('*');
tp.enableRule('common/nbsp/*');
tp.enableRule('ru/nbsp/*');
tp.enableRule('common/html/url');
tp.enableRule('common/html/e-mail');

export default memoize((text) => tp.execute(text));
