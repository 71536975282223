import './RoundGroupModal.scss';
import { Suspense, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import roundReportsResource from 'app/roundsReports/roundReports.resource';
import { bem } from 'lib/bem';
import { IssueDate, IssueStatus } from 'app/issues/IssueFields';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { AttributesView } from 'app/issues/Attributes';
import { TRoundGroupItem } from 'app/roundsReports/types';
import ChildIssuesList from 'app/roundsReports/ChildIssuesList';
import { openModal } from 'lib/ui/Modal';
import { Date, Loader, ReduxModal } from 'lib/ui';
import { IssueDetailsLazy } from 'app/issues/lazy';

const { block, element } = bem('RoundGroup');

function getTitle(issue) {
  const location = issue.managed_object.split(' — ').join(', ');
  return (
    <div {...element('title')}>
      <div {...element('date')}>
        <div>{t('issues.status.created')}</div>
        <IssueDate issue={issue} />
      </div>

      {issue.close_date && (
        <div>
          <div {...element('date')}>
            <div>{t('issues.status.closed')}</div>
            <Date value={issue.close_date} format="long" />
          </div>
          {issue.overdue && issue.close_date_expected && (
            <div {...element('overdue')}>
              <p>{t('issues.status.overdue_text')}</p>
              <Date value={issue.close_date_expected} showTime={false} />
            </div>
          )}
        </div>
      )}

      <div {...element('title__name')}>
        <div {...element('title__name_title')}>{issue.name}</div>
        <IssueStatus
          value={issue.current_status}
          showIcon={false}
          showInProgress
          overdue={issue.overdue}
        />
      </div>
      <div {...element('title__location')}>{location}</div>
    </div>
  );
}

interface Props {
  groups: { data: { data: TRoundGroupItem } };
}

export default ReduxModal({
  name: 'roundGroup',
  routing: false,
  getTitle: (_, { data }) => getTitle(data),
  mapStateToProps: (state, { data }) => {
    const { id } = data;
    return {
      groups: roundReportsResource.roundGroups(state, id),
    };
  },
})((props: Props) => {
  const dispatch = useDispatch();

  const childRounds = props.groups.data.data.child_rounds;
  const relatedIssues = props.groups.data.data.related_issues;

  const { issue } = props.groups.data.data;

  const renderAttributes = useCallback((item) => {
    if (item.attributes && !isEmpty(item.attributes)) return <AttributesView issue={item} />;
    return <div {...element('no_data')}>{t('common.no_results')}</div>;
  }, []);

  const onRowClick = useCallback(
    (item) => {
      dispatch(openModal('issue', item.id));
    },
    [dispatch],
  );

  const renderChildRounds = useCallback(() => {
    return childRounds.map((item, index) => (
      <Accordion disableGutters key={item.id} {...element('child')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}a-content`}
          id={`panel${index}a-header`}
          {...element('child__summary')}
        >
          <div {...element('point-title')}>
            <CheckCircleIcon
              {...element('point-title', {}, item.current_status === 'closed' ? 'closed' : 'open')}
            />
            <div>{item.issue_type.name}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 2, pb: 2 }}>{renderAttributes(item)}</AccordionDetails>
      </Accordion>
    ));
  }, [childRounds, renderAttributes]);

  return (
    <div {...block()}>
      <Suspense fallback={<Loader />}>
        <IssueDetailsLazy listMode="my" />
      </Suspense>
      <AttributesView issue={issue} />
      {childRounds.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>{renderChildRounds()}</Box>
      )}
      {relatedIssues.length > 0 && <ChildIssuesList data={relatedIssues} onRowClick={onRowClick} />}
    </div>
  );
});
