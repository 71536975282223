import type { TreeData } from 'shared/ui/async-tree-view';

export const bfsSearch = (tree: TreeData, targetId: number) => {
  const queue = [...tree['0'].children];

  while (queue.length > 0) {
    const currNode = queue.shift();
    if (currNode?.id === targetId) {
      return currNode;
    }

    if (currNode?.hasChildren && !!tree[currNode.id]) {
      queue.push(...tree[currNode.id].children);
    }
  }
  return null;
};
