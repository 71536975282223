import { ReactEventHandler, useMemo } from 'react';
import { MUIStyledCommonProps } from '@mui/system';
import { styled } from '@mui/material/styles';

import { useImageLoaded } from 'lib/hooks/useImageLoaded';

type UrlIconProps = {
  url: string;
  onImgSrcError?: ReactEventHandler<HTMLImageElement>;
  className?: string;
  waitLoad?: boolean;
} & Pick<MUIStyledCommonProps, 'sx'>;

const StyledImg = styled('img')``;

export const UrlIcon = ({ url, onImgSrcError, sx, className = 'icon', waitLoad }: UrlIconProps) => {
  const [ref, loaded, onLoad] = useImageLoaded();

  const imgProps = useMemo(
    () => ({
      onError: onImgSrcError,
      src: url,
      className: `${className} ${waitLoad ? (loaded ? '' : 'Icon-hidden') : ''}`,
      ref,
      onLoad,
      alt: '',
    }),
    [className, loaded, onImgSrcError, onLoad, ref, url, waitLoad],
  );

  return <StyledImg sx={sx} {...imgProps} />;
};
