import { bem } from 'lib/bem';
import { GlyphName, Icon } from 'lib/ui/icon';

import './LocationFullPath.scss';

const { block } = bem('LocationFullPath');

type Props = {
  location: any;
  beforeIcon?: GlyphName;
};

const LocationFullPath = ({ location, beforeIcon }: Props) => {
  if (location) {
    const path = [...(location.parent_names || []), location.name];

    return (
      <div {...block()}>
        <div>
          {beforeIcon != null && <Icon glyph={beforeIcon} className="LocationLabel__icon" />}
          <p>{path && path.length ? path.join('  ›  ') : null}</p>
        </div>
      </div>
    );
  }
  return null;
};

export default LocationFullPath;
