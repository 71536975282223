import { useCallback, useEffect, useMemo, useState } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { useNavigate } from 'react-router-dom';
import { flowRight } from 'lodash';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  List,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';

import { brandedThemeOptions } from 'lib/ui/AppThemeProvider/theme';
import { appFullVersion } from 'config/constants';
import { withRouter } from 'config/withRouter';
import { reconnect } from 'lib/resource';
import { Logo } from 'lib/ui';
import { Icon } from 'lib/ui/icon';
import { bem } from 'lib/bem';
import { routesMap } from 'config/routesMap';
import { useAuthContext } from 'app/providers';
import { notificationsQueries } from 'entities/notification';
import { DotBadge } from 'shared/ui/dot-badge';
import CustomScrollbars from 'lib/ui/CustomScrollbars/CustomScrollbars';
import device from 'lib/device';
import { Badge } from 'lib/ui/Badge';

import brandedData from '../../config/brandedData';
import { menuItems } from './menuItems';
import MenuUserBar from './MenuUserBar';
import MenuLoginButton from './MenuLoginButton';
import { MenuItem } from './MenuItem';
import MenuToggleButton from './MenuToggleButton';
import './Menu.scss';

const { block, element } = bem('Menu');

function preventBodyScrolling() {
  document.documentElement.style.overflow = 'hidden';
}

function allowBodyScrolling() {
  document.documentElement.style.overflow = 'scroll';
}

const useStyles = (drawerWidth) =>
  makeStyles(() => ({
    drawerRoot: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      width: drawerWidth,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  }));

export default flowRight(
  withRouter,
  enhanceWithClickOutside,
  reconnect((state) => ({
    expanded: state.menu.expanded,
    hidden: state.menu.hidden,
  })),
)(({ dispatch, hidden, expanded, location }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const { data } = useQuery({ ...notificationsQueries.unseenCount, refetchInterval: 300000 });
  const { userData } = useAuthContext();

  const handleScreenWidthChange = useCallback(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  const smallScreen = screenWidth < 1200 || !device.is.desktop;

  const trueExpanded = smallScreen && expanded;

  const drawerWidth = trueExpanded ? '100%' : hidden ? 48 : 290;

  const handleBack = () => {
    navigate(routesMap.main.path);
  };

  const toggleMenu = useCallback(() => {
    dispatch({
      type: hidden ? 'SHOW_MENU' : 'HIDE_MENU',
    });
  }, [dispatch, hidden]);

  const userMenuItems = useMemo(() => (userData ? menuItems(userData) : []), [userData]);

  const handleAppBarClick = useCallback(
    (evt) => {
      evt.stopPropagation();

      if (hidden) {
        dispatch({
          type: 'SHOW_MENU',
        });
      }
      if (
        evt.currentTarget.dataset?.group === 'true' &&
        evt.target.classList.contains('_withIcon')
      ) {
        return;
      }
      dispatch({
        type: trueExpanded ? 'COLLAPSE_MENU' : 'EXPAND_MENU',
      });
    },
    [dispatch, hidden, trueExpanded],
  );

  const renderMenuItem = useCallback(
    (item: any, index: number) => (
      <MenuItem
        hidden={hidden}
        key={item.subMenu?.length === 1 ? item.subMenu[0].label : item.label}
        item={item}
        index={index}
        pathname={location.pathname}
        smallScreen={smallScreen}
        onClick={handleAppBarClick}
        user={userData}
      />
    ),
    [hidden, location.pathname, smallScreen, handleAppBarClick, userData],
  );

  const renderItems = useCallback(
    () => (
      <List
        className="Menu-items"
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          '& > div': {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          },
        }}
      >
        <CustomScrollbars>
          {userMenuItems.map((item, index) => {
            if (item.id === 'notifications') {
              return !device.is.phone ? (
                <Badge content={data && 'response' in data ? data.response.data.count : 0}>
                  {renderMenuItem(item, index)}
                </Badge>
              ) : undefined;
            }

            return renderMenuItem(item, index);
          })}
        </CustomScrollbars>
      </List>
    ),
    [data, renderMenuItem, userMenuItems],
  );

  useEffect(() => {
    window.addEventListener('resize', handleScreenWidthChange);
    return () => {
      window.removeEventListener('resize', handleScreenWidthChange);
    };
  }, [handleScreenWidthChange]);

  useEffect(() => {
    if (smallScreen) {
      if (trueExpanded) {
        preventBodyScrolling();
      } else {
        allowBodyScrolling();
      }
    }
  }, [expanded, smallScreen, trueExpanded]);

  const { drawerRoot } = useStyles(drawerWidth)();

  return (
    <Box
      sx={{
        width: drawerWidth,
        height: '90%',
        flexShrink: 0,
      }}
      {...block()}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          display: smallScreen ? 'inherit' : 'none',
          boxShadow: 'none',
          top: device.is.phone ? '49px' : '0px',
        }}
      >
        <Toolbar
          sx={{
            borderBottom: trueExpanded ? '1px solid rgb(204, 204, 204, 0.5)' : 'none',
            minHeight: '68px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {location.pathname === routesMap.notifications.path ? (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleBack}
              >
                <Icon glyph="arrow_back" />
              </IconButton>
            ) : (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleAppBarClick}
              >
                <Icon glyph="menu" width={24} height={24} />
              </IconButton>
            )}
            <Logo
              width={brandedThemeOptions.menuLogoWidth}
              height={brandedThemeOptions.menuLogoHeight}
            />
          </Box>
          {device.is.phone && location.pathname !== routesMap.notifications.path && (
            <Box>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => navigate(routesMap.notifications.path)}
              >
                <DotBadge
                  {...element('badge')}
                  variant="dot"
                  invisible={data && 'response' in data && data.response.data.count === 0}
                  color="error"
                >
                  <Icon glyph="notification_bell_icon" width={24} height={24} />
                </DotBadge>
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        {...block()}
        PaperProps={{
          sx: {
            mt: trueExpanded ? '117px' : 0,
            overflowX: 'hidden',
            bgcolor: 'primary.main',
            display: !expanded && smallScreen ? 'none !important' : '',
          },
          classes: {
            root: drawerRoot,
          },
          className: 'Menu-content',
        }}
        variant="permanent"
        anchor="left"
        classes={{
          root: drawerRoot,
        }}
        sx={{ visibility: smallScreen && !expanded ? 'hidden' : 'visible' }}
      >
        <Box
          className="Menu-container"
          sx={{
            display: 'flex',
            paddingBottom: smallScreen ? '60px' : 0,
            flexGrow: 1,
            flexDirection: 'column',
            color: brandedThemeOptions.menuTextColor || '#FFF',
            fill: brandedThemeOptions.menuTextColor || '#FFF',
          }}
        >
          <Box
            sx={{
              mt: 2,
              ml: hidden ? '12px' : 2,
              display: trueExpanded ? 'none' : 'inherit',
            }}
          >
            <Logo
              width={hidden ? 24 : brandedThemeOptions.menuLogoWidth}
              height={hidden ? 24 : brandedThemeOptions.menuLogoHeight}
              hidden={hidden}
            />
          </Box>
          <Box
            sx={
              hidden
                ? {
                    visibility: 'hidden',
                    whiteSpace: 'nowrap',
                    mt: trueExpanded ? 2 : 8,
                  }
                : { mt: trueExpanded ? 2 : 8 }
            }
          >
            {userData?.isGuest ? (
              <MenuLoginButton {...element('login')} />
            ) : (
              userData && <MenuUserBar {...element('user')} currentUser={userData} />
            )}
          </Box>
          {renderItems()}
        </Box>
        {!smallScreen && (
          <>
            <MenuToggleButton hidden={hidden} onClick={toggleMenu} />
            <Typography
              fontSize="10px"
              lineHeight="20px"
              sx={{
                color: brandedThemeOptions.menuTextColor || '#FFF',
                margin: '2px auto 2px 20px',
                visibility: hidden ? 'hidden' : 'visible',
                whiteSpace: 'nowrap',
              }}
            >
              {appFullVersion}
            </Typography>
          </>
        )}
      </Drawer>
    </Box>
  );
});
