import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, getFormValues, change, Form } from 'redux-form';
import { flow } from 'lodash';
import { Tooltip } from '@mui/material';

import { t } from 'lib/i18n';
import { FormBuilder, Button } from 'lib/ui';
import { Icon } from 'lib/ui/icon';
import { createCaptcha, validateCaptcha } from 'app/captcha/captcha.api';
import { useMounting } from 'lib/hooks/useMounting';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';
import { CaptchaModel } from 'app/captcha/types';

import './CaptchaForm.scss';

const formName = 'captchaForm';

const { block, element } = bem('CaptchaForm');

interface Props {
  values?: Record<string, any>;
  proceed?: (captcha: CaptchaModel) => Promise<any>;
}

const CaptchaForm = flow<any, any, any>(
  reconnect((state) => {
    return {
      values: getFormValues(formName)(state),
    };
  }),
  reduxForm({
    form: formName,
  }),
)((props: Props) => {
  const { values, proceed } = props;

  const [captcha, setCaptcha] = useState<any>(null);

  const init = useCallback(() => {
    createCaptcha().then(({ response }) => {
      setCaptcha(response.data);
    });
  }, []);

  useMounting(init);

  const invalid = useMemo(() => {
    if (!values) {
      return true;
    }
    return !values.code;
  }, [values]);

  const dispatch = useDispatch();

  const retry = useCallback(() => {
    createCaptcha().then(({ response }) => {
      setCaptcha(response.data);
      dispatch(change(formName, 'code', ''));
    });
    return false;
  }, [dispatch]);

  const [submitting, setSubmitting] = useState(false);

  const validate = useCallback(
    (e) => {
      e.preventDefault();
      if (!invalid && captcha) {
        setSubmitting(true);
        validateCaptcha({
          data: values?.code?.toString(),
          uuid: captcha.uuid,
        }).then(({ httpStatusCode }) => {
          if (httpStatusCode === 400) {
            retry();
          } else {
            const windowObject = window as any;
            if (
              windowObject.webkit &&
              windowObject.webkit.messageHandlers &&
              windowObject.webkit.messageHandlers.captcha
            ) {
              windowObject.webkit.messageHandlers.captcha.postMessage(
                JSON.stringify({
                  data: values?.code.toString(),
                  uuid: captcha?.uuid,
                }),
              );
            }
            try {
              if (JsToAndroidBridge && JsToAndroidBridge.sendData) {
                JsToAndroidBridge.sendData(
                  JSON.stringify(
                    JSON.stringify({
                      data: values?.code?.toString(),
                      uuid: captcha.uuid,
                    }),
                  ),
                );
              } else {
                console.error('JsToAndroidBridge is undefined');
              }
            } catch {
              console.log('error');
            }
            if (proceed) {
              return proceed({
                data: values?.code?.toString(),
                uuid: captcha.uuid,
              });
            }
          }
          setSubmitting(false);
        });
      }
    },
    [captcha, invalid, proceed, retry, values],
  );

  const onRetryClick = (evt: MouseEvent) => {
    evt.preventDefault();
    retry();
  };

  if (!captcha) {
    return null;
  }

  const onEnterPress = (evt) => {
    if (evt.key === 'Enter' && !invalid) {
      validate(evt);
    }
  };

  return (
    <div className={proceed ? '' : 'CaptchaMobileModal'} onKeyDown={onEnterPress}>
      <p {...element('title')}>{t('captcha.modal_title')}</p>
      <Form {...block()} onSubmit={validate}>
        <div {...element('captcha-container')}>
          <img src={captcha.data} alt="" />
          <Tooltip title={t('captcha.other_code')} arrow placement="top">
            <button onClick={onRetryClick} {...element('other-code-label')}>
              <Icon glyph="refresh" />
            </button>
          </Tooltip>
        </div>
        <div {...element('input-container')}>
          <FormBuilder.Text
            {...element('captcha-input')}
            name="code"
            autoComplete="off"
            autoFocus
            type="tel"
          />
          <Button
            {...element('validate-button')}
            disabled={invalid || submitting}
            onClick={validate}
            type="submit"
          >
            {submitting ? <BusyIndicator size={16} /> : t('captcha.submit_button')}
          </Button>
        </div>
      </Form>
    </div>
  );
});

export default CaptchaForm;
