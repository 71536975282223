import { getDateAsString } from 'lib/helpers/dates';
import { READABLE_DATE } from 'lib/constants/dates';

export interface FootballField {
  id: number;
  name: string;
  full_path: string;
}

export const getFieldLabel = (item: FootballField) => {
  return item.full_path;
};

export const getFieldValue = (item: FootballField) => {
  if (!item) return null;
  return item.id;
};

export const getDateLabel = (date) => getDateAsString(date, READABLE_DATE);
