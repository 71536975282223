import { api } from '../api-methods';
import { LocationApiSchema } from '../v2/data-contracts';
import { getSaveIssueChanges, getUrlLocationById } from './api-routes';

class IssuesApi {
  saveIssueChanges(payload: { id: number; attributes: Record<string, unknown> }) {
    const { id, attributes } = payload;
    return api.post(getSaveIssueChanges(id), {
      body: {
        attributes,
      },
    });
  }
}

class LocationApi {
  // Get location by ID
  getLocationById(payload: { id: number }) {
    return api.get<LocationApiSchema>(getUrlLocationById(payload.id));
  }
}

export const apiClient_v1 = {
  issues: new IssuesApi(),
  locations: new LocationApi(),
};
