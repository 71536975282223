import { ComponentType, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryParams } from 'lib/helpers/useQueryParams';
import { ScopeResult } from 'lib/resource';
import { FormBuilder } from 'lib/ui';
import { Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { Col } from 'lib/ui/Col';
import { compose } from 'redux';
import { reduxForm, initialize, getFormValues } from 'redux-form';
import LocationByRoleSelect from 'app/analyticalReports/ui/LocationByRoleSelect';
import { t_prefixed } from 'lib/i18n';

import { IssueTypesTreeSelect } from '../../../issueTypes';
import { LocationTreeSelect } from '../../../locations';
import { LocationTypesSelect } from '../../../locationTypes';
import { UserModel } from '../../../users/types';
import usersResource from '../../../users/users.resource';
import {
  dashboardFiltersFormConfig,
  getInitialWidgetFilters,
  saveWidgetFilters,
  WidgetFiltersValues,
} from './model';

const tr = t_prefixed('analytical_reports.filters');

interface Props {
  sx?: SxProps;
}

interface InjectedProps {
  currentUser: ScopeResult<UserModel>;
  values: WidgetFiltersValues;
}

const Filters = ({ sx, currentUser, values }: Props & InjectedProps) => {
  const dispatch = useDispatch();

  const { setQuery } = useQueryParams();

  useEffect(() => {
    if (currentUser.data) {
      dispatch(
        initialize(dashboardFiltersFormConfig.form, getInitialWidgetFilters(currentUser.data)),
      );
    }
  }, [currentUser.data, dispatch]);

  useEffect(() => {
    if (values && currentUser.data) {
      setQuery(values as unknown as Record<string, unknown>);
      saveWidgetFilters(currentUser.data, values);
    }
  }, [currentUser.data, setQuery, values]);

  return (
    <Col
      sx={{
        borderBottom: ({ palette }) => `1px solid ${palette.separators?.main}`,
        ...sx,
      }}
    >
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <LocationByRoleSelect title={tr('location')} name="locationId" forQueryForm multiple />
        </Grid>
        <Grid item xs={6} sx={{ mt: 'auto' }}>
          <FormBuilder.DateRange title={tr('date_period.label')} name="customDatePeriod" />
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <LocationTypesSelect
            name="locationTypeId"
            clearable
            title={tr('location_type')}
            normalized
          />
        </Grid>
        <Grid item xs={6}>
          <IssueTypesTreeSelect
            name="issueTypeId"
            clearable
            title={tr('issue_type')}
            forQueryForm
          />
        </Grid>
      </Grid>
    </Col>
  );
};

export default compose<ComponentType<Props & InjectedProps>, any, ComponentType<Props>>(
  reduxForm(dashboardFiltersFormConfig),
  reconnect((state) => {
    return {
      currentUser: usersResource.current(state, {}),
      values: getFormValues(dashboardFiltersFormConfig.form)(state),
    };
  }),
)(Filters);
