import { api } from 'lib/api';

function uploadCreatedFile(photo, shoulContinue) {
  if (!shoulContinue()) return null;

  return ({ response }) => api.patch(`/files/${response.id}/`, {
    body: photo.resized ? photo.resized.buffer : photo.buffer,

    headers: {
      'Content-Type': 'application/octet-stream',
      Offset: 0,
    },
  });
}

export default function uploadPhoto(photo, shouldContinue) {
  if (!shouldContinue()) return null;

  return api.post('/files/')
    .then(uploadCreatedFile(photo, shouldContinue));
}
