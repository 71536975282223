import { MutationCache, QueryClient } from '@tanstack/react-query';

import { notifyMutationSuccess } from './notifyMutationSuccess';

const mutationCache = new MutationCache({
  onSuccess: (_, __, ___, mutation) => {
    notifyMutationSuccess(mutation);
  },
});

export const queryClient = new QueryClient({
  mutationCache,
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      // Период в течении которого результаты запросов будут браться из кеша вместо сети
      staleTime: 1000 * 60 * 5, // 5 мин
    },
  },
});
