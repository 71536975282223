import createSagaMiddleware from 'redux-saga';
import appSaga from 'app/app.saga';
import app, { appReducer } from 'app/app.reducer';
import { createStore, compose, applyMiddleware } from 'redux';

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  let store;

  if ((window as any).__REDUX_DEVTOOLS_EXTENSION__) {
    store = createStore(app, compose(applyMiddleware(sagaMiddleware), (window as any).__REDUX_DEVTOOLS_EXTENSION__()));
  } else {
    store = createStore(app,
      compose(
        applyMiddleware(sagaMiddleware),
      ));
  }

  sagaMiddleware.run(appSaga);

  return store;
}

export const store = configureStore();

export type RootState = ReturnType<typeof appReducer>
export type AppDispatch = typeof store.dispatch
