import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, getFormValues, initialize, isValid, reduxForm } from 'redux-form';
import { flow } from 'lodash';

import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { Button, FormBuilder } from 'lib/ui';
import users from 'app/users/users.resource';
import UserPasswordField from 'app/users/UserPasswordField';
import AcceptTermsText from 'app/entry/AcceptTermsText';
import { PrivacyFooter } from 'shared/ui/privacy-footer';

import PhoneStoreLinks from '../../PhoneStoreLinks';
import './Login.scss';

const { block, element } = bem('Login');
const valuesSelector = getFormValues('login');
const isValidSelector = isValid('login');
const { internationalMobilePhone } = FormBuilder.validators;

export default flow<any, any, any>(
  connect((state) => ({
    values: valuesSelector(state),
    isValidForm: isValidSelector(state),
    mobileNumber: (state as any).login.mobileNumber,
  })),
  reduxForm({
    form: 'login',
  }),
)((props) => {
  const { isValidForm, values, dispatch, mobileNumber, isNeedCheckbox } = props;

  const [isError, setIsError] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [isChecked, setChecked] = useState(!isNeedCheckbox);

  useEffect(() => {
    dispatch(initialize('login', { mobile: mobileNumber }));
  }, []);

  const login = ({ mobile, password }) => {
    try {
      users.login({
        mobile: mobile.replace(/[^+\d]/, ''),
        password,
      });
    } catch (e) {
      setIsError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login({
      mobile: (values.mobile || '').replace(/^(8)/, '+7').replace(/[^+\d]/g, ''),
      password: values.password,
    });
    return false;
  };

  const handleChangeCheckbox = () => {
    setChecked(!isChecked);
  };

  return (
    <div
      {...block({
        isTouched: submitPressed,
        invalid: !isValidForm,
      })}
    >
      <div {...element('wrap')}>
        <PhoneStoreLinks />

        <Form onSubmit={handleSubmit}>
          <FormBuilder.Tel
            adornment={
              !(values && values.mobile) || internationalMobilePhone()(values.mobile)
                ? undefined
                : 'check_circle'
            }
            required
            props={{ autoFocus: true }}
            name="mobile"
            title={t('users.fields.mobile')}
            validate={[internationalMobilePhone()]}
            autofocus
            fullWidth
            hint={t('login.hint')}
            hideMark
          />

          <UserPasswordField
            hideMark
            error={isError ? t('field_errors.invalid_login') : undefined}
            required
            blockAutoFill={false}
            isInvalid={isError}
          />

          {isNeedCheckbox && (
            <PrivacyFooter isChecked={isChecked} handleChangeCheckbox={handleChangeCheckbox} />
          )}

          <Button disabled={!isChecked} type="submit">
            {t('login.submit')}
          </Button>
          {!isNeedCheckbox && <AcceptTermsText type="registration" />}
        </Form>
      </div>
    </div>
  );
});
