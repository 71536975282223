import { memo, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { BookingStatus, DayInfo, Slot } from '../types';
import DaySlotsView from './DaySlotsView';
import { isDateIsoWeekend } from '../../../../../helpers/dates';
import { useMounting } from '../../../../../hooks/useMounting';
import './SharingDaySlots.scss';

interface InputValueProps {
  sharedSlots: Slot[];
  unsharedSlots: Slot[];
}

interface Props {
  input: {
    value: InputValueProps;
    onChange: (value: InputValueProps) => void;
  };
  data: {
    value: DayInfo[];
    initialValue: InputValueProps;
    data: {
      hint: string;
    };
  };
}

const { block, element } = bem('SharingDaySlots');

const SharingDaySlots = ({
  input: {
    value = {
      sharedSlots: [],
      unsharedSlots: [],
    },
    onChange,
  },
  data: {
    value: initialSlots,
    data: { hint: attrHint },
  },
}: Props) => {
  const [internalValue, setInternalValue] = useState<InputValueProps>({
    sharedSlots: [],
    unsharedSlots: [],
  });

  const handleInit = useCallback(() => {
    const sharedSlots: Slot[] = [];
    const unsharedSlots: Slot[] = [];

    initialSlots.forEach((x) => {
      if (x.status === BookingStatus.AVAILABLE) {
        sharedSlots.push(x.slots[0]);
      } else if (x.status === BookingStatus.UNAVAILABLE) {
        unsharedSlots.push(x.slots[0]);
      }
    });

    setInternalValue({
      sharedSlots,
      unsharedSlots,
    });

    onChange({
      sharedSlots: [],
      unsharedSlots: [],
    });
  }, [initialSlots, onChange]);

  useEffect(() => {
    handleInit();
  }, [handleInit]);

  const getSlotWrapperModifiers = useCallback((day: DayInfo): Record<string, boolean> => {
    const modifiers: Record<string, boolean> = { monthDay: true };
    return modifiers;
  }, []);

  const getSlotModifiers = useCallback(
    ({ slots, date }: DayInfo): Record<string, boolean> => {
      const currentSlot = slots[0];
      const isSlotSelected = !!(
        currentSlot && internalValue.sharedSlots.find((x) => x.id === currentSlot.id)
      );
      const modifiers = {
        weekEnd: isDateIsoWeekend(date),
        selected: Boolean(isSlotSelected),
      };
      return modifiers;
    },
    [internalValue.sharedSlots],
  );

  const shareSlot = useCallback(
    (selectedSlot: Slot) => {
      const newValue = { ...internalValue };
      newValue.sharedSlots.push(selectedSlot);
      newValue.unsharedSlots = newValue.unsharedSlots.filter((x) => x.id !== selectedSlot.id);
      setInternalValue(newValue);

      const newFieldValue = { ...value };
      const foundSharedSlot = newFieldValue.sharedSlots.find((x) => x.id === selectedSlot.id);
      const foundUnsharedSlot = newFieldValue.unsharedSlots.find((x) => x.id === selectedSlot.id);
      if (foundUnsharedSlot) {
        newFieldValue.unsharedSlots = newFieldValue.unsharedSlots.filter(
          (x) => x.id !== selectedSlot.id,
        );
      }
      if (!foundSharedSlot && !foundUnsharedSlot) {
        newFieldValue.sharedSlots.push(selectedSlot);
      }
      onChange(newFieldValue);
    },
    [internalValue, onChange, value],
  );

  const unshareSlot = useCallback(
    (selectedSlot: Slot) => {
      const newValue = { ...internalValue };
      newValue.unsharedSlots.push(selectedSlot);
      newValue.sharedSlots = newValue.sharedSlots.filter((x) => x.id !== selectedSlot.id);
      setInternalValue(newValue);

      const newFieldValue = { ...value };
      const foundSharedSlot = newFieldValue.sharedSlots.find((x) => x.id === selectedSlot.id);
      const foundUnsharedSlot = newFieldValue.unsharedSlots.find((x) => x.id === selectedSlot.id);
      if (foundSharedSlot) {
        newFieldValue.sharedSlots = newFieldValue.sharedSlots.filter(
          (x) => x.id !== selectedSlot.id,
        );
      }
      if (!foundUnsharedSlot && !foundSharedSlot) {
        newFieldValue.unsharedSlots.push(selectedSlot);
      }
      onChange(newFieldValue);
    },
    [internalValue, onChange, value],
  );

  const handleSlotSelection = useCallback(
    ({ status, slots }: DayInfo) =>
      () => {
        const selectedSlot = slots[0];

        const foundSharedSlot = internalValue.sharedSlots.find((x) => x.id === selectedSlot.id);
        const foundUnsharedSlot = internalValue.unsharedSlots.find((x) => x.id === selectedSlot.id);

        if (foundSharedSlot) {
          unshareSlot(selectedSlot);
        } else if (foundUnsharedSlot) {
          shareSlot(selectedSlot);
        }
      },
    [internalValue.sharedSlots, internalValue.unsharedSlots, shareSlot, unshareSlot],
  );

  const checkIfSlotButtonDisabled = useCallback(({ status }: DayInfo) => {
    return status === BookingStatus.BOOKED || status === BookingStatus.EMPTY_SLOTS;
  }, []);

  return (
    <Box {...block()}>
      {attrHint && <Box {...element('hint')}>{attrHint}</Box>}
      <DaySlotsView
        initialSlots={initialSlots}
        getSlotWrapperModifiers={getSlotWrapperModifiers}
        getSlotModifiers={getSlotModifiers}
        onSlotSelection={handleSlotSelection}
        checkIfSlotButtonDisabled={checkIfSlotButtonDisabled}
      />
    </Box>
  );
};

export default memo(SharingDaySlots);
