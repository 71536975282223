import { memoize } from 'lodash';

export default class Attribute {
  _id: any;

  id: any;

  pattern: any;

  validate: any;

  constructor(data) {
    Object.assign(this, data);

    this._id = data.id;
    // Prefer to use internal_name as attribute id
    this.id = data.internal_name || data.id;
    this.pattern = new RegExp(data.validator || '');

    this.validate = memoize((value) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'required' does not exist on type 'Attrib... Remove this comment to see the full error message
      const { pattern, required } = this;

      if (required && !value) {
        return false;
      }

      if (value && !(pattern.test(value))) {
        return false;
      }

      return true;
    });
  }
}
