import { memo } from 'react';
import { getLocationCardTitle } from 'lib/helpers/location';
import { ReduxModal } from 'lib/ui';

import locations from '../../locations.resource';
import LocationCardContent from './LocationCardContent';
import { SAVE_LOCATION_MODAL } from '../../config';

export default ReduxModal({
  name: SAVE_LOCATION_MODAL,
  routing: true,

  getTitle: (data, { locationData, readOnly }) =>
    getLocationCardTitle(locationData.data, readOnly, data),

  mapStateToProps: (state, { data }) => {
    return {
      locationData: (locations as any).byId(state, String(data).split('.')[0], {
        skipOutdated: true,
      }),
    };
  },
})(memo(LocationCardContent));
