import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import isPointWithinRadius from 'geolib/es/isPointWithinRadius';
import { touch } from 'redux-form';

import {
  TStatus,
  getGeoStatus,
  GeoStatus,
} from 'lib/ui/FormBuilder/types/Geolocation/GeolocationStatus';
import VerifyLocationView from 'lib/ui/VerifyLocationView';

interface IPosition {
  lat: number;
  lon: number;
}

const Geolocation = ({ data: { data }, input: { onChange, name } }) => {
  const dispatch = useDispatch();
  const { coordinates } = data;

  const lat = (coordinates && coordinates.lat) || null;
  const lon = (coordinates && coordinates.lon) || null;

  const initialStatus = !coordinates || !data.options.radius ? GeoStatus.setting_error : null;

  const [currentPosition, setCurrentPosition] = useState<IPosition | null>(null);
  const [status, setStatus] = useState<TStatus | null>(initialStatus);

  const onGetClick = useCallback(() => {
    setStatus(GeoStatus.loading);
    if (!navigator.geolocation) {
      setStatus(GeoStatus.unavailable);
    } else {
      navigator.geolocation.watchPosition(
        (position) => {
          setCurrentPosition({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (err) => {
          if (err.code === 1) {
            setStatus(GeoStatus.blocked);
          } else setStatus(GeoStatus.error);
        },
        {
          enableHighAccuracy: true,
        },
      );
    }
  }, []);

  const statusView = useMemo(() => getGeoStatus(status, onGetClick), [onGetClick, status]);

  useEffect(() => {
    if (currentPosition) {
      if (isPointWithinRadius(currentPosition, { lat, lon }, data.options.radius)) {
        onChange(currentPosition);
        setStatus(GeoStatus.success);
      } else {
        setStatus(GeoStatus.out);
        dispatch(touch('issueAttributes', name));
      }
    }
  }, [currentPosition, data.options.radius, dispatch, lat, lon, name, onChange]);

  return <VerifyLocationView cta={data.cta} statusView={statusView} name={data.name} />;
};

export default Geolocation;
