import './UserPasswordField.scss';
import { t_prefixed } from 'lib/i18n';
import { FormBuilder } from 'lib/ui';

const fieldLabel = t_prefixed('users.fields');

const { validators } = FormBuilder;
const maxLength64 = validators.maxLength(64);

const passwordValidators = [
  validators.password(),
  validators.minLength(6),
  maxLength64,
];

// Workaround for chrome fields autocomplete
function BlockAutoFill() {
  return (
    <input
      type="password"
      name="block-autocomplete"
      style={{
        opacity: 0.0, width: '1px', height: '1px', position: 'absolute',
      }}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
      tabIndex="-1"
    />
  );
}

export default function UserPasswordField(props) {
  return (
    <FormBuilder.Password
      {...props}
      title={fieldLabel('password')}
      validate={passwordValidators}
      name="password"
      prepend={props.blockAutoFill && <BlockAutoFill />}
    >
      {props.blockAutoFill
        && <BlockAutoFill />}
    </FormBuilder.Password>
  );
}

UserPasswordField.defaultProps = {
  blockAutoFill: true,
};
