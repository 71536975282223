import { api } from 'lib/api';

export { default as reportsSaga } from './reports.saga';
export { default as reportsReducer } from './reports.reducer';
export { default as reports } from './reports.resource';

export function getReportLink(id, search) {
  return api.get(`/v2/reports/${id}`, {
    search: {
      ...search,
      offset: 0,
      count: 9000,
      out: 'xlsx',
    },
  });
}
