import { memo } from 'react';
import { isString } from 'lodash';
import { Box } from '@mui/material';

import { bem } from 'lib/bem';
import tp from 'lib/typograph';
import { useImagesHandling } from 'lib/hooks/useImagesHandling';
// eslint-disable-next-line import/no-cycle
import UniversalLink from 'lib/ui/UniversalLink/UniversalLink';
import { ActivityModel, ActivityType } from 'app/activities/types';
// eslint-disable-next-line import/no-cycle
import OpenHtmlModal from 'lib/ui/OpenHtmlModal/OpenHtmlModal';
import { UrlIcon } from 'lib/ui/icon';

import './Activity.scss';

const { block, element } = bem('Activity');

interface Props {
  activity: ActivityModel;
  link:
    | string
    | {
        pathname: string;
        query: Record<string, any>;
      };
}

const getImgStyles = (theme) => ({
  background: theme.palette.customBackground.activity || theme.palette.primary.main,
});

const Activity = ({ activity, link }: Props) => {
  const [failedImages, onImgLoadError] = useImagesHandling();

  const isLinkExternal =
    activity.type === ActivityType.open_url && !activity.params.open_url.in_app;

  return (
    <>
      {activity.params?.open_url?.in_app ? <OpenHtmlModal /> : null}
      <UniversalLink {...block()} to={link} external={isLinkExternal}>
        <div>
          {failedImages.includes(activity.icon_url) ? (
            <Box
              sx={{
                bgcolor: (theme) =>
                  theme.palette.customBackground?.activity ?? theme.palette.primary.main,
              }}
              {...element('icon')}
            />
          ) : (
            <UrlIcon
              className={element('icon').className}
              url={activity.icon_url}
              onImgSrcError={onImgLoadError}
              waitLoad
              sx={getImgStyles}
            />
          )}
          <div {...element('name')}>{tp(activity.name)}</div>
        </div>
      </UniversalLink>
    </>
  );
};

export default memo(Activity);
