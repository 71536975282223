import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDateIsoWeekend } from 'lib/helpers/dates';
import { contextNotification } from 'app/notifications';

import { BookingStatus, DayInfo, SlotsFieldProps } from '../types';
import DaySlotsView from './DaySlotsView';

const BookingDaySlots = ({
  input: { onChange, value: inputValue },
  data: { value },
}: SlotsFieldProps) => {
  const dispatch = useDispatch();
  const { bookingDate } = useSelector((state) => state) as any;
  const notify = contextNotification('bookings');

  const initialSlots = useMemo(() => value || [], [value]);

  useEffect(() => {
    if (bookingDate) {
      const initialDate = initialSlots.filter((slot) => slot.date === bookingDate);
      onChange(initialDate);
    }
  }, []);

  const areSelectedSlotsExist = useMemo(() => {
    if (inputValue && Array.isArray(inputValue) && inputValue.length > 0) {
      return inputValue.some(({ slots }) => {
        return slots.length > 0;
      });
    }
    return false;
  }, [inputValue]);

  const handleDaySelection = useCallback(
    (day: DayInfo) => () => {
      if (!areSelectedSlotsExist) {
        onChange([day]);
      } else if (inputValue.length === 1) {
        const existingDate = inputValue[0].date;
        const selectedDate = day.date;

        const existingDateIndex = initialSlots.findIndex((x) => x.date === existingDate);
        const selectedDateIndex = initialSlots.findIndex((x) => x.date === selectedDate);

        let result: DayInfo[] = [];

        if (existingDateIndex < selectedDateIndex) {
          result = initialSlots.slice(existingDateIndex, selectedDateIndex + 1);
        } else if (existingDateIndex > selectedDateIndex) {
          result = initialSlots.slice(selectedDateIndex, existingDateIndex + 1);
        }

        const inRangeExistBookedSlot = result.some(({ status }) => status === BookingStatus.BOOKED);

        if (inRangeExistBookedSlot) {
          const notifyAction = notify('unavailable_slots', {}, { error: true });
          dispatch(notifyAction);
          onChange([day]);
        } else {
          onChange(result);
        }
      } else {
        onChange([day]);
      }
    },
    [areSelectedSlotsExist, dispatch, initialSlots, notify, onChange, inputValue],
  );

  const selectedDays = useMemo(() => {
    if (areSelectedSlotsExist) {
      return inputValue.map((x) => x.date);
    }
    return [];
  }, [areSelectedSlotsExist, inputValue]);

  const getSlotWrapperModifiers = useCallback(
    (day: DayInfo): Record<string, boolean> => {
      const modifiers: Record<string, boolean> = { monthDay: true };
      if (selectedDays.length > 1 && selectedDays.includes(day.date)) {
        const startDay = selectedDays[0];
        const endDay = selectedDays[selectedDays.length - 1];
        const inRange = day.date !== startDay && day.date !== endDay;

        modifiers.start = day.date === startDay;
        modifiers.end = day.date === endDay;
        modifiers.inRange = inRange;

        return modifiers;
      }
      return modifiers;
    },
    [selectedDays],
  );

  const getSlotModifiers = useCallback(
    (day: DayInfo): Record<string, boolean> => {
      const modifiers = { weekEnd: isDateIsoWeekend(day.date) };
      const startDay = selectedDays[0];
      const endDay = selectedDays[selectedDays.length - 1];
      const inRange = day.date !== startDay && day.date !== endDay;

      if (selectedDays.includes(day.date)) {
        return { ...modifiers, selected: !inRange };
      }
      return modifiers;
    },
    [selectedDays],
  );

  const checkIfSlotButtonDisabled = useCallback(({ status }: DayInfo) => {
    return status !== BookingStatus.AVAILABLE;
  }, []);

  return (
    <DaySlotsView
      initialSlots={initialSlots}
      getSlotWrapperModifiers={getSlotWrapperModifiers}
      getSlotModifiers={getSlotModifiers}
      onSlotSelection={handleDaySelection}
      checkIfSlotButtonDisabled={checkIfSlotButtonDisabled}
    />
  );
};

export default memo(BookingDaySlots);
