import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';

import './TimeSlots.scss';
import { contextNotification } from 'app/notifications';

import { BookingStatus, DayInfo, Slot, SlotsFieldProps } from '../types';
import TimeSlotsView from './TimeSlotsView';

const BookingTimeSlots = ({
  input: { onChange, value },
  data: { value: _initialSlots },
}: SlotsFieldProps) => {
  const initialSlots = _initialSlots ?? [];
  const { bookingDate } = useSelector((state) => state) as any;

  const initialDayIndex = useMemo(() => {
    if (!bookingDate) return 0;
    return initialSlots.findIndex((item) => item.date === bookingDate);
  }, [bookingDate, initialSlots]);

  const [selectedDayIndex, setSelectedDayIndex] = useState(initialDayIndex);

  const handleDaySelection = useCallback(
    (dayIndex) => () => {
      if (initialSlots[dayIndex]) {
        setSelectedDayIndex(dayIndex);
      }
    },
    [initialSlots],
  );

  const areSelectedSlotsExist = useMemo(() => {
    if (value && Array.isArray(value) && value.length > 0) {
      return value.some(({ slots }) => {
        return slots.length > 0;
      });
    }
    return false;
  }, [value]);

  const selectedSlots = useMemo(() => {
    if (areSelectedSlotsExist) {
      let result: Slot[] = [];
      value.forEach(({ slots }) => {
        result = [...result, ...slots];
      });
      return result;
    }
    return [];
  }, [areSelectedSlotsExist, value]);

  const dispatch = useDispatch();
  const notify = contextNotification('bookings');

  const handleSlotSelection = useCallback(
    (slot) => () => {
      const selectedDay = initialSlots[selectedDayIndex].date;

      if (!areSelectedSlotsExist) {
        onChange([{ date: selectedDay, slots: [slot] }]);
      } else if (selectedSlots.length === 1) {
        const existingDay = value[0].date;
        const existingDayIndex = initialSlots.findIndex((x) => x.date === existingDay);

        const selectedDayInitialSlots = initialSlots[selectedDayIndex].slots;
        const existingDayInitialSlots = initialSlots[existingDayIndex].slots;

        let selectedSlotIndex = selectedDayInitialSlots.findIndex((x) => x.id === slot.id);
        let existingSlotIndex = existingDayInitialSlots.findIndex(
          (x) => x.id === selectedSlots[0].id,
        );

        let result: DayInfo[] = [];
        if (existingDayIndex > selectedDayIndex) {
          result = [
            {
              date: selectedDay,
              slots: selectedDayInitialSlots.slice(
                selectedSlotIndex,
                selectedDayInitialSlots.length,
              ),
            },
          ];
          result = [...result, ...initialSlots.slice(selectedDayIndex + 1, existingDayIndex)];
          result.push({
            date: existingDay,
            slots: existingDayInitialSlots.slice(0, existingSlotIndex + 1),
          });
        } else if (existingDayIndex < selectedDayIndex) {
          result = [
            {
              date: existingDay,
              slots: existingDayInitialSlots.slice(
                existingSlotIndex,
                existingDayInitialSlots.length,
              ),
            },
          ];
          result = [...result, ...initialSlots.slice(existingDayIndex + 1, selectedDayIndex)];
          result.push({
            date: selectedDay,
            slots: selectedDayInitialSlots.slice(0, selectedSlotIndex + 1),
          });
        } else {
          existingSlotIndex = selectedDayInitialSlots.findIndex(
            (x) => x.id === selectedSlots[0].id,
          );
          selectedSlotIndex = selectedDayInitialSlots.findIndex((x) => x.id === slot.id);
          let neededSlots: Slot[] = [];
          if (existingSlotIndex > selectedSlotIndex) {
            neededSlots = selectedDayInitialSlots.slice(selectedSlotIndex, existingSlotIndex + 1);
          } else if (existingSlotIndex < selectedSlotIndex) {
            neededSlots = selectedDayInitialSlots.slice(existingSlotIndex, selectedSlotIndex + 1);
          } else {
            onChange([]);
            return;
          }

          result = [{ date: selectedDay, slots: neededSlots }];
        }

        const areAllSlotsAvailable = result.every(({ slots }) => {
          return slots.every((x) => x.status === 'available');
        });
        if (areAllSlotsAvailable) {
          onChange(result);
        } else {
          const notifyAction = notify('unavailable_slots', {}, { error: true });
          dispatch(notifyAction);
          onChange([{ date: selectedDay, slots: [slot] }]);
        }
      } else {
        onChange([{ date: selectedDay, slots: [slot] }]);
      }
    },
    [
      areSelectedSlotsExist,
      selectedSlots,
      onChange,
      value,
      initialSlots,
      selectedDayIndex,
      dispatch,
      notify,
    ],
  );

  const getSlotWrapperModifiers = useCallback(
    (slot: Slot) => {
      const modifiers: Record<string, boolean> = {};
      if (areSelectedSlotsExist && selectedSlots.length > 1) {
        const startSlotId = selectedSlots[0].id;
        const endSlotId = selectedSlots[selectedSlots.length - 1].id;

        modifiers.start = slot.id === startSlotId;
        modifiers.end = slot.id === endSlotId;

        modifiers.inRange =
          !isNil(selectedSlots.find((x) => x.id === slot.id)) &&
          slot.id !== startSlotId &&
          slot.id !== endSlotId;
      }
      return modifiers;
    },
    [areSelectedSlotsExist, selectedSlots],
  );

  const getSlotModifiers = useCallback(
    (slot: Slot) => {
      const modifiers: Record<string, boolean> = {};
      if (areSelectedSlotsExist && selectedSlots.length >= 1) {
        const startSlotId = selectedSlots[0].id;
        const endSlotId = selectedSlots[selectedSlots.length - 1].id;
        modifiers.selected = slot.id === startSlotId || slot.id === endSlotId;
      }
      return modifiers;
    },
    [areSelectedSlotsExist, selectedSlots],
  );

  const checkIfSlotButtonDisabled = useCallback(({ status }) => {
    return status !== BookingStatus.AVAILABLE;
  }, []);

  return (
    <TimeSlotsView
      initialSlots={initialSlots ?? []}
      getSlotWrapperModifiers={getSlotWrapperModifiers}
      getSlotModifiers={getSlotModifiers}
      onSlotSelection={handleSlotSelection}
      checkIfSlotButtonDisabled={checkIfSlotButtonDisabled}
      selectedDayIndex={selectedDayIndex}
      onChangeDay={handleDaySelection}
    />
  );
};

export default memo(BookingTimeSlots);
