import './ConfirmCodeModal.scss';
import { flow } from 'lodash';
import { bem } from 'lib/bem';
import { t } from 'lib/i18n';
import { FormBuilder, ReduxModal } from 'lib/ui';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useCallback, useEffect } from 'react';

const { block, element } = bem('ConfirmCodeModal');
const { validators } = FormBuilder;
const valueSelector = formValueSelector('confirmCode');

export default flow<any, any, any, any>(
  ReduxModal({
    name: 'confirmCode',
    routing: false,
    getTitle: () => t('users.confirm_title'),
  }),
  reduxForm({
    form: 'confirmCode',
  }),
  connect((state) => ({
    confirmation_code: valueSelector(state, 'confirmation_code'),
  })),
)((props) => {
  const handleSubmit = useCallback(() => {
    props.onSubmit({
      ...props.data,
      confirmation_code: String(props.confirmation_code),
    });

    props.onClose();
  }, [props]);

  const handleCancel = useCallback(() => {
    props.reset();
    props.onClose();
  }, [props]);

  useEffect(() => {
    props.reset();
  }, []);

  const limitCodeInput = (value = '') => {
    return value.replace(/[^\d]/g, '');
  };

  return (
    <div {...block()}>
      <div {...element('description')}>
        {t('users.confirmation_code_description_without_number')}
      </div>
      <FormBuilder.Text
        required
        {...element('confirmCode')}
        maxLength={4}
        placeholder="XXXX"
        name="confirmation_code"
        validate={[validators.length(4), validators.max(9999)]}
        normalize={limitCodeInput}
      />

      <FormBuilder.ButtonsGroup
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        disabled={props.invalid}
        submitText={props.submitButtonText || t('common.confirm')}
      />
    </div>
  );
});
