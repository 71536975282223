import { useCallback, useState } from 'react';
import DaysCarousel from 'lib/ui/FormBuilder/types/Slots/TimeSlots/DaysCarousel';
import { getDateAsString, getFutureDate } from 'lib/helpers/dates';

// eslint-disable-next-line prefer-spread
const calendar = Array.apply(null, Array(90)).map((item, index) => {
  return {
    date: getDateAsString(getFutureDate(index)),
    slots: [],
  };
});

interface IDaysCarouselFilterProps {
  handleDaySelection: any;
}

const DaysCarouselFilter = ({ handleDaySelection }: IDaysCarouselFilterProps) => {
  const [selectedDayIndex, setSelectedDayIndex] = useState(0);

  const onChangeDay = useCallback(
    (dayIndex: number) => () => {
      setSelectedDayIndex(dayIndex);
      const { date } = calendar[dayIndex];
      handleDaySelection(date);
    },
    [handleDaySelection],
  );

  return (
    <DaysCarousel
      selectedDayIndex={selectedDayIndex}
      initialSlots={calendar}
      onChangeDay={onChangeDay}
      isBusyness={false}
      titleMonthOnly
    />
  );
};

export default DaysCarouselFilter;
