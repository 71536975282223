import { mapValues } from 'lodash';
import getRequestKey from './getRequestKey';

function initializeFetching(state, key, requestAction) {
  return {
    ...state,
    data: {
      ...state.data,
      [key]: {
        ...state.data[key],
        fetching: true,
      },
    },
  };
}

function setRequestIndex(state, key, action) {
  return {
    ...state,
    data: {
      ...state.data,
      [key]: {
        ...state.data[key],
        requestIndex: action.requestIndex.value,
      },
    },
  };
}

function completeFetching(state, key, action, normalizer) {
  const { index, data } = normalizer(action.response);
  const lastRequestIndex = state.data[key].requestIndex;

  if (lastRequestIndex !== action.requestIndex.value) {
    return state;
  }

  return {
    ...state,
    outdated: false,
    lastUpdateTime: Date.now(),
    index: {
      ...state.index,
      ...index,
    },
    data: {
      ...state.data,
      [key]: {
        data,
        total: Number(action.total),
        fetching: false,
        outdated: false,
      },
    },
  };
}

export default function endpointReducer(scope) {
  const { actionName, normalizer, reducer } = scope;

  return (state = { index: {} }, action) => {
    const requestAction = action.requestAction || action;

    switch (action.type) {
      case `${actionName}/INVALIDATE`: {
        return {
          ...state,
          data: mapValues((state as any).data, (response) => ({
            ...response,
            failed: false,
            outdated: true,
            fetching: false,
          })),
        };
      } case `${actionName}`: {
        return initializeFetching(state, getRequestKey(requestAction.payload), requestAction);
      } case `${actionName}_START`: {
        return setRequestIndex(state, getRequestKey(requestAction.payload), action);
      } case `${actionName}_SUCCESS`: {
        return completeFetching(state, getRequestKey(requestAction.payload), action, normalizer);
      } case `${actionName}_FAIL`: {
        const key = getRequestKey(requestAction.payload);

        return {
          ...state,
          lastUpdateTime: Date.now(),
          data: {
            ...(state as any).data,
            [key]: {
              ...(state as any).data[key],
              fetching: false,
              failed: true,
            },
          },
        };
      } default: return reducer(state, action, scope.resource);
    }
  };
}
