import IssuesOverdueWidget from 'app/analyticalReports/ui/widgets/IssuesOverdueWidget';

import { IssuesByTypeWidgetLazy } from './IssuesByTypeWidget';
import IssuesInProgressWidget from './IssuesInProgressWidget';
import { IssuesRatesWidgetLazy } from './IssuesRatesWidget';
import IssuesTotalWidget from './IssuesTotalWidget';
import { ResponsiblesWidgetLazy } from './ResponsiblesWidget';

export const DASHBBOARD_WIDGETS = {
  issuesTotal: {
    key: 'issuesTotal',
    Component: IssuesTotalWidget,
  },
  issuesByType: {
    key: 'issuesByType',
    Component: IssuesByTypeWidgetLazy,
  },
  issuesInProgress: {
    key: 'issuesInProgress',
    Component: IssuesInProgressWidget,
  },
  responsibles: {
    key: 'responsibles',
    Component: ResponsiblesWidgetLazy,
  },
  issuesRates: {
    key: 'issuesRates',
    Component: IssuesRatesWidgetLazy,
  },
  issuesOverdue: {
    key: 'issuesOverdue',
    Component: IssuesOverdueWidget,
  },
};
