import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { omit } from 'lodash';
import { qsStringify } from 'lib/helpers/qs';
import { withRouter } from 'config/withRouter';

import { setModalExtraData } from './index';

const EXCLUDED_PROPS = [
  'location',
  'params',
  'query',
  'route',
  'routeList',
  'routeParams',
  'router',
  'routes',
];

function getModalPath(modal, data, router, overwriteLocationQuery) {
  return {
    pathname: router.location.pathname,
    search: qsStringify(
      overwriteLocationQuery
        ? {
            [modal]: data,
          }
        : { ...router.location.query, [modal]: data },
    ),
  };
}

const ModalLink = withRouter((props: any) => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (props.extraData) {
      dispatch(setModalExtraData(props.to, props.extraData));
    }
  }, [dispatch, props.extraData, props.to]);

  return (
    <Link
      {...omit(props, ...EXCLUDED_PROPS)}
      onClick={handleClick}
      // activeClassName="_active"
      // className={({ isActive }) =>
      //   isActive ? `${props.className ?? ''} _active` : props.className ?? ''
      // }
      to={getModalPath(props.to, props.data, props.router, props.overwriteLocationQuery)}
    >
      {props.children}
    </Link>
  );
});

export default ModalLink;
