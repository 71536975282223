import { CSSProperties } from 'react';

import { brandedThemeOptions } from 'lib/ui/AppThemeProvider/theme';
import { GlyphName, Icon } from 'lib/ui/icon';

import { ExpandIconType } from '../lib/types';

interface Props {
  element: { className: string };
  expanded?: boolean;
  expandIconType?: ExpandIconType;
  onExpand?(): void;
  projected?: boolean;
}

export const ExpandIcon = ({ element, expanded, expandIconType, onExpand, projected }: Props) => {
  let expandedIconName: GlyphName = 'expand_more';
  if (onExpand && expandIconType === 'folder') {
    expandedIconName = expanded || projected ? 'opened_folder' : 'closed_folder';
  }

  return (
    <div
      {...element}
      onClick={onExpand}
      style={
        {
          '--color': `${brandedThemeOptions.baseColor}`,
        } as CSSProperties
      }
    >
      <Icon glyph={expandedIconName} />
    </div>
  );
};
