import { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, change } from 'redux-form';
import { isEqual, isEmpty } from 'lodash';

import { Page } from 'lib/ui';
import queryForm from 'lib/queryForm';
import InventoryReportFilters from 'app/inventories/InventotyReport/InventoryReportFilters';
import { InventoryReportTable } from 'app/inventories/InventotyReport/InventoryReportTable';
import inventoriesResource from 'app/inventories/inventories.resource';
import { t } from 'lib/i18n';
import { usePrevious } from 'lib/hooks/usePrevious';
import { IInventoryReportItem } from 'app/inventories/types';
import EmptyPlaceholder from 'lib/ui/EmptyPlaceholder';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';

const filterForm = 'InventoryReportForm';

const FilterForm = queryForm({ form: filterForm })(
  memo(function InventoryReportForm(props) {
    return <InventoryReportFilters value={(props as any).values} />;
  }),
);

const InventoryReport = connect((state, props) => {
  const filters = getFormValues(filterForm)(state) || {};
  return {
    filtersVal: filters,
  };
})(({ filtersVal }) => {
  const [data, setData] = useState<Array<IInventoryReportItem>>();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(filtersVal);

  const stocks = useSelector((state: any) => state.inventories.stocks.index);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!filters.stock_id && stocks) {
      const stockId = (
        Object.values(stocks).filter((item: any) => isEmpty(item.children))[0] as any
      ).id;
      dispatch(change('InventoryReportForm', 'stock_id', stockId));
    }
  }, [dispatch, filters, filters.stock_id, stocks]);

  const prevFilter = usePrevious(filters);

  useEffect(() => {
    if (!isEqual(prevFilter, filtersVal)) {
      setFilters(filtersVal);
    }
  }, [filtersVal, prevFilter]);

  useEffect(() => {
    setLoading(true);
    if (filters.stock_id) {
      inventoriesResource.reportList.request(filters).then((resp) => {
        setData(resp);
        setLoading(false);
      });
    }
  }, [filters]);

  return (
    <Page title={t('menu.inventory_report')} fullWidth>
      <FilterForm />
      {loading ? (
        <BusyIndicator />
      ) : data && data.length ? (
        <InventoryReportTable items={data} />
      ) : (
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'className' is missing in type '{ placeho... Remove this comment to see the full error message
        <EmptyPlaceholder placeholder={t('common.no_results')} />
      )}
    </Page>
  );
});

export default InventoryReport;
