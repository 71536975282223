export { default as list } from './listEndpoint';
export { default as item } from './itemEndpoint';
export { default as simpleList } from './simpleListEndpoint';
export { default as itemV2 } from './itemV2Endpoint';

// TODO item v2 because old item endpoint needs other format when it is new API response.

export enum EndpointType {
  LIST = 'list',
  ITEM = 'item',
  SIMPLE_LIST = 'simpleList',
  ITEM_V2 = 'itemV2'
}
