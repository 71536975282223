import { useCallback, useState } from 'react';
import DOMPurify from 'dompurify';
import { Fade, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { bem } from 'lib/bem';
import { Icon } from 'lib/ui/icon';
import { useNotifications, NotificationMessage, NotificationsPositionEnum } from 'features/notify';

import './Notifications.scss';

const { block, element } = bem('Notifications');

const DEFAULT_POSITION = NotificationsPositionEnum.TOP_RIGHT;

export const Notifications = () => {
  const [notifications, setNotifications] = useState<NotificationMessage[]>();

  const { closeNotification } = useNotifications((_notifications) => {
    setNotifications(_notifications);
  });

  const StyledNotificationItem = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      top: '68px',
      left: 0,
      width: '100vw',
      background: theme.palette.primary.main,
      position: 'fixed',
    },
  }));

  const handleClose = useCallback(
    (message) => {
      closeNotification(message.id);
    },
    [closeNotification],
  );

  return (
    <div {...block(getPositionModifiers(DEFAULT_POSITION))}>
      {notifications?.map((notification) => {
        return (
          <Fade key={notification.id} in timeout={200}>
            <StyledNotificationItem
              {...element('item', getModifiers(notification))}
              key={notification.id}
            >
              <Icon
                fullSize
                glyph={notification.type === 'error' ? 'warning' : 'info_filled'}
                {...element('icon', getModifiers(notification))}
              />
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.text) }} />
              {notification?.persistent && (
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={() => handleClose(notification)}
                >
                  <Icon glyph="close" {...element('close-icon')} />
                </IconButton>
              )}
            </StyledNotificationItem>
          </Fade>
        );
      })}
    </div>
  );
};

function getModifiers(notification) {
  return {
    error: notification.type === 'error',
  };
}

function getPositionModifiers(position) {
  return {
    center: position === 'center',
  };
}
