import { createResource } from 'lib/resource';
import { v4 } from 'uuid';
import { api } from 'lib/api';
import { BookingStatus, DayInfo } from 'lib/ui/FormBuilder/types/Slots/types';
import { put } from 'redux-saga/effects';
import { closeModal } from 'lib/ui/Modal';
import { SAVE_LOCATION_MODAL } from 'app/locations/config';

import { slotsRenderers } from '../issues/Attributes/attributeInputRenderers';
import { ManagedObjectAttribute } from './types';

const versionPrefix = 'v3';

function* closeOnSuccess() {
  yield put(closeModal(SAVE_LOCATION_MODAL));
}

export const getActionsById = ({ payload: { id, type } }) =>
  api.get(`${versionPrefix}/managed_objects/${id}/actions/${type}/`);

const createOrUpdateAction =
  (methodName: 'post' | 'put') =>
  ({ payload: { id, type, typeId, scheduleId, attributes } }) => {
    return api[methodName](`${versionPrefix}/managed_objects/${id}/actions/${type}/`, {
      body: { type_id: typeId, schedule_id: scheduleId, attributes },
    });
  };

const normalizeBookingItem = ({ data }) => {
  if (data && data.attributes) {
    data.attributes.forEach((attr: ManagedObjectAttribute) => {
      if (slotsRenderers.includes(attr.renderer)) {
        const byDays = data.schedule ? data.schedule.by_days : false;

        attr.id = attr.internal_name;
        attr.name = undefined;
        attr.hint = attr.cta;
        attr.cta = undefined;

        if (attr.value) {
          attr.value.forEach((day: DayInfo) => {
            if (byDays) {
              if (day.slots.length === 0) {
                day.status = BookingStatus.EMPTY_SLOTS;
              } else {
                day.status = day.slots[0].status;
              }
            }

            day.slots.forEach((slot) => {
              slot.id = v4();
            });
          });
        }
      }
    });
    data.managed_object_type.attributes.forEach((attr) => {
      if (slotsRenderers.includes(attr.renderer)) {
        attr.id = attr.internal_name;
        attr.name = null;
        attr.hint = attr.cta;
        attr.cta = null;
      }
    });
  }
};

export default createResource('managedObjects', {
  scopes: {
    actions: {
      request: getActionsById,
      type: 'item',
      normalizer: (response) => {
        normalizeBookingItem(response);
        return response;
      },
    },
  },
  mutators: {
    createAction: {
      request: createOrUpdateAction('post'),
      onSuccess: closeOnSuccess,
    },
    updateAction: {
      request: createOrUpdateAction('put'),
      onSuccess: closeOnSuccess,
    },
    deleteAction: {
      request: ({ payload: { id, type } }) =>
        api.delete(`${versionPrefix}/managed_objects/${id}/actions/${type}/`),
      onSuccess: closeOnSuccess,
    },
  },
});
