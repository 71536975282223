import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Chip } from '@mui/material';

type DragObject = {
  index: number;
};

const DraggableChip = ({ item, index, moveTextSample, onDelete }) => {
  const ref = useRef<any>(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'sample',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'sample',
    hover: (unit: DragObject, monitor) => {
      const dragIndex = unit.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientYOffset = monitor.getClientOffset()?.y;
      const hoverActualY =
        clientYOffset == null ? hoverBoundingRect.top : clientYOffset - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      moveTextSample(dragIndex, hoverIndex);
      unit.index = hoverIndex;
    },
  });

  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={ref}>
      <Chip
        label={item}
        onDelete={() => onDelete(item)}
        sx={{
          cursor: 'grab',
          opacity,
          marginBottom: '10px',
          marginRight: '10px',
        }}
      />
    </div>
  );
};

export default DraggableChip;
