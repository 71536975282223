const initialState = {
  items: [] as any[],
};

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return {
        items: [action.message, ...state.items],
        position: action?.position,
      };
    case 'HIDE_NOTIFICATION':
      return {
        items: state.items.filter((i) => i.id !== action.message.id),
      };
    default:
      return state;
  }
}
