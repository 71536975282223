import { bem } from 'lib/bem';
import { Date, Table } from 'lib/ui';
import { t, t_prefixed } from 'lib/i18n';
import { Paper } from '@mui/material';
import { IssueStatus } from 'app/issues/IssueFields';

const { block, element } = bem('ChildIssuesList');

const label = t_prefixed('round_reports.columns');

const columns = [
  {
    header: label('id'),
    accessor: 'display_id',
    render: (props) => <div>{props.value}</div>,
  },
  {
    header: label('name'),
    accessor: 'issue_type.name',
    render: (props) => <div>{props.value}</div>,
  },
  {
    header: label('location'),
    accessor: 'location',
    render: (props) => {
      const value = props.value.full_path.split(' — ');
      return (
        <div className="NestedPath">
          {value &&
            value.map((item, index) => (
              <div className="NestedPath__item" key={index}>
                {item}
              </div>
            ))}
        </div>
      );
    },
  },
  {
    header: label('created'),
    accessor: 'created',
    render: (props) => <Date value={props.value} showIcon={false} showTime={false} />,
  },
  {
    header: label('status'),
    accessor: 'current_status',
    render: (props) => <IssueStatus value={props.value} showIcon={false} showInProgress />,
  },
  {
    header: label('responsible'),
    accessor: 'responsible',
    render: (props) => (
      <div {...element('responsibles')}>
        {props.value.map((user, index) => (
          <div key={user.id}>
            {user.full_name}
            {props.value.length - 1 !== index ? ';' : ''}
          </div>
        ))}
      </div>
    ),
  },
];

const ChildIssuesList = ({ data, onRowClick }) => {
  return (
    <div {...block()} style={{ marginTop: '2rem' }}>
      <div {...element('title')}>{t('round_reports.related_issues')}</div>

      <Paper
        sx={{
          borderRadius: '8px',
          border: '1px solid rgba(231, 238, 255, 0.72)',
          boxShadow: '0px 1px 12px -2px rgba(231, 238, 255, 0.64)',
          marginTop: '1rem',
          width: '100%',
        }}
      >
        <Table
          data={data}
          {...element('table')}
          columns={columns}
          fixedHeaderAtTableTop
          onRowClick={onRowClick}
          sx={{
            maxHeight: 500,
            border: 'none !important',
            borderRadius: '8px 8px 0 0',
            '& .MuiTableCell-head': {
              borderRight: 'none',
              borderBottom: '1px solid rgba(231, 238, 255, 0.72) !important',
              color: '#667790',
              maxWidth: '150px',
              borderRadius: '8px 8px 0 0',
            },
            '& .MuiTableCell-body': {
              color: '#112950',
              maxWidth: '180px',
              padding: '0.5rem 0 0.5rem 1rem',
            },
            '& .MuiTableRow-root': {
              '&:last-child td, &:last-child th': { border: 0 },
              '&:hover': {
                backgroundColor: 'transparent',
                cursor: 'pointer',
              },
              '& .NestedPath': {
                '&__item': {
                  overflowWrap: 'break-word',
                },
              },
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default ChildIssuesList;
