import { Component, PropsWithChildren } from 'react';
import enhanceWithClickOutside from 'react-click-outside';

import { Icon } from 'lib/ui/icon';

import './Tooltip.scss';

const { block, element } = bem('Tooltip');

export default enhanceWithClickOutside(
  class Tooltip extends Component<PropsWithChildren> {
    static propTypes = {
      handler: PropTypes.any,
      showByClick: PropTypes.bool,
    };

    state = {
      visible: false,
    };

    render() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'showByClick' does not exist on type 'Rea... Remove this comment to see the full error message
      const { showByClick } = this.props;
      const { visible } = this.state;

      return (
        <div {...block({ showByClick, visible }, (this.props as any).className)}>
          <div {...element('handler')} onClick={this.handleHandlerClick}>
            {this.renderHandler()}
          </div>

          <div {...element('dropdown')} onClick={this.handleDropdownClick}>
            <div {...element('arrow')} />
            {this.props.children}
          </div>
        </div>
      );
    }

    handleHandlerClick = (e) => {
      if (!(this.props as any).showByClick) return;
      this.setState((state) => ({
        visible: !(state as any).visible,
      }));

      e.stopPropagation();
      e.preventDefault();
    };

    handleClickOutside = () => {
      if (!(this.props as any).showByClick) return;
      this.setState({ visible: false });
    };

    handleDropdownClick = (e) => {
      if (!(this.props as any).showByClick) return;
      this.setState({ visible: false });
      e.stopPropagation();
      e.preventDefault();
    };

    renderHandler() {
      if ((this.props as any).handler) return (this.props as any).handler;

      return <Icon glyph="info" />;
    }
  },
);
