import firebase from 'firebase/app';
import 'firebase/messaging';
import { brandName } from 'config/constants';
import { sendFcmToken } from 'app/common.api';

import { firebaseConfig } from './config';

export const isPushApiSupported = () => {
  return 'PushManager' in window;
};

let messaging: firebase.messaging.Messaging | null = null;
if (isPushApiSupported()) {
  firebase.initializeApp(firebaseConfig[brandName]);
  messaging = firebase.messaging();
}

export const requestFirebaseNotificationPermission = async () => {
  if (isPushApiSupported()) {
    await messaging?.requestPermission();
    return messaging?.getToken();
  }

  return undefined;
};

export const refreshFcmToken = () => {
  if (isPushApiSupported()) {
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        sendFcmToken(firebaseToken as string);
      })
      .catch((e) => {
        console.log(e);
      });
  }
};

export const clearFcmToken = () => {
  if (isPushApiSupported()) {
    return messaging?.deleteToken();
  }
};
