import './Rating.scss';
import { useCallback, useState } from 'react';
import { Rating } from '@mui/material';
import { Star as StarIcon } from '@mui/icons-material';

interface Props {
  value: number | null;
  editable?: boolean;
  starsCount?: number;
  showEmpty?: boolean;
  onChange?: any;
  className?: string;
  withLabel?: boolean;
}

const labels: { [index: string]: string } = {
  1: t('issues.modals.rate.rating.awful'),
  2: t('issues.modals.rate.rating.poor'),
  3: t('issues.modals.rate.rating.fair'),
  4: t('issues.modals.rate.rating.good'),
  5: t('issues.modals.rate.rating.excellent'),
};

export default function ({
  value, editable, starsCount = 5, showEmpty = false, onChange, className, withLabel,
}: Props): JSX.Element {
  value = (showEmpty && !editable) ? 0 : value;
  const [hover, setHover] = useState(-1);

  const handleChange = useCallback((evt) => {
    const newValue = parseInt(evt.target.value, 10);
    if (value !== newValue) {
      onChange(newValue);
    }
  }, [onChange, value]);

  return (
    <div className="Rating__wrapper">
      <Rating
        value={value}
        className={className}
        max={starsCount}
        onChange={handleChange}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        readOnly={!editable}
        onClick={(evt) => evt.stopPropagation()}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        sx={{
          '& .MuiRating-iconFilled': {
            color: 'inherit',
          },
        }}
      />
      {withLabel && value !== null && (
      <div className="Rating__label">{labels[hover !== -1 ? hover : value]}</div>
      )}
    </div>
  );
}
