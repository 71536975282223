import { get } from 'lodash';
import { createSelector } from 'reselect';

export function moveTreeItem(type, {
  id,
  oldParentId,
  parentId,
  ...extraParams
}) {
  return {
    type,
    id,
    oldParentId,
    parentId,
    ...extraParams,
  };
}

/**
 * Приводит дерево к плоскому виду,
 * заменяя вложенность на ссылки по id.
 * Это упрощает дальнейшие операции с деревом в Redux.
 */
function normalizeTree(sourceTree, byId = {}) {
  const tree = { ...sourceTree };
  byId[tree.id] = tree;

  const children = tree.children || [];
  tree.children = [];

  children.forEach((child) => {
    byId[tree.id].children.push(child.id);
    child.$parentId = tree.id;
    child.$parent = tree;
    normalizeTree(child, byId);
  });

  return {
    root: tree.id,
    byId,
  };
}

function moveTreeNode(state, action) {
  const {
    parentId, id, beforeId, data = {},
  } = action;
  const { byId } = state.data;

  const item = {
    ...(byId[id] || data),
    id: id || data.id,
  };

  item.children = item.children || [];
  const oldParent = byId[action.oldParentId];
  const newParent = byId[parentId];

  const oldParentScope = {};

  if (oldParent) {
    oldParentScope[oldParent.id] = {
      ...oldParent,
      children: oldParent.children.filter((i) => i !== id),
    };
  }

  return {
    ...state,
    data: {
      root: state.data.root,
      byId: {
        ...state.data.byId,
        ...oldParentScope,

        [newParent.id]: {
          ...newParent,
          children: (() => {
            const newChildren = [...newParent.children].filter((i) => i !== item.id);

            if (beforeId) {
              const index = newChildren.indexOf(beforeId);
              newChildren.splice(index, 0, item.id);
            } else {
              newChildren.push(item.id);
            }

            return newChildren;
          })(),
        },

        [item.id]: {
          ...item,
          $parentId: newParent.id,
        },
      },
    },
  };
}

function updateTreeNode(state, action) {
  const { byId } = state.data;

  return {
    ...state,
    data: {
      ...state.data,
      byId: {
        ...byId,
        [action.id]: {
          ...byId[action.id],
          ...action.data,
        },
      },
    },
  };
}

function removeTreeNode(state, action) {
  const { id } = action;
  const { byId } = state.data;
  const parent = byId[action.parentId];

  return {
    ...state,
    data: {
      root: state.data.root,
      byId: {
        ...byId,
        [parent.id]: {
          ...parent,
          children: parent.children.filter((i) => i !== id),
        },
      },
    },
  };
}

export function treeReducer(prefix, options = {}) {
  const initialState = {
    fetching: false,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case `${prefix}_START`:
        return {
          ...state,
          fetching: true,
        };
      case `${prefix}_SUCCESS`:
        return {
          data: normalizeTree(action.response),
          fetching: false,
        };
      case `${prefix}_FAIL`:
        return {
          ...state,
          fetching: false,
        };
      case (options as any).moveOn:
        return moveTreeNode(state, action);
      case (options as any).updateOn:
        return updateTreeNode(state, action);
      case (options as any).removeOn:
        return removeTreeNode(state, action);
      default:
        return state;
    }
  };
}

export function mapTree(tree, parentTreeNodeId = '', parent) {
  if (!tree) return null;
  const rootNode = tree.byId[tree.root];
  const $treeNodeId = `${parentTreeNodeId}_${tree.root}`;

  const result = {
    ...rootNode,
    $treeNodeId,
    $parent: parent || {},
    byId: tree.byId,
    children: rootNode.children.map((id) => mapTree({
      byId: tree.byId,
      root: id,
    }, $treeNodeId, rootNode)),
  };

  return result;
}

export const treeSelector = (path) => createSelector(
  (state) => get(state, path),
  (tree) => ({
    fetching: tree.fetching,
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 1.
    data: mapTree(tree.data),
  }),
);
