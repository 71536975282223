import './FieldsBuilder.scss';
import { Date, MonetarySign, Gallery } from 'lib/ui';
import { t } from 'lib/i18n';
import number from 'lib/number';
import { bem } from 'lib/bem';
import { mapValues } from 'lodash';

const { block, element } = bem('Field');

const fields = {
  Text({ value }) {
    return <div>{value}</div>;
  },

  YesNo({ value }) {
    return <div>{t(`common.${value ? 'yes' : 'no'}`)}</div>;
  },

  Currency({ value }) {
    return (
      <div>
        {number(value / 100, '0.00')}
        <MonetarySign />
      </div>
    );
  },

  Number({ value }) {
    return <div>{number(value)}</div>;
  },

  Photo({ value, title }) {
    return <Gallery fileIds={value} title={title} />;
  },

  Date({ value }) {
    return <Date value={value} />;
  },
};

export const FieldsBuilder = mapValues(fields, (ValueRenderer) => (props) => {
  if (props.value === undefined) {
    return null;
  }

  return (
    <div {...block({}, props.className)}>
      {props.title && !props.hideTitle && <div {...element('label')}>{props.title}</div>}
      <div {...element('value')}>
        <ValueRenderer {...props} />
        {props.children}
      </div>
    </div>
  );
});
