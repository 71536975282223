import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Table, TableContainer } from '@mui/material';

import GridHeader from './GridHeader/GridHeader';
import GridBody from './GridBody/GridBody';
import { GridProps } from './types';
import { bem } from '../../bem';
import './Table.scss';
import { useMounting } from '../../hooks/useMounting';

const { block, element } = bem('Table');

export const DEFAULT_SORT_DIRECTION = 'desc';

const Grid = ({
  columns,
  data,
  fixedHeader,
  sorting,
  onSort,
  onRowClick,
  rowModifiers,
  sx,
  fixedHeaderAtTableTop,
  onColumnReorder,
}: GridProps) => {
  const headerRef = useRef<any>(null);
  const stickyHeaderRef = useRef<any>(null);

  const updateStickyHeaderPosition = useCallback(() => {
    const header = headerRef.current;
    const stickyHeader = stickyHeaderRef.current;

    if (header && stickyHeader) {
      const { top, left, width } = header.getBoundingClientRect();
      const { style } = stickyHeader;

      style.width = `${width}px`;

      if (top <= 0) {
        style.display = 'block';
        style.position = 'fixed';
        style.left = `${left}px`;
      } else {
        style.display = 'none';
      }
    }
  }, []);

  const updateStickyHeaderCells = useCallback(() => {
    const header = headerRef.current;
    const stickyHeader = stickyHeaderRef.current;

    if (header && stickyHeader) {
      const cells = [...header.querySelectorAll('th')];
      const stickyHeaderCells = [...stickyHeader.querySelectorAll('th')];

      cells.forEach((cell, i) => {
        stickyHeaderCells[i].style.width = `${cell.clientWidth + 2}px`;
        stickyHeaderCells[i].style.height = `${cell.clientHeight + 2}px`;
      });

      updateStickyHeaderPosition();
    }
  }, [updateStickyHeaderPosition]);

  const initFixedHeader = useCallback(() => {
    if (fixedHeader) {
      updateStickyHeaderCells();
      updateStickyHeaderPosition();
      window.addEventListener('resize', updateStickyHeaderCells);
      window.addEventListener('scroll', updateStickyHeaderPosition);

      return () => {
        window.removeEventListener('resize', updateStickyHeaderCells);
        window.removeEventListener('scroll', updateStickyHeaderPosition);
      };
    }
  }, [fixedHeader, updateStickyHeaderCells, updateStickyHeaderPosition]);

  useMounting(initFixedHeader);

  const [columnsOrder, setColumnsOrder] = useState<string[]>([]);
  const handleColumnsReorder = useCallback(
    (newColumnsOrder: string[]) => {
      setColumnsOrder(newColumnsOrder);
      if (onColumnReorder) {
        onColumnReorder(newColumnsOrder);
      }
    },
    [onColumnReorder],
  );

  const orderedColumns = useMemo(() => {
    return [...columns].sort(
      (a, b) => columnsOrder.indexOf(a.accessor) - columnsOrder.indexOf(b.accessor),
    );
  }, [columns, columnsOrder]);

  const handleSort = useCallback(
    (column) => () => {
      const isColumnSortable = column.sortableField ? true : column.sortable;
      if (isColumnSortable && onSort) {
        let sortableColumn = column.accessor;
        if (column.sortableField) {
          sortableColumn = column.sortableField;
        } else if (column.sortable && typeof column.sortable === 'string') {
          sortableColumn = column.sortable;
        }

        onSort({
          column: sortableColumn,
          direction: sorting ? (sorting.direction === 'desc' ? 'asc' : 'desc') : 'desc',
        });
      }
    },
    [onSort, sorting],
  );

  return (
    <TableContainer sx={sx} {...block()}>
      <Table>
        <GridHeader
          columns={orderedColumns}
          onSort={handleSort}
          fixedHeader={false}
          headerRef={headerRef}
          fixedHeaderAtTableTop={fixedHeaderAtTableTop}
          onColumnReorder={handleColumnsReorder}
          sorting={sorting}
        />
        {fixedHeader && (
          <GridHeader
            columns={orderedColumns}
            onSort={handleSort}
            fixedHeader
            headerRef={stickyHeaderRef}
            onColumnReorder={handleColumnsReorder}
            sorting={sorting}
          />
        )}
        <GridBody
          data={data}
          columns={orderedColumns}
          onRowClick={onRowClick}
          rowModifiers={rowModifiers}
        />
      </Table>
    </TableContainer>
  );
};

export default memo(Grid);
