import './IssueDate.scss';
import { Date } from 'lib/ui';

export default function IssueDate({ issue, format = 'long' }) {
  return (
    <div className="IssueDate">

      <Date value={issue.created} format={format} />
    </div>
  );
}
