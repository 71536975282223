import {
  DndProvider,
  ExpandIconType,
  DragEndItem,
  FilteredTreeItems,
  DndItemAction,
  DndItem,
  DndComponentType,
} from 'shared/ui/dnd-provider';
import { ActionsBlock } from 'widgets/actions-block';

interface Props<ItemData> {
  data?: DndItem<ItemData>[];
  filteredData?: FilteredTreeItems;
  depthLevel?: number;
  draggable?: boolean;
  indentationWidth?: number;
  expandIconType?: ExpandIconType;
  ContentComponent: DndComponentType<ItemData, HTMLDivElement>;
  getActions: (item: DndItem<ItemData>) => DndItemAction[];
  onDragEnd?: (item: DragEndItem<ItemData>) => void;
}

export const Tree = <ItemData,>({
  data = [],
  filteredData = [],
  draggable = false,
  depthLevel = 0,
  expandIconType,
  getActions,
  onDragEnd,
  ContentComponent,
}: Props<ItemData>) => {
  return (
    <DndProvider<ItemData, HTMLDivElement>
      data={data}
      filteredData={filteredData}
      draggable={draggable}
      expandable
      depthLevel={depthLevel}
      expandIconType={expandIconType}
      onDragEnd={onDragEnd}
      getActions={getActions}
      ContentComponent={ContentComponent}
      ActionsComponent={ActionsBlock}
    />
  );
};
