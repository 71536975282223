import { CSSProperties, HTMLAttributes, ReactNode, useState } from 'react';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UniqueIdentifier } from '@dnd-kit/core';

import { bem } from 'lib/bem';
import { useClickOutside } from 'lib/hooks/useClickOutside';

import { ExpandIconType } from '../lib/types';
import { DraggableIcon } from './DraggableIcon';
import './SortableItem.scss';
import { ExpandIcon } from './ExpandIcon';

interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id' | 'content'> {
  id: UniqueIdentifier;
  depth: number;
  draggable?: boolean;
  clone?: boolean;
  hovered?: boolean;
  expanded?: boolean;
  last?: boolean;
  indentationWidth?: number;
  expandIconType?: ExpandIconType;
  onExpand?(): void;
  projected?: boolean;
  showIconOnDrag?: boolean;

  content?: ReactNode;
  actions?: ReactNode;
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
  !(isSorting || wasDragging);

const { element } = bem('SortableItem');
export const SortableItem = ({
  id,
  depth,
  clone,
  hovered,
  last,
  expanded,
  draggable,
  indentationWidth = 36,
  expandIconType = 'arrow',
  onExpand,
  content,
  actions,
  projected,
  showIconOnDrag,
  ...props
}: Props) => {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled: !draggable,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const [active, setActive] = useState(false);
  const handleClick = () => {
    setActive(true);
  };
  const handleClickOutside = () => {
    setActive(false);
  };
  const { ref: outsideRef } = useClickOutside(handleClickOutside);

  return (
    <li
      className={
        element('wrapper', {
          root: depth === 0,
          clone,
          ghost: isDragging,
          disableInteraction: isSorting,
        }).className
      }
      ref={setDroppableNodeRef}
      style={
        {
          '--spacing':
            depth === 0 ? `${indentationWidth * depth}px` : `${indentationWidth * depth + 15}px`,
        } as CSSProperties
      }
      {...props}
    >
      <div
        ref={setDraggableNodeRef}
        className={
          element('node', { hovered, last, root: depth === 0, expanded, projected }).className
        }
        style={style}
      >
        <div className={element('indicator').className} />
        {draggable && (
          <DraggableIcon
            element={element('icon-drag')}
            attributes={attributes}
            listeners={listeners}
            style={
              {
                '--spacing': `${indentationWidth * depth}px`,
              } as CSSProperties
            }
          />
        )}
        {(onExpand || showIconOnDrag) && (
          <ExpandIcon
            element={element(`icon-${expandIconType}`, { expanded })}
            expanded={expanded}
            onExpand={onExpand}
            expandIconType={expandIconType}
            projected={projected}
          />
        )}

        <div className={element('content').className}>{content}</div>
        {!isDragging && (
          <div
            className={element('actions', { active }).className}
            onClick={handleClick}
            ref={outsideRef}
          >
            {actions}
          </div>
        )}
      </div>
    </li>
  );
};
