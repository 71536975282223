import moment from 'moment';

import { t } from 'lib/i18n';

const unitKeys = ['years', 'months', 'days', 'hours', 'minutes'] as const;

type Props = {
  duration?: any;
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
  months?: number;
  years?: number;
  nowStatusText?: string;
};

export const Duration = (props: Props) => {
  const {
    seconds = 0,
    minutes = 0,
    hours = 0,
    days = 0,
    months = 0,
    years = 0,
    nowStatusText,
    duration,
  } = props;

  const durationData = (duration || moment.duration()).add({
    seconds,
    minutes,
    hours,
    days,
    months,
    years,
  });

  if (durationData._data.days) {
    duration._data.minutes = 0;
  }

  return (
    <span>
      {unitKeys
        .map((unit) => {
          const unitValue = durationData._data[unit];
          return unitValue && `${unitValue} ${t(`duration.short.${unit}`)}`;
        })
        .filter(Boolean)
        .join(', ') ||
        nowStatusText ||
        t('common.now')}
    </span>
  );
};
