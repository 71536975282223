export const getInputHints = (inputs, commonLengthLimit): Record<string, string> | undefined => {
  const getHint = (inputValue, maxLength) =>
    `${inputValue ? inputValue.length : 0}/${maxLength || 0}`;

  if (inputs) {
    return Object.keys(inputs).reduce((acc, inputName) => {
      const input = inputs[inputName];
      if (input) {
        return { ...acc, [inputName]: getHint(input.value, input.maxLength || commonLengthLimit) };
      }
      return { ...acc, [inputName]: '' };
    }, {});
  }

  return undefined;
};
