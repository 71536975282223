import { CSSProperties } from '@mui/system/CSSProperties';
import { PaletteOptions, ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { hexToRgb } from '../../colors';
import { BrandName } from '../../../types/brand';
import { brandName } from '../../../config/constants';

interface BrandedTheme {
  baseColor: CSSProperties['color'];
  activeColor: CSSProperties['color'];
  separatorColor: CSSProperties['color'];
  applicationName: string;
  activityColor?: CSSProperties['color'];
  entryLogoWidth: number;
  entryLogoHeight: number;
  menuLogoWidth: number;
  menuLogoHeight: number;
  menuTextColor?: CSSProperties['color'];
  accentColor: CSSProperties['color'];
}

export const brandsInfo: Record<BrandName, BrandedTheme> = {
  officeservice: {
    baseColor: '#004077',
    separatorColor: '#1d5b91',
    activeColor: 'rgb(255,221,0)',
    applicationName: 'ОФИС\u00A0СЕРВИС',
    entryLogoWidth: 336,
    entryLogoHeight: 78,
    menuLogoWidth: 150,
    menuLogoHeight: 35,
    accentColor: '#004077',
  },
  tagpoint: {
    baseColor: '#212121',
    separatorColor: '#383939',
    activeColor: 'rgb(255,221,0)',
    applicationName: 'TAGPOINT.',
    entryLogoWidth: 250,
    entryLogoHeight: 45,
    menuLogoWidth: 200,
    menuLogoHeight: 32,
    accentColor: '#3878FF',
  },
  teorema: {
    baseColor: '#005151',
    separatorColor: 'rgba(255, 255, 255, 0.16)',
    activeColor: '#FFD300',
    applicationName: 'ТЕОРЕМА',
    entryLogoWidth: 236,
    entryLogoHeight: 70,
    menuLogoWidth: 150,
    menuLogoHeight: 60,
    accentColor: '#005151',
  },
  waveservice: {
    baseColor: '#212121',
    separatorColor: '#383939',
    activeColor: 'rgb(255,221,0)',
    applicationName: 'WAVESERVICE.',
    entryLogoWidth: 288,
    entryLogoHeight: 35,
    menuLogoWidth: 200,
    menuLogoHeight: 28,
    accentColor: '#3878FF',
  },
  waveserviceEN: {
    baseColor: '#212121',
    separatorColor: '#383939',
    activeColor: 'rgb(255,221,0)',
    applicationName: 'WAVESERVICE.',
    entryLogoWidth: 288,
    entryLogoHeight: 35,
    menuLogoWidth: 200,
    menuLogoHeight: 28,
    accentColor: '#212121',
  },
  landia: {
    baseColor: '#00C758',
    separatorColor: 'rgba(255, 255, 255, 0.16)',
    activeColor: '#FFE78C',
    applicationName: 'G-LANDIA Eco',
    entryLogoWidth: 335,
    entryLogoHeight: 55,
    menuLogoWidth: 200,
    menuLogoHeight: 28,
    menuTextColor: '#001E2F',
    accentColor: '#00C758',
  },
  zabotaapp: {
    baseColor: 'rgb(0, 150, 183)',
    separatorColor: '#383939',
    activeColor: 'rgb(249, 163, 30)',
    applicationName: 'С заботой о Вас!',
    entryLogoWidth: 200,
    entryLogoHeight: 56,
    menuLogoWidth: 140,
    menuLogoHeight: 40,
    menuTextColor: 'rgb(236, 248, 250)',
    accentColor: 'rgb(0, 150, 183)',
  },
  suop: {
    baseColor: 'rgb(0, 150, 183)',
    separatorColor: '#383939',
    activeColor: 'rgb(249, 163, 30)',
    applicationName: 'С заботой о Вас!',
    entryLogoWidth: 200,
    entryLogoHeight: 56,
    menuLogoWidth: 140,
    menuLogoHeight: 40,
    menuTextColor: 'rgb(236, 248, 250)',
    accentColor: 'rgb(0, 150, 183)',
  },
  gsp: {
    baseColor: '#0072BF',
    separatorColor: 'rgba(255, 255, 255, 0.16)',
    activeColor: '#53A7DD',
    applicationName: 'Мой офис',
    entryLogoWidth: 215,
    entryLogoHeight: 60,
    menuLogoWidth: 140,
    menuLogoHeight: 40,
    menuTextColor: '#FFF',
    accentColor: '#0072BF',
  },
  'code-service': {
    baseColor: '#212121',
    separatorColor: '#383939',
    activeColor: '#F2672B',
    applicationName: 'CODE SERVICE',
    entryLogoWidth: 270,
    entryLogoHeight: 35,
    menuLogoWidth: 220,
    menuLogoHeight: 28,
    accentColor: '#3878FF',
  },
  nornikel: {
    baseColor: '#0061BF',
    separatorColor: '#383939',
    activeColor: '#FDD231',
    applicationName: 'Забота-24',
    entryLogoWidth: 240,
    entryLogoHeight: 35,
    menuLogoWidth: 138,
    menuLogoHeight: 24,
    accentColor: '#3878FF',
  },
  elma: {
    baseColor: '#002569',
    separatorColor: '#383939',
    activeColor: '#FDD231',
    applicationName: 'ЭЛМА-Сервис',
    entryLogoWidth: 173,
    entryLogoHeight: 35,
    menuLogoWidth: 119,
    menuLogoHeight: 24,
    accentColor: '#3878FF',
  },
  rkvr: {
    baseColor: '#171D35',
    separatorColor: '#383939',
    activeColor: '#FDD231',
    applicationName: 'RUKVER Service',
    entryLogoWidth: 159,
    entryLogoHeight: 40,
    menuLogoWidth: 119,
    menuLogoHeight: 30,
    accentColor: '#3878FF',
  },
  myoffice20: {
    baseColor: '#212121',
    separatorColor: '#383939',
    activeColor: 'rgb(255,221,0)',
    applicationName: 'Мой офис 2.0',
    entryLogoWidth: 130,
    entryLogoHeight: 48,
    menuLogoWidth: 130,
    menuLogoHeight: 48,
    accentColor: '#3878FF',
  },
};

export const brandedThemeOptions = brandsInfo[brandName];

const paletteOptions: PaletteOptions = {
  primary: {
    main: brandedThemeOptions.baseColor ?? '',
    light: '#FFF',
  },

  text: {
    primary: '#000514',
  },

  customBackground: {
    main: '#F1F2F2',
    input: '#FFF',
    highlight: 'rgba(255,255,255,0.1)',
    darker: 'rgba(0,0,0,0.05)',
    activity: brandedThemeOptions.activityColor,
  },

  separators: {
    main: '#CCC',
    menu: brandedThemeOptions.separatorColor,
    field: '#DCDCDC',
  },

  active: brandedThemeOptions.activeColor,

  success: {
    main: '#319D40',
  },

  info: {
    main: '#0079FF',
  },

  error: {
    main: '#FF0029',
  },
};

const defaultThemeOptions: ThemeOptions = {
  palette: paletteOptions,

  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          outline: 'none',
          fontFamily: 'inherit',
          cursor: 'pointer',
          borderRadius: '1px',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          padding: '0.625rem 1rem',
          background: brandedThemeOptions.baseColor,
          textTransform: 'none',
          minWidth: 'unset',
          color: '#FFF',
        },
        contained: {
          background: `${brandedThemeOptions.baseColor} !important`,
          color: '#FFF',
          '&.Mui-disabled': {
            cursor: 'default',
            opacity: '0.2 !important',
            background: `${brandedThemeOptions.baseColor} !important`,
            color: '#FFF',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: 20,
          width: 20,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
          '& .MuiOutlinedInput-input': {
            height: 'inherit',
          },
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontStyle: 'inherit',
        },
        input: {
          background: '#FFF',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          outline: 'none',
          fontFamily: 'inherit',
          cursor: 'pointer',
          border: 'none',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          padding: '0.5rem 1rem',
          transition: 'opacity 0.2s ease',
          borderRadius: '2px',

          '&:hover': {
            '&._ghost': {
              background: 'rgba(0, 0, 0, 0.04)',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: '0 !important',
        },
        notchedOutline: {
          border: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'unset !important',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontStyle: 'inherit',

          '&.Mui-disabled': {
            color: '#000514 !important',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgba(0,0,0,0.55)',
          backdropFilter: 'blur(2px)',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontStyle: 'inherit',

          '&:focus': {
            backgroundColor: 'transparent',
          },

          '&.Mui-selected': {
            color: '#FFF',

            '&:hover': {
              backgroundColor: brandedThemeOptions.baseColor,
            },
          },

          '&:focus.Mui-selected': {
            color: '#FFF',
            backgroundColor: brandedThemeOptions.baseColor,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          'div[role=presentation]': {
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontStyle: 'inherit',
            textTransform: 'capitalize',
          },
        },
      },
    },
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          margin: '0 4px',
          '& .MuiTypography-root': {
            backgroundColor: '#fff',
            border: 'none',
            borderRadius: '18px',
            height: '36px',
            margin: '8px 0',
            textTransform: 'capitalize',

            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },

            '&.Mui-selected': {
              color: '#fff',
              backgroundColor: brandedThemeOptions.baseColor,
            },
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            color: '#FFF !important',
            backgroundColor: `${brandedThemeOptions.baseColor} !important`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontStyle: 'inherit',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontStyle: 'inherit',
          lineHeight: '1rem',
          padding: 0,
          display: 'flex',
          '& svg': {
            marginLeft: 'auto',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          background: '#f6f6f6',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '& .PrivatePickersToolbar-root': {
            '& .MuiButton-root': {
              background: 'none',
            },
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          width: 'unset',
        },
        item: {
          '&:first-child': {
            paddingLeft: 0,
          },
        },
      },
    },
  },

  mixins: {
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },

    centerPosition: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },

    rightPosition: (rightOffset = 0) => ({
      position: 'absolute',
      top: '50%',
      right: rightOffset,
      transform: 'translateY(-50%)',
    }),

    leftPosition: (leftOffset = 0) => ({
      position: 'absolute',
      top: '50%',
      right: leftOffset,
      transform: 'translateY(-50%)',
    }),

    placeholder: (css) => ({
      '&::-webkit-input-placeholder': css,
      '&:-moz-placeholder': css,
      '&::-moz-placeholder': css,
      '&:-ms-input-placeholder': css,
    }),

    clickable: {
      cursor: 'pointer',
      userSelect: 'none',
      '&:active': {
        opacity: 0.8,
      },
    },

    highlightable: {
      '&:hover': {
        background: paletteOptions.customBackground?.darker,
      },
    },

    capitalize: {
      '&:capitalize': {
        textTransform: 'uppercase',
      },
    },

    label: {
      borderRadius: '3px',
      background: '#EEE',
      padding: '0.2rem 0.5rem',
      whiteSpace: 'nowrap',
    },

    buttonsGroup: {
      '& > *': {
        display: 'inline-block',
        marginLeft: '1px',
      },
      button: {
        borderRadius: 0,
      },
      '& > :first-child, & > :first-child button': {
        borderBottomLeftRadius: '3px',
        borderTopLeftRadius: '3px',
      },
      '& > :last-child, & > :last-child button': {
        borderBottomRightRadius: '3px',
        borderTopRightRadius: '3px',
      },
    },

    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },

    backgroundOpacity: (hex, opacity = 0.3) => ({
      background: `rgba(${hex != null ? hexToRgb(hex)?.join(',') : null}, ${opacity})`,
    }),

    noSelect: {
      '-webkit-touch-callout': 'none',
      '-webkit-user-select': 'none',
      '-khtml-user-select': 'none',
      '-moz-user-select': 'none',
      msUserSelect: 'none',
      userSelect: 'none',
    },
  },

  spacing: 8,
};

export const theme = createTheme({ ...defaultThemeOptions });
