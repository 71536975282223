import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';

export const useDebounceField = (input, wait = 250) => {
  const [debounceFieldValue, setDebounceFieldValue] = useState('');
  const [debouncing, setDebouncing] = useState(false);
  const lastInputValue = useRef(input.value);

  useEffect(() => {
    if (debouncing) {
      return;
    }
    if (input.value === lastInputValue.current) {
      return;
    }
    lastInputValue.current = input.value;
    setDebounceFieldValue(input.value);
  }, [debouncing, input.value]);

  const call = useMemo(
    () =>
      debounce((onChange, evt?: any) => {
        setDebouncing(false);
        onChange(evt);
      }, wait),
    [setDebouncing, wait],
  );

  const onChange = useCallback(
    (evt) => {
      setDebouncing(true);
      if (evt) {
        evt.persist();
        call(input.onChange, evt);
        setDebounceFieldValue(evt.target.value);
      } else {
        const e = { target: { value: null } };

        call(input.onChange);
        setDebounceFieldValue(e.target.value ?? '');
      }
    },
    [call, input.onChange],
  );

  const onBlur = useCallback(
    (evt) => {
      call.cancel();
      setDebouncing(false);
      input.onChange(evt);
      input.onBlur(evt);
    },
    [call, input],
  );

  return useMemo(
    () => ({
      ...input,
      value: debounceFieldValue,
      onChange,
      onBlur,
    }),
    [debounceFieldValue, input, onBlur, onChange],
  );
};
