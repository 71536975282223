import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateSMSSettingsSchema } from 'shared/api/v4/swagger/data-contracts';
import { apiClient_v4 } from 'shared/api/v4/client';
import { mutationKeysFactory } from 'shared/lib/react-query/mutationKeysFactory';

import { smsSettingsQueries } from './queries';

const mutationKey = mutationKeysFactory('sms-settings');

export const smsSettingsMutationKeys = { update: mutationKey('update') };

export const useUpdateSmsSettings = () => {
  const client = useQueryClient();

  return useMutation({
    meta: {},
    mutationKey: smsSettingsMutationKeys.update, // Используется для показа сообщения об успешном обновлении
    mutationFn: async ({
      smsSettingsId,
      item,
    }: {
      smsSettingsId: number;
      item: UpdateSMSSettingsSchema;
    }) => apiClient_v4.smsSettings.updateSmsSettings({ smsSettingsId, item }),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: smsSettingsQueries.settings.queryKey });
    },
  });
};
