import { useEffect, useState } from 'react';
import { ScopeResult } from 'lib/resource';
import { DASHBOARD_LAYOUTS } from 'app/analyticalReports/config';

import { DashboardLayoutModel, dashboardsResource } from './dashboards.resource';

export const useReactGridLayout = (userLayouts: ScopeResult<DashboardLayoutModel>) => {
  const [layouts, setLayouts] = useState({});

  useEffect(() => {
    setLayouts(userLayouts.data?.settings || DASHBOARD_LAYOUTS);
  }, [userLayouts.data?.settings]);

  const handleLayoutChange = (layout, newLayouts) => {
    dashboardsResource.saveLayouts(newLayouts);
    setLayouts(newLayouts);
  };

  const [dragging, setDragging] = useState(false);
  const [resizing, setResizing] = useState(false);

  const handleDrag = () => {
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleResizeStart = () => {
    setResizing(true);
  };

  const handleResizeEnd = () => {
    setResizing(false);
  };

  return {
    onLayoutChange: handleLayoutChange,
    dragging,
    resizing,
    onDrag: handleDrag,
    onDragStop: handleDragEnd,
    onResizeStart: handleResizeStart,
    onResizeStop: handleResizeEnd,
    layouts,
  };
};
