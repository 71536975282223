import { qsParse } from 'lib/helpers/qs';
import { FormConfig } from 'lib/helpers/reduxForm';
import { userLocalStorage } from 'app/users';
import { UserModel } from 'app/users/types';

export enum DatePeriod {
  TODAY = 'day',
  YESTERDAY = 'yesterday',
  CURRENT_WEEK = 'week',
  CURRENT_MONTH = 'month',
  CURRENT_YEAR = 'year',
}

export interface WidgetFiltersValues {
  customDatePeriod: {
    start: string;
    end: string;
  };
  locationId: number;
  issueTypeId: number;
  locationTypeId: number;
}

interface DateRangeParam {
  date_from: string;
  date_to: string;
}

type WidgetFiltersDto = {
  managed_object_ids: number[];
  issue_type_id: number;
  managed_object_type_id: number;
} & DateRangeParam;

export const dashboardFiltersFormConfig: FormConfig<WidgetFiltersValues> = {
  form: 'dashboardFilters',
};

export const mapDashboardFilters = (
  values?: Partial<WidgetFiltersValues>,
): Partial<WidgetFiltersDto> => {
  if (!values) {
    return {};
  }
  const { locationId, issueTypeId, locationTypeId, customDatePeriod } = values;
  const baseDto: Partial<WidgetFiltersDto> = {
    managed_object_ids: locationId ? [locationId] : [],
    issue_type_id: issueTypeId || undefined,
    managed_object_type_id: locationTypeId || undefined,
  };
  if (customDatePeriod) {
    if (customDatePeriod.start) {
      baseDto.date_from = customDatePeriod.start;
    }
    if (customDatePeriod.end) {
      baseDto.date_to = customDatePeriod.end;
    }
  }
  return baseDto;
};

const storageKey = 'dashboard-filters';

export const getSavedWidgetFilters = (user: UserModel) => {
  return userLocalStorage.get<Partial<WidgetFiltersValues>>({ user, key: storageKey });
};

export const saveWidgetFilters = (user: UserModel, value: Partial<WidgetFiltersValues>) => {
  userLocalStorage.set({ user, key: storageKey, value });
};

export const getInitialWidgetFilters = (currentUser: UserModel): Partial<WidgetFiltersValues> => {
  const savedFilters = getSavedWidgetFilters(currentUser);
  return {
    ...savedFilters,
    ...qsParse(window.location.search),
  };
};
