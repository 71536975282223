import { useCallback, useEffect, useRef, useState } from 'react';

export const useClickOutside = (effectFn) => {
  const [clickedOutside, setClickedOutside] = useState(false);

  const ref = useRef<any>(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current) {
        if (!ref.current.contains(event.target)) {
          if (effectFn) {
            effectFn();
          }
        }
        setClickedOutside(!ref.current.contains(event.target));
      }
    },
    [effectFn],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return {
    ref,
    clickedOutside,
  };
};
