export const getLocalStorageItem = (key) => {
  const storedItem = localStorage.getItem(key);
  if (storedItem) {
    return JSON.parse(storedItem);
  }
  return null;
};

export const saveLocalStorageItem = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item));
};
