// eslint-disable-next-line import/no-cycle
import { FormBuilder } from 'lib/ui';
import { reconnect } from 'lib/resource';

import roundReportsResource from '../roundReports.resource';

const getLabel = (type) => type.name;

const getValue = (type) => {
  if (typeof type !== 'object') return type;

  return type.id;
};

const normalize = (items = []) => {
  return items.map((item: { id: string }) => {
    if (typeof item !== 'object') return item;
    return item.id;
  });
};

export const RoundReportsIssueTypesSelect = reconnect((state) => ({
  issueTypes: roundReportsResource.issueTypes(state, {}, { forceFetchOnMount: false }),
}))((props) => {
  return (
    <FormBuilder.MultiSelect
      name="issue_type_ids"
      options={props.issueTypes?.data}
      getItemLabel={getLabel}
      getItemValue={getValue}
      normalize={normalize}
      {...props}
    />
  );
});
