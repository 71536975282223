export { default as issues } from './issues.resource';
export { issuesSaga } from './issues.saga';
export { default as IssueShortStatus } from './IssueShortStatus';

export const ISSUE_STATUSES = [
  'moderation',
  'in_progress',
  'on_check',
  'closed',
  'opened',
];
