import { all, call, put } from 'redux-saga/effects';
import { takeMap } from 'lib/saga-utils';
import { contextNotification } from 'app/notifications';
import metaflows from './metaflows.resource';

const notify = contextNotification('metaflows');

export default function* metaflowsSaga() {
  yield all([
    call(metaflows.saga),

    call(takeMap({
      'METAFLOWS/CREATE_SUCCESS': function* () {
        yield all([
          put(notify('created')),
        ]);
      },

      'METAFLOWS/UPDATE_SUCCESS': function* () {
        yield all([
          put(notify('updated')),
        ]);
      },

      'METAFLOWS/REMOVE_SUCCESS': function* () {
        yield all([
          put(notify('removed')),
        ]);
      },
    })),
  ]);
}
