import { api } from 'lib/api';
import { createResource } from 'lib/resource';
import { put } from 'redux-saga/effects';
import { closeModal } from 'lib/ui/Modal';

import { SAVE_METAFLOW_MODAL } from './config';

export const getMetaflows = ({ payload }) => api.get('/metaflows/', { search: payload });

function* closeOnSuccess() {
  yield put(closeModal(SAVE_METAFLOW_MODAL));
}

export default createResource('metaflows', {
  scopes: {
    list: getMetaflows,
  },

  byId: ({ payload }) => api.get(`/metaflows/${payload}/`),

  mutators: {
    create: {
      request: ({ payload }) =>
        api.post('/metaflows/create', {
          body: payload,
        }),
      onSuccess: closeOnSuccess,
    },

    update: {
      request: ({ payload }) => {
        const { id, ...body } = payload;

        return api.post(`/metaflows/${id}/update`, {
          body,
        });
      },
      onSuccess: closeOnSuccess,
    },

    remove: ({ payload }) => api.post(`/metaflows/${payload.id}/delete`),
  },
});
