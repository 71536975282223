import 'core-js/stable';

import './index.scss';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import App from 'app/app';
import { createRoot } from 'react-dom/client';
import i18n from 'lib/i18n/i18n';
import { StyledEngineProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { registerFcmSW } from 'lib/service-workers/firebaseMessaging';
import { store } from 'config/redux';
import { AuthProvider, QueryClientProvider } from 'app/providers';
import { TeoremaBookingScheduler } from 'app/bookings/TeoremaBookingScheduler';

// import * as ReactDOM from 'react-dom';
import AppThemeProvider from './lib/ui/AppThemeProvider';
import brandedData from './config/brandedData';

document.title = brandedData.applicationName;

export const getStore = () => store;

registerFcmSW();

const currentPathname = window.location.pathname;

const root = createRoot(document.getElementById('main')!);

if (currentPathname.includes('booking_scheduler')) {
  root.render(<TeoremaBookingScheduler path={currentPathname} />);
} else {
  // ReactDOM.render(
  //   <QueryClientProvider>
  //     <StyledEngineProvider injectFirst>
  //       <I18nextProvider i18n={i18n}>
  //         <Provider store={store}>
  //           <AppThemeProvider>
  //             <LocalizationProvider dateAdapter={AdapterMoment}>
  //               <App history={browserHistory} />
  //             </LocalizationProvider>
  //           </AppThemeProvider>
  //         </Provider>
  //       </I18nextProvider>
  //     </StyledEngineProvider>
  //   </QueryClientProvider>,
  //   document.getElementById('main'),);
  root.render(
    <QueryClientProvider>
      <StyledEngineProvider injectFirst>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <AppThemeProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </LocalizationProvider>
            </AppThemeProvider>
          </Provider>
        </I18nextProvider>
      </StyledEngineProvider>
    </QueryClientProvider>,
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (module.hot) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  module.hot.accept();
}
