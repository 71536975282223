import './Modal.scss';
import { flowRight } from 'lodash';
import { reconnect } from 'lib/resource';
import { withRouter } from 'config/withRouter';

import ModalContainer from './ModalContainer';
import ModalComponent from './ModalComponent';

import { closeModal } from '.';

/**
 * HoC. Создает компонент модального окна.
 *
 * @param options {Object}
 *   Options object
 *
 * @param options.name {String}
 *   Идентификатор окна. Используется для открытия окна
 *   с помощью redux actions, а также для роутинга.
 *
 * @param options.getTitle {function}
 *   Возвращает заголовок окна.
 *   ((data, props) => Component|String)
 *
 * @param options.mapStateToProps {function}
 *   Используется для связывания данных из redux-store.
 *   Можно (и очень часто требуется) использовать как reconnect.
 *   В случае, если в окно будут переданы ресурсы, то до их загрузки.
 *
 * @param options.routing {Boolean}
 *   Флаг синхронизации с роутером.
 *   Если включен, при открытии/закрытии окна будет изменять location.
 *   И также окно будет открываться при изменении location.

 *   Например, smart.space/feed/?issue=1000 открывает модальное окно
 *   с name=issues, передавая props.data=1000.
 *
 *   @param options.backdropClick {Boolean}
 *   Если присвоить false, то окно не будет закрываться при клике мимо
 *
 * @param modifiers {Object}
 *   БЭМ-модификаторы, которые будут применены к компоненту.
 *   Так, удобно использовать { top: true } ччтобы модальное окно было
 *   вертикально приклеено к верху экрана, а не центру. Может пригодиться в
 *   случае, когда высота окна изменяется динамически.
 *
 */
export default function ReduxModal<P>({
  name,
  mapStateToProps = () => {},
  routing = false,
  loader = () => {},
  getTitle = (data: any, props: any) => '',
  modifiers = {},
  backdropClick = true,
  className,
}: any): (BodyComponent) => any {
  return (BodyComponent) => {
    const modal = { name, routing };

    function getModalName(selfProps) {
      return selfProps.name || name;
    }

    const _mapStateToProps = (state, selfProps) => {
      const name = getModalName(selfProps);
      const modalData = state.modal[name];
      const modalExtraData = state.modal[`${name}ExtraData`];
      const isOpened = !!modalData;

      const props = {
        modal,
        modalExtraData,
        modifiers,
        isOpened,
        modalProps: selfProps.modalProps,
        component: BodyComponent,
        getTitle,
        data: modalData,
        className: `_${name} _${className}`,
        backdropClick,
      };

      return {
        ...props,
        ...mapStateToProps(state, { ...props, ...selfProps }),
      };
    };

    const mapDispatchToProps = (dispatch, props) => ({
      dispatch,
      onClose: (data) => {
        dispatch(closeModal(getModalName(props)));

        if (props.onClose) {
          props.onClose(data);
        }
      },
    });

    const Component = reconnect(_mapStateToProps, mapDispatchToProps)(ModalComponent);

    return flowRight(
      withRouter,
      reconnect((state, selfProps) => ({
        name: getModalName(selfProps),
        modalData: state.modal[getModalName(selfProps)],
        routing,
        isOpened: Boolean(state.modal[getModalName(selfProps)]),
        component: Component,
        modalProps: selfProps.modalProps,
      })),
    )(ModalContainer);
  };
}
