import { PureComponent } from 'react';
import { Portal } from 'react-portal';
import { debounce, omit, omitBy, flowRight } from 'lodash';
import queryString from 'query-string';
import { isDirty } from 'redux-form';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { bem } from 'lib/bem';
import { IconAsButton } from 'lib/ui/icon';
// eslint-disable-next-line import/no-cycle
import confirm from 'lib/ui/Modal/Confirm';
import { t } from 'lib/i18n';
// eslint-disable-next-line import/no-cycle
import { reconnect } from 'lib/resource';
import { saveIssueChanges } from 'entities/issues';

import BusyIndicator from '../BusyIndicator/BusyIndicator';
import device from '../../device';

const { block, element } = bem('Modal');

const EXCLUDED_PROPS = [
  'location',
  'params',
  'query',
  'route',
  'routeList',
  'routeParams',
  'router',
  'routes',
];

const EXCLUDED_FORMS = [
  'filter',
  'reportselect',
  'pagination',
  'employeeform',
  'quicklogin',
  'roundreportsform',
];

const EXCLUDED_MODALS = ['_confirm ', '_galleryModal', '_roundGroup'];

function isClickOnScrollBar(e) {
  const SCROLL_BAR_SIZE = 17;
  if (window.innerWidth - e.clientX < SCROLL_BAR_SIZE) return true;
}

const getDirtyForms = (state, forms) => {
  const filteredForms = forms.filter((form) => {
    let isExcluded = false;
    EXCLUDED_FORMS.some((item) => {
      if (form.toLowerCase().includes(item)) {
        isExcluded = true;
        return true;
      }
      return false;
    });
    return !isExcluded;
  });
  return filteredForms.map((name) => isDirty(name)(state)) || [];
};

export default flowRight(
  reconnect((state) => ({
    forms: Object.keys(state.form),
  })),
  reconnect((state, props) => ({
    dirty: getDirtyForms(state, props.forms),
  })),
)(
  class Modal extends PureComponent<any> {
    static propTypes = {
      data: PropTypes.any,
      component: PropTypes.func.isRequired,
      className: PropTypes.string,
      onClose: PropTypes.func,
      isOpened: PropTypes.bool,
      getTitle: PropTypes.func,
    };

    _isMounted: any;

    modal: any;

    overlayIndex: any;

    overlayed: any;

    state = {
      closing: false,
      loaded: false,
    };

    private dialogRef: any;

    componentDidMount() {
      this._isMounted = true;
      this.setRoute((this.props as any).data);

      if (!this.isLoading(this.props)) {
        this.setState({ loaded: true });
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
      this.clearRoute();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (!this.isLoading(nextProps) && !this.state.loaded) {
        this.setState({ loaded: true });
      }
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
      if (nextProps.data !== (this.props as any).data && (this.props as any).routing) {
        this.setRoute(nextProps.data);
      }
    }

    clearRoute() {
      if ((this.props as any).routing) {
        this.setRoute(null);
      }
    }

    setRoute = debounce((data) => {
      if (!(this.props as any).routing) return;

      const { name } = this.props;
      const { location } = (this.props as any).router;

      const query = omitBy(
        {
          ...location.query,
          [name]: data,
        },
        (value) => value === null,
      );

      (this.props as any).router.navigate(`${location.pathname}?${queryString.stringify(query)}`);
    });

    checkIfVerticalScrollbarVisible = () => {
      if (this.dialogRef) {
        return this.dialogRef.clientHeight > document.body.clientHeight;
      }
      return false;
    };

    /* eslint-disable-next-line */
    setDialogRef = (el) => {
      this.dialogRef = el;
    };

    render() {
      /* eslint-disable-next-line */
      const LoadingError = () => (
        <div {...element('loadingError')}>{t('common.loading_error_description')}</div>
      );

      const BodyComponent = this.props.component;

      const modalProps: Record<string, any> = omit(
        {
          ...this.props,
          isLoadingFailed: this.isLoadingFailed(),
        },
        ...EXCLUDED_PROPS,
      );

      const { isOpened, getTitle, modifiers, className } = this.props;

      const { width, modalContentStyle = {} } = this.props.modalProps || {};

      const showTitle =
        this.state.loaded &&
        (getTitle(modalProps.data, modalProps) ||
          (this.props.modalProps ? !this.props.modalProps.hideEmptyTitle : true));

      const verticalScrollbarVisible = this.checkIfVerticalScrollbarVisible();

      return this.state.loaded ? (
        <Dialog
          scroll="body"
          {...block(modifiers, className)}
          open={isOpened}
          onClose={this.handleClose}
          fullScreen={!!device.is.phone}
          fullWidth
          classes={{
            container: element('container', { scrollable: verticalScrollbarVisible }).className,
          }}
          PaperProps={{
            sx: {
              ...{
                maxWidth: 800,
                width,
              },
              ...modalContentStyle,
            },
            className: `content${verticalScrollbarVisible ? ' _scrollable' : ''}`,
            ref: this.setDialogRef,
          }}
        >
          <DialogTitle {...element('title')}>
            {showTitle && <div>{getTitle(modalProps.data, modalProps)}</div>}
            <IconAsButton {...element('close')} onClick={this.handleClose} glyph="new_close" />
          </DialogTitle>
          <DialogContent
            {...element('content', { withTitle: showTitle, scrollable: verticalScrollbarVisible })}
          >
            {this.isLoadingFailed() ? <LoadingError /> : <BodyComponent {...modalProps} />}
          </DialogContent>
        </Dialog>
      ) : (
        <Portal isOpen>
          <BusyIndicator
            sx={{
              position: 'absolute',
              left: '50%',
              top: `calc(${window.scrollY}px + 50vh)`,
              zIndex: 2000,
            }}
          />
        </Portal>
      );
    }

    getResources(props = this.props) {
      return Object.keys(props)
        .map((propName) => props[propName])
        .filter((prop) => prop && prop.$ResourceResults);
    }

    isLoading(props = this.props) {
      return this.getResources(props).some((prop) => prop.fetching);
    }

    isLoadingFailed() {
      return this.getResources().some((prop) => prop.failed);
    }

    handleSaveAction({ modalData, attributes } = this.props) {
      saveIssueChanges({ id: parseInt(modalData, 10), attributes });
    }

    handleClose = (e?: any, reason?: string) => {
      const { backdropClick } = this.props;
      if (e && isClickOnScrollBar(e)) return;

      if (!backdropClick && reason && reason === 'backdropClick') return;

      const handleAction = () => {
        if (this._isMounted) {
          this.setState({
            closing: true,
          });
        }
        this.clearRoute();
        onClose();
      };

      const { className, dirty, onClose } = this.props;
      const isChanged = dirty.includes(true);

      const isExcludedModal = !!EXCLUDED_MODALS.filter((item) =>
        (className as string).includes(item),
      ).length;

      if (!isExcludedModal && isChanged) {
        if (className.includes('_issue')) {
          confirm(t('issues.save_changes'), {
            onClose: handleAction,
          })
            .then(handleAction)
            .then(() => this.handleSaveAction(this.props));
        } else {
          confirm(t('common.modal_confirm')).then(() => handleAction());
        }
      } else {
        handleAction();
      }
    };
  },
);
