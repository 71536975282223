import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';

import VerifyLocationView from 'lib/ui/VerifyLocationView';
import {
  TStatus,
  getCameraStatus,
  QRcodeStatus,
} from 'lib/ui/FormBuilder/types/QRcodeVerify/QRcodeVerifyStatus';

const QRcodeVerify = ({ data: { data }, input: { onChange } }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const qrcodeValue = useSelector((state) => (state as any).qrcodeScanner);
  const issuesById = useSelector((state) => (state as any).issues.byId.data);
  const [status, setStatus] = useState<TStatus | null>(null);

  useEffect(() => {
    if (qrcodeValue) {
      dispatch({
        type: 'PUT_QRCODE_INFO',
        name: 'qrcodeScanner',
        value: '',
      });
    }
  }, [dispatch, qrcodeValue]);

  const locationID = useMemo(() => {
    const location = searchParams.get('location');
    const issue = searchParams.get('issue');

    if (location && !issue) return location;
    if (issue) {
      const [issueId] = issue.split('.');
      return issuesById[issueId].data.location_id;
    }
  }, [issuesById, searchParams]);

  useEffect(() => {
    if (qrcodeValue) {
      setStatus(QRcodeStatus.success);
      onChange(qrcodeValue);
    }
  }, [onChange, qrcodeValue]);

  const onScanClick = useCallback(() => {
    navigate(`/qrcode/scanner?loc=${locationID}`);
  }, [locationID, navigate]);

  const statusView = useMemo(() => getCameraStatus(status, onScanClick), [onScanClick, status]);

  return <VerifyLocationView cta={data.cta} statusView={statusView} name={data.name} />;
};

export default QRcodeVerify;
