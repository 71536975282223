import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { useQuery } from '@tanstack/react-query';
import { Box, Theme, useMediaQuery } from '@mui/material';

import { getActionsById } from 'app/managedObjects/managedObjects.resource';
import { SAVE_LOCATION_MODAL } from 'app/locations/config';
import { openModal } from 'lib/ui/Modal';
import { Button, confirm, ModalLink, Page, PageActions } from 'lib/ui';
import { t, t_prefixed } from 'lib/i18n';
import { bem } from 'lib/bem';
import { locationsQueries, useMoveLocation, useRemoveLocation } from 'entities/locations';
import { DndItem, DndItemAction, DragEndItem } from 'shared/ui/dnd-provider';
import { LocationsFileUploader } from 'app/locations/LocationsFileUploader';
import { Tree } from 'shared/ui/tree';
import { ManagedObjectTreeNode } from 'shared/api/v4/swagger/data-contracts';
import { SearchField } from 'widgets/search';
import { ContentBlock } from 'widgets/content-block';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';
import { EmptyBlock } from 'shared/ui/empty-block';
import { IconAsButton } from 'lib/ui/icon';

import LocationCard from './LocationCard';
import './Locations.scss';

const { block, element } = bem('Locations');
const label = t_prefixed('locations.pages.edit_locations');

type TreeItemData = {
  name: string;
  parent_names: string[];
  subtitle?: string;
};
const getSubtitle = (item: ManagedObjectTreeNode) => {
  const owner = item.owner && `${t('locations.fields.location_holder')}: ${item.owner}`;
  const allowAnonymous = item.allow_anonymous && t('locations.fields.allow_anonymous_label');

  return [item.managed_object_type_name, allowAnonymous, owner].filter((it) => it).join(' · ');
};

function mapTree(items: ManagedObjectTreeNode[]) {
  return items.map((item) => {
    const newItem: DndItem<TreeItemData> = {
      ...item,
      id: item?.id ?? 0,
      itemData: {
        name: item.name,
        parent_names: item.parent_names ?? [],
        subtitle: getSubtitle(item),
      },
    };

    if (newItem.children && newItem.children.length > 0) {
      newItem.children = mapTree(item.children ?? []);
    }
    return newItem;
  });
}
export const Locations = () => {
  const dispatch = useDispatch();
  const [locationFilter, setLocationFilter] = useState<string>('');
  const isNarrowScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down(650));

  const { data: locationsResponse, isFetching } = useQuery(locationsQueries.list({}));
  const { data: filteredLocationsResponse } = useQuery(
    locationsQueries.byName({ name: locationFilter }),
  );
  const { mutate: removeLocation, isPending: isRemovePending } = useRemoveLocation();
  const { mutate: moveLocation } = useMoveLocation();

  const filteredLocations = filteredLocationsResponse?.response.data;

  const locations = useMemo(() => {
    const data =
      locationFilter && filteredLocations?.length === 0
        ? undefined
        : locationsResponse?.response.data;
    return data && mapTree(data);
  }, [filteredLocations, locationFilter, locationsResponse]);

  const handleRemove = useCallback(
    async (item: DndItem<TreeItemData>) => {
      const { itemData } = item;

      const result = await confirm(
        label('remove_confirmation', {
          name: [...(itemData?.parent_names ?? []), itemData?.name].join(' – '),
        }),
        {
          proceedText: t('common.remove'),
        },
      );
      if (result && item?.id) {
        removeLocation(Number(item.id));
      }
    },
    [removeLocation],
  );

  const handleEdit = useCallback(
    (item: DndItem<TreeItemData>) => {
      getActionsById({ payload: { id: item.id, type: 'booking_item' } }).then((response) => {
        if (response.error) {
          dispatch(openModal('locationCard', item.id));
        } else {
          const {
            response: { data },
          } = response;
          dispatch(openModal('locationCard', `${item.id}.booking.${data.id}`, { ...data }));
        }
      });
    },
    [dispatch],
  );

  const handleAdd = useCallback(
    (item: DndItem<TreeItemData>) => {
      const data = item?.id ? `${item.id}.new` : '0.new';
      dispatch(openModal('locationCard', data, {}));
    },
    [dispatch],
  );

  const handleCopy = useCallback(
    (item: DndItem<TreeItemData>) => {
      dispatch(openModal('locationCard', `${item?.id}.copy`, {}));
    },
    [dispatch],
  );

  const handleDragEnd = useCallback(
    ({ parentId, itemId, beforeId }: DragEndItem<TreeItemData>) => {
      moveLocation({
        parentId: Number(parentId),
        beforeId: Number(beforeId),
        id: Number(itemId),
      });
    },
    [moveLocation],
  );

  const getActions = useCallback(
    (item: DndItem<TreeItemData>): DndItemAction[] => {
      const actions: (DndItemAction & { cond?: (item: DndItem<TreeItemData>) => boolean })[] = [
        {
          name: 'Edit&Copy&Add',
          component: (
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                padding: '4px 8px',
              }}
            >
              <IconAsButton glyph="pen" width={24} height={24} onClick={() => handleEdit?.(item)} />
              <IconAsButton
                glyph="content_copy"
                width={24}
                height={24}
                onClick={() => handleCopy?.(item)}
              />
              <IconAsButton
                glyph="add_new"
                width={24}
                height={24}
                onClick={() => handleAdd(item)}
              />
            </Box>
          ),
          cond: () => isNarrowScreen,
          isMenu: true,
        },
        {
          name: 'Edit',
          onClick: () => handleEdit(item),
          icon: 'pen',
          cond: () => !isNarrowScreen,
        },
        {
          name: 'Copy',
          onClick: () => handleCopy(item),
          icon: 'content_copy',
          cond: () => !isNarrowScreen,
        },
        {
          name: 'Add',
          onClick: () => handleAdd(item),
          icon: 'add_new',
          cond: () => !isNarrowScreen,
        },
        {
          name: 'Remove',
          onClick: () => handleRemove(item),
          isMenu: true,
        },
        {
          name: 'File Uploader',
          component: <LocationsFileUploader parentId={Number(item.id ?? 0)} />,
          isMenu: true,
        },
      ];

      return actions.filter(({ cond }) => (cond ? cond(item) : true));
    },
    [isNarrowScreen, handleAdd, handleCopy, handleEdit, handleRemove],
  );

  const handleFieldChange = (value: string) => {
    setLocationFilter(value);
  };

  return (
    <>
      <LocationCard editable readOnly={false} />

      <Page
        {...block()}
        title={label('title')}
        actions={
          <PageActions>
            <ModalLink to={SAVE_LOCATION_MODAL} data="0.new">
              <Button>{t('common.add')}</Button>
            </ModalLink>
          </PageActions>
        }
      >
        <SearchField onChange={handleFieldChange} />
        {locationFilter && filteredLocations?.length === 0 && (
          <EmptyBlock textKey="common.no_results" />
        )}

        {(isFetching || isRemovePending) &&
          createPortal(
            <div {...element('busy')}>
              <BusyIndicator />
            </div>,
            document.body,
          )}

        <Tree
          data={locations}
          filteredData={filteredLocations}
          depthLevel={-1}
          draggable
          getActions={getActions}
          onDragEnd={handleDragEnd}
          ContentComponent={ContentBlock}
        />
      </Page>
    </>
  );
};
