export { default as ModalLink } from './ModalLink';
export { default as ReduxModal } from './ReduxModal';
export { default as Modal } from './ModalComponent';
export { ConfirmationMountPoint, createConfirmation } from './modalMounter';

export function openModal(name, data, extraData?: Record<string, any>) {
  return {
    type: 'OPEN_MODAL',
    name,
    data,
    extraData,
  };
}

interface ModalToOpenAfterCloseConfig {
  name: string;
  data: any;
  extraData?: Record<string, any>;
}

export function closeModal(name, modalToOpenAfterClose: ModalToOpenAfterCloseConfig | null = null) {
  return {
    type: 'CLOSE_MODAL',
    name,
    modalToOpenAfterClose,
  };
}

export function setModalExtraData(name, extraData) {
  return {
    type: 'SET_EXTRA_DATA',
    name,
    extraData,
  };
}
