const boldRegex = /\\u000b/;

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean,
): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

const processText = (text: string) => {
  return text.replace(boldRegex, '');
};

/**
 * Set returned values in dangerouslySetInnerHTML
 * @param title
 */
export const useParseTitle = (title: string) => {
  if (!title) {
    return ['', ''];
  }
  const lastBoldCharIndex = findLastIndex(title.split(''), (el) => boldRegex.test(el));
  const boldText = title.substring(0, lastBoldCharIndex);
  const commonText = title.substring(lastBoldCharIndex);

  return [processText(boldText), processText(commonText)];
};
