// eslint-disable-next-line import/no-cycle
import { closeDictEditor } from 'app/common/DictEditor/closeDictEditor';
import { api } from 'lib/api';
import { createResource } from 'lib/resource';

const v2 = 'v2';
const newBackPrefix = 'v4';

const activities = createResource('activities', {
  scopes: {
    byLocation: ({ payload }) => {
      if (payload) {
        return api.get(`/${v2}/locations/${payload}/activities/`);
      }
    },
    list: ({ payload: { create_issue } }) =>
      api.get(`/${v2}/activities/`, create_issue && { search: { types: 'create_issue' } }),
    adminActivities: () => api.get(`/${newBackPrefix}/activities/`),
    activityRelatedIssueTypes: ({ payload }) =>
      api.get(`/${newBackPrefix}/activities/${payload.id}/issue_types`),
    icons: {
      request: () => api.get(`/${newBackPrefix}/activities/icons/`),
      type: 'list',
      normalizer: (response) => response,
      selector: (data) => data,
    },
  },
  mutators: {
    create: {
      request: ({ payload }) => {
        return api.post(`/${newBackPrefix}/activities/`, { body: payload });
      },
      onSuccess: closeDictEditor,
    },

    update: {
      request: ({ payload }) => {
        const { id, ...body } = payload;
        return api.put(`/${newBackPrefix}/activities/${id}/`, { body });
      },
      onSuccess: closeDictEditor,
    },

    move: {
      request: ({ payload }) => {
        const { id, beforeId, body } = payload;
        return api.put(`/${newBackPrefix}/activities/${id}/`, {
          body: {
            ...body,
            before_id: beforeId,
          },
        });
      },
    },

    delete({ payload }) {
      const { id } = payload;
      return api.delete(`/${newBackPrefix}/activities/${id}/`);
    },
  },
});

export default activities;
