import { ReactNode } from 'react';

import { bem } from 'lib/bem';
import { IconAsButton } from 'lib/ui/icon';

import './Label.scss';

const { block } = bem('Label');

interface Props {
  children: ReactNode;
  removable?: boolean;
  onRemove?: () => void;
  className?: string;
}

export default function Label({ children, removable, onRemove, className }: Props) {
  const handleMouseDown = (e) => {
    e.stopPropagation();
  };

  const handleRemove = (e) => {
    if (onRemove) {
      onRemove();
    }
    e.stopPropagation();
  };

  return (
    <div {...block({ removable }, className)}>
      {children}

      {removable && (
        <IconAsButton glyph="close" onMouseDown={handleMouseDown} onClick={handleRemove} />
      )}
    </div>
  );
}
