import './NumberInput.scss';
import { Component } from 'react';
import { isNil, round } from 'lodash';
import { TextField } from '@mui/material';

import device from 'lib/device';
import { bem } from 'lib/bem';
import i18n from 'lib/i18n/i18n';
import { resources } from 'lib/i18n';

const { block } = bem('NumberInput');

const NUMBER_CHARCODES = {
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
};

const DELIMITER_CHARCODES = {
  44: '.',
  46: ',',
};

const MINUS_SIGN_CHARCODE = 45;
const MAX_NUMBER = 2 ** 31 - 1;

const currentDelimiter =
  resources[i18n.language]?.translation.numeralLocale.delimiters.decimal || ',';

type OwnState = any;

type State = OwnState & typeof NumberInput.defaultProps;

export class NumberInput extends Component<object, State> {
  static defaultProps = {
    delimiter: currentDelimiter,
    minFracSize: 0,
    maxFracSize: 6,
    min: -MAX_NUMBER,
    max: MAX_NUMBER,
    multiplier: 1,
    maxLength: undefined,
  };

  input: any;

  selection: any;

  state = {
    viewValue: '',
  };

  componentDidMount() {
    this.setViewValue(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!isNil(newProps.value) && !this.isFocused()) {
      this.setViewValue(newProps);
    }
  }

  setViewValue(props) {
    this.setState({
      viewValue:
        isNil(props.value) || props.value === ''
          ? ''
          : this.normalizeStrValue(String(this.normalize(props.value)), props),
    });
  }

  isFocused() {
    return this.input === document.activeElement;
  }

  normalize(value) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'multiplier' does not exist on type 'Read... Remove this comment to see the full error message
    const { multiplier, maxFracSize } = this.props;
    return round(value / multiplier, maxFracSize);
  }

  render() {
    return (
      <TextField
        fullWidth
        placeholder={(this.props as any).placeholder}
        type={this.getInputType()}
        // eslint-disable-next-line no-return-assign
        inputRef={(input) => (this.input = input)}
        value={this.state.viewValue || ''}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onKeyUp={this.saveCaretPosition}
        onKeyPress={this.filterInput}
        inputProps={{
          maxLength: (this.props as any).maxLength,
          className: (this.props as any).inputClassName,
        }}
      />
    );
  }

  getInputType() {
    return device.is.desktop ? 'text' : 'number';
  }

  filterInput = (event) => {
    const { charCode } = event;

    if (charCode in NUMBER_CHARCODES) {
      return;
    }
    if (!this.isInteger() && charCode in DELIMITER_CHARCODES) {
      return;
    }
    if (!this.isPositive() && charCode === MINUS_SIGN_CHARCODE) {
      return;
    }

    event.preventDefault();
    return false;
  };

  handleChange = (event) => {
    this.saveCaretPosition(event);
    const value = this.inputValue();

    if (value.match(this.getPattern())) {
      this.updateValue(value);
    } else {
      this.moveCaretLeft();
    }

    this.loadCaretPosition();
  };

  handleBlur = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { value, delimiter, onBlur } = this.props;

    if (isNil(value) || value === '') {
      this.updateValue('');
    } else {
      const strValue = String(this.normalize(value)).replace(/[,.]/g, delimiter);
      this.updateValue(strValue);
    }

    if (onBlur) onBlur();
  };

  inputValue() {
    return this.normalizeStrValue(this.input.value);
  }

  saveCaretPosition = (event) => {
    const { selectionStart, selectionEnd } = event.target;

    this.selection = {
      start: selectionStart,
      end: selectionEnd,
    };
  };

  moveCaretLeft() {
    this.selection = {
      start: this.selection.start - 1,
      end: this.selection.end - 1,
    };
  }

  loadCaretPosition() {
    requestAnimationFrame(() => {
      try {
        this.input.selectionStart = this.selection.start;
        this.input.selectionEnd = this.selection.end;
      } catch (e) {
        console.error(e);
      }
    });
  }

  updateValue(value) {
    this.setState({ viewValue: value });
    this.updateNumberValue(value);
  }

  updateNumberValue(value) {
    if (value === '-') {
      value = '';
    }

    if (value === '') {
      (this.props as any).onChange(null);
      return;
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'delimiter' does not exist on type 'Reado... Remove this comment to see the full error message
    const { delimiter } = this.props;
    const numberValue = Number(value.replace(delimiter, '.')) * (this.props as any).multiplier;

    if (numberValue !== (this.props as any).value) {
      (this.props as any).onChange(isNaN(numberValue) ? null : numberValue);
    }
  }

  getPattern() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'delimiter' does not exist on type 'Reado... Remove this comment to see the full error message
    const { delimiter, maxFracSize } = this.props;
    const fracPart = `${delimiter}?(\\d{0,${maxFracSize}})`;
    return new RegExp(`^(-?\\d*${maxFracSize > 0 ? fracPart : ''})?$`);
  }

  isInteger() {
    return (this.props as any).integer;
  }

  isPositive() {
    return (this.props as any).min >= 0;
  }

  normalizeStrValue(value = '', props = this.props) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'delimiter' does not exist on type 'Reado... Remove this comment to see the full error message
    const { delimiter, maxFracSize } = props;
    value = value.replace(/[,.]/g, delimiter);

    if (value === delimiter) {
      return '';
    }

    const pattern = new RegExp(`(\\${delimiter}\\d{${maxFracSize}})\\d*`);
    return String(value).replace(pattern, '$1');
  }
}
