import { useEffect } from 'react';
import { reconnect } from 'lib/resource';
import { Page } from 'lib/ui';
import BookingItemForm from '../BookingItemForm';
import { managedObjects } from '../../managedObjects';
import { BookingItemAction } from '../types';

export default reconnect((state, { params: { locationId } }) => {
  return {
    bookingItem: (managedObjects as any).actions(state, { id: locationId, type: 'booking_item' }, { skipOutdated: true }),
  };
})(({
  router,
  params: { locationId },
  bookingItem,
  route,
}) => {
  const isMiniApp = router && router.location.pathname.startsWith('/loc/');

  useEffect(() => {
    return () => {
      managedObjects.actions.invalidate();
    };
  }, []);

  return (
    <Page
      title={t('bookings.pages.new_sharing.title')}
      backPath={isMiniApp ? `/loc/${locationId}` : `/?location=${locationId}`}
    >
      <BookingItemForm
        bookingItem={bookingItem.data}
        action={BookingItemAction.SHARE}
        list={route.list}
        router={router}
        route={route}
      />
    </Page>
  );
});
